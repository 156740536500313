











import { mapState } from 'vuex';
import { Modal } from 'ant-design-vue';
import { Click } from '@/components/Auth';
import { Component, Inject, Vue } from 'vue-property-decorator';
import PRIVILEGE from '@/common/privileges';

interface Actions {
    selectedRows: any;
}

@Component<Actions>({
    components: {
        Click
    },
    computed: {
        ...mapState({
            loading() {
                return this.state().loading;
            },
            selectedRows: (state: any) =>
                state.unionCouponSearchPage.selectedRows
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;

    public data() {
        return {
            PRIVILEGE
        };
    }

    public handleCreateSuccess() {
        this.dispatch('search');
    }

    public handleRefresh() {
        this.dispatch('search');
    }
    public stopActivities() {
        Modal.confirm({
            title: '提示',
            content: '终止会导致活动未发放就停止！终止后不可恢复！',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRows.join(','),
                    status: '2'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    
    public handleAddAdPlan() {
        this.$router.push('/console/activity/createUnionCoupon');
    }
}

export default Actions;
