










































































































import {Component, Inject, Watch,Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import moment from 'moment';
import {deleteEmpty} from '@/utils';
import qs from 'querystringify';
import {DatePicker} from '@/components/custom-form-components';

interface SearchForm {
    form: any;
}
interface QueryParams {
    status: string;
    name: string;
    platActivity: string;
    queryTimeFlag: string;
    queryStartTime: string;
    queryEndTime: string;
    activityStartTime: string;
    activityEndTime: number;
    createdBy: string;
    sponsorName: string;
}
@Component({
    components: {
        DatePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.activitiesDraftSearchPage.query);
            },
            loading: (state: any) => state.activitiesDraftSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public dispatch!: any;
     public currentPaytype!: String;
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }
    public created() {
        this.currentPaytype = this.$store.state.activityManage.payType
        
    }
    @Watch('$store.state.activityManage.payType') 
    public payTypeChange(newVal: String, oldVal: String) {
        
        this.currentPaytype = this.$store.state.activityManage.payType
    }
    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {

            if (error) {
                return this.$message.error('请填写正确信息');
            }

            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            // this.$router.push({
            //     path: `/console/account/user/search?${qs.stringify(query)}`
            // });
            this.dispatch('initQuery', query);
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any) {
       let queryTimeFlag = '';
        if(values.queryTime && values.queryTime[0]){
            queryTimeFlag ='2';
        }
        return {
            payType: this.$store.state.activityManage.payType,
            status: '0',//运营中
            name: values.name,
            platActivity: values.platActivity,
            activityType: values.activityType,
            queryTimeFlag: queryTimeFlag,//激活时间
            queryStartTime: 
                values.queryTime &&
                values.queryTime[0] &&
                values.queryTime[0].format('YYYY-MM-DD HH:mm:ss'),
            queryEndTime:
                values.queryTime &&
                values.queryTime[1] &&
                values.queryTime[1].format('YYYY-MM-DD HH:mm:ss'),
            activityStartTime: 
                values.activityTime &&
                values.activityTime[0] &&
                values.activityTime[0].format('YYYY-MM-DD HH:mm:ss'),
            activityEndTime:
                values.activityTime &&
                values.activityTime[1] &&
                values.activityTime[1].format('YYYY-MM-DD HH:mm:ss'),
            createdBy: values.createdBy,
            sponsorName: values.sponsorName
        };
    }

    private formatFormData(query: any) {

        return {
            ...query,
            queryTime: [
                query.queryStartTime && moment(query.queryStartTime),
                query.queryEndTime && moment(query.queryEndTime)
            ],
            activityTime: [
                query.activityStartTime && moment(query.activityStartTime),
                query.activityEndTime && moment(query.activityEndTime)
            ]
        };
    }
}

export default SearchForm;
