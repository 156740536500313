var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SearchCommonLayout", {
        attrs: { humanReadableQuery: _vm.humanReadableQuery },
        scopedSlots: _vm._u([
          {
            key: "search-form",
            fn: function() {
              return [_c("SearchForm", { ref: "searchForm" })]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c("Actions", {
                  on: {
                    dateChange: function($event) {
                      return _vm.$refs.searchForm.handleDateChange($event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "table",
            fn: function() {
              return [_c("SearchTable")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }