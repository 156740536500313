var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "UploadDrawer",
        [
          _c(
            "template",
            { slot: "trigger" },
            [_c("a-button", [_vm._v("上传白名单")])],
            1
          )
        ],
        2
      ),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.Q_WHITELIST.UPDATE_STATUS] },
        on: { pass: _vm.handleOpenClick },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRows.length === 0 || _vm.loading
                    }
                  },
                  [_vm._v("跳转ON\n            ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.Q_WHITELIST.UPDATE_STATUS] },
        on: { pass: _vm.handleCloseClick },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRows.length === 0 || _vm.loading
                    }
                  },
                  [_vm._v("跳转OFF\n            ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.Q_WHITELIST.READY_UPDATE] },
        on: { pass: _vm.handleOpenStatus },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRows.length === 0 || _vm.loading
                    }
                  },
                  [_vm._v("报备状态ON\n            ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.Q_WHITELIST.EXPORT_MERCHANT_EXCEL] },
        on: { pass: _vm.handleDownload },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(slotProps) {
              return [
                _c("a-button", { attrs: { disabled: _vm.downloading } }, [
                  _vm._v("导出Excel表\n            ")
                ])
              ]
            }
          }
        ])
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }