var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "span",
        { on: { click: _vm.handleTriggerClick } },
        [_vm._t("trigger", [_c("a", [_vm._v("点击")])])],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "分配商户号" },
          on: { ok: _vm.okHandler, cancel: _vm.cancelHandler },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("a-textarea", {
            attrs: { placeholder: "请输入商户号和线下终端号", rows: 10 },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          }),
          _c("p", { staticStyle: { "margin-top": "5px" } }, [
            _vm._v(
              "备注：商户号和线下终端号请使用半角逗号分隔，每条请使用半角分号分隔"
            )
          ]),
          _vm.error
            ? _c(
                "div",
                [
                  _c("a-divider", { staticStyle: { margin: "10px 0px" } }),
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }