

















import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
interface RefuseDetailForm {
  form: any;
  formData: any;
}
@Component<RefuseDetailForm>({
    components: {
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return ( this.formatFormData());
            },
        }),
    },
})

class RefuseDetailForm extends Vue {
    @Prop()
    public value!: any;
    public form;
   
   public data() {
      return {
          form: this.$form.createForm(this),
      };
  }
  private formatFormData() {
        return {
            auditOpinion: sessionStorage.getItem('auditOpinion')
        };
    }
    
}
export default RefuseDetailForm;
