



























import { Component, Inject, Vue } from 'vue-property-decorator';
import * as services from './services';
import Role from '@/components/custom-form-components/Role.vue';
import { Click } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

interface CreateModal {
    form: any;
}

@Component({
    components: {
        Role,
        Click
    }
})
class CreateModal extends Vue {

    public visible = false;

    public loading = false;

    public PRIVILEGE = PRIVILEGE;

    //@Inject()
    //public dispatch!: any;

    public data() {
        return {
            form: this.$form.createForm(this)
        };
    }

    public handleClose() {
        if (this.loading) {
            return;
        }
        this.visible = false;
    }

    public onClick() {
        this.visible = true;
    }

    public handleCreate(e: any) {
        e.preventDefault();
        this.form.validateFields(async (error: any, values: any) => {
            if (!error) {
                const data = values.data.replace(/\s+/g, '');
                const aus: string[][] = Array.from(new Set(data.split(/[;]/)
                    .filter(Boolean))).map(item => (item as string).split(/[,，]/));
                const errors = this.checkAus(aus); 
                if (errors.length > 0) {
                    const errMsg = '无效输入: ' + errors.join(',');
                    this.$message.error(errMsg);
                    return;
                }
                this.$emit('change', aus);
                this.visible = false;
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    private checkAus(aus: string[][]): string[] {
        const errors = aus.reduce((memo: string[], item: string[]) => {
            if (item.length != 2) {
                return memo.concat(item.join(','));
            }
            if (!/^[A-Za-z\d]{15}$/.test(item[0]) || !/^[A-Za-z\d]{8}$/.test(item[1])) {
                return memo.concat(item.join(','));
            } 
            return memo;
        }, []);
        return errors;
    }
}
export default CreateModal;
