










































import {Component,Prop, Inject,Vue,Provide, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import {Click} from '@/components/Auth';
import * as services from '../../inspection/SearchPage/services';
interface index {
  form: any;
}
    @Component({
        components: {
            Show,
            Click,
        }
    })
    export default class index extends Vue {
        public PRIVILEGE = PRIVILEGE;
        @Prop()
        public title!: string | number;
        @Prop()
        public applyId !: string;
        @Prop()
        public paytype !: string;
        public visible = false;
        public form;
        @Prop()
        public value!: any;
        public auditOpinion:any;
        @Inject()
        public dispatch!: any;
        public created() {
        }

        public data() {
            return {
                auditOpinion: '您好，活动剩余名额不足，请持续关注其他活动！',
                form: this.$form.createForm(this),
            };
        }
        public handleClose() {
            this.visible = false;
        }

        public async handleOK() {
            
            let text;
            this.form.validateFields((error: any, values: any) => {
                text = values.auditOpinion;
            });
            if(!text || text.length>200){
                this.$message.error('审核意见长度不能为空且不超过200');
                return;
            }
            try {
                await services.updateStatus({
                    applyId: this.applyId,
                    status:'REFUSE',
                    auditOpinion:text
                });
                this.dispatch('search');
            } catch (e) {
                this.$message.error(e.message);
            }
            this.visible = false;
        }

        public onClick() {
            this.visible = true;
        }
        public typeChange(value: any, options: any) {
            let text = '';
            if (value === '1') {
                text= '您好，活动剩余名额不足，请持续关注其他活动！';
            }else if (value === '2') {
                text= '您好，您的店铺不符合活动方的要求，请持续关注其他活动！' ;
            }else if (value === '3') {
                text= '您好，您店铺的终端不符合要求，请持续关注其他活动！' ;
            }else if(value === '4') {
                text= '您好，本活动已结束，请持续关注其他活动！' ;
            }else{
                text= '其他原因' ;
            }
            this.form.setFieldsValue({ auditOpinion: text });
            this.auditOpinion = text;
        }
    }
