





















import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
import SearchForm from './SearchForm.vue';
import Actions from './Actions.vue';
import SearchTable from './SearchTable.vue';
import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import './store';
// import columns from './columns';
import {queryToHumanReadable} from '@/utils';

const humanReadableQuerySchema = {
   
};

@Component({
    components: {
        Actions,
        SearchForm,
        SearchTable,
        SearchCommonLayout,
        Show
    },
    computed: {
        ...mapState({
            
        })
    }
})
export default class QWhitelistPage extends Vue {
    public storeName = 'activityMerSearchPage';
    public PRIVILEGE = PRIVILEGE;
    public humanReadableQuery = null;
   

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(
            this.storeName + '/' + action,
            payload
        );
    }

    public created() {
        this.$store.dispatch('activityMerSearchPage/initQuery', {
            ...this.$route.query
        });
    }

    @Watch('$store.state.activityMerSearchPage.updateStatusError')
    public updateStatusErrorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    @Watch('$store.state.activityMerSearchPage.query')
    public watchHumanReadableQuery(query: any) {
        console.log('humanReadableQuery:', query);
        this.humanReadableQuery = queryToHumanReadable(query, humanReadableQuerySchema);
    }

    // 路由驱动页面查询
    @Watch('$route')
    public routeListener(route: any) {
        console.log('route:', route);
        this.dispatch('initQuery', this.$route.query);
    }

    // private handleFieldChange(e: any) {
    //     this.commit('setFields', e);
    // }
}
