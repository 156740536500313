var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      size: "small",
      rowKey: _vm.rowKey,
      columns: _vm.columnsWithIdx || [],
      dataSource: _vm.dataSourceWithIdx || [],
      scroll: _vm.scroll,
      bordered: "",
      locale: {
        emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
      },
      pagination: {
        total: _vm.total || 0,
        pageSize: _vm.pageSize,
        current: _vm.page || 1,
        showSizeChanger: true,
        pageSizeOptions: ["20", "50", "100"],
        showQuickJumper: true,
        showTotal: function(total) {
          return "总计 " + total + " 条记录"
        },
        onChange: _vm.handlePageChange,
        onShowSizeChange: _vm.handlePageSizeChange
      }
    },
    scopedSlots: _vm._u(
      [
        _vm._l(_vm.clearTexts, function(slot) {
          return {
            key: slot.key,
            fn: function(text, record) {
              return _c(
                "span",
                { key: slot.key },
                [
                  _c("ClearText", {
                    attrs: {
                      text: text,
                      action: slot.action,
                      getClearText: slot.getClearText,
                      params: slot.getParams(record)
                    }
                  })
                ],
                1
              )
            }
          }
        }),
        {
          key: "remarks",
          fn: function(text) {
            return _c(
              "span",
              {},
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v(
                        "\n                " + _vm._s(text) + "\n            "
                      )
                    ]),
                    _vm._v(
                      "\n            " +
                        _vm._s(text && text.substring(0, 10) + "...") +
                        "\n        "
                    )
                  ],
                  2
                )
              ],
              1
            )
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }