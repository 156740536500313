





































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {SignUpActivityStatus, SignUpActivityType} from '@/components/custom-form-components';
import UploadImage from '@/components/custom-form-components/UploadImage.vue';
import ImageCrop from '@/components/custom-form-components/ImageCrop/index.vue';
import VueUeditorWrap from 'vue-ueditor-wrap';
import config from '@/config';
interface DetailForm {
    form: any;
}

@Component<DetailForm>({
    components: {
        UploadImage,
        ImageCrop,
        SignUpActivityStatus,
        SignUpActivityType,
        VueUeditorWrap
    },
    computed: {
       ...mapState({
                formData(state: any) {
                    return state.activitySignUpPage.data && this.formatFormData(state.activitySignUpPage.data);
                }
            }),
        editorProps() {
                return {
                    key: 'key_' + DetailForm.inc++,
                    config: {
                        serverUrl: config.BASE_URL + '/console/ad/ueditor',
                        autoHeightEnabled: false,
                        initialFrameHeight:
                            this.bodyClientHeight -
                            this.toolBarHeight -
                            this.bottomHeight -
                            5,
                        initialFrameWidth: '100%',
                        disabled:true,
                        UEDITOR_HOME_URL: process.env.BASE_URL + 'UEditor/',
                        imagePopup: false,
                        toolbars: [
                            [
                                'source',
                                '|',
                                'undo',
                                'redo',
                                '|',
                                'bold',
                                'italic',
                                'underline',
                                'fontborder',
                                'strikethrough',
                                'superscript',
                                'subscript',
                                'removeformat',
                                'formatmatch',
                                'autotypeset',
                                'blockquote',
                                'pasteplain',
                                '|',
                                'forecolor',
                                'backcolor',
                                'insertorderedlist',
                                'insertunorderedlist',
                                'selectall',
                                '|',
                                'rowspacingtop',
                                'rowspacingbottom',
                                'lineheight',
                                '|',
                                'paragraph',
                                'fontfamily',
                                'fontsize',
                                '|',
                                'indent',
                                '|',
                                'justifyleft',
                                'justifycenter',
                                'justifyright',
                                'justifyjustify',
                                '|',
                                'touppercase',
                                'tolowercase',
                                '|',
                                'link',
                                'unlink',
                                '|',
                                'imagenone',
                                'imageleft',
                                'imageright',
                                'imagecenter',
                                '|',
                                'simpleupload',
                                'emotion',
                                'insertvideo',
                                'map',
                                'pagebreak',
                                '|',
                                'horizontal',
                                'spechars',
                                '|',
                                'inserttable',
                                'deletetable',
                                'insertparagraphbeforetable',
                                'insertrow',
                                'deleterow',
                                'insertcol',
                                'deletecol',
                                'mergecells',
                                'mergeright',
                                'mergedown',
                                'splittocells',
                                'splittorows',
                                'splittocols',
                                '|',
                                'print',
                                'searchreplace'
                            ]
                        ]
                    }
                };
            }
    }
})
class DetailForm extends Vue {
    public imageFile: any = null;
    public remark='';
    public created() {
    }

    public data() {
        return {
         form: this.$form.createForm(this),
        };
    }


    private formatFormData(rawData: any) {
        if (rawData && rawData.activityImage) {
            this.imageFile = [{
                uid: -1,
                url: rawData.activityImage,
                status: 'done',
                thumbUrl: rawData.activityImage,
                name: 'image',
                lastModified: Date.now(),
                lastModifiedDate: new Date(),
                size: 0,
                type: 'image/jpeg',
                response: null
            }];
        }
        this.remark=rawData.remark;
        return {
            ...rawData
        }
    }

}

export default DetailForm;
