var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.USER.GROUP] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [_vm._t("trigger")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            title: "角色变更",
            visible: _vm.visible,
            confirmLoading: _vm.loading
          },
          on: { cancel: _vm.handleClose, ok: _vm.handleOk }
        },
        [
          _c("a-input", {
            staticStyle: { "margin-bottom": "30px" },
            attrs: { disabled: true, value: _vm.userName }
          }),
          _c("Role", {
            attrs: { value: _vm.value === null ? undefined : _vm.value },
            on: { change: _vm.handleChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }