























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AdAssignMerchants from './AdAssignMerchants.vue';
import AdAssignUpload from './AdAssignUpload.vue';
import {mapState} from 'vuex';
import BizDistrictBulkSelector from './BulkSelector/BizDistrictBulkSelector.vue';
import MallBulkSelector from './BulkSelector/MallBulkSelector.vue';
@Component<AdAssign>({
    components: {
        AdAssignMerchants,
        AdAssignUpload,
        BizDistrictBulkSelector,
        MallBulkSelector
    },
    computed: {
        aus() {
            return this.value && this.value.aus || [];
        },
        malls() {
            return this.selectedMalls;
        },
        bizDistricts() {
            return this.selectedBizDistricts;
        },
        uploadAus() {
            return this.value && this.value.upload_aus || [];
        }
    }
})
class AdAssign extends Vue {

    @Prop()
    public value!: any;

    @Prop()
    public disabled!: any;

    public selectedMalls: any = null;

    public selectedBizDistricts: any = null;

    public mallsSelectorVisible = false;

    public bizDistrictsSelectorVisible = false;

    public created() {
        this.init();
    }

    public init() {
        this.selectedMalls = this.value && this.value.malls || [];
        this.selectedBizDistricts = this.value && this.value.biz_districts || [];
    }

    public handleOk(key: string, value: any) {
        if (key === 'malls') {
            this.mallsSelectorVisible = false;
        } else  if (key === 'biz_districts') {
            this.bizDistrictsSelectorVisible = false;
        }

        this.change(key, value);
    }

    public handleCancel(key: string) {
        if (key === 'malls') {
            this.selectedMalls = this.value && this.value.malls || [];
        } else if (key === 'biz_districts') {
            this.selectedBizDistricts = this.value && this.value.biz_districts || [];
        }
    }

    public change(key: string, value: any) {
        let newValue;
        if (value.length === 0) {
            newValue = {
                ...this.value
            };
            delete newValue[key];
        } else {
            newValue = {
                ...this.value,
                [key]: value
            };
        }

        this.$emit('change', newValue);
    }
}
export default AdAssign;
