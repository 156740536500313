var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "actions-wrapper" },
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.MARKETINGDATA.HAMI_EXCEL] },
        on: { pass: _vm.handleDownload },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(slotProps) {
              return [
                _c(
                  "a-button",
                  { attrs: { disabled: _vm.loading || _vm.downloading } },
                  [_vm._v("导出Excel数据")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "a-button",
        {
          attrs: { disabled: _vm.loading },
          on: {
            click: function($event) {
              return _vm.handleDateClick("last7")
            }
          }
        },
        [_vm._v("最近7天")]
      ),
      _c(
        "a-button",
        {
          attrs: { disabled: _vm.loading },
          on: {
            click: function($event) {
              return _vm.handleDateClick("last30")
            }
          }
        },
        [_vm._v("最近30天")]
      ),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }