













































































































































































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import * as validators from '../../validators';
import {
    DatePicker,
    DisableDatePicker,
    ScheduleTimePicker,
    IrregularScheduleTimePicker
} from '@/components/custom-form-components';
interface DetailForm {
    baseForm: any;
    merchantForm: any;
}

@Component<DetailForm>({
    components: {
        DisableDatePicker,
        ScheduleTimePicker,
        IrregularScheduleTimePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return (
                    state.zhongxinSearchPage.data &&
                    this.formatFormData(state.zhongxinSearchPage.data)
                );
            },
            spinning(state) {
                return(
                    state.zhongxinSearchPage.loading
                )
            }
        })
    }
})
class DetailForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    public validators = validators;
    //页面tab
    public baseForm;
    public merchantForm;
    public activeTab: any;

    public baseData: any;
    public shopData: any;
    public activitySponsors: any;
    // public promoType: any;

    public data() {
        return {
            activeTab: '1',
            // promoType: '0',
            baseData: {},
            //第一页数据
            baseForm: this.$form.createForm(this),
            activitySponsors: [],
            //第二页数据
            merchantForm: this.$form.createForm(this),
            shopData: [],
            hasTermNocolumns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo'
                },
                {
                    title: '终端号',
                    dataIndex: 'termNo'
                },
            ],
            noTermNocolumns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo'
                },
            ]
        };
    }
    public created() {}

    // public initTimeRuleData(data:any){
    //     this.timeRuleData = data;
    // }

    // public handleTimeRuleHide(){

    //     this.timeRulevisible = false;
    // }

    // public initNoTimeRuleData(data:any){
    //     this.noTimeRuleData = data;
    // }

    // public handleNoTimeRuleHide(){
    //     console.log(this.noTimeRuleData)

    //     this.noTimeRulevisible = false;
    // }

    public tabChange(key: any) {
        this.activeTab = key;
    }

    // public handleNext(e: any) {
    //    this.activeTab = "2";
    // }

    // public handleCreate(e: any) {
    //     e.preventDefault();
    //     const queryString = this.$store.getters[
    //         'activitiesDraftSearchPage/queryString'
    //     ]();
    //     this.$router.push({
    //         path: '/console/activity/open/search' + queryString
    //     });
    // }

    // public async handleCancel() {
    //     const queryString = this.$store.getters[
    //         'activitiesDraftSearchPage/queryString'
    //     ]();
    //     this.$router.push({
    //         path: '/console/activity/open/search' + queryString
    //     });
    // }
    private formatFormData(rawData: any) {
        console.info('detail rawdata ', rawData);
        // this.promoType = rawData.promoType;
        return {
            ...rawData,
        };
    }
    // public handleBack() {
    //     this.activeTab = "1";
    // }
}

export default DetailForm;
