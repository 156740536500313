



































































































































































































































































































































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {DisableDatePicker, ScheduleTimePicker, IrregularScheduleTimePicker} from '@/components/custom-form-components';
import CreateModal from './CreateModal.vue';
import ShopUpload from './ShopUpload.vue';
import moment from 'moment';
import * as validators from '../../validators';

interface EditActForm {
    baseForm: any;
    otherForm: any;
}

@Component<EditActForm>({
    components: {
        DisableDatePicker,
        CreateModal,
        ShopUpload,
        ScheduleTimePicker,
        IrregularScheduleTimePicker
    },
    computed: {
        ...mapState({
            
        })
    }
})
class EditActForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;

    public baseForm;

    public otherForm;
    public formData :any;
    public baseData:any;
    public activeTab:any;
    public timeRuleData:any;
    public timeRulevisible:boolean = false;
    public noTimeRuleData:any;
    public noTimeRulevisible:boolean = false;
    public shopData:any;
    public bankData:any;
    public activitySponsors:any;
    public budgetLimitCheck:any;
    public paywayCheck:any;
    public discountRuleCheck:any;

    public isDefault: boolean = false;

    public conflictReplaceList: any;

    public merchantTotal = 0;

    public validators = validators;
    public timeArrayTemp = [];
    public dateArrayTemp:any = [];
    public activityId:any;
    public formateData="false";
    public activityDateType='0';
    public orgId='lakala';
    public mounted() {
        setTimeout(() => {
        console.log(this.$store.state.activityEditPage);
        let _data = this.$store.state.activityEditPage.data
        console.log(_data);
        this.formData = this.formatFormData(_data);
        console.log(this.formData);
        }, 300)
    }

    public data() {
        return {
          baseData: {},
          baseForm: this.$form.createForm(this),
          otherForm: this.$form.createForm(this),
          formData:{},
          activeTab: "1",
          formateData:"false",
          timeRuleData: {},
          timeRulevisible: false,
          noTimeRuleData: {},
          noTimeRulevisible: false,
          radioStyle: {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginLeft: '120px',
          },
          budgetLimit: "2",
          budgetInput: false,
          discountRuleCheck: "1",
          budgetLimitCheck: "2",
          paywayCheck: "0",
          shopData: [],
          bankData:[],
          activitySponsors:[],
          timeArrayTemp : [],
          ateArrayTemp:[],
          columns: [
            {
                title: '商户号',
                dataIndex: 'merNo',
            },
            {
                title: '终端号',
                dataIndex: 'termNo',
            },
            {
                title: '操作',
                dataIndex: 'index',
                scopedSlots: {
                    customRender: 'action'
                }
            }
          ]
        };
    }

    public discountRuleChange(e:any) {
        this.discountRuleCheck = e.target.value;
    }

    public timeRuleChange(e:any){
        this.activityDateType = e.target.value;
        if(e.target.value === '1'){
            //.timeRuleData = {};
            this.timeRulevisible = true;
        }else if(e.target.value === '2'){
            //.noTimeRuleData = {};
            this.noTimeRulevisible = true;
        }
    }

    public initTimeRuleData(data:any){
        this.timeRuleData = data;
    }

    public handleTimeRuleHide(){
        console.log(this.timeRuleData.days);
        if(!this.timeRuleData.days || this.timeRuleData.days.length === 0){
            this.$message.error('星期不能为空');
            return
        }
        if(!this.timeRuleData.timerange || this.timeRuleData.timerange.length === 0 || this.timeRuleData.timerange.length % 2 !== 0){
            this.$message.error('时间区间不能为空');
            return
        }
        for (var rule of this.timeRuleData.timerange) {
            if(rule === null){
                this.$message.error('时间区间不能为空');
                return
            }
        }
        this.timeArrayTemp = [];
        for(var i=1;i<=this.timeRuleData.timerange.length / 2;i++){
            const startTime = this.timeRuleData.timerange[(i-1)*2].format('HH:mm');
            const endTime = this.timeRuleData.timerange[i * 2 - 1].format('HH:mm');
            // @ts-ignore
            this.timeArrayTemp.push(startTime + '-' + endTime);
        }
        this.timeRulevisible = false;
    }

    public handleTimeRuleHideCancel(){

        this.timeRulevisible = false;
    }

    public initNoTimeRuleData(data:any){
        this.noTimeRuleData = data;
    }

    public handleNoTimeRuleHide(){
        if(!this.noTimeRuleData.daterange || this.noTimeRuleData.daterange.length === 0){
            this.$message.error('日期区间不能为空');
            return
        }
        for (var drule of this.noTimeRuleData.daterange) {
            if(drule === null){
                this.$message.error('日期区间不能为空');
                return
            }
        }
        if(!this.noTimeRuleData.timerange || this.noTimeRuleData.timerange.length === 0 || this.noTimeRuleData.timerange.length % 2 !== 0){
            this.$message.error('时间区间不能为空');
            return
        }
        for (var rule of this.noTimeRuleData.timerange) {
            if(rule === null){
                this.$message.error('时间区间不能为空');
                return
            }
        }
        this.timeArrayTemp = [];
        this.dateArrayTemp = [];
        for(var i=1;i<=this.noTimeRuleData.timerange.length / 2;i++){
            const startTime = this.noTimeRuleData.timerange[(i-1)*2].format('HH:mm');
            const endTime = this.noTimeRuleData.timerange[i * 2 - 1].format('HH:mm');
            // @ts-ignore
            this.timeArrayTemp.push(startTime + '-' + endTime);
        }

        this.noTimeRuleData.daterange.forEach((date:any)=> {
            if(date){
                const startDate = date[0].format('YYYYMMDD');
                const endDate = date[1].format('YYYYMMDD');
                this.dateArrayTemp.push(startDate + '-' + endDate);
            }
        })
        this.noTimeRulevisible = false;
    }
    public handleNoTimeRuleHideCancel(){

        this.noTimeRulevisible = false;
    }
    public tabChange(key:any) {
        if(key === "2"){
            this.baseForm.validateFields((error: any, values: any) => {
                if(!error){
                    const reduceAmount = parseFloat(values.reduceAmount);
                    const useSill = parseFloat(values.useSill);
                    if(+reduceAmount > +useSill){
                        this.$message.error('减价金额必须小于使用门槛金额');
                        return
                    }
                    if(values.warnRatio > 100){
                        this.$message.error('预警阈值不能大于100%');
                        return
                    }
                    if(values.timeRule == '1'){
                        if(this.timeRuleData.days == undefined || this.timeRuleData.days.length === 0){
                            this.$message.error('规则日期天数不能为空错误');
                            return
                        }
                        if(this.timeRuleData.timerange == undefined || this.timeRuleData.timerange.length % 2 !== 0){
                            this.$message.error('规则日期时间范围错误');
                            return
                        }
                        for (var rule of this.timeRuleData.timerange) {
                            if(rule == null){
                                this.$message.error('规则日期时间不能为空');
                                return
                            }
                        }
                    }else if(values.timeRule == '2'){

                    }
                    this.formatBaseForm(values);
                    this.activeTab = "2";
                }else {
                    this.$message.error('请填写正确信息');
                }
            })
        }else{
            this.activeTab = key;
        }
    }

    public budgetLimitChange(value:any, options:any) {
        this.budgetLimitCheck = value;
        if(value === '2'){
            this.otherForm.setFieldsValue({budgetLimitInput: ''})
        }
    }

    public paywayChange(e:any) {
        if(e.target.value === '1'){
            this.paywayCheck = '1';
        }else{
            this.paywayCheck = '0';
            // this.otherForm.setFieldsValue({bankName: ''});
            // this.otherForm.setFieldsValue({cardType: ''});
            // this.otherForm.setFieldsValue({cardBin: ''});
        }
    }

    public handleNext(e: any) {
        e.preventDefault();
        this.baseForm.validateFields((error: any, values: any) => {
            console.log(values);
            if (!error) {
                const reduceAmount = parseFloat(values.reduceAmount);
                const useSill = parseFloat(values.useSill);
                if(+reduceAmount > +useSill){
                    this.$message.error('减价金额必须小于使用门槛金额');
                    return
                }
                if(values.warnRatio > 100){
                     this.$message.error('预警阈值不能大于100%');
                    return
                }
                if(values.timeRule === '1'){
                    if(this.timeRuleData.days == undefined || this.timeRuleData.days.length === 0){
                        this.$message.error('规则日期天数不能为空错误');
                        return
                    }
                    if(this.timeRuleData.timerange == undefined || this.timeRuleData.timerange.length % 2 !== 0){
                        this.$message.error('规则日期时间范围错误');
                        return
                    }
                    for (var rule of this.timeRuleData.timerange) {
                        if(rule === null){
                            this.$message.error('规则日期时间不能为空');
                            return
                        }
                    }
                }else if(values.timeRule === '2'){

                }
                this.formatBaseForm(values);
                this.activeTab = "2";
            } else {
                this.$message.error('请填写正确信息');
            }
        })
    }

    public changeShopData(value: any){
        if(this.shopData.length === 3000){
            this.$message.error('店铺最多3000家上限');
            return;
        }
        let shopIndex = 1;
        value.forEach((data:any)=> {
            let flag = false;
            for(let shop of this.shopData){
                if(shop.merNo === data[0] && shop.termNo === data[1]){
                    flag = true;
                    break;
                }
            }
            if(this.shopData.length){
                shopIndex = this.shopData[this.shopData.length - 1].index + 1;
            }

            if(!flag){
                const objData = {
                index: shopIndex,
                merNo: data[0],
                termNo: data[1]
            }
            this.shopData.push(objData);
            }
        })
    }

    public deleteShopData(record: any){
        this.shopData = (this.shopData as object[]).filter((data: any) => data.index !== record.index);
    }

    public handleCreate(e: any) {
        e.preventDefault();
        this.otherForm.validateFields((error: any, values: any) => {
            if (!error) {
                console.log('budgetAmount',values.budget);
                console.log('dailyAmountLimit',values.budgetLimitInput);
                console.log(null != values.budgetLimitInput && '' != values.budgetLimitInput);
                if(null != values.budgetLimitInput && '' != values.budgetLimitInput && parseFloat(values.budgetLimitInput) > parseFloat(values.budget)){
                    this.$message.error('当天消耗金额上限必须小于活动预算金额');
                    return
                }
                if(null === this.shopData || this.shopData.length < 1){
                    this.$message.error('请添加活动商户');
                    return
                }
                this.handleSubmitConfirm(values);
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public handleSubmitConfirm(values: any) {
        Modal.confirm({
            title: '提示',
            content: '是否确认提交?',
            onOk: () => {
                let baseData = this.baseData;
                console.log(this.baseData);
                if(null === baseData){
                    baseData = this.formatBaseForm(values);
                }
                const otherData = this.formatOtherForm(values);
                const allData = { ...baseData, ...otherData };
                console.log(allData)
                this.$store.dispatch('activityCreatePage/editAct', allData);
                
            }
        });
        
        
    }
    @Watch('$store.state.activityCreatePage.error')
    public errorMsg(newVal: string, oldVal: string) {
        let errorMsg =this.$store.state.activityCreatePage.error.message;
        if (errorMsg) {
            this.$message.error(errorMsg);
        }
    }
    @Watch('$store.state.activityCreatePage.editSuccess')
    public editSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$message.success('提交成功');
            this.$emit('onCancel');
        }
    }
    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                this.$emit('onCancel');
            }
        });
    }

    public handleBack() {
        this.activeTab = "1";
    }

    private formatBaseForm(baseData: any) {
        let timeArray = [];
        let dateArray:any = [];
        if(baseData.timeRule === '1'){
            for(var i=1;i<=this.timeRuleData.timerange.length / 2;i++){
                const startTime = this.timeRuleData.timerange[(i-1)*2].format('HH:mm');
                const endTime = this.timeRuleData.timerange[i * 2 - 1].format('HH:mm');
                timeArray.push(startTime + '-' + endTime);
            }
        }else if(baseData.timeRule === '2'){
            console.log(this.noTimeRuleData.daterange);
            for(var i=1;i<=this.noTimeRuleData.timerange.length / 2;i++){
                const startTime = this.noTimeRuleData.timerange[(i-1)*2].format('HH:mm');
                const endTime = this.noTimeRuleData.timerange[i * 2 - 1].format('HH:mm');
                timeArray.push(startTime + '-' + endTime);
            }

            this.noTimeRuleData.daterange.forEach((date:any)=> {
                if(date){
                    const startDate = date[0].format('YYYYMMDD');
                    const endDate = date[1].format('YYYYMMDD');
                    dateArray.push(startDate + '-' + endDate);
                }
            })
        }

        const sponsor ={
                sponsorName: baseData.capital,
                warnRatio: baseData.warnRatio,
                warnMobile: baseData.warnMobile
            }
        this.activitySponsors.push(sponsor);
        this.baseData = {
            activityName: baseData.name,
            gwActivityId: baseData.gwActivityId,
            orgId:this.orgId,
            activityType: baseData.discountWay+'',
            platActivity: baseData.batchNo,
            goodsTag: baseData.discountFlag,

            discountAmount: (baseData.reduceAmount * 100).toFixed(0),
            payLine: (baseData.useSill*100).toFixed(0),
            startTime: baseData.range_valiable_time[0].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
            endTime:  baseData.range_valiable_time[1].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
            availableTime: {
                type: baseData.timeRule,
                weekDays: this.timeRuleData.days,
                datePeriods: dateArray,
                timePeriods: timeArray
            },
            activitySponsors: this.activitySponsors,
        }
    }

    private formatOtherForm(otherFromData: any) {
        let dailyAmountLimit = '';
        if(null != otherFromData.budgetLimitInput && '' != otherFromData.budgetLimitInput){
            dailyAmountLimit =(otherFromData.budgetLimitInput * 100 ).toFixed(0);
        }
        if(this.paywayCheck === '1'){
            const objData = {
                bankName: otherFromData.bankName,
                cardType: otherFromData.cardType,
                cardBin: otherFromData.cardBin
            }
            this.bankData.push(objData);
        }
        
        const otherData = {
            budgetAmount:(otherFromData.budget * 100).toFixed(0),
            allUser: otherFromData.allUser,
            dailyAmountLimit: dailyAmountLimit,
            userLimit: {
                countLimit: otherFromData.timesLimit,
                dailyCountLimit: otherFromData.discountRuleInput
            },
            merchantList: this.shopData,
            payType:otherFromData.payway,
            bankCardList:  this.bankData,
            prompt :  otherFromData.remark,
            activityId:this.activityId
        }
        return otherData;
    }

    private formatFormData(rawData: any) {
        console.log(rawData);
         if(rawData.userLimit != null && rawData.userLimit.dailyCountLimit != null){
             this.discountRuleCheck = '2'
         }
         let budgetLimit = '2'
         if(null != rawData.dailyAmountLimit && '' != rawData.dailyAmountLimit){
             budgetLimit = '1';
             this.budgetLimitCheck = '1';
         }
        this.shopData= rawData.merchantList;
        let bankName ='-';
        if(null != rawData.bankCardList[0] && null!=rawData.bankCardList[0].bankName){
            bankName = rawData.bankCardList[0].bankName;
            this.paywayCheck='1'
        }
        let cardBin ='-';
        if(null != rawData.bankCardList[0] && null!=rawData.bankCardList[0].cardBin){
            cardBin = rawData.bankCardList[0].cardBin;
        }
        let cardType ='-';
        if(null != rawData.bankCardList[0] && null!=rawData.bankCardList[0].cardType){
            cardType = rawData.bankCardList[0].cardType;
        }
        //
        let weekDays:any =[];
        if(null != rawData.availableTime.weekDays && rawData.availableTime.weekDays.length>0){

           rawData.availableTime.weekDays.forEach((data:string)=> {
                weekDays.push(data+'');
           })
        }
         this.shopData= rawData.merchantList.map((item:any, index:number)=> ({
                index,
                ...item,
            }));
        if(rawData.orgId){
            this.orgId = rawData.orgId;
        }
        this.activityId = rawData.activityId;
        this.timeRuleData.days = weekDays;
        this.timeArrayTemp = rawData.availableTime.timePeriods;
        this.dateArrayTemp = rawData.availableTime.datePeriods;
        let timerange:any = [];
        if(this.timeArrayTemp != null && this.timeArrayTemp.length>0){
            this.timeArrayTemp.forEach((data:string)=> {
                timerange.push(moment(data.split('-')[0],'HH:mm'));
                timerange.push(moment(data.split('-')[1],'HH:mm'));
            })
        }
        this.activityDateType = rawData.availableTime.type;
        if('1' === rawData.availableTime.type){
           this.timeRuleData['timerange'] = timerange;
        }else if('2' === rawData.availableTime.type){
            this.noTimeRuleData['timerange'] = timerange;
            let dateList:any = [];
            if(this.dateArrayTemp != null && this.dateArrayTemp.length>0){
                this.dateArrayTemp.forEach((data:string)=> {
                    let datarange = [];
                    datarange.push(moment(data.split('-')[0],'YYYYMMDD'));
                    datarange.push(moment(data.split('-')[1],'YYYYMMDD'));
                    dateList.push(datarange);
                });
                
            }
            this.noTimeRuleData['daterange']=dateList;
        }
        console.log('datarange',this.noTimeRuleData.daterange);
        return {
            ...rawData,
            discountRuleCheck:this.discountRuleCheck,
            shopData: rawData.merchantList,
            budgetLimit:budgetLimit,
            paywayCheck:this.paywayCheck,
            bankName: bankName,
            cardBin:cardBin,
            cardType:cardType,
            weekDays:weekDays,
            dailyCountLimit:rawData.userLimit != null ?rawData.userLimit.dailyCountLimit:'',
            countLimit:rawData.userLimit != null &&  rawData.userLimit.countLimit!= null ?rawData.userLimit.countLimit:'',
            sponsorName:rawData.activitySponsors[0].sponsorName,
            warnRatio:rawData.activitySponsors[0].warnRatio,
            warnMobile:rawData.activitySponsors[0].warnMobile,
            availableTimeType:rawData.availableTime.type,
            activityTime: [
                    rawData.startTime && moment(rawData.startTime),
                    rawData.endTime && moment(rawData.endTime)
                ]

        }
    }
}

export default EditActForm;
