













































































import {Component, Prop, Vue, Provide, Inject} from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import {getValueByPath} from '@/utils';
import {mapState} from 'vuex';
import columns from './columns';
import {check, Show, Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import './store';
import config from '@/config';

@Component({
    components: {
        Drawer,
        // SearchTable,
        // SearchForm,
        Show,
        Click
    },
    computed: {
        ...mapState({
            list: (state: any) =>
                state.qWhitelistUpload.list.map((item: any, idx: any) => ({
                    idx: (state.qWhitelistUpload.page - 1) * state.qWhitelistUpload.pageSize + idx + 1,
                    ...item
                })),
            total: (state: any) => state.qWhitelistUpload.total,
            pageSize: (state: any) => state.qWhitelistUpload.pageSize,
            page: (state: any) => state.qWhitelistUpload.page,
            loading: (state: any) => state.qWhitelistUpload.loading,
            token: (state: any) => {
                return state.login.session && state.login.session.token;
            },
            downloadingErrorExcel: (state: any) => state.qWhitelistUpload.downloadingErrorExcel,
            refreshing: (state: any) => state.qWhitelistUpload.refreshing
        })
    }
})
class Store extends Vue {
    // @Prop()
    // // tslint:disable-next-line:variable-name
    // public mall_id!: string;

    // @Prop()
    // public title!: string | number;
    @Inject()
    public privileges!: any;

    public PRIVILEGE = PRIVILEGE;

    private timer: any;

    private uploading = false;

    public data() {
        return {
            columns,
            config
        };
    }

    @Provide()
    public state() {
        return this.$store.state['qWhitelistUpload'];
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(
            'qWhitelistUpload/' + action,
            payload
        );
    }

    public handleRefreshClick(record: any) {
        this.dispatch('refresh', record.id);
    }

    public handleDownloadErrorClick(record: any) {
        this.dispatch('downloadErrorExcel', record.id);
    }

    public handleFileUpload({file, fileList, event}: any) {

        this.uploading = true;
        if (file && file.status === 'done' && file.response) {
            if (file.response.status.code !== 0) {
                this.$message.error(file.response.status.desc);
            } else {
                this.dispatch('search');
            }
        }

        if (file.status !== 'uploading') {
            this.uploading = false;
        }
    }

    public disabledUpload() {
        if (check([this.PRIVILEGE.Q_WHITELIST.UPLOAD_EXCEL], this.privileges).length > 0) {
            return true;
        }
        return false;
    }

    public handleDownloadTemplateClick() {
        this.dispatch('downloadTemplateExcel');
    }

    // @Provide()
    // public commit(action: string, payload?: any) {
    //     this.$store.commit(
    //         'mallSearchPage/' + this.mall_id + '@store/' + action,
    //         payload
    //     );
    // }

    // public errorListener(error: any) {
    //     if (error) {
    //         this.$message.error(error.message);
    //     }
    // }

    public handlePageChange(page: number) {
        this.dispatch('pageChange', page);
    }

    public handleDrawerOpen() {
        if (check([this.PRIVILEGE.Q_WHITELIST.TASK_LIST], this.privileges).length > 0) {
            return;
        }
        this.dispatch('search');
    }
}

export default Store;
