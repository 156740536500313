



















import DetailForm from './DetailForm.vue';
 import {Component,Prop, Vue,Provide, Watch} from 'vue-property-decorator';
 import {Show} from '@/components/Auth';
 import PRIVILEGE from '@/common/privileges';

 @Component({
     components: {
         Show,
         DetailForm
     }
 })
 export default class index extends Vue {
     public PRIVILEGE = PRIVILEGE;
     @Prop()
     public title!: string | number;
     @Prop()
     public activityId !: string;
     @Prop()
     public paytype !: string;
     public created() {
     }

     @Watch('activityId')
     public initQueryData(error: any) {
         if(this.activityId == null){
             return; 
         }
         this.$store.dispatch('activitySignUpPage/initData', this.activityId);

     }
     @Provide()
     public dispatch(action: string, payload?: any) {
         this.$store.dispatch(this.storeName + '/' + action, payload);
     }
     
     public onClose() {
         this.$emit('close');
     }
 }
