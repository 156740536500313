






























































































import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import { mapState } from 'vuex';
import columns from './columns';
import { check, Show, Click } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import './store';
import config from '@/config';

@Component({
    components: {
        Drawer,
        Show,
        Click
    },
    computed: {
        ...mapState({
            list: (state: any) =>
                state.sharedQuotaMerTask.list.map((item: any, idx: any) => ({
                    idx:
                        (state.sharedQuotaMerTask.page - 1) *
                            state.sharedQuotaMerTask.pageSize +
                        idx +
                        1,
                    ...item
                })),
            total: (state: any) => state.sharedQuotaMerTask.total,
            pageSize: (state: any) => state.sharedQuotaMerTask.pageSize,
            page: (state: any) => state.sharedQuotaMerTask.page,
            loading: (state: any) => state.sharedQuotaMerTask.loading,
            token: (state: any) => {
                return state.login.session && state.login.session.token;
            },
            downloadingErrorExcel: (state: any) =>
                state.sharedQuotaMerTask.downloadingErrorExcel,
            refreshing: (state: any) => state.sharedQuotaMerTask.refreshing
        }),
        uploadAction() {
            const Action: any = {
                act_mer_group_bind:
                    '/console/activity/merGroup/file/batchBindMer',
                'act_mer_group_change1': '/console/activity/merGroup/file/batchCreate',
                'act_mer_group_change2': '/console/activity/merGroup/file/batchUpdate',
                'act_mer_group_change3': '/console/activity/merGroup/file/batchChangeStatus'
            };
            const operateType = this.operateType || '';
            return `${config.XY_BASE_URL}${Action[`${this.uploadType}${operateType}`]}`;
        }
    }
})

class Store extends Vue {
    @Prop()
    public uploadType?: string;
    @Prop()
    public operateType?: string;
    @Inject()
    public privileges!: any;
    public fileList: any = [];
    public PRIVILEGE = PRIVILEGE;
    private timer: any;
    private uploading = false;
    public data() {
        return {
            columns,
            config
        };
    }
    public created() {}
    @Provide()
    public state() {
        return this.$store.state['sharedQuotaMerTask'];
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch('sharedQuotaMerTask/' + action, payload);
    }

    public handleRefreshClick(record: any) {
        this.dispatch('refresh', {
            taskId: record.taskId,
            type: this.uploadType,
            operateType: this.operateType || ''
        });
    }

    public handleDownloadErrorClick(record: any) {
        this.dispatch('downloadErrorExcel', {
            taskId: record.taskId,
            type: this.uploadType,
            operateType: this.operateType || ''
        });
    }

    public handleFileUpload({ file, fileList, event }: any) {
        this.fileList = [
            {
                ...file,
                name: ({
                    uploading: '正在上传',
                    done: '上传完成',
                    error: '上传失败',
                    removed: ''
                } as any)[file.status]
            }
        ];
        this.uploading = true;
        if (file && file.status === 'done' && file.response) {
            if (file.response.status.code !== 0) {
                this.$message.error(file.response.status.desc);
            } else {
                this.dispatch('search');
            }
        }

        if (file.status !== 'uploading') {
            this.uploading = false;
        }
    }

    public disabledUpload() {
        if (this.uploadType === 'act_mer_group_bind') {
            if (check([this.PRIVILEGE.SHARED_QUOTA_CONF.BANCH_BIND],this.privileges).length > 0) {
                return true;
            }
        } else {
            if (this.operateType === '1' && check([this.PRIVILEGE.SHARED_QUOTA_CONF.BATCH_INSERT],this.privileges).length > 0) {
                return true;
            }
            if (this.operateType === '2' && check([this.PRIVILEGE.SHARED_QUOTA_CONF.BATCH_UPDATE],this.privileges).length > 0) {
                return true;
            }
            if (this.operateType === '3' && check([this.PRIVILEGE.SHARED_QUOTA_CONF.BATCH_STATUS],this.privileges).length > 0) {
                return true;
            }
        }
       
        return false;
    }

    public handleDownloadTemplateClick() {
        if (this.uploadType === 'act_mer_group_bind') {
            window.open(
                process.env.BASE_URL + '共享额度绑定商户模板.xlsx',
                '_blank'
            );
        } else {
            const downloadMap = {
                1: '批量新增共享额度模板.xlsx',
                2: '批量修改共享额度模板.xlsx',
                3: '批量上下线共享额度模板.xlsx'
            }
            window.open(
                process.env.BASE_URL + downloadMap[this.operateType],
                '_blank'
            );
        }
    }

    public handlePageChange(page: number) {
        this.dispatch('pageChange', page);
    }

    public handleDrawerOpen() {
        this.$store.state.sharedQuotaMerTask.list = [];
        this.$store.state.sharedQuotaMerTask.total = 0;
        if (this.uploadType === 'act_mer_group_bind') {
            if (check([this.PRIVILEGE.SHARED_QUOTA_CONF.BANCH_BIND],this.privileges).length > 0) {
                return true;
            }
        } else {
            if (this.operateType === '1' && check([this.PRIVILEGE.SHARED_QUOTA_CONF.BATCH_INSERT],this.privileges).length > 0) {
                return true;
            }
            if (this.operateType === '2' && check([this.PRIVILEGE.SHARED_QUOTA_CONF.BATCH_UPDATE],this.privileges).length > 0) {
                return true;
            }
            if (this.operateType === '3' && check([this.PRIVILEGE.SHARED_QUOTA_CONF.BATCH_STATUS],this.privileges).length > 0) {
                return true;
            }
        }

        this.$store.dispatch('sharedQuotaMerTask/initQuery', {
            type: this.uploadType,
            operateType: this.operateType || ''
        });
    }
}

export default Store;
