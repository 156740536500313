







import {mapState} from 'vuex';
import {Component, Inject,Watch, Vue} from 'vue-property-decorator';
import CreateModal from './CreateModal.vue';
import PRIVILEGE from '@/common/privileges';
import {Click} from '@/components/Auth';
import { Modal } from 'ant-design-vue';
interface Actions {
    hideMessage: any;
    selectedRows: any;
}
@Component({
    components: {
        Click,
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.merSubsidyActSearchPage.loading,
            selectedRows: (state: any) =>state.merSubsidyActSearchPage.selectedRowKeys
        })
    }
})
class Actions extends Vue {

    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;
    public data() {
        return {
            hideMessage: null,
            PRIVILEGE
        };
    }
    public handleCreateSuccess() {
        this.dispatch('search');
    }
    public mounted() {
        console.log(this.$store)
    }
    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }
    public handleRefresh() {
        this.dispatch('search');
    }
    
    public handleAddAdPlan() {
        this.$router.push('/console/activity/createMerSubsidyAct');
    }
}

export default Actions;
