





























































































import {Component, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';

import ServicePlatform from '@/components/custom-form-components/ServicePlatform';
import {Region} from '@/components/custom-form-components';

import {deleteEmpty} from '@/utils';
import qs from 'querystringify';

interface SearchForm {
    form: any;
}

export interface Query {
    mall_id: string;
    mall_name: string;
    mall_belong: string;
    address: string;
    province: string;
    city: string;
    district: string;
}

export interface FormData {
    mall_id: string;
    mall_name: string;
    mall_belong: string;
    address: string;
    region: string[];
}

@Component({
    components: {
        Region,
        ServicePlatform
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.mallSearchPage.query);
            },
            loading: (state: any) => state.mallSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/mall/search?${qs.stringify(query)}`
            });

        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: FormData): Query {
        return {
            mall_id: values.mall_id,
            mall_name: values.mall_name,
            mall_belong: values.mall_belong,
            address: values.address,
            province: values.region && values.region[0],
            city: values.region && values.region[1],
            district: values.region && values.region[2]
        };
    }

    private formatFormData(query: Query): FormData {
        return {
            ...query,
            region: [query.province, query.city, query.district].map((r: any) => r || undefined)
        };
    }
}

export default SearchForm;
