



























































































import {Component, Inject, Vue} from "vue-property-decorator";
import {mapState} from "vuex";
import {deleteEmpty} from "@/utils";
import qs from "querystringify";
import {
    DatePicker
} from "@/components/custom-form-components";

interface SearchForm {
    form: any;
}

@Component({
    components: {
        DatePicker
    },
    computed: {
        ...mapState({
            formData() {
                return this.formatFormData(this.state().query);
            },
            loading() {
                return this.state().loading;
            }
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public state!: any;

    @Inject()
    public dispatch!: any;

    @Inject()
    public commit!: any;

    @Inject()
    public routerPush!: any;

    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error("请填写正确信息");
            }

            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/blacklist/search?${qs.stringify(query)}`
            });
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any) {
        const {type_name, type_value, name, remark} = values;
        return {
            type_name,
            type_value,
            name,
            remark,
            start_time:
                values.range_created_time &&
                values.range_created_time[0] &&
                values.range_created_time[0].format("YYYY-MM-DD HH:mm:ss"),
            end_time: values.range_created_time &&
                values.range_created_time[1] &&
                values.range_created_time[1].format("YYYY-MM-DD HH:mm:ss"),
        };
    }

    private formatFormData(query: any) {
        return {
            ...query,
            // role_id: query.role_id && parseInt(query.role_id, 10)
        };
    }
}

export default SearchForm;
