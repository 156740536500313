




















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import * as services from './services';
import PRIVILEGE from '@/common/privileges';
import {mapState} from 'vuex';
import {Click} from '@/components/Auth';
import {DatePicker} from '@/components/custom-form-components';
import moment from 'moment';
import {isTrueEmpty, deleteEmpty} from '@/utils';

interface SumModal {
    form: any;
    formData: any;
}

const orderStatus = [
    {
        label: '待支付',
        value: 'WAIT_PAY'
    },
    {
        label: '支付中',
        value: 'PAYING'
    },
    {
        label: '已付款',
        value: 'PAID'
    },
    {
        label: '交易失败',
        value: 'FAIL'
    },
    {
        label: '交易错误',
        value: 'ERROR'
    },
    {
        label: '已退款',
        value: 'REFUND'
    },
    {
        label: '交易关闭',
        value: 'CLOSED'
    },
    {
        label: '退款中',
        value: 'REFUND_PROCESSING'
    },
    {
        label: '退款失败',
        value: 'REFUND_FAIL'
    }
];

@Component({
    components: {
        Click,
        DatePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.orderSearchPage.query);
            },
        })
    }
})
class SumModal extends Vue {
    // public status = 'PAID';
    public visible = false;
    public dataSource: any = [];
    public loading = false;
    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public orderStatus!: any;

    public tags = [
        {
            key: 'PAID',
            label: '已付款'
        },
        {
            key: 'CLOSED',
            label: '交易关闭'
        },
        {
            key: 'FAIL',
            label: '交易失败'
        },
        {
            key: 'ERROR',
            label: '交易错误'
        },
        {
            key: 'REFUND',
            label: '已退款'
        },
        {
            key: 'REFUND_FAIL',
            label: '退款失败'
        }
    ];

    public columns = [
        {
            title: '交易类型',
            dataIndex: 'trade_type'
        },
        {
            title: '订单金额(元)',
            dataIndex: 'order_price',
            align: 'right'
        },
        {
            title: '折扣金额(元)',
            dataIndex: 'discount_price',
            align: 'right'
        },
        {
            title: '商圈折扣金额(元)',
            dataIndex: 'hami_discount_price',
            align: 'right'
        },
        {
            title: '实收金额(元)',
            dataIndex: 'pay_price',
            align: 'right'
        },
        {
            title: '交易笔数',
            dataIndex: 'sum_transaction_number',
            align: 'right'
        },
        {
            title: '折扣交易笔数',
            dataIndex: 'sum_discount_number',
            align: 'right'
        },
        {
            title: '商圈折扣笔数',
            dataIndex: 'sum_hamidiscount_number',
            align: 'right'
        }
    ];

    public data() {
        return {
            form: this.$form.createForm(this),
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public handleClose() {
        this.visible = false;
    }

    public async handleOk() {
        this.visible = false;
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            this.formData.range_trade_time = values.range_trade_time;
            this.formData.range_refund_time = values.range_refund_time;
            this.formData.status = this.formData.status || 'PAID';
            this.sum(this.formData.status);
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    public async handleOrderStatusChange(e: any) {
        const status = e.target.value;
        // 保存当前的 order status
        this.formData.status = e.target.value;
        await this.sum(status);
    }

    public async sum(status: string) {
        try {
            const query = deleteEmpty({
                ...this.formatQuery(this.formData),
                r: Math.random(),
                status
            });
            this.loading = true;
            this.dataSource = [];
            const ret = await services.sum(query);
            const formatPrice = this.formatPrice;
            this.dataSource = [
                {
                    trade_type: '支付宝',
                    order_price: formatPrice(ret.alipay_sum_total_amount),
                    discount_price:
                        '-' + formatPrice(ret.alipay_sum_discount_amount),
                    hami_discount_price:
                        '-' + formatPrice(ret.alipay_sum_hami_discount),
                    pay_price: formatPrice(ret.alipay_sum_pay_amount),
                    sum_transaction_number: ret.alipay_sum_transaction_number,
                    sum_discount_number: ret.alipay_sum_discount_number,
                    sum_hamidiscount_number: ret.alipay_sum_hamidiscount_number,
                },
                {
                    trade_type: '微信',
                    order_price: formatPrice(ret.wx_sum_total_amount),
                    discount_price:
                        '-' + formatPrice(ret.wx_sum_discount_amount),
                    hami_discount_price:
                        '-' + formatPrice(ret.wx_sum_hami_discount),
                    pay_price: formatPrice(ret.wx_sum_pay_amount),
                    sum_transaction_number: ret.wx_sum_transaction_number,
                    sum_discount_number: ret.wx_sum_discount_number,
                    sum_hamidiscount_number: ret.wx_sum_hamidiscount_number,
                },
                {
                    trade_type: '云闪付',
                    order_price: formatPrice(ret.yunshan_sum_total_amount),
                    discount_price:
                        '-' + formatPrice(ret.yunshan_sum_discount_amount),
                    hami_discount_price:
                        '-' + formatPrice(ret.yunshan_sum_hami_discount),
                    pay_price: formatPrice(ret.yunshan_sum_pay_amount),
                    sum_transaction_number: ret.yunshan_sum_transaction_number,
                    sum_discount_number: ret.yunshan_sum_discount_number,
                    sum_hamidiscount_number: ret.yunshan_sum_hamidiscount_number,
                },
                {
                    trade_type: '微信H5',
                    order_price: formatPrice(ret.wx_h5_sum_total_amount),
                    discount_price:
                        '-' + formatPrice(ret.wx_h5_sum_discount_amount),
                    hami_discount_price:
                        '-' + formatPrice(ret.wx_h5_sum_hami_discount),
                    pay_price: formatPrice(ret.wx_h5_sum_pay_amount),
                    sum_transaction_number: ret.wx_h5_sum_transaction_number,
                    sum_discount_number: ret.wx_h5_sum_discount_number,
                    sum_hamidiscount_number: ret.wx_h5_sum_hamidiscount_number,
                }
            ];
        } catch (e) {
            this.$message.error(e.message);
        } finally {
            this.loading = false;
        }
    }

    public async onClick() {
        this.visible = true;
        // 设置时间
        if (!this.formData.start_trade_time && !this.formData.end_trade_time
            && !this.formData.start_refund_time && !this.formData.end_refund_time) {
            const rangeTradeTime = this.formData.range_trade_time;
            if (isTrueEmpty(rangeTradeTime[0]) || isTrueEmpty(rangeTradeTime[1])) {
                this.formData.range_trade_time = [
                    moment().subtract(1, 'day').hours(0).minutes(0).seconds(0), moment()
                ];
            }
            // const rangeRefundTime = this.formData.range_refund_time;
            // if (isTrueEmpty(rangeRefundTime[0]) || isTrueEmpty(rangeRefundTime[1])) {
            //     this.formData.range_refund_time = [
            //         moment().subtract(1, 'day').hours(0).minutes(0).seconds(0), moment()
            //     ];
            // }
        }

        await this.sum(this.orderStatus || 'PAID');
    }

    private formatPrice(price: number | undefined) {
        return (price ? price / 100 : 0).toFixed(2);
    }

    private formatQuery(values: any) {
        return {
            store_name: values.store_name,
            merchant_no: values.merchant_no,
            sn: values.sn,
            pay_channel: values.pay_channel,
            pay_type: values.pay_type,
            status: values.status,
            order_id: values.order_id,
            mallcoo_code: values.mallcoo_code,
            hami_coupon_no: values.hami_coupon_no,
            mall_name: values.mall_name,
            district_name: values.district_name,
            nick_name: values.nick_name,
            user_name: values.user_name,
            mobile: values.mobile,
            min_total_amount: isTrueEmpty(values.total_amount && values.total_amount[0])
                ? null
                : Math.round(values.total_amount[0] * 100),
            max_total_amount: isTrueEmpty(values.total_amount && values.total_amount[1])
                ? null
                : Math.round(values.total_amount[1] * 100),
            min_pay_amount: isTrueEmpty(values.pay_amount && values.pay_amount[0])
                ? null
                : Math.round(values.pay_amount[0] * 100),
            max_pay_amount: isTrueEmpty(values.pay_amount && values.pay_amount[1])
                ? null
                : Math.round(values.pay_amount[1] * 100),
            min_discount_amount: isTrueEmpty(values.discount_amount && values.discount_amount[0])
                ? null
                : Math.round(values.discount_amount[0] * 100),
            max_discount_amount: isTrueEmpty(values.discount_amount && values.discount_amount[1])
                ? null
                : Math.round(values.discount_amount[1] * 100),
            min_hami_discount: isTrueEmpty(values.hami_discount && values.hami_discount[0])
                ? null
                : Math.round(values.hami_discount[0] * 100),
            max_hami_discount: isTrueEmpty(values.hami_discount && values.hami_discount[1])
                ? null
                : Math.round(values.hami_discount[1] * 100),
            start_trade_time:
                values.range_trade_time &&
                values.range_trade_time[0] &&
                values.range_trade_time[0].format('YYYY-MM-DD HH:mm:ss'),
            end_trade_time:
                values.range_trade_time &&
                values.range_trade_time[1] &&
                values.range_trade_time[1].format('YYYY-MM-DD HH:mm:ss'),
            start_refund_time:
                values.range_refund_time &&
                values.range_refund_time[0] &&
                values.range_refund_time[0].format('YYYY-MM-DD HH:mm:ss'),
            end_refund_time:
                values.range_refund_time &&
                values.range_refund_time[1] &&
                values.range_refund_time[1].format('YYYY-MM-DD HH:mm:ss'),
            start_create_time:
                values.range_create_time &&
                values.range_create_time[0] &&
                values.range_create_time[0].format('YYYY-MM-DD HH:mm:ss'),
            end_create_time:
                values.range_create_time &&
                values.range_create_time[1] &&
                values.range_create_time[1].format('YYYY-MM-DD HH:mm:ss'),
            org_name: values.org_name
        };
    }

    private formatFormData(query: any) {
        return {
            ...query,
            status: query.status,
            mall_name: query.mall_name,
            district_name: query.district_name,
            total_amount: [
                query.min_total_amount &&
                parseInt(query.min_total_amount, 10) / 100,
                query.max_total_amount && parseInt(query.max_total_amount, 10) / 100
            ],
            pay_amount: [
                query.min_pay_amount && parseInt(query.min_pay_amount, 10) / 100,
                query.max_pay_amount && parseInt(query.max_pay_amount, 10) / 100
            ],
            range_trade_time: [
                query.start_trade_time && moment(query.start_trade_time),
                query.end_trade_time && moment(query.end_trade_time)
            ],
            range_refund_time: [
                query.start_refund_time && moment(query.start_refund_time),
                query.end_refund_time && moment(query.end_refund_time)
            ],
            range_create_time: [
                query.start_create_time && moment(query.start_create_time),
                query.end_create_time && moment(query.end_create_time)
            ]
        };
    }
}

export default SumModal;
