var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "biz-district-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate }
        },
        [
          _c(
            "span",
            { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
            [_vm._v("基本信息")]
          ),
          _c("a-divider", { staticStyle: { margin: "6px 0" } }),
          _c(
            "a-form-item",
            { attrs: { label: "名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "actName",
                      {
                        rules: [
                          { required: true, message: "请填写必填项" },
                          { max: 64, message: "长度超限，请检查后重新输入!" }
                        ]
                      }
                    ],
                    expression:
                      "[\n                    'actName',\n                    {\n                        rules: [\n                            {required: true, message: '请填写必填项'},\n                            {max: 64, message: '长度超限，请检查后重新输入!'}\n                        ]\n                    }\n                ]"
                  }
                ],
                attrs: { maxLength: 64, placeholder: "请输入64个字以内" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "类型" } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "actType",
                        {
                          rules: [{ required: true, message: "类型不能为空" }]
                        }
                      ],
                      expression:
                        "[\n                    'actType',\n                    { \n                        rules: [\n                            { required: true, message: '类型不能为空' }\n                        ]\n                    },\n                ]"
                    }
                  ],
                  attrs: { placeholder: "请选择" }
                },
                [
                  _c("a-select-option", { attrs: { value: "OPEN_CARD" } }, [
                    _vm._v("开卡活动")
                  ]),
                  _c("a-select-option", { attrs: { value: "MARKET_ACT" } }, [
                    _vm._v("营销活动")
                  ]),
                  _c("a-select-option", { attrs: { value: "FEE_SUBSIDY" } }, [
                    _vm._v("手续费补贴")
                  ]),
                  _c(
                    "a-select-option",
                    { attrs: { value: "DEPOSIT_REWARD" } },
                    [_vm._v("存款奖励")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动时间" } },
            [
              _c("DisableDatePicker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activity_time",
                      { rules: [{ required: true, message: "请填写必填项" }] }
                    ],
                    expression:
                      "['activity_time',{rules: [{required: true, message: '请填写必填项'}]}]"
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "APP展示银行" } },
            [
              _c("policyBank", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "actBank",
                      {
                        initialValue: _vm.formData.actBank,
                        rules: [{ required: true, message: "请填写必填项!" }]
                      }
                    ],
                    expression:
                      "['actBank', \n                    {\n                        initialValue: formData.actBank,\n                        rules: [{ required: true, message: '请填写必填项!' }]\n                    }\n                ]\n            "
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "数据传输银行" } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "transmittedBank",
                        {
                          initialValue: _vm.formData.transmittedBank,
                          rules: [{ validator: _vm.validateTransmittedBank }]
                        }
                      ],
                      expression:
                        "['transmittedBank', \n                    {\n                        initialValue: formData.transmittedBank,\n                        rules: [\n                            { validator: validateTransmittedBank }\n                        ]\n                    }\n                ]"
                    }
                  ],
                  attrs: {
                    showSearch: "",
                    allowClear: "",
                    optionFilterProp: "children",
                    placeholder: "请选择",
                    mode: "multiple"
                  }
                },
                _vm._l(_vm.bankList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.bankId, attrs: { value: item.bankId } },
                    [_vm._v(_vm._s(item.bankName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "银行机构" } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "bankId",
                        {
                          initialValue: _vm.formData.bankId
                        }
                      ],
                      expression:
                        "['bankId', \n                        {\n                            initialValue: formData.bankId\n                        }\n                    ]"
                    }
                  ],
                  attrs: {
                    showSearch: "",
                    allowClear: "",
                    optionFilterProp: "children",
                    placeholder: "请选择"
                  }
                },
                _vm._l(_vm.bankList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.bankId, attrs: { value: item.bankId } },
                    [_vm._v(_vm._s(item.bankName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动区域" } },
            [
              _c("districtCodeTransfer", {
                attrs: { targetDistrictCodeData: _vm.districtCodeList },
                on: { change: _vm.changeDistrictCodeData }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动详情" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "actDetail",
                      {
                        rules: [{ required: true, message: "活动详情不能为空" }]
                      }
                    ],
                    expression:
                      "[\n                    'actDetail',\n                    {\n                        rules: [\n                           { required: true, message: '活动详情不能为空' }\n                        ]\n                    }\n                ]"
                  }
                ],
                attrs: {
                  placeholder: "请输入",
                  maxLength: 1024,
                  autosize: { minRows: 5, maxRows: 10 }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "MCC限制" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merMcc",
                      {
                        rules: [
                          { pattern: /^.{0,60}$/, message: "必填，限制40字" }
                        ]
                      }
                    ],
                    expression:
                      "[\n                    'merMcc',\n                    {\n                        rules: [\n                            {pattern: /^.{0,60}$/, message: '必填，限制40字'}\n                        ]\n                    }\n                ]"
                  }
                ],
                attrs: {
                  placeholder: "请输入",
                  autosize: { minRows: 5, maxRows: 10 }
                }
              })
            ],
            1
          ),
          _c(
            "span",
            { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
            [_vm._v("其他规则")]
          ),
          _c("a-divider", { staticStyle: { margin: "6px 0" } }),
          _c(
            "a-form",
            { staticClass: "mall-create-form", attrs: { form: _vm.otherForm } },
            _vm._l(_vm.formData, function(item, index) {
              return _c(
                "span",
                { key: index },
                [
                  _c(
                    "a-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !item.disabled || _vm.validObj[item.key] == "Y",
                          expression:
                            "!item.disabled || validObj[item.key] == 'Y'"
                        }
                      ],
                      attrs: { label: item.label }
                    },
                    [
                      item.type == null
                        ? _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  item.key,
                                  {
                                    rules: [
                                      {
                                        required: _vm.validObj[item.key] == "Y",
                                        message: item.label + "不能为空"
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                                item.key,\n                                {\n                                    rules: [\n                                    { required: validObj[item.key] == 'Y', message: item.label + '不能为空' }\n                                    ]\n                                }\n                             ]"
                              }
                            ]
                          })
                        : _vm._e(),
                      item.type == "radio"
                        ? _c(
                            "a-radio-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    item.key,
                                    { initialValue: item.value }
                                  ],
                                  expression:
                                    "[item.key,{initialValue:item.value}]"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  return _vm.changeRadioCheck(
                                    $event,
                                    item.options
                                  )
                                }
                              }
                            },
                            _vm._l(item.options, function(option, index) {
                              return _c(
                                "span",
                                { key: index },
                                [
                                  _c(
                                    "a-radio",
                                    { attrs: { value: option.value } },
                                    [_vm._v(_vm._s(option.label))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      item.type == "checkbox"
                        ? _c(
                            "a-checkbox-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [item.key],
                                  expression: "[item.key]"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  return _vm.changeCheck($event, item.options)
                                }
                              }
                            },
                            [
                              _c(
                                "a-row",
                                _vm._l(item.options, function(option, index) {
                                  return _c(
                                    "span",
                                    { key: index },
                                    [
                                      _c(
                                        "a-checkbox",
                                        { attrs: { value: option.value } },
                                        [_vm._v(_vm._s(option.label))]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.type == "textarea"
                        ? _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  item.key,
                                  {
                                    rules: [
                                      {
                                        required: _vm.validObj[item.key] == "Y",
                                        message: item.label + "不能为空"
                                      }
                                    ]
                                  }
                                ],
                                expression:
                                  "[\n                        item.key,\n                        {\n                            rules: [\n                            { required: validObj[item.key] == 'Y', message: item.label + '不能为空' }\n                            ]\n                        }\n                        ]"
                              }
                            ],
                            attrs: { autosize: { minRows: 2, maxRows: 6 } }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.loading
                      }
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("\n                    取消\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }