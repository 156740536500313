



















import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
import SearchForm from './SearchForm.vue';
import Actions from './Actions.vue';
import SearchTable from './SearchTable.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Show } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Actions,
        SearchForm,
        SearchTable,
        SearchCommonLayout,
        Show
    },
    computed: {}
})
export default class CompanyQueryConfPage extends Vue {
    public PRIVILEGE = PRIVILEGE;
    public humanReadableQuery = null;

    @Watch('$store.state.companyQueryConfSearchPage.error')
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    // 路由驱动页面查询
    @Watch('$route')
    public routeListener() {
        this.$store.dispatch(
            'companyQueryConfSearchPage/initQuery',
            this.$route.query
        );
    }
}
