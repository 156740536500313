


































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import moment from 'moment';
import {isTrueEmpty, deleteEmpty} from '@/utils';
import qs from 'querystringify';

import Mall from '@/components/custom-form-components/Mall';
import payType from '@/components/custom-form-components/payType.vue';
import PayChannel from '@/components/custom-form-components/PayChannel';
// import PayType from '@/components/custom-form-components/PayType';
import OrderStatus from '@/components/custom-form-components/OrderStatus';
import BizDistrict from '@/components/custom-form-components/BizDistrict.vue';
import PriceRange from '@/components/custom-form-components/PriceRange.vue';
import Org from '@/components/custom-form-components/Org';
import formRules from '@/common/formRules';

import {
    DatePicker
} from '@/components/custom-form-components';

interface SearchForm {
    form: any;
}

@Component({
    components: {
        Mall,
        PayChannel,
        payType,
        OrderStatus,
        BizDistrict,
        PriceRange,
        Org,
        DatePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.orderSearchPage.query);
            },
            loading: (state: any) => state.orderSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
     public expand: boolean = false;
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this),
            formRules
        };
    }
    public toggle() {
        this.expand = !this.expand;
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            if(values.offline_terminal_id && !values.merchant_no){
               return this.$message.error('线下终端号必须与商户号绑定查询');
            }
            let startTime = new Date(values.range_create_time[0]);
            let endTIme = new Date(values.range_create_time[1]);
            if(!values.range_create_time || !values.range_create_time[0] || !values.range_create_time[1]){
                this.$message.error('订单创建时间必填');
                return;
            }
            if((endTIme - startTime) / (1000 * 60 * 60 * 24)>30){
                this.$message.error('订单创建时间区间不能超过30天');
                return;
            }    
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/order/search?${qs.stringify(query)}`
            });

        });
    }

    public handleReset() {
        // this.form.resetFields();
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any) {
        return {
            store_name: values.store_name,
            merchant_no: values.merchant_no,
            offline_terminal_id: values.offline_terminal_id,
            sn: values.sn,
            pay_channel: values.pay_channel,
            pay_type: values.pay_type,
            status: values.status,
            order_id: values.order_id,
            mallcoo_code: values.mallcoo_code,
            hami_coupon_no: values.hami_coupon_no,
            mall_name: values.mall_name,
            district_name: values.district_name,
            nick_name: values.nick_name,
            user_name: values.user_name,
            mobile: values.mobile,
            comp_org_name: values.comp_org_name,
            trade_no: values.trade_no,
            order_no: values.order_no,
            min_total_amount: isTrueEmpty(values.total_amount && values.total_amount[0])
                ? null
                : Math.round(values.total_amount[0] * 100),
            max_total_amount: isTrueEmpty(values.total_amount && values.total_amount[1])
                ? null
                : Math.round(values.total_amount[1] * 100),
            min_pay_amount: isTrueEmpty(values.pay_amount && values.pay_amount[0])
                ? null
                : Math.round(values.pay_amount[0] * 100),
            max_pay_amount: isTrueEmpty(values.pay_amount && values.pay_amount[1])
                ? null
                : Math.round(values.pay_amount[1] * 100),
            min_discount_amount: isTrueEmpty(values.discount_amount && values.discount_amount[0])
                ? null
                : Math.round(values.discount_amount[0] * 100),
            max_discount_amount: isTrueEmpty(values.discount_amount && values.discount_amount[1])
                ? null
                : Math.round(values.discount_amount[1] * 100),
            min_hami_discount: isTrueEmpty(values.hami_discount && values.hami_discount[0])
                ? null
                : Math.round(values.hami_discount[0] * 100),
            max_hami_discount: isTrueEmpty(values.hami_discount && values.hami_discount[1])
                ? null
                : Math.round(values.hami_discount[1] * 100),
            start_trade_time:
                values.range_trade_time &&
                values.range_trade_time[0] &&
                values.range_trade_time[0].format('YYYY-MM-DD HH:mm:ss'),
            end_trade_time:
                values.range_trade_time &&
                values.range_trade_time[1] &&
                values.range_trade_time[1].format('YYYY-MM-DD HH:mm:ss'),
            start_refund_time:
                values.range_refund_time &&
                values.range_refund_time[0] &&
                values.range_refund_time[0].format('YYYY-MM-DD HH:mm:ss'),
            end_refund_time:
                values.range_refund_time &&
                values.range_refund_time[1] &&
                values.range_refund_time[1].format('YYYY-MM-DD HH:mm:ss'),
            start_create_time:
                values.range_create_time &&
                values.range_create_time[0] &&
                values.range_create_time[0].format('YYYY-MM-DD HH:mm:ss'),
            end_create_time:
                values.range_create_time &&
                values.range_create_time[1] &&
                values.range_create_time[1].format('YYYY-MM-DD HH:mm:ss'),
            // org_name: values.org_name
        };
    }

    private formatFormData(query: any) {

        return {
            ...query,
            mall_name: query.mall_name,
            district_name: query.district_name,
            total_amount: [
                query.min_total_amount &&
                parseInt(query.min_total_amount, 10) / 100,
                query.max_total_amount && parseInt(query.max_total_amount, 10) / 100
            ],
            pay_amount: [
                query.min_pay_amount && parseInt(query.min_pay_amount, 10) / 100,
                query.max_pay_amount && parseInt(query.max_pay_amount, 10) / 100
            ],
            range_trade_time: [
                query.start_trade_time && moment(query.start_trade_time),
                query.end_trade_time && moment(query.end_trade_time)
            ],
            range_refund_time: [
                query.start_refund_time && moment(query.start_refund_time),
                query.end_refund_time && moment(query.end_refund_time)
            ],
            range_create_time: [
                query.start_create_time && moment(query.start_create_time),
                query.end_create_time && moment(query.end_create_time)
            ]
        };
    }
}

export default SearchForm;
