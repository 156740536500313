var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-form-item-wrapper" },
    [
      [
        _c(
          "a-form",
          { attrs: { form: _vm.form } },
          [
            _c(
              "a-form-item",
              _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "16%" },
                    attrs: { type: "dashed" },
                    on: { click: _vm.add }
                  },
                  [
                    _c("a-icon", { attrs: { type: "plus" } }),
                    _vm._v(" 新增\n          ")
                  ],
                  1
                )
              ],
              1
            ),
            _vm._l(_vm.form.getFieldValue("keys"), function(k, index) {
              return _c(
                "a-form-item",
                { key: "data" + index, attrs: { keys: k, required: false } },
                [
                  _vm._v("\n      入口通道: \n      "),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "16%" },
                      attrs: { name: "channel" + index, id: "channel" + index },
                      on: {
                        change: function($event) {
                          return _vm.channelChange(k)
                        }
                      },
                      model: {
                        value: _vm.linkListData[index].channel,
                        callback: function($$v) {
                          _vm.$set(_vm.linkListData[index], "channel", $$v)
                        },
                        expression: "linkListData[index].channel "
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: "SHT" } }, [
                        _vm._v("商户通APP")
                      ]),
                      _c("a-select-option", { attrs: { value: "SHT_MINI" } }, [
                        _vm._v("商户通小程序")
                      ]),
                      _c("a-select-option", { attrs: { value: "CMMP" } }, [
                        _vm._v("招财考拉")
                      ]),
                      _c("a-select-option", { attrs: { value: "WSF" } }, [
                        _vm._v("微商服公众号")
                      ])
                    ],
                    1
                  ),
                  _c("a-input", {
                    ref: "url" + index,
                    refInFor: true,
                    staticStyle: { "margin-left": "20px", width: "45%" },
                    attrs: {
                      name: "url" + index,
                      id: "url" + index,
                      disabled: true
                    },
                    on: {
                      change: function($event) {
                        return _vm.urlChange(k)
                      }
                    },
                    model: {
                      value: _vm.linkListData[index].url,
                      callback: function($$v) {
                        _vm.$set(_vm.linkListData[index], "url", $$v)
                      },
                      expression: "linkListData[index].url"
                    }
                  }),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "8%" },
                      attrs: { type: "dashed" },
                      on: {
                        click: function() {
                          return _vm.create(k)
                        }
                      }
                    },
                    [_vm._v("\n          生成\n      ")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "8%" },
                      attrs: { type: "dashed" },
                      on: {
                        click: function() {
                          return _vm.copyHandler(k)
                        }
                      }
                    },
                    [_vm._v("\n          复制\n      ")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "8%" },
                      attrs: { type: "dashed" },
                      on: {
                        click: function() {
                          return _vm.remove(k)
                        }
                      }
                    },
                    [_vm._v("\n          删除\n      ")]
                  )
                ],
                1
              )
            })
          ],
          2
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }