var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        staticClass: "sn-search-table",
        attrs: {
          rowKey: "id",
          bordered: true,
          columns: _vm.columns,
          size: "small",
          dataSource: _vm.list,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
          }
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function(text, record) {
              return _c(
                "span",
                { staticClass: "ops" },
                [
                  _c("Click", {
                    attrs: { requires: [_vm.PRIVILEGE.STORE.UNBIND_SN] },
                    on: {
                      pass: function($event) {
                        return _vm.handleUnbindClick(record)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function(slotProps) {
                            return [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    size: "small",
                                    href: "javascript:;",
                                    disabled:
                                      record.status !== 1 || _vm.unbinding
                                  }
                                },
                                [_vm._v("解绑SN")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }