var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CommonLayout", {
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function() {
          return [_c("Actions")]
        },
        proxy: true
      },
      {
        key: "table",
        fn: function() {
          return [_c("SearchTable")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }