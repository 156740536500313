

















































import {AutoComplete, Row, Col} from 'ant-design-vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import regions, {RegionItem} from '@/lib/regions';

@Component({
    components: {
        [AutoComplete.name]: AutoComplete,
        [Row.name]: Row,
        [Col.name]: Col
    },
    computed: {
        span() {
            const {province, city, district} = this as any;
            return 24 / [province, city, district].filter(Boolean).length;
        },
        provinces() {
            const list: any = regions.map((r: RegionItem) => r.label);
            return list;
        },
        cities() {
            const self: any = this;
            if (self.value && self.value[0]) {
                const provinceItem = regions.find(
                    (r: RegionItem) => r.label === self.value[0]
                );
                if (provinceItem && provinceItem.children) {
                    const list: any = provinceItem.children.map(
                        (r: RegionItem) => r.label
                    );
                    list.__parent = provinceItem;
                    return list;
                }
            }
            return [];
        },
        districts() {
            const self: any = this;
            // 如果城市有输入
            if (self.value && self.value[1]) {
                const parent = self.cities.__parent;
                if (parent) {
                    const city = parent.children.find(
                        (r: RegionItem) => r.label === self.value[1]
                    );
                    if (city) {
                        return city.children.map((r: RegionItem) => r.label);
                    }
                }
            }
            return [];
        }
    }
})
class RegionComponent extends Vue {
    @Prop()
    public value!: any;

    @Prop()
    public province!: boolean;

    @Prop()
    public city!: boolean;

    @Prop()
    public district!: boolean;

    @Prop()
    public disableAutoComplete!: boolean;

    public handleProvinceChange(e: any) {
        this.$emit('change', [
            e,
            // this.getValue('city'),
            // this.getValue('district')
        ]);
    }

    public handleCityChange(e: any) {
        this.$emit('change', [
            this.getValue('province'),
            e,
            // this.getValue('district')
        ]);
    }

    public handleDistrictChange(e: any) {
        this.$emit('change', [
            this.getValue('province'),
            this.getValue('city'),
            e
        ]);
    }

    public filterOption(input: any, option: any) {
        return (
            option.componentOptions.children[0].text
                .toUpperCase()
                .indexOf(input.toUpperCase()) >= 0
        );
    }

    private getValue(key: string) {
        switch (key) {
            case 'province':
                return this.value && this.value[0];
            case 'city':
                return this.value && this.value[1];
            case 'district':
                return this.value && this.value[2];
        }
    }
}

export default RegionComponent;
