















    import {Modal} from 'ant-design-vue';
    import {Component, Watch, Vue, Inject} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import PRIVILEGE from '@/common/privileges';
    import {Click} from '@/components/Auth';
    import CreateModal from "./CreateModal.vue";
    import AccountCheckYX from "./AccountCheckYX.vue";
    
    interface Actions {
        selectedRowKeys: any;
        hideMessage: any;
    }
	@Component({
        components: {
           Click,
           CreateModal,
           AccountCheckYX
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.StatementOperatePage.loading,
                downloading: (state: any) => state.StatementOperatePage.downloading,
                selectedRowKeys: (state: any) => state.StatementOperatePage.selectedRowKeys,
            })
        }
    })
   class Actions extends Vue {
       @Inject()
        public dispatch!: any;

        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        public handleRefresh() {
           this.dispatch('search');
        }
       
        
    }

    export default Actions;
