var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-button",
        {
          attrs: { disabled: _vm.loading },
          on: { click: _vm.handleAddAdPlan }
        },
        [_vm._v("新增活动")]
      ),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.BANK_ACTIVITY.STATUS] },
        on: { pass: _vm.updateStatus },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRows.length === 0 || _vm.loading
                    }
                  },
                  [_vm._v("失效")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }