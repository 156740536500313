var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ad-edit-form-wrapper" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "8px",
            "font-size": "12px",
            color: "gray"
          }
        },
        [
          _vm._v(
            "创建时间: " +
              _vm._s(_vm.created_time) +
              "    最近编辑: " +
              _vm._s(_vm.updated_time)
          )
        ]
      ),
      _vm.formData
        ? _c(
            "a-form",
            {
              staticClass: "ad-edit-form",
              attrs: { form: _vm.form },
              on: { submit: _vm.handleSubmit }
            },
            [
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  attrs: { label: "广告ID" }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "id",
                          {
                            initialValue: _vm.formData.id,
                            rules: [{ required: true }]
                          }
                        ],
                        expression:
                          "[\n                    'id',\n                    {\n                        initialValue: formData.id,\n                        rules: [{required: true}]\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: true, placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "默认广告" } },
                [
                  _c(
                    "a-switch",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "isDefault",
                            {
                              valuePropName: "checked",
                              initialValue: _vm.formData.isDefault,
                              rules: [
                                {
                                  required: true
                                }
                              ]
                            }
                          ],
                          expression:
                            "['isDefault', {\n                valuePropName: 'checked',\n                initialValue: formData.isDefault,\n                rules: [{\n                    required: true\n                }]\n            }]"
                        }
                      ],
                      attrs: { disabled: true }
                    },
                    [
                      _c("a-icon", {
                        attrs: { slot: "checkedChildren", type: "check" },
                        slot: "checkedChildren"
                      }),
                      _c("a-icon", {
                        attrs: { slot: "unCheckedChildren", type: "close" },
                        slot: "unCheckedChildren"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "广告名称" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "title",
                          {
                            initialValue: _vm.formData.title,
                            rules: [{ required: true, message: "请填写必填项" }]
                          }
                        ],
                        expression:
                          "[\n                    'title',\n                    {\n                        initialValue: formData.title,\n                        rules: [{required: true, message: '请填写必填项'}]\n                    }\n                ]"
                      }
                    ],
                    attrs: { disabled: _vm.formDisabled, placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "上架时间" } },
                [
                  _c("SchedulePicker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "schedule",
                          {
                            initialValue: _vm.formData.schedule,
                            rules: [
                              {
                                required: true,
                                message: "请填写必填项"
                              },
                              {
                                validator: _vm.validators.scheduleValidator
                              }
                            ]
                          }
                        ],
                        expression:
                          "['schedule', {\n                initialValue: formData.schedule,\n                rules: [{\n                    required: true,\n                    message: '请填写必填项'\n                },\n                {\n                    validator: validators.scheduleValidator\n                }]\n            }]"
                      }
                    ],
                    attrs: { disabled: _vm.formDisabled }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "广告位置" } },
                [
                  _c("AdPositionSelector", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "position",
                          {
                            initialValue: _vm.formData.position,
                            rules: [{ required: true, message: "请填写必填项" }]
                          }
                        ],
                        expression:
                          "['position', \n                {\n                    initialValue: formData.position,\n                    rules: [{required: true, message: '请填写必填项'}]\n                }\n            ]"
                      }
                    ],
                    attrs: {
                      disabledOptions: _vm.formData.isDefault
                        ? []
                        : ["C_HOME_BOTTOM"],
                      disabled: _vm.formDisabled
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "展现方式" } },
                [
                  _c("AdMaterialTplSelector", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "materialTpl",
                          {
                            initialValue: _vm.formData.materialTpl,
                            rules: [{ required: true, message: "请填写必填项" }]
                          }
                        ],
                        expression:
                          "['materialTpl', \n                {\n                    initialValue: formData.materialTpl,\n                    rules: [{required: true, message: '请填写必填项'}]\n                }\n            ]"
                      }
                    ],
                    attrs: { disabled: _vm.formDisabled }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "广告物料" } },
                [
                  _c("AdMaterialList", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "material_content_terminal_list",
                          {
                            initialValue:
                              _vm.formData.material_content_terminal_list,
                            rules: [
                              { required: true, message: "请填写必填项" },
                              {
                                validator: _vm.validators.materialListValidator(
                                  _vm.tplClass
                                )
                              }
                            ]
                          }
                        ],
                        expression:
                          "['material_content_terminal_list', \n                    {\n                        initialValue: formData.material_content_terminal_list,\n                        rules: [\n                            {required: true, message: '请填写必填项'},\n                            {validator: validators.materialListValidator(tplClass)}\n                        ]\n                    }\n                ]"
                      }
                    ],
                    attrs: {
                      tplClass: _vm.tplClass,
                      max: _vm.tplClassMaxNumber,
                      disabled: !_vm.tplClass || _vm.formDisabled
                    }
                  })
                ],
                1
              ),
              !_vm.formData.isDefault
                ? _c(
                    "a-form-item",
                    { attrs: { label: "分配" } },
                    [
                      _c("AdAssign", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "assign",
                              {
                                initialValue: _vm.formData.assign,
                                rules: [
                                  {
                                    required: true,
                                    message: "请填写必填项"
                                  },
                                  {
                                    validator: _vm.validators.assignValidator
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                    'assign',\n                    {\n                        initialValue: formData.assign,\n                        rules: [{\n                            required: true,\n                            message: '请填写必填项'\n                        }, {\n                            validator: validators.assignValidator\n                        }]\n                    }\n                ]"
                          }
                        ],
                        attrs: { disabled: _vm.formDisabled }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { style: { textAlign: "left" }, attrs: { span: 24 } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            disabled: _vm.formDisabled
                          }
                        },
                        [_vm._v("\n                    提交\n                ")]
                      ),
                      _c(
                        "a-button",
                        {
                          style: { marginLeft: "8px" },
                          on: { click: _vm.handleCancel }
                        },
                        [_vm._v("\n                    取消\n                ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("AdAssignProgress", { ref: "assign" }),
      _c("AdConflictModal", { ref: "conflict" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }