










































































































import {Modal} from 'ant-design-vue';
import {Component, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import UploadImage from '@/components/custom-form-components/UploadImage.vue';
import ImageCrop from '@/components/custom-form-components/ImageCrop/index.vue';
import config from '@/config';
import {SignUpActivityStatus, SignUpActivityType,DisableDatePicker,districtCodeTransfer} from '@/components/custom-form-components';
import VueUeditorWrap from 'vue-ueditor-wrap';
interface SearchForm {
    form: any;
    hasLogo: boolean;
}

@Component({
    components: {
        UploadImage,
        ImageCrop,
        DisableDatePicker,
        districtCodeTransfer,
        SignUpActivityStatus,
        SignUpActivityType,
        VueUeditorWrap
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.activitySignUpPage.loading,
        }),
        editorProps() {
            return {
                key: 'key_' + SearchForm.inc++,
                config: {
                    serverUrl: config.BASE_URL + '/console/ad/ueditor',
                    autoHeightEnabled: false,
                    initialFrameHeight:
                        this.bodyClientHeight -
                        this.toolBarHeight -
                        this.bottomHeight -
                        5,
                    initialFrameWidth: '100%',
                    UEDITOR_HOME_URL: process.env.BASE_URL + 'UEditor/',
                    imagePopup: false,
                    toolbars: [
                        [
                            'source',
                            '|',
                            'undo',
                            'redo',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            'fontborder',
                            'strikethrough',
                            'superscript',
                            'subscript',
                            'removeformat',
                            'formatmatch',
                            'autotypeset',
                            'blockquote',
                            'pasteplain',
                            '|',
                            'forecolor',
                            'backcolor',
                            'insertorderedlist',
                            'insertunorderedlist',
                            'selectall',
                            '|',
                            'rowspacingtop',
                            'rowspacingbottom',
                            'lineheight',
                            '|',
                            'paragraph',
                            'fontfamily',
                            'fontsize',
                            '|',
                            'indent',
                            '|',
                            'justifyleft',
                            'justifycenter',
                            'justifyright',
                            'justifyjustify',
                            '|',
                            'touppercase',
                            'tolowercase',
                            '|',
                            'link',
                            'unlink',
                            '|',
                            'imagenone',
                            'imageleft',
                            'imageright',
                            'imagecenter',
                            '|',
                            'simpleupload',
                            'emotion',
                            'insertvideo',
                            'map',
                            'pagebreak',
                            '|',
                            'horizontal',
                            'spechars',
                            '|',
                            'inserttable',
                            'deletetable',
                            'insertparagraphbeforetable',
                            'insertrow',
                            'deleterow',
                            'insertcol',
                            'deletecol',
                            'mergecells',
                            'mergeright',
                            'mergedown',
                            'splittocells',
                            'splittorows',
                            'splittocols',
                            '|',
                            'print',
                            'searchreplace'
                        ]
                    ]
                }
            };
        }
    }
})
class SearchForm extends Vue {
    public districtCodeList = [];
    public remark='';
    public data() {
        return {
            
            config,
            form: this.$form.createForm(this),
            hasLogo: false,
            districtCodeList:[]
        };
    }
    public created() {
        // setTimeout(() =>{
        //    this.districtCodeList= this.$store.state.activitySignUpPage.districtCodeData;
        // },9000);
        //  console.log("districtCodeList:"+this.districtCodeList);
    }
    private changeDistrictCodeData(rawData: any) {
        this.districtCodeList = rawData;
    }
    public handleCreate(e: any) {
        
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                if(!this.remark || this.remark.length>8048){
                    this.$message.error('请输入8048字以内的活动描述');
                    return;
                }
                let applyEndTime = new Date(values.signUpTime[1]);
                let endTIme = new Date(values.activityTime[1]);
                if(applyEndTime.getTime()>endTIme.getTime()){
                    this.$message.error('报名结束时间不能大于活动时间的结束时间');
                    return;
                }

                if(null != this.districtCodeList && this.districtCodeList.length >5 ){
                    this.$message.error('最多勾选5个城市');
                    return;
                }
                console.log(values.initCount+'----'+values.totalCount);
                if(values.initCount && parseFloat(values.initCount) >= parseFloat(values.totalCount)){
                    this.$message.error('初始名额必须小于总名额');
                    return;
                }
               
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => {
                        this.$store.dispatch(
                            'activitySignUpPage/create',
                            this.formatParams(values)
                        );
                        this.$message.success('提交成功');
                        const queryString = this.$store.getters[
                            'activitySignUpPage/queryString'
                        ]();
                        this.$router.push({
                            path: '/console/config/search' + queryString
                        });
                    },
                    onCancel() {
                        // do nothing
                    }
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    @Watch('$store.state.activitySignUpPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$message.success('提交成功');
            const queryString = this.$store.getters[
                'activitySignUpPage/queryString'
            ]();
            this.$router.push({
                path: '/console/config/search' + queryString,
            });
        }
    }
    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters['activitySignUpPage/queryString']();
                this.$router.push({
                    path: '/console/config/search' + queryString
                });
            },
            onCancel() {
                // do nothing
            }
        });
    }

    /**
     * 将表单数据格式化为请求参数
     */
    private formatParams(formData: any) {
        console.log("formData",formData);
        let initCount = 0;
        if(formData.initCount){
            initCount = formData.initCount;
        }
        console.log("000");
        const formValue =  {
            activityName: formData.name,
            startTime: formData.activityTime[0].format(
            'YYYY-MM-DD HH:mm:ss'
            ),
            endTime: formData.activityTime[1].format(
                'YYYY-MM-DD HH:mm:ss'
            ),
            applyStartTime: formData.signUpTime[0].format(
                'YYYY-MM-DD HH:mm:ss'
            ),
            applyEndTime: formData.signUpTime[1].format(
                'YYYY-MM-DD HH:mm:ss'
            ),
            activityType: formData.activityType,
            activityImage: formData.logo && formData.logo[0] && formData.logo[0].url,
            totalCount: formData.totalCount,
            initCount: initCount,
            districtCodeList: this.districtCodeList,
            remark: this.remark
        };
        console.log(formValue);
        return formValue;
    }
}

export default SearchForm;
