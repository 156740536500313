








































import { Component, Prop,Inject, Vue, Watch } from 'vue-property-decorator';
import config from '@/config';
import {mapState} from 'vuex';
import {check, Show, Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
const fakeDoneFile = {
    name: '上传完成',
    status: 'done',
    uid: 'uid'
};

@Component<HexiaoUpload>({
    computed: {
        ...mapState({
            token: (state: any) => {
                return state.login.session && state.login.session.token;
            }
        })
    }
})
class HexiaoUpload extends Vue {

    @Prop()
    public value!: any;
    @Inject()
    public dispatch!: any;
    @Inject()
    public privileges!: any;

    public PRIVILEGE = PRIVILEGE;

    public visible = false;

    public config = config;

    public uploading = false;

    public fileList: any = [];

    public error: any = [];

    public created() {
        if (this.value && this.value.length > 0) {
            this.fileList = [fakeDoneFile];
        }
        this.error = [];
    }
    public onClick() {
        this.visible = true;
    }
    public handleTriggerClick() {
        this.visible = true;
        this.error = [];
        this.fileList = [];
    }

    public handlerRemove() {
        this.fileList = [];
    }
    public disabledUpload() {
            if (check([this.PRIVILEGE.DISTRICT_CODE.UPLOAD_EXCEL], this.privileges).length > 0) {
                return true;
            }
            return false;
        }
    public handleFileUpload({file, fileList, event}: any) {
        this.fileList = [{
            ...file,
            name: ({
                uploading: '正在上传',
                done: '导入成功！',
                error: '导入失败！请检查网络或联系技术人员！',
                removed: ''
            } as any)[file.status]
        }];
        this.uploading = true;
        if (file && file.status === 'done' && file.response) {
            if (file.response.status.code !== 0) {
                this.$message.error(file.response.status.desc);
            } else {
                const errors = file.response.result;
                if (errors.length > 0) {
                    this.fileList = [];
                    this.error = errors;
                    return;
                    // return this.$message.error(errors[0]);
                } else {
                    this.error = [];
                     this.visible = false;
                    this.dispatch('search');
                }
                this.visible = false;
            }
        }

        if (file.status !== 'uploading') {
            this.uploading = false;
        }
    }
     
    public handleDownloadTemplateClick() {
        window.open(process.env.BASE_URL + 'district_code.xlsx', '_blank')
    }

}
export default HexiaoUpload;
