var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.ACTIVITY_INSPECTIOON.LIST] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [_vm._t("trigger")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            title: "外呼结果",
            visible: _vm.visible,
            footer: "",
            width: 900
          },
          on: { ok: _vm.handleOK, cancel: _vm.handleClose }
        },
        _vm._l(this.callQuestions, function(item, index) {
          return _c("span", [
            _c("br"),
            _vm._v(
              "\n                   问题： " +
                _vm._s(item.question) +
                "\n                    "
            ),
            _c("br"),
            _vm._v(
              "\n                   回答： " +
                _vm._s(item.answer) +
                "\n                "
            )
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }