var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning } },
        [
          _c(
            "a-tabs",
            {
              attrs: { activeKey: _vm.activeTab, type: "card" },
              on: { change: _vm.tabChange }
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "基础规则" } },
                [
                  _vm.formData
                    ? _c(
                        "a-form",
                        {
                          staticClass: "ad-create-form",
                          attrs: { form: _vm.baseForm },
                          on: { submit: _vm.handleNext }
                        },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "活动名称" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "name",
                                      {
                                        initialValue: _vm.formData.name,
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写必填项"
                                          },
                                          {
                                            max: 64,
                                            message:
                                              "长度超限，请检查后重新输入!"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                                'name',\n                                {\n                                    initialValue: formData.name,\n                                    rules: [\n                                        {required: true, message: '请填写必填项'},\n                                        {max: 64, message: '长度超限，请检查后重新输入!'}\n                                    ]\n                                }\n                            ]"
                                  }
                                ],
                                attrs: {
                                  maxLength: "64",
                                  placeholder: "请输入64个字以内"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "主题活动ID" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "budgetActivity",
                                      {
                                        initialValue:
                                          _vm.formData.budgetActivity,
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写必填项"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                                'budgetActivity',\n                                {\n                                    initialValue: formData.budgetActivity,\n                                    rules: [\n                                        {required: true, message: '请填写必填项'}\n                                    ]\n                                }\n                            ]"
                                  }
                                ],
                                attrs: {
                                  disabled: true,
                                  placeholder: "请输入主题活动ID"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "批次号" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "stockId",
                                      {
                                        initialValue: _vm.formData.stockId,
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写必填项"
                                          },
                                          {
                                            pattern: /^[^\s]*$/,
                                            message: "不允许出现空格"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                                'stockId',\n                                {\n                                    initialValue: formData.stockId,\n                                    rules: [\n                                        {required: true, message: '请填写必填项'},{pattern: /^[^\\s]*$/, message: '不允许出现空格'}\n                                    ]\n                                }\n                            ]"
                                  }
                                ],
                                attrs: { disabled: true, placeholder: "" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "是否在小程序展示" } },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "showFlag",
                                        {
                                          initialValue: _vm.formData.showFlag
                                        }
                                      ],
                                      expression:
                                        "['showFlag',\n                                {\n                                    initialValue: formData.showFlag\n                                }\n                            ]"
                                    }
                                  ],
                                  attrs: { disabled: true }
                                },
                                [
                                  _c("a-radio", { attrs: { value: "0" } }, [
                                    _vm._v(
                                      "\n                                是\n                            "
                                    )
                                  ]),
                                  _c("a-radio", { attrs: { value: "1" } }, [
                                    _vm._v(
                                      "\n                                否\n                            "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "优惠渠道" } },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "channelId",
                                        {
                                          initialValue: _vm.formData.channelId,
                                          rules: [
                                            {
                                              required: true,
                                              message: "优惠渠道不能为空"
                                            }
                                          ]
                                        }
                                      ],
                                      expression:
                                        "[\n                            'channelId',\n                            {\n                                initialValue: formData.channelId,\n                                rules: [\n                                    { required: true, message: '优惠渠道不能为空' }\n                                ]\n                            },\n                        ]"
                                    }
                                  ],
                                  attrs: {
                                    disabled: true,
                                    placeholder: "请选择优惠渠道"
                                  }
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "weixin" } },
                                    [
                                      _vm._v(
                                        "\n                            微信\n                        "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "alipay" } },
                                    [
                                      _vm._v(
                                        "\n                            支付宝\n                        "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "unionpay" } },
                                    [
                                      _vm._v(
                                        "\n                            银联\n                        "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "lakala" } },
                                    [
                                      _vm._v(
                                        "\n                            拉卡拉\n                        "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "面额" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "promoAmount",
                                      {
                                        initialValue: (
                                          _vm.formData.promoAmount / 100
                                        ).toFixed(2),
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写必填项"
                                          },
                                          {
                                            pattern: /^[1-9]{1}\d{0,8}(\.\d{1,2})?$/,
                                            message:
                                              "请输入正确的金额,必须大于1元"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                                'promoAmount',\n                                {\n                                    initialValue: (formData.promoAmount / 100).toFixed(2),\n                                    rules: [\n                                        {required: true, message: '请填写必填项'},\n                                        {pattern: /^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$/, message: '请输入正确的金额,必须大于1元'}\n                                    ]\n                                }\n                            ]"
                                  }
                                ],
                                attrs: {
                                  disabled: true,
                                  placeholder: "不能为空/面额不小于1元"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "使用门槛" } },
                            [
                              _vm._v(
                                "\n                        消费满 \n                        "
                              ),
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "orderMinAmt",
                                      {
                                        initialValue: (
                                          _vm.formData.orderMinAmt / 100
                                        ).toFixed(2),
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写必填项"
                                          },
                                          {
                                            pattern: /^[1-9]{1}\d{0,8}(\.\d{1,2})?$/,
                                            message:
                                              "金额不能为空/必须大于1元/使用门槛金额需大于券面额"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                                'orderMinAmt',\n                                {\n                                    initialValue: (formData.orderMinAmt / 100).toFixed(2),\n                                     rules: [\n                                        {required: true, message: '请填写必填项'},\n                                        {pattern: /^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$/, message: '金额不能为空/必须大于1元/使用门槛金额需大于券面额'}\n                                    ]\n                                }\n                            ]"
                                  }
                                ],
                                staticStyle: { width: "70%" },
                                attrs: {
                                  disabled: true,
                                  placeholder:
                                    "不能为空/大于1元/金额需大于券面额"
                                }
                              }),
                              _vm._v(
                                "\n                         元可使用\n                    "
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "活动有效期" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "startTime",
                                      {
                                        initialValue:
                                          _vm.formData.validStartTime +
                                          "—" +
                                          _vm.formData.validEndTime
                                      }
                                    ],
                                    expression:
                                      "[\n                                'startTime',\n                                {\n                                    initialValue: formData.validStartTime +'—'+formData.validEndTime\n                                }\n                            ]"
                                  }
                                ],
                                attrs: { disabled: true, placeholder: "" }
                              }),
                              _c(
                                "a-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "validType",
                                        {
                                          initialValue: _vm.formData.validType,
                                          rules: [{ required: true }]
                                        }
                                      ],
                                      expression:
                                        "[\n                                'validType', \n                                {\n                                    initialValue: formData.validType,\n                                    rules: [{required: true}]\n                                }\n                            ]"
                                    }
                                  ],
                                  attrs: { disabled: true }
                                },
                                [
                                  _c(
                                    "a-radio",
                                    { attrs: { value: "ABSOLUTE" } },
                                    [_vm._v("有效期内，任意时段可用")]
                                  ),
                                  _c(
                                    "a-radio",
                                    { attrs: { value: "RELATIVE" } },
                                    [
                                      _vm._v(
                                        "\n                                有效期内，用户领取后天\n                                "
                                      ),
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "validDays",
                                              {
                                                initialValue:
                                                  _vm.formData.validDays
                                              }
                                            ],
                                            expression:
                                              "[\n                                        'validDays',\n                                        {initialValue: formData.validDays}\n                                    ]"
                                          }
                                        ],
                                        staticStyle: { width: "20%" },
                                        attrs: { disabled: true }
                                      }),
                                      _vm._v(
                                        "\n                                内可用\n                            "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "券状态" } },
                            [
                              _vm._v(
                                "\n                            \n                        "
                              ),
                              _c(
                                "a-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "status",
                                        {
                                          initialValue: _vm.formData.status
                                        }
                                      ],
                                      expression:
                                        "['status',\n                            {\n                                initialValue: formData.status,\n                            }]"
                                    }
                                  ]
                                },
                                [
                                  _c("a-radio", { attrs: { value: "1" } }, [
                                    _vm._v(
                                      "\n                                生效\n                            "
                                    )
                                  ]),
                                  _c("a-radio", { attrs: { value: "2" } }, [
                                    _vm._v(
                                      "\n                                失效\n                            "
                                    )
                                  ]),
                                  _c("a-radio", { attrs: { value: "3" } }, [
                                    _vm._v(
                                      "\n                                已删除\n                            "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "使用说明" } },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "description",
                                      {
                                        initialValue: _vm.formData.description,
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写必填项!"
                                          },
                                          {
                                            pattern: /^.{1,1024}$/,
                                            message: "必填，限制1024字"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                                'description',\n                                {\n                                    initialValue: formData.description,\n                                    rules: [\n                                        {required: true, message: '请填写必填项!'},\n                                        {pattern: /^.{1,1024}$/, message: '必填，限制1024字'}\n                                    ]\n                                }\n                            ]"
                                  }
                                ],
                                attrs: {
                                  disabled: true,
                                  placeholder: "必填，限制1024字"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "是否结算" } },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "settleFlag",
                                        {
                                          initialValue: _vm.formData.settleFlag
                                        }
                                      ],
                                      expression:
                                        "[\n                                'settleFlag', \n                                {\n                                    initialValue: formData.settleFlag,\n                                }\n                            ]"
                                    }
                                  ],
                                  attrs: { disabled: true }
                                },
                                [
                                  _c(
                                    "a-radio",
                                    { attrs: { value: "1", checked: true } },
                                    [_vm._v("是")]
                                  ),
                                  _c(
                                    "a-radio",
                                    { attrs: { value: "0", checked: true } },
                                    [_vm._v("否")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.formData.settleFlag == "1",
                                  expression: "formData.settleFlag == '1'"
                                }
                              ],
                              attrs: { label: "结算比：" }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "settleRate	",
                                      {
                                        initialValue: "100",
                                        rules: [
                                          {
                                            required: false,
                                            message: "请输入整数/不能为0或100"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                                'settleRate\t',\n                                {\n                                    initialValue: '100',\n                                    rules: [{required: false, message: '请输入整数/不能为0或100'}]\n                                }\n                            ]"
                                  }
                                ],
                                staticStyle: { width: "95%" },
                                attrs: { disabled: true, type: "number" }
                              }),
                              _vm._v(" %\n                        ")
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                {
                                  style: { textAlign: "left" },
                                  attrs: { span: 24 }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        "html-type": "submit"
                                      }
                                    },
                                    [_vm._v("下一步")]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      style: { marginLeft: "8px" },
                                      on: { click: _vm.handleCancel }
                                    },
                                    [_vm._v("取消")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "其他规则" } },
                [
                  _vm.formData
                    ? _c(
                        "a-form",
                        {
                          staticClass: "ad-create-form",
                          attrs: { form: _vm.merchantForm },
                          on: { submit: _vm.handleNext2 }
                        },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "上传类型" } },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "listType",
                                        { initialValue: _vm.formData.listType }
                                      ],
                                      expression:
                                        "['listType', {initialValue: formData.listType}]"
                                    }
                                  ],
                                  attrs: { disabled: true },
                                  on: { change: _vm.chooseMerchantType }
                                },
                                [
                                  _c(
                                    "a-radio",
                                    {
                                      attrs: {
                                        value: "merIdAndTermId",
                                        checked: true
                                      }
                                    },
                                    [_vm._v("商户号和终端号")]
                                  ),
                                  _c(
                                    "a-radio",
                                    {
                                      attrs: { value: "merId", checked: true }
                                    },
                                    [_vm._v("商户号")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "活动店铺", required: "" } },
                            [
                              _c("CreateModal", {
                                attrs: { onlyMer: _vm.onlyMer },
                                on: {
                                  change: function($event) {
                                    return _vm.changeShopData($event)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "trigger",
                                      fn: function() {
                                        return [
                                          _c(
                                            "a-button",
                                            { attrs: { type: "primary" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.onlyMer
                                                    ? "点击添加商户号"
                                                    : "点击添加商户号和对应线下终端号"
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2706522483
                                )
                              }),
                              _c(
                                "ShopUpload",
                                {
                                  attrs: { onlyMer: _vm.onlyMer },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeShopData($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "trigger" },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: { "margin-left": "20px" }
                                        },
                                        [_vm._v("批量上传")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _vm.shopData.length > 0
                            ? _c(
                                "a-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c("a-table", {
                                    attrs: {
                                      columns: _vm.onlyMer
                                        ? _vm.noTermNocolumns
                                        : _vm.hasTermNocolumns,
                                      dataSource: _vm.shopData,
                                      size: "small"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "action",
                                          fn: function(text, record) {
                                            return _c(
                                              "a",
                                              {
                                                attrs: { href: "javascript:;" },
                                                on: {
                                                  click: function() {
                                                    return _vm.deleteShopData(
                                                      record
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("删除")]
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2353402790
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                {
                                  style: { textAlign: "left" },
                                  attrs: { span: 24 }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      style: { marginRight: "8px" },
                                      on: { click: _vm.handleCancel }
                                    },
                                    [_vm._v("返回")]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      style: { marginRight: "8px" },
                                      on: {
                                        click: function($event) {
                                          _vm.activeTab = "1"
                                        }
                                      }
                                    },
                                    [_vm._v("上一步")]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        "html-type": "submit"
                                      }
                                    },
                                    [_vm._v("提交")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }