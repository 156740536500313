var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate }
        },
        [
          _c(
            "span",
            { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
            [_vm._v("基本信息")]
          ),
          _c("a-divider", { staticStyle: { margin: "6px 0" } }),
          _c(
            "a-form-item",
            { attrs: { label: "名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "actName",
                      {
                        initialValue: _vm.formData.name,
                        rules: [
                          { required: true, message: "请填写必填项" },
                          { max: 64, message: "长度超限，请检查后重新输入!" }
                        ]
                      }
                    ],
                    expression:
                      "[\n                    'actName',\n                    { initialValue: formData.name,\n                        rules: [ \n                            {required: true, message: '请填写必填项'},\n                            {max: 64, message: '长度超限，请检查后重新输入!'}\n                        ]\n                    }\n                ]"
                  }
                ],
                attrs: {
                  disabled: true,
                  maxLength: 64,
                  placeholder: "请输入64个字以内"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "类型" } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "actType",
                        {
                          initialValue: _vm.formData.templetType,
                          rules: [{ required: true, message: "类型不能为空" }]
                        }
                      ],
                      expression:
                        "[\n                    'actType',\n                    { initialValue: formData.templetType,\n                        rules: [\n                            { required: true, message: '类型不能为空' }\n                        ]\n                    },\n                ]"
                    }
                  ],
                  attrs: { disabled: true, placeholder: "请选择" }
                },
                [
                  _c("a-select-option", { attrs: { value: "OPEN_CARD" } }, [
                    _vm._v("开卡活动")
                  ]),
                  _c("a-select-option", { attrs: { value: "MARKET_ACT" } }, [
                    _vm._v("营销活动")
                  ]),
                  _c("a-select-option", { attrs: { value: "FEE_SUBSIDY" } }, [
                    _vm._v("手续费补贴")
                  ]),
                  _c(
                    "a-select-option",
                    { attrs: { value: "DEPOSIT_REWARD" } },
                    [_vm._v("存款奖励")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动时间" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityTime",
                      {
                        initialValue:
                          _vm.formData.startTime + "—" + _vm.formData.endTime
                      }
                    ],
                    expression:
                      "[\n                    'activityTime',\n                    {\n                        initialValue: formData.startTime +'—'+formData.endTime\n                    }\n                ]"
                  }
                ],
                attrs: { disabled: true, placeholder: "-" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "APP展示银行" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "actBank",
                      {
                        initialValue: _vm.formData.actBank
                      }
                    ],
                    expression:
                      "['actBank', \n                    {\n                        initialValue: formData.actBank\n                    }\n                ]\n            "
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "数据传输银行" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "transmittedBank",
                      {
                        initialValue: _vm.formData.transmittedBank
                      }
                    ],
                    expression:
                      "['transmittedBank', \n                    {\n                        initialValue: formData.transmittedBank\n                    }\n                ]\n            "
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "银行机构" } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "bankId",
                        {
                          initialValue: _vm.formData.bankId
                        }
                      ],
                      expression:
                        "['bankId', \n                    {\n                        initialValue: formData.bankId\n                    }\n                ]"
                    }
                  ],
                  attrs: {
                    showSearch: "",
                    allowClear: "",
                    disabled: true,
                    placeholder: "请选择"
                  }
                },
                _vm._l(_vm.bankIDList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.bankId, attrs: { value: item.bankId } },
                    [_vm._v(_vm._s(item.bankName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动区域" } },
            [
              _c("districtCodeTransfer", {
                attrs: {
                  disabled: true,
                  targetDistrictCodeData: _vm.formData.actRegionArea
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动详情" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "actDetail",
                      {
                        initialValue: _vm.formData.description,
                        rules: [
                          { pattern: /^.{0,300}$/, message: "必填，限制400字" }
                        ]
                      }
                    ],
                    expression:
                      "[\n                    'actDetail',\n                    {initialValue:formData.description,\n                        rules: [\n                            {pattern: /^.{0,300}$/, message: '必填，限制400字'}\n                        ]\n                    }\n                ]"
                  }
                ],
                attrs: {
                  disabled: true,
                  placeholder: "请输入",
                  autosize: { minRows: 5, maxRows: 10 }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "MCC限制" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merMcc",
                      {
                        initialValue: _vm.formData.merMcc,
                        rules: [
                          { pattern: /^.{0,300}$/, message: "必填，限制400字" }
                        ]
                      }
                    ],
                    expression:
                      "[\n                    'merMcc',\n                    { initialValue:formData.merMcc,\n                        rules: [\n                            {pattern: /^.{0,300}$/, message: '必填，限制400字'}\n                        ]\n                    }\n                ]"
                  }
                ],
                attrs: { disabled: true, autosize: { minRows: 5, maxRows: 10 } }
              })
            ],
            1
          ),
          _c(
            "span",
            { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
            [_vm._v("其他规则")]
          ),
          _c("a-divider", { staticStyle: { margin: "6px 0" } }),
          _vm._l(_vm.configData, function(item, index) {
            return _c(
              "span",
              { key: index },
              [
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !item.disabled || _vm.validObj[item.key] == "Y",
                        expression:
                          "!item.disabled || validObj[item.key] == 'Y'"
                      }
                    ],
                    attrs: { label: item.label }
                  },
                  [
                    item.type == null
                      ? _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                item.key,
                                { initialValue: _vm.formData[item.key] }
                              ],
                              expression:
                                "[item.key ,{initialValue: formData[item.key]}]"
                            }
                          ],
                          attrs: { disabled: true }
                        })
                      : _vm._e(),
                    item.type == "radio"
                      ? _c(
                          "a-radio-group",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  item.key,
                                  { initialValue: _vm.formData[item.key] + "" }
                                ],
                                expression:
                                  "[item.key,{initialValue: formData[item.key]+''}]"
                              }
                            ],
                            attrs: { disabled: true }
                          },
                          _vm._l(item.options, function(option, index) {
                            return _c(
                              "span",
                              { key: index },
                              [
                                _c(
                                  "a-radio",
                                  {
                                    attrs: {
                                      value: option.value,
                                      checked: true
                                    }
                                  },
                                  [_vm._v(_vm._s(option.label))]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    item.type == "checkbox"
                      ? _c(
                          "a-checkbox-group",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  item.key,
                                  { initialValue: _vm.formData[item.key] }
                                ],
                                expression:
                                  "[item.key,{initialValue: formData[item.key]}]"
                              }
                            ],
                            attrs: { disabled: true }
                          },
                          [
                            _c(
                              "a-row",
                              _vm._l(item.options, function(option, index) {
                                return _c(
                                  "span",
                                  { key: index },
                                  [
                                    _c(
                                      "a-checkbox",
                                      { attrs: { value: option.value } },
                                      [_vm._v(_vm._s(option.label))]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "textarea"
                      ? _c("a-textarea", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                item.key,
                                { initialValue: _vm.formData[item.key] }
                              ],
                              expression:
                                "[item.key,{initialValue: formData[item.key]}]"
                            }
                          ],
                          attrs: {
                            disabled: true,
                            autosize: { minRows: 2, maxRows: 6 },
                            maxlength: "256"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }