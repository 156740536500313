










import CommonLayout from '@/components/CommonLayout.vue';
import Actions from './Actions.vue';
import SearchTable from './SearchTable.vue';
import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import columns from './columns';
import {queryToHumanReadable} from '@/utils';


@Component({
    components: {
        Actions,
        SearchTable,
        CommonLayout
    },
    computed: {
        ...mapState({
            fields: (state: any) => state.sendTaskSearchPage.fields,
        })
    }
})
export default class SendTaskSearchPage extends Vue {

    @Prop()
    public active!: boolean;

    public storeName = 'sendTaskSearchPage';

    @Provide()
    public state() {
        return this.$store.state[this.storeName];
    }

    @Provide()
    public getters(name: string) {
        return this.$store.getters[this.storeName + '/' + name];
    }

    @Provide()
    public commit(mutation: string, payload?: any) {
        this.$store.commit(
            this.storeName + '/' + mutation,
            payload
        );
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(
            this.storeName + '/' + action,
            payload
        );
    }

    @Provide()
    public routerPush() {
        this.$router.push({
            path: '/console/store/sms/send/task' + this.getters('queryString')()
        });
    }

    public data() {
        return {
            columns
        };
    }

    public created() {
        // this.$store.dispatch('sendTaskSearchPage/initQuery', {
        //     ...this.$route.query
        // });
    }

    @Watch('$store.state.sendTaskSearchPage.error')
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    

    private handleFieldChange(e: any) {
        this.$store.commit('sendTaskSearchPage/setFields', e);
    }
}
