












































import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import DatePicker from '@/components/custom-form-components/DatePicker.vue';
import { deleteEmpty, trimParams } from '@/utils';
import qs from 'querystringify';
import moment from 'moment';
import formRules from '@/common/formRules';
import { ShortMessageStatus } from '@/components/custom-form-components';

interface SearchForm {
    form: any;
}

export interface Query {
    mobile: string;
}

export interface FormData {
    mobile: string;
}

@Component({
    components: {
        DatePicker,
        ShortMessageStatus,
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.shopWhiteListSearchPage.query);
            },
            loading: (state: any) => state.shopWhiteListSearchPage.loading,
        }),
    },
})
class SearchForm extends Vue {
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this),
            formRules,
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            console.log('mobile', query)
            this.$router.push({
                path: `/console/shopWhiteList/search?${qs.stringify(query)}`,
            });
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined,
            });
        });
    }

    private formatQuery(values: FormData): Query {
        return {
            mobile: values.mobile,
        };
    }

    private formatFormData(query: Query): FormData {
        return {
            mobile: query.mobile,
        };
    }
}

export default SearchForm;
