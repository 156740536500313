







































































































































































































































































































































































































































































































































































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {
    DisableDatePicker,
    ScheduleTimePicker,
    IrregularScheduleTimePicker,
} from '@/components/custom-form-components';
import CreateModal from './CreateModal.vue';
import ShopUpload from './ShopUpload.vue';
import * as validators from '../../validators';

interface CreateForm {
    baseForm: any;
    discountForm: any;
    merchantForm: any;
}

@Component<CreateForm>({
    components: {
        DisableDatePicker,
        CreateModal,
        ShopUpload,
        ScheduleTimePicker,
        IrregularScheduleTimePicker,
    },
    computed: {
        ...mapState({}),
    },
})
class CreateForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    public validators = validators;
    //页面tab
    public baseForm;
    public discountForm;
    public merchantForm;
    public activeTab: any;

    public baseData: any;
    public shopData: any;
    public activitySponsors: any;
    public discountType: any;

    public created() {}

    public data() {
        return {
            activeTab: '1',
            baseData: {},
            //第一页数据
            baseForm: this.$form.createForm(this),
            activitySponsors: [],
            //第二页数据
            discountForm: this.$form.createForm(this),
            discountType: '2',

            //第三页数据
            merchantForm: this.$form.createForm(this),
            shopData: [],
            columns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo',
                },
                {
                    title: '终端号',
                    dataIndex: 'termNo',
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action',
                    },
                },
            ],
        };
    }

    public tabChange(key: any) {
        if (key === '2') {
            this.handleNextAction();
        } else if (key === '3') {
            this.handleNextAction2();
        } else {
            console.info('activeTab', key);
            this.activeTab = '1';
        }
    }

    public handleNext(e: any) {
        e.preventDefault();
        this.handleNextAction();
    }

    public handleNext2(e: any) {
        e.preventDefault();
        this.handleNextAction2();
    }
    public handleNext3(e: any) {
        e.preventDefault();
        this.handleNextAction3();
    }

    public handleNextAction() {
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                this.formatBaseForm(values, '1');
                this.activeTab = '2';
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    public handleNextAction2() {
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                this.formatBaseForm(values, '1');
                this.discountForm.validateFields((error: any, values: any) => {
                    if (!error) {
                        const orderMinAmt = values.orderMinAmount;
                        if (orderMinAmt) {
                            var reg = /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/;
                            if (reg.test(orderMinAmt) == false) {
                                this.$message.error(
                                    '订单最小金额只能填数字且不能为0'
                                );
                                return false;
                            }
                            if (values.discountType == 0 && orderMinAmt * 100 <= values.firstValue0 * 100 ) {
                                this.$message.error(
                                    '订单最小金额必须大于立减金额'
                                );
                                return false;
                            }
                            if (values.discountType == 2 && orderMinAmt * 100 <= values.firstValue2 * 100 ) {
                                this.$message.error(
                                    '订单最小金额必须大于随机立减 金额下限'
                                );
                                return false;
                            }
                        }

                        const rangeMaxAmt = parseFloat(values.secValue2);
                        const rangeMinAmt = parseFloat(values.firstValue2);
                        console.log(rangeMaxAmt, rangeMinAmt);
                        console.log(rangeMaxAmt < rangeMinAmt);
                        if (
                            '2' === values.discountType &&
                            rangeMaxAmt * 100 < rangeMinAmt * 100
                        ) {
                            this.$message.error('随机立减 上限金额需大于下限金额');
                            return false;
                        }
                        this.formatBaseForm(values, '2');
                        this.activeTab = '3';
                    } else {
                        this.$message.error('请填写优惠的正确信息');
                    }
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    public handleNextAction3() {
        this.merchantForm.validateFields((error: any, values: any) => {
            if (!error) {
                console.info('merchantForm start');
                this.formatBaseForm(values, '3');
                if (null == this.shopData || this.shopData.length < 1) {
                    this.$message.error('请添加活动商户');
                    return;
                }
                console.info('handleSubmitConfirm start');
                this.handleSubmitConfirm(values);
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public changeDiscountType(e: any) {
        this.discountType = e.target.value;
    }

    public changeShopData(value: any) {
        if (this.shopData.length === 3000) {
            this.$message.error('店铺最多3000家上限');
            return;
        }
        value.forEach((data: any) => {
            let flag = false;
            for (let shop of this.shopData) {
                if (shop.merNo === data[0] && shop.termNo === data[1]) {
                    flag = true;
                    break;
                }
            }
            if (!flag) {
                const objData = {
                    index: this.shopData.length + 1,
                    merNo: data[0],
                    termNo: data[1],
                };
                this.shopData.push(objData);
            }
        });
    }

    public deleteShopData(record: any) {
        this.shopData = (this.shopData as object[]).filter(
            (data: any) => data.index !== record.index
        );
    }

    public handleSubmitConfirm(values: any) {
        Modal.confirm({
            title: '提示',
            content: '是否确认提交?',
            onOk: () => {
                let baseData = this.baseData;

                const formData = { ...baseData };
                console.log(formData);
                this.$store.dispatch(
                    'activityCreateUnionPage/create',
                    formData
                );
            },
        });
    }

    @Watch('$store.state.activityCreateUnionPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$message.success('提交成功');
            const queryString = this.$store.getters[
                'activitiesDraftSearchPage/queryString'
            ]();
            this.$router.push({
                path: '/console/activity/draft/search' + queryString,
            });
        }
    }

    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters[
                    'activitiesDraftSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/activity/draft/search' + queryString,
                });
            },
        });
    }

    private formatBaseForm(formData: any, activeTab: any) {
        if (activeTab == '1') {
            this.baseData = {
                activityName: formData.name,
                gwActivityId: formData.gwActivityId,
                orgId: 'lakala',
                platActivity: formData.unionActivityId,
                budgetAmount: (formData.activityTotalAmount * 100).toFixed(0),
                startTime: formData.range_valiable_time[0].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                endTime: formData.range_valiable_time[1].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                warnRatio: formData.warnRatio,
                warnMobile: formData.warnMobile,
                sponsorName: formData.sponsorName,
            };
        } else if (activeTab == '2') {
            if (formData.orderMinAmount) {
                this.baseData.payLine = (formData.orderMinAmount * 100).toFixed(
                    0
                );
            }
            this.baseData.rulePredicate = formData.discountType;
            switch (formData.discountType) {
                case '0':
                    this.baseData.rulePredicate = 'fix';
                    break;
                case '1':
                    this.baseData.rulePredicate = 'discount';
                    break;
                case '2':
                    this.baseData.rulePredicate = 'rand';
                    break;
                case '3':
                    this.baseData.rulePredicate = 'post-discount';
                    break;
                case '4':
                    this.baseData.rulePredicate = 'single';
                    break;
            }
            console.info('firstValue' + formData.discountType);
            console.info(
                'firstValue' + formData['firstValue' + formData.discountType]
            );
            console.info('secValue' + formData.discountType);
            console.info(
                'secValue' + formData['secValue' + formData.discountType]
            );
            if ('0' == formData.discountType) {
                this.baseData.discountConfig = (
                    formData['firstValue' + formData.discountType] * 100
                ).toFixed(0);
            } else if ('1' == formData.discountType) {
                this.baseData.discountConfig =
                    (
                        formData['firstValue' + formData.discountType]
                    ) +
                    ',' +
                    (
                        formData['secValue' + formData.discountType] * 100
                    ).toFixed(0);
            } else if ('4' == formData.discountType) {
                this.baseData.discountConfig =
                    formData['firstValue' + formData.discountType];
            } else {
                this.baseData.discountConfig =
                    (
                        formData['firstValue' + formData.discountType] * 100
                    ).toFixed(0) +
                    ',' +
                    (
                        formData['secValue' + formData.discountType] * 100
                    ).toFixed(0);
            }
        } else if (activeTab == '3') {
            this.baseData.merchantList = this.shopData;
        }
    }

    @Watch('$store.state.adCreatePage.id')
    public watchCreateSuccess(id: string) {}
}

export default CreateForm;
