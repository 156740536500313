var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper" },
    [
      _c(
        "div",
        [
          _vm._v("\n        短信包整体售出笔数/金额统计\n        "),
          _c(
            "a-button",
            {
              attrs: { disabled: _vm.loading, size: "small" },
              on: { click: _vm.fetch }
            },
            [_vm._v("刷新")]
          )
        ],
        1
      ),
      _c("a-table", {
        staticStyle: { "margin-top": "6px" },
        attrs: {
          columns: _vm.fields,
          dataSource: _vm.list,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "--"
          },
          pagination: false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }