




























import {mapState} from 'vuex';
import {Component, Inject, Vue} from 'vue-property-decorator';
import columns from './columns';
import {calcTableScrollX, getTop} from '@/utils';

interface SearchTable {
    selectedRowKeys: any[];
}

@Component({
    components: {
    },
    computed: {
        rowSelection() {
            const self: any = this;
            const {selectedRowKeys} = self;
            return {
                selectedRowKeys,
                onChange: self.onSelectChange,
                hideDefaultSelections: true
            };
        },
        ...mapState({
            sends: (state: any) =>
                state.sendTaskSearchPage.sends.map((order: any, idx: any) => ({
                    idx: (state.sendTaskSearchPage.page - 1) * state.sendTaskSearchPage.pageSize + idx + 1,
                    ...order
                })),
            total: (state: any) => state.sendTaskSearchPage.total,
            pageSize: (state: any) => state.sendTaskSearchPage.pageSize,
            page: (state: any) => state.sendTaskSearchPage.page,
            loading: (state: any) => state.sendTaskSearchPage.loading
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public state!: any;
    
    @Inject()
    public dispatch!: any;

    @Inject()
    public privileges!: any;

    @Inject()
    public commit!: any;

    @Inject()
    public routerPush!: any;

    public fixed = 0;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + (-top) + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            selectedRowKeys: [],
            columns,
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.selectedRowKeys = selectedRowKeys;
    }

    public handlePageChange(page: number) {
        //this.commit('setPagination', {page});
        //this.routerPush();
        //this.dispatch('initQuery', this.$route.query);
        this.dispatch('pageChange', page);
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        //this.commit('setPagination', {pageSize, page: 1});
        //this.routerPush();
        //this.dispatch('initQuery', this.$route.query);
        this.dispatch('pageSizeChange', pageSize);
    }
}

export default SearchTable;
