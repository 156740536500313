var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-tabs",
        {
          attrs: { activeKey: _vm.activeTab, type: "card" },
          on: { change: _vm.tabChange }
        },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "基础规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.baseForm },
                  on: { submit: _vm.handleNext }
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动名称" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "name",
                              {
                                rules: [
                                  { required: true, message: "请填写必填项" },
                                  {
                                    max: 64,
                                    message: "长度超限，请检查后重新输入!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                            'name',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {max: 64, message: '长度超限，请检查后重新输入!'}\n                                ]\n                            }\n                        ]"
                          }
                        ],
                        attrs: {
                          maxLength: "64",
                          placeholder: "请输入64个字以内"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "主题活动ID" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "budgetActivity",
                              {
                                rules: [
                                  { required: true, message: "请填写必填项" },
                                  {
                                    max: 32,
                                    message: "长度超限，请检查后重新输入!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                            'budgetActivity',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {max: 32, message: '长度超限，请检查后重新输入!'}\n                                ]\n                            }\n                        ]"
                          }
                        ],
                        attrs: {
                          type: "number",
                          maxLength: "32",
                          placeholder: "请输入主题活动ID"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "批次号" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "stockId",
                              {
                                rules: [
                                  { required: true, message: "请填写必填项" },
                                  {
                                    max: 32,
                                    message: "长度超限，请检查后重新输入!"
                                  },
                                  {
                                    pattern: /^[^\s]*$/,
                                    message: "不允许出现空格"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                            'stockId',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {max: 32, message: '长度超限，请检查后重新输入!'},{pattern: /^[^\\s]*$/, message: '不允许出现空格'}\n                                ]\n                            }\n                        ]"
                          }
                        ],
                        attrs: {
                          maxLength: "32",
                          placeholder: "请填写已创建的微信活动批次号"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "是否在小程序展示" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: ["showFlag", { initialValue: "0" }],
                              expression: "['showFlag', {initialValue: '0'}]"
                            }
                          ],
                          on: { change: _vm.showAllDiscount }
                        },
                        [
                          _c(
                            "a-radio",
                            { attrs: { value: "0", checked: true } },
                            [_vm._v("是")]
                          ),
                          _c(
                            "a-radio",
                            { attrs: { value: "1", checked: true } },
                            [_vm._v("否")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "优惠渠道" } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "channelId",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "优惠渠道不能为空"
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                            'channelId',\n                            { \n                                rules: [\n                                    { required: true, message: '优惠渠道不能为空' }\n                                ]\n                            },\n                        ]"
                            }
                          ],
                          attrs: { placeholder: "请选择优惠渠道" }
                        },
                        [
                          _c(
                            "a-select-option",
                            { attrs: { value: "weixin" } },
                            [
                              _vm._v(
                                "\n                            微信\n                        "
                              )
                            ]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "alipay" } },
                            [
                              _vm._v(
                                "\n                            支付宝\n                        "
                              )
                            ]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "unionpay" } },
                            [
                              _vm._v(
                                "\n                            银联\n                        "
                              )
                            ]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: "lakala" } },
                            [
                              _vm._v(
                                "\n                            拉卡拉\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "面额" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "promoAmount",
                              {
                                rules: [
                                  { required: true, message: "请填写必填项" },
                                  {
                                    pattern: /^[1-9]{1}\d{0,8}(\.\d{1,2})?$/,
                                    message: "请输入正确的金额,必须大于1元"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                            'promoAmount',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {pattern: /^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$/, message: '请输入正确的金额,必须大于1元'}\n                                ]\n                            }\n                        ]"
                          }
                        ],
                        staticStyle: { width: "96%" },
                        attrs: { placeholder: "不能为空/面额不小于1元" }
                      }),
                      _vm._v(" 元\n                ")
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "使用门槛" } },
                    [
                      _vm._v(
                        "\n                    消费满 \n                    "
                      ),
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "orderMinAmt",
                              {
                                rules: [
                                  { required: true, message: "请填写必填项" },
                                  {
                                    pattern: /^[1-9]{1}\d{0,8}(\.\d{1,2})?$/,
                                    message:
                                      "金额不能为空/必须大于1元/使用门槛金额需大于券面额"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                            'orderMinAmt',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项'},\n                                    {pattern: /^[1-9]{1}\\d{0,8}(\\.\\d{1,2})?$/, message: '金额不能为空/必须大于1元/使用门槛金额需大于券面额'}\n                                ]\n                            }\n                        ]"
                          }
                        ],
                        staticStyle: { width: "75%" },
                        attrs: {
                          placeholder: "不能为空/大于1元/金额需大于券面额"
                        }
                      }),
                      _vm._v(
                        "\n                     元可使用\n                "
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "可用时间" } },
                    [
                      _c("DisableDatePicker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "range_valiable_time",
                              {
                                rules: [
                                  { required: true, message: "请填写必填项" }
                                ]
                              }
                            ],
                            expression:
                              "['range_valiable_time',{rules: [{required: true, message: '请填写必填项'}]}]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "validType",
                                { initialValue: "ABSOLUTE" }
                              ],
                              expression:
                                "['validType', {initialValue: 'ABSOLUTE'}]"
                            }
                          ],
                          on: { change: _vm.timeRuleChange }
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              style: _vm.radioStyle,
                              attrs: { value: "ABSOLUTE" }
                            },
                            [_vm._v("有效期内，任意时段可用")]
                          ),
                          _c(
                            "a-radio",
                            {
                              style: _vm.radioStyle,
                              attrs: { value: "RELATIVE" }
                            },
                            [
                              _vm._v(
                                "有效期内，用户领取后天\n                            "
                              ),
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["validDays"],
                                    expression: "['validDays']"
                                  }
                                ],
                                staticStyle: { width: "20%" },
                                attrs: { type: "number" }
                              }),
                              _vm._v("\n                        内可用")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-modal",
                        {
                          attrs: {
                            maskClosable: false,
                            destroyOnClose: "",
                            visible: _vm.timeRulevisible
                          },
                          on: {
                            ok: _vm.handleTimeRuleHide,
                            cancel: _vm.handleTimeRuleHideCancel
                          }
                        },
                        [
                          _c("ScheduleTimePicker", {
                            attrs: { value: _vm.timeRuleData },
                            on: { change: _vm.initTimeRuleData }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-modal",
                        {
                          attrs: {
                            maskClosable: false,
                            destroyOnClose: "",
                            visible: _vm.noTimeRulevisible
                          },
                          on: {
                            ok: _vm.handleNoTimeRuleHide,
                            cancel: _vm.handleNoTimeRuleHideCancel
                          }
                        },
                        [
                          _c("IrregularScheduleTimePicker", {
                            attrs: { value: _vm.noTimeRuleData },
                            on: { change: _vm.initNoTimeRuleData }
                          })
                        ],
                        1
                      ),
                      _vm.baseForm.getFieldValue("validType") === "1"
                        ? _c(
                            "div",
                            { staticStyle: { "margin-left": "125px" } },
                            [
                              _c(
                                "a-checkbox-group",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "weekdays",
                                        {
                                          initialValue: this.timeRuleData
                                            .validDays
                                        }
                                      ],
                                      expression:
                                        "['weekdays', {initialValue: this.timeRuleData.validDays}]"
                                    }
                                  ],
                                  staticStyle: { width: "80%" },
                                  attrs: { disabled: "" }
                                },
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "a-checkbox",
                                            { attrs: { value: "1" } },
                                            [_vm._v("周一")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "a-checkbox",
                                            { attrs: { value: "2" } },
                                            [_vm._v("周二")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "a-checkbox",
                                            { attrs: { value: "3" } },
                                            [_vm._v("周三")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "a-checkbox",
                                            { attrs: { value: "4" } },
                                            [_vm._v("周四")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "a-checkbox",
                                            { attrs: { value: "5" } },
                                            [_vm._v("周五")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-row",
                                    { staticStyle: { "margin-top": "6px" } },
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "a-checkbox",
                                            { attrs: { value: "6" } },
                                            [_vm._v("周六")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "a-checkbox",
                                            { attrs: { value: "7" } },
                                            [_vm._v("周日")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("br"),
                              _vm._v("时间区间：\n                        "),
                              _vm._l(this.timeArrayTemp, function(item, index) {
                                return _c("span", { key: index }, [
                                  _c("br"),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item) +
                                      "\n                        "
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.baseForm.getFieldValue("validType") === "2"
                        ? _c(
                            "div",
                            { staticStyle: { "margin-left": "125px" } },
                            [
                              _vm._v(
                                "\n                        日期区间：\n                        "
                              ),
                              _vm._l(this.dateArrayTemp, function(item, index) {
                                return _c("span", { key: index }, [
                                  _c("br"),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item) +
                                      "\n                        "
                                  )
                                ])
                              }),
                              _c("br"),
                              _vm._v("时间区间：\n                        "),
                              _vm._l(this.timeArrayTemp, function(item, index) {
                                return _c("span", { key: index }, [
                                  _c("br"),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item) +
                                      "\n                        "
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "使用说明" } },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "description",
                              {
                                rules: [
                                  { required: true, message: "请填写必填项!" },
                                  {
                                    pattern: /^.{1,256}$/,
                                    message: "必填，限制256字"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                            'description',\n                            {\n                                rules: [\n                                    {required: true, message: '请填写必填项!'},\n                                    {pattern: /^.{1,256}$/, message: '必填，限制256字'}\n                                ]\n                            }\n                        ]"
                          }
                        ],
                        staticStyle: { width: "95%" },
                        attrs: {
                          placeholder: "必填，限制256字",
                          autosize: { minRows: 10, maxRows: 10 },
                          maxlength: "256"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "是否结算" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: ["settleFlag", { initialValue: "1" }],
                              expression: "['settleFlag', {initialValue: '1'}]"
                            }
                          ],
                          on: { change: _vm.showSettleFlag }
                        },
                        [
                          _c(
                            "a-radio",
                            { attrs: { value: "1", checked: true } },
                            [_vm._v("是")]
                          ),
                          _c(
                            "a-radio",
                            { attrs: { value: "0", checked: true } },
                            [_vm._v("否")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isSettleFlag == "1",
                          expression: "isSettleFlag == '1'"
                        }
                      ],
                      attrs: { label: "" }
                    },
                    [
                      _vm._v(
                        "\n                    结算比(暂时不可修改)：\n                    "
                      ),
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "settleRate	",
                              {
                                initialValue: "100",
                                rules: [
                                  {
                                    required: false,
                                    message: "请输入整数/不能为0或100"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                            'settleRate\t',\n                            {\n                                initialValue: '100',\n                                rules: [{required: false, message: '请输入整数/不能为0或100'}\n                            ]}\n                        ]"
                          }
                        ],
                        staticStyle: { width: "60%" },
                        attrs: {
                          type: "number",
                          placeholder: "不能为空/请输入整数/不能为0或100",
                          readonly: true
                        }
                      }),
                      _vm._v(" %\n                    ")
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { style: { textAlign: "left" }, attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              style: { marginRight: "8px" },
                              on: { click: _vm.handleCancel }
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", "html-type": "submit" }
                            },
                            [_vm._v("下一步")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "其他规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.otherForm },
                  on: { submit: _vm.handleCreate }
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "上传类型" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "listType",
                                { initialValue: "merIdAndTermId" }
                              ],
                              expression:
                                "['listType', {initialValue: 'merIdAndTermId'}]"
                            }
                          ],
                          on: { change: _vm.chooseMerchantType }
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              attrs: { value: "merIdAndTermId", checked: true }
                            },
                            [_vm._v("商户号和终端号")]
                          ),
                          _c(
                            "a-radio",
                            { attrs: { value: "merId", checked: true } },
                            [_vm._v("商户号")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动店铺", required: "" } },
                    [
                      _c("CreateModal", {
                        attrs: { onlyMer: _vm.onlyMer },
                        on: {
                          change: function($event) {
                            return _vm.changeShopData($event)
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "trigger",
                            fn: function() {
                              return [
                                _c("a-button", { attrs: { type: "primary" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.onlyMer
                                        ? "点击添加商户号"
                                        : "点击添加商户号和对应线下终端号"
                                    )
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c(
                        "ShopUpload",
                        {
                          attrs: { onlyMer: _vm.onlyMer },
                          on: {
                            change: function($event) {
                              return _vm.changeShopData($event)
                            }
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "trigger" },
                            [
                              _c(
                                "a-button",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v("批量上传")]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm.shopData.length > 0
                    ? _c(
                        "a-form-item",
                        { attrs: { label: "" } },
                        [
                          _c("a-table", {
                            attrs: {
                              columns: _vm.onlyMer
                                ? _vm.noTermNocolumns
                                : _vm.hasTermNocolumns,
                              dataSource: _vm.shopData,
                              size: "small"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "action",
                                  fn: function(text, record) {
                                    return _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:;" },
                                        on: {
                                          click: function() {
                                            return _vm.deleteShopData(record)
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  }
                                }
                              ],
                              null,
                              false,
                              2353402790
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { style: { textAlign: "left" }, attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", "html-type": "submit" }
                            },
                            [_vm._v("提交")]
                          ),
                          _c(
                            "a-button",
                            {
                              style: { marginLeft: "8px" },
                              on: { click: _vm.handleBack }
                            },
                            [_vm._v("返回")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }