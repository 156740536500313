

















































import {Component, Inject, Vue} from 'vue-property-decorator';
import moment from 'moment';
import {mapState} from 'vuex';
import {deleteEmpty} from '@/utils';
import qs from 'querystringify';

interface SearchForm {
    form: any;
}
interface QueryParams {
    expenseAccount	:string
}
@Component({
    components: {
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.accountActivitySearchPage.query);
            },
            loading: (state: any) => state.accountActivitySearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {

            if (error) {
                return this.$message.error('请填写正确信息');
            }

            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.dispatch('initQuery', query);
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any) {
        
        return values;
    }

    private formatFormData(query: any) {

        return {
            ...query
        };
    }
}

export default SearchForm;
