


















import {mapState} from 'vuex';
import {Component, Watch, Inject, Vue} from 'vue-property-decorator';
import {Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import moment from 'moment';
import DateDrift from 'date-drift';

interface Actions {
    selectedRows: any;
    hideMessage: any;
}

@Component({
    components: {
        Click,
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.subsidyDetailSearchPage.loading,
            downloading: (state: any) => state.subsidyDetailSearchPage.downloading,
            selectedRows: (state: any) => state.subsidyDetailSearchPage.selectedRows,
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            hideMessage: null,
            PRIVILEGE
        };
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }
    public todayTime() {
        const d = new Date(); 
        return new Date(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`+' 00:00:00');
    }
    public todayTime1() {
        const d = new Date(); 
        return new Date(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`+' 23:59:59');
    }
    public handleRefresh() {
        let queryString = this.$store.getters['subsidyDetailSearchPage/queryString']();
        if(queryString.indexOf('startTime') == -1){
            let startTime = moment(new DateDrift(this.todayTime()).driftDays(0).getDate());
            let endTime = moment(new DateDrift(this.todayTime1()).driftDays(0).getDate());
           
            this.dispatch('queryChange', {
                startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
                endTime: endTime.format('YYYY-MM-DD HH:mm:ss')
            });
            queryString = this.$store.getters['subsidyDetailSearchPage/queryString']();
        }
        console.log(queryString)
        this.$router.push({
            path: '/console/subsidyDetail/search' + queryString
        });
    }
    
    @Watch('$store.state.subsidyDetailSearchPage.downloading')
    public watchDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }

    @Watch('$store.state.subsidyDetailSearchPage.downloadError')
    public watchDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error('导出失败，请联系技术人员!');
        }
    }

    @Watch('$store.state.subsidyDetailSearchPage.lastDownloadTime')
    public watchLastDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }

    public handleDownload() {
        this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
        this.$store.dispatch('subsidyDetailSearchPage/download');
    }
}

export default Actions;
