






















    import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
    import SearchForm from './SearchForm.vue';
    import Actions from './Actions.vue';
    import SearchTable from './SearchTable.vue';
    import FieldsFilter from '@/components/FieldsFilter.vue';
    import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import {Show} from '@/components/Auth';
    import PRIVILEGE from '@/common/privileges';
    import columns from './columns';
    import {queryToHumanReadable} from '@/utils';
    import './store';
    import CardItem from '@/components/CardItem.vue';
	import { message ,Modal} from 'ant-design-vue';
    const humanReadableQuerySchema: any = {

    };

    @Component({
        components: {
            Actions,
            SearchForm,
            SearchCommonLayout,
            Show,
            FieldsFilter,
            SearchTable,
        },
        computed: {
            ...mapState({
                fields: (state: any) => state.shopWhiteListSearchPage.fields,
                sortArr: (state: any) => state.shopWhiteListSearchPage.sortFields,
                humanReadableQuery(state: any) {
                    return queryToHumanReadable(state.shopWhiteListSearchPage.query, humanReadableQuerySchema);
                }
            })
        }
    })
    export default class ShortMessage extends Vue {
        public storeName = 'shopWhiteListSearchPage';
        public PRIVILEGE = PRIVILEGE;
        public fixed = false;
        public columns = columns;

        @Provide()
        public dispatch(action: string, payload?: any) {
            this.$store.dispatch(
                this.storeName + '/' + action,
                payload
            );
        }

        public created() {
            this.$store.dispatch('shopWhiteListSearchPage/initQuery', {
                ...this.$route.query
            });
        }

        @Watch('$store.state.shopWhiteListSearchPage.error')
        public errorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        @Watch('$store.state.shopWhiteListSearchPage.updateStatusError')
        public updateStatusErrorListener(error: any) {
            return error && !error.silent && this.$message.error(error.message);
        }

        // 路由驱动页面查询
        @Watch('$route')
        public routeListener(route: any) {
            this.dispatch('initQuery', this.$route.query);
        }

        public handleFixed() {
            this.fixed = true;
        }
    }
