
















































import {Component, Inject, Vue} from 'vue-property-decorator';
import * as services from './services';
import {Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

interface CreateModal {
    form: any;
}

@Component({
    components: {
        Click
    }
})
class CreateModal extends Vue {

    public visible = false;

    public loading = false;

    public PRIVILEGE = PRIVILEGE;

    @Inject()
    public dispatch!: any;

    public data() {
        return {
            form: this.$form.createForm(this)
        };
    }

    public handleClose() {
        if (this.loading) {
            return;
        }
        this.visible = false;
    }

    public onClick() {
        this.visible = true;
    }

    public handleCreate(e: any) {
        e.preventDefault();
        this.form.validateFields(async (error: any, values: any) => {
            if (!error) {
                try {
                    this.loading = true;
                    await services.createUserWhitelist(values);
                    this.visible = false;
                    this.$emit('success');
                    this.dispatch('search');
                } catch (e) {
                    this.$message.error(e.message);
                } finally {
                    this.loading = false;
                }
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
}

export default CreateModal;
