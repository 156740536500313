






























import {Component, Prop, Vue, Provide} from 'vue-property-decorator';

interface Canvas {
    active: any;
    x: number;
    y: number;
    r: number;
    width: number;
    height: number;
}

@Component<Canvas>({
    components: {},
    computed: {},
})
class Canvas extends Vue {

    @Prop()
    public image!: string;

    private CANVAS_MAX_WIDTH = 300;

    private CANVAS_MAX_HEIGHT = 300;

    @Provide()
    public setActive(el: any) {
        this.active = el;
    }

    @Provide()
    public getActive() {
        return this.active;
    }

    public created() {
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('mouseup', this.handleMouseUp);
    }

    public destroyed() {
        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('mouseup', this.handleMouseUp);
    }

    public imageError(e: Error) {
        this.$emit('loadError', e);
    }

    public imageLoaded(e: any) {
        // 当图片没有加载完成，但是点击了确认删除，会导致Canvas组件的dom节点以及img节点被清除
        if (!this.$refs.image) {
            return;
        }
        this.width = (this.$refs.image as any).naturalWidth;
        this.height = (this.$refs.image as any).naturalHeight;
        this.r = this.width > this.height ? this.CANVAS_MAX_WIDTH / this.width : this.CANVAS_MAX_HEIGHT / this.height;
        this.$emit('loaded', {
            width: this.width,
            height: this.height,
            r: this.r
        });
    }

    public data() {
        return {
            x: 0,
            y: 0,
            r: 1,
            width: 0,
            height: 0,
            active: null,
            localX: 0,
            localY: 0
        };
    }

    public handleMouseUp() {
        this.active = null;
    }

    // 元素相对于body的offsetTop
    public getOffsetAndScrollRelativeBody(el: any) {
        let offsetTop = 0;
        let offsetLeft = 0;
        let scrollTop = 0;
        let scrollLeft = 0;
        while (el && el.tagName !== 'BODY') {
            offsetTop += el.offsetTop;
            offsetLeft += el.offsetLeft;
            scrollTop += el.scrollTop;
            scrollLeft += el.scrollLeft;
            el = el.offsetParent;
        }
        return {offsetTop, offsetLeft, scrollTop, scrollLeft};
    }

    public handleMouseMove(e: MouseEvent) {
        const canvasOffsetAndScroll = this.getOffsetAndScrollRelativeBody(this.$refs.canvas);
        this.x = e.clientX - canvasOffsetAndScroll.offsetLeft + canvasOffsetAndScroll.scrollLeft + window.scrollX;
        this.y = e.clientY - canvasOffsetAndScroll.offsetTop + canvasOffsetAndScroll.scrollTop + window.scrollY;
    }

}

export default Canvas;
