var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "user-search-table",
        attrs: {
          size: "middle",
          rowKey: "user_id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.columns,
          dataSource: _vm.users,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
          },
          scroll: {
            x: _vm.calcTableScrollX(_vm.columns, 62),
            y: _vm.fixed || undefined
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: function(total) {
              return "总计 " + total + " 条记录"
            },
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "mobile",
            fn: function(text, record) {
              return _c("ClearText", {
                attrs: {
                  text: text,
                  action: "/console/user/console/username",
                  getClearText: function(result) {
                    return result.mobile
                  },
                  params: { user_id: record.user_id }
                }
              })
            }
          },
          {
            key: "action",
            fn: function(text, record) {
              return _c(
                "span",
                { staticClass: "ops" },
                [
                  _c("RoleChangeModal", {
                    attrs: {
                      userId: record.user_id,
                      userName: record.first_name
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "trigger",
                          fn: function(_) {
                            return [
                              _c(
                                "a-button",
                                {
                                  attrs: { size: "small", href: "javascript:;" }
                                },
                                [_vm._v("角色变更")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.handleDeleteConfirm(record.user_id)
                        }
                      }
                    },
                    [_vm._v("删除")]
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }