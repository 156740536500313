




































































import {Component, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import moment from 'moment';
import {deleteEmpty} from '@/utils';
import qs from 'querystringify';

import Role from '@/components/custom-form-components/Role.vue';

import {
    DatePicker
} from '@/components/custom-form-components';

interface SearchForm {
    form: any;
}

@Component({
    components: {
        DatePicker,
        Role
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.operateLogSearchPage.query);
            },
            loading: (state: any) => state.operateLogSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }

            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/operate-log/search?${qs.stringify(query)}`
            });

        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any) {
        return {
            name: values.name,
            module: values.module,
            role_id: values.role_id,
            start_time:
                values.range_time &&
                values.range_time[0] &&
                values.range_time[0].format('YYYY-MM-DD HH:mm:ss'),
            end_time:
                values.range_time &&
                values.range_time[1] &&
                values.range_time[1].format('YYYY-MM-DD HH:mm:ss')
        };
    }

    private formatFormData(query: any) {
        return {
            ...query,
            range_time: [
                query.start_time && moment(query.start_time),
                query.end_time && moment(query.end_time)
            ]
        };
    }
}

export default SearchForm;
