



















import EditActForm from './EditActForm.vue';
import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Show,
        EditActForm
    }
})
export default class CreatePage extends Vue {
    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public title!: string | number;
    @Prop()
    public activityInfo !: any;
    @Prop()
    public paytype !: string;
    public created() {
    }

    @Watch('activityInfo')
    public initQueryData(error: any) {
        if(!this.activityInfo){
            return; 
        }
        this.$store.dispatch('unionCouponSearchPage/initData', {
            promotionId: this.activityInfo.promotionId,
            listType: this.activityInfo.merLimit == '1' ? 'merId' : 'merIdAndTermId', // merLimit: 商户/商终限制标识：0-不限制(暂时不用);1-商户列表;2-商终列表
        });
    }

    

    @Watch('$store.state.unionCouponSearchPage.error') 
    public errorCreateListener(error: any) {
       
        return error && !error.silent && this.$message.error(error.message);
    }

    public onClose() {
        this.$emit('close');
    }
}
