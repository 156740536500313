var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-form-item-wrapper" },
    [
      _vm._m(0),
      _c("a-divider", { staticStyle: { margin: "6px 0" } }),
      [
        _c(
          "a-form",
          { attrs: { form: _vm.form } },
          [
            _c(
              "a-form-item",
              _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "60%" },
                    attrs: { type: "dashed" },
                    on: { click: _vm.add }
                  },
                  [
                    _c("a-icon", { attrs: { type: "plus" } }),
                    _vm._v(" 添加日期区间\n          ")
                  ],
                  1
                )
              ],
              1
            ),
            _vm._l(_vm.form.getFieldValue("keys"), function(k, index) {
              return _c(
                "a-form-item",
                { key: "data" + index, attrs: { keys: k, required: false } },
                [
                  _c("a-range-picker", {
                    staticStyle: { width: "93%" },
                    attrs: { format: "YYYY-MM-DD" },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.daterange[index],
                      callback: function($$v) {
                        _vm.$set(_vm.daterange, index, $$v)
                      },
                      expression: "daterange[index]"
                    }
                  }),
                  _vm.form.getFieldValue("keys").length > 1
                    ? _c("a-icon", {
                        staticClass: "dynamic-delete-button",
                        attrs: {
                          type: "minus-circle-o",
                          disabled: _vm.form.getFieldValue("keys").length === 1
                        },
                        on: {
                          click: function() {
                            return _vm.remove(k)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            _c(
              "a-form-item",
              _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "60%" },
                    attrs: { type: "dashed" },
                    on: { click: _vm.addTime }
                  },
                  [
                    _c("a-icon", { attrs: { type: "plus" } }),
                    _vm._v(" 添加时间区间\n          ")
                  ],
                  1
                )
              ],
              1
            ),
            _vm._l(_vm.form.getFieldValue("timeKeys"), function(k, index) {
              return _c(
                "a-form-item",
                { key: index, attrs: { timeKeys: k, required: false } },
                [
                  k < 2
                    ? _c(
                        "span",
                        [
                          _c("a-time-picker", {
                            staticStyle: { width: "45%" },
                            attrs: { format: "HH:mm" },
                            on: { change: _vm.timerangeChange },
                            model: {
                              value: _vm.timerange[index * (index + 1)],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.timerange,
                                  index * (index + 1),
                                  $$v
                                )
                              },
                              expression: "timerange[index * (index + 1)]"
                            }
                          }),
                          _vm._v(" \n            - \n            "),
                          _c("a-time-picker", {
                            staticStyle: { width: "45%" },
                            attrs: { format: "HH:mm" },
                            on: { change: _vm.timerangeChange },
                            model: {
                              value: _vm.timerange[index * (index + 1) + 1],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.timerange,
                                  index * (index + 1) + 1,
                                  $$v
                                )
                              },
                              expression: "timerange[index * (index + 1) + 1]"
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _c("a-time-picker", {
                            staticStyle: { width: "45%" },
                            attrs: { format: "HH:mm" },
                            on: { change: _vm.timerangeChange },
                            model: {
                              value: _vm.timerange[index * index],
                              callback: function($$v) {
                                _vm.$set(_vm.timerange, index * index, $$v)
                              },
                              expression: "timerange[index * index]"
                            }
                          }),
                          _vm._v(" \n            - \n            "),
                          _c("a-time-picker", {
                            staticStyle: { width: "45%" },
                            attrs: { format: "HH:mm" },
                            on: { change: _vm.timerangeChange },
                            model: {
                              value: _vm.timerange[index * index + 1],
                              callback: function($$v) {
                                _vm.$set(_vm.timerange, index * index + 1, $$v)
                              },
                              expression: "timerange[index * index + 1]"
                            }
                          })
                        ],
                        1
                      ),
                  _vm.form.getFieldValue("timeKeys").length > 1
                    ? _c("a-icon", {
                        staticClass: "dynamic-delete-button",
                        attrs: {
                          type: "minus-circle-o",
                          disabled:
                            _vm.form.getFieldValue("timeKeys").length === 1
                        },
                        on: {
                          click: function() {
                            return _vm.removeTime(index)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            })
          ],
          2
        )
      ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("可在有效期内任意选择时间天数，以及可用时段。"),
      _c("br"),
      _vm._v(
        "\n      因当前系统支持问题，如选择不规则时间，卡包内当前仅会展示有效期，建议\n      将具体可用日期以及时间段填写在代金券使用说明内。"
      ),
      _c("br"),
      _vm._v("\n      可按周期星期选择日期，或指定具体日期")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }