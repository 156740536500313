var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        { staticClass: "ad-create-form", attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "主题名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["name", { initialValue: _vm.name }],
                    expression: "['name', {initialValue: name}]"
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "头寸编号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "expenseAccount",
                      { initialValue: _vm.expenseAccount }
                    ],
                    expression:
                      "['expenseAccount', {initialValue: expenseAccount}]"
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "业主方" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["ownerId", { initialValue: _vm.ownerId }],
                    expression: "['ownerId', {initialValue: ownerId}]"
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "备注" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["description", { initialValue: _vm.description }],
                    expression: "['description', {initialValue: description}]"
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }