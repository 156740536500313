



































































import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { deleteEmpty } from '@/utils';
import qs from 'querystringify';

interface SearchForm {
    form: any;
}

export interface Query {
    mobile: string;
    userName: string;
    nickName: string;
}

export interface FormData {
    mobile: string;
    userName: string;
    nickName: string;
}

@Component({
    components: {},
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.companyQueryConfSearchPage.query);
            },
            loading: (state: any) => state.companyQueryConfSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/companyQueryConf/search?${qs.stringify(query)}`
            });
        });
    }

    public handleReset() {
        this.form.setFieldsValue({
            mobile: undefined,
            userName: undefined,
            nickName: undefined
        });
    }

    private formatQuery(values: FormData): Query {
        return {
            mobile: values.mobile,
            userName: values.userName,
            nickName: values.nickName
        };
    }

    private formatFormData(query: Query): FormData {
        return {
            ...query
        };
    }
}

export default SearchForm;
