





















import {Component, Vue, Inject} from 'vue-property-decorator';
import SearchTable from '@/components/SearchTable.vue';
import {mapState} from 'vuex';
import {defaultCustomRender, priceCustomRender} from '@/utils';

@Component({
    components: {
        SearchTable
    },
    computed: {
        ...mapState({
            list() {
                return this.state().list;
            },
            total() {
                return this.state().total;
            },
            page() {
                return this.state().page;
            },
            pageSize() {
                return this.state().pageSize;
            },
            loading() {
                return this.state().loading;
            }
        })
    }
})
class StoreSearchTable extends Vue {

    @Inject()
    public state!: any;
    @Inject()
    public dispatch!: any;

    public data() {
        const genderMap = {
            0: '未知',
            1: '男',
            2: '女'
        };
        return {
            columns: [{
                title: '会员昵称',
                dataIndex: 'customer.nick_name',
                fixed: 'left',
                width: 120,
                customRender: defaultCustomRender
            }, {
                title: '性别',
                dataIndex: 'customer.gender',
                customRender(gender: '0' | '1' | '2') {
                    return genderMap[gender] || '-';
                }
            }, {
                title: '会员手机号',
                dataIndex: 'customer.mobile',
                width: 200,
                scopedSlots: {customRender: 'mobile'},
            }, {
                title: '国家',
                dataIndex: 'customer.country',
                customRender: defaultCustomRender
            }, {
                title: '省',
                dataIndex: 'customer.province',
                customRender: defaultCustomRender
            }, {
                title: '市',
                dataIndex: 'customer.city',
                customRender: defaultCustomRender
            }, {
                title: '商场会员注册时间',
                dataIndex: 'created_time',
                customRender: defaultCustomRender
            }, {
                title: '会员卡号',
                dataIndex: 'customer.member_no',
                customRender: defaultCustomRender
            }, {
                title: '消费笔数',
                dataIndex: 'order.paid_count',
                customRender: defaultCustomRender
            }, {
                title: '总消费(元)',
                dataIndex: 'order.net_income',
                customRender: priceCustomRender
            }, {
                title: '最后消费时间',
                dataIndex: 'order.last_consume_time',
                customRender: defaultCustomRender
            }]
        };
    }

    public handlePageChange(page: number) {
        this.dispatch('pageChange', page);
    }

    public handlePageSizeChange(size: number) {
        this.dispatch('pageSizeChange', size);
    }
}

export default StoreSearchTable;
