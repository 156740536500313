var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.OPERATE_LOG.EXCEL] },
        on: { pass: _vm.handleDownload },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(slotProps) {
              return [
                _c(
                  "a-button",
                  { attrs: { disabled: _vm.loading || _vm.downloading } },
                  [_vm._v("导出Excel数据")]
                )
              ]
            }
          }
        ])
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.OPERATE_LOG.DELETE] },
        on: { pass: _vm.handleDelete },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(slotProps) {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled:
                        _vm.selectedRows.length === 0 ||
                        _vm.loading ||
                        _vm.deleting
                    }
                  },
                  [_vm._v("删除日志")]
                )
              ]
            }
          }
        ])
      }),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.OPERATE_LOG.DELETE] },
        on: { pass: _vm.handleDelete30Days },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(slotProps) {
              return [
                _c(
                  "a-button",
                  { attrs: { disabled: _vm.loading || _vm.deleting } },
                  [_vm._v("清空30天前日志")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }