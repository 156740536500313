

































import {Component, Prop, Vue, Provide, Inject} from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import SearchTable from './SearchTable.vue';
import SearchForm from './SearchForm.vue';
import store from './store';
import {getValueByPath} from '@/utils';
import {check, Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Drawer,
        SearchTable,
        SearchForm,
        Show
    }
})
class Store extends Vue {
    @Prop()
    // tslint:disable-next-line:variable-name
    public mall_id!: string;

    @Prop()
    public title!: string | number;
    @Inject()
    public privileges!: any;

    public PRIVILEGE = PRIVILEGE;

    @Provide()
    public state() {
        return getValueByPath(this.$store.state, [
            'mallSearchPage',
            this.mall_id + '@store'
        ]);
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(
            'mallSearchPage/' + this.mall_id + '@store/' + action,
            payload
        );
    }

    @Provide()
    public commit(action: string, payload?: any) {
        this.$store.commit(
            'mallSearchPage/' + this.mall_id + '@store/' + action,
            payload
        );
    }

    public created() {
        if (!this.state()) {
            this.$store.registerModule(
                ['mallSearchPage', this.mall_id + '@store'],
                store()
            );
            this.commit('setQuery', {mall_id: this.mall_id});
            this.$watch(function() {
                return this.$store.state.mallSearchPage[this.mall_id + '@store']
                    && this.$store.state.mallSearchPage[this.mall_id + '@store'].error;
            }, this.errorListener);
        }
    }

    // destroyed() {
    //     this.$store.unregisterModule([
    //         'mallSearchPage',
    //         this.mall_id + '@store'
    //     ]);
    // }

    public errorListener(error: any) {
        if (error) {
            this.$message.error(error.message);
        }
    }

    public handleDrawerOpen() {

        if (check([this.PRIVILEGE.MALL.STORE], this.privileges).length === 0) {
            this.dispatch('resetQuery', {mall_id: this.mall_id});
        }

        // if (this.state().list.length === 0) {
        //     this.dispatch('search');
        // }
    }
}

export default Store;
