






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component<AdContainerSelector>({})
class AdContainerSelector extends Vue {

    private static groups: any = {};

    private static inc = 0;

    @Prop()
    public value!: any;

    @Prop()
    public groupId!: symbol;

    @Prop()
    public allowContainers!: string[];

    @Prop()
    public disabled!: any;

    public id: string = 'key_' + AdContainerSelector.inc++;

    public currentGroup: any = [];

    public selectedByOthers: any = [];

    @Watch('currentGroup', {deep: true})
    public watch(currentGroup: any) {
        this.selectedByOthers = Object.keys(currentGroup).reduce((memo: any, id: any) => {
            return id === this.id ? memo : memo.concat(currentGroup[id]);
        }, []);
    }

    public created() {
        if (!AdContainerSelector.groups[this.groupId]) {
            AdContainerSelector.groups[this.groupId] = {};
        }
        AdContainerSelector.groups[this.groupId][this.id] = [];
        this.currentGroup = AdContainerSelector.groups[this.groupId];
        this.currentGroup[this.id] = [...this.value];
    }

    public destroyed() {
        this.changeHandler([]);
    }

    public changeHandler(checkedValues: any) {
        this.forceFireWatch();
        this.currentGroup[this.id] = [...checkedValues];
        this.$emit('change', [...checkedValues]);
    }

    private forceFireWatch() {
        Object.keys(this.currentGroup).forEach((instanceId: string) => {
            this.currentGroup[instanceId] = [...this.currentGroup[instanceId]];
        });
    }
}
export default AdContainerSelector;
