




















import { Component, Prop, Vue } from 'vue-property-decorator';
import DatePicker from './DatePicker.vue';
import moment from 'moment';
const plainOptions = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
interface SchedulePicker {
  endOpen: any;
  endValue: any;
  startValue: any;
}

@Component<SchedulePicker>({
    components: {
        DatePicker
    },
    computed: {
        range() {
            return this.value && this.value.range;
        },
        days() {
            return this.value && this.value.days;
        },
        indeterminate() {
            return this.value && this.value.days && this.value.days.length > 0 && this.value.days.length < 7;
        },
        checkAll() {
            return this.value && this.value.days && this.value.days.length === 7;
        }
    }
})
class SchedulePicker extends Vue {

    @Prop()
    public value!: any;

    @Prop()
    public disabled!: any;

    public rangeChangeHandler(range: moment.Moment[]) {
        this.$emit('change', {range, days: this.value && this.value.days});
    }

    public daysChangeHandler(days: any) {
        this.$emit('change', {range: this.value && this.value.range, days});
    }

    public onCheckAllChange(e: any) {
        const days = e.target.checked ? plainOptions : [];
        this.$emit('change', {range: this.value && this.value.range, days});
    }
}
export default SchedulePicker;
