


















import { Component, Prop, Vue } from 'vue-property-decorator';
import Rich from '@/components/RichEditorDrawer.vue';
import ImageCrop from '@/components/custom-form-components/ImageCrop/index.vue';
@Component<ImageTextLink>({
    components: {
        ImageCrop,
        Rich
    },
    computed: {
    }
})
class ImageTextLink extends Vue {

    public static defaultTerminals = [] as const;

    public static allowContainers = ['H5', 'MINI_APP'] as const;

    public static validator(value: any) {
        if (!value.image) {
            return '图片/二维码不能为空';
        }
    }

    @Prop()
    public data!: any;

    @Prop()
    public disabled!: any;

    public imageFile: any = null;

    public created() {
        if (this.data && this.data.image) {
            this.imageFile = [{
                uid: -1,
                url: this.data.image,
                status: 'done',
                thumbUrl: this.data.image,
                name: 'image',
                lastModified: Date.now(),
                lastModifiedDate: new Date(),
                size: 0,
                type: 'image/jpeg',
                response: null
            }];
        }
    }

    public changeHandler(key: string, value: any) {
        if (key === 'image') {
            this.imageFile = value;
            if (value.length === 0) {
                value = '';
            } else if (value[0].status === 'done') {
                value = value[0].url;
            } else {
                return;
            }
        };

        this.$emit('change', {
            ...this.data,
            [key]: value
        });
    }

}
export default ImageTextLink;
