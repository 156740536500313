import {request_yx,downloadRequest_yx} from '@/utils';
import * as Constant from '@/common/constant';
export async function search(params: any) {

    try {
        var api = '/console/activity/applyItem/query'
        return await request_yx({
            method: 'POST',
            api,
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        });
    } catch (e) {
        const error: any = new Error('获取活动列表失败, 请联系技术人员!');
        error.silent = e.is403;
        throw error;
    }
}
export async function updateStatus(params: any) {
    var api = '/console/activity/applyItem/updateStatus'
    try {
        return await request_yx({
            method: 'POST',
            api,
            data: params
        });
    } catch (e) {
        // const error: any = new Error('更新状态失败, 请联系技术人员!');
        e.silent = e.is403;
        throw e;
    }
}
export async function downloadXls(params: any) {
    return await downloadRequest_yx({
        data: params,
        prefix: 'activity_apply_item',
        api: '/console/activity/applyItem/download'
    });
}

