var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "biz-district-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate }
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "活动名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name",
                      {
                        rules: [
                          { required: true, message: "请填写必填项!" },
                          { max: 64, message: "长度超限，请检查后重新输入!" }
                        ]
                      }
                    ],
                    expression:
                      "['name', {\n                    rules: [\n                        {required: true, message: '请填写必填项!'},\n                        {max: 64, message: '长度超限，请检查后重新输入!'}\n                    ]\n                }]"
                  }
                ],
                attrs: { placeholder: "请填写活动名称" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动时间" } },
            [
              _c("DisableDatePicker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityTime",
                      { rules: [{ required: true, message: "请选择活动时间" }] }
                    ],
                    expression:
                      "['activityTime',{rules: [{required: true, message: '请选择活动时间'}]}]"
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "报名时间" } },
            [
              _c("DisableDatePicker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "signUpTime",
                      {
                        rules: [
                          { required: true, message: "请选择活动报名时间!" }
                        ]
                      }
                    ],
                    expression:
                      "['signUpTime', {rules: [{required: true, message: '请选择活动报名时间!',}]}]"
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动类型" } },
            [
              _c("SignUpActivityType", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityType",
                      {
                        rules: [{ required: true, message: "活动类型不能为空" }]
                      }
                    ],
                    expression:
                      "[\n                    'activityType',\n                    { \n                        rules: [\n                            { required: true, message: '活动类型不能为空' }\n                        ]\n                    },\n                ]"
                  }
                ],
                attrs: { placeholder: "请选择活动类型" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "总名额" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "totalCount",
                      {
                        rules: [
                          { required: true, message: "请填写必填项!" },
                          {
                            pattern: /^[1-9][0-9]{0,5}$/,
                            message: "必须是大于0的整数,低于999999"
                          }
                        ]
                      }
                    ],
                    expression:
                      "['totalCount', {\n                    rules: [{required: true, message: '请填写必填项!'},\n                    {pattern: /^[1-9][0-9]{0,5}$/, message: '必须是大于0的整数,低于999999'}]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入商户数量限额，低于999999" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "初始名额" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "initCount",
                      {
                        rules: [
                          { required: false, message: "请填写必填项!" },
                          { pattern: /^[0-9]\d*$/, message: "必须是整数" }
                        ]
                      }
                    ],
                    expression:
                      "['initCount',{\n                    rules: [{required: false, message: '请填写必填项!'},\n                    {pattern: /^[0-9]\\d*$/, message: '必须是整数'}]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入初始名额，低于总名额限制" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "开展地区" } },
            [
              _c("districtCodeTransfer", {
                attrs: { targetDistrictCodeData: [] },
                on: { change: _vm.changeDistrictCodeData }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动宣传图" } },
            [
              _c("ImageCrop", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "logo",
                      { rules: [{ required: true, message: "请填写必填项!" }] }
                    ],
                    expression:
                      "['logo', {rules: [{required: true, message: '请填写必填项!'}]}]"
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动描述" } },
            [
              _c("vue-ueditor-wrap", {
                attrs: { config: _vm.editorProps.config },
                model: {
                  value: _vm.remark,
                  callback: function($$v) {
                    _vm.remark = $$v
                  },
                  expression: "remark"
                }
              }),
              _c("span", [
                _vm._v(
                  "提示：请输入8048字内的活动介绍，介绍内容建议包含：1.终端要求，2.活动门槛，3.是否需要商户出资，4.活动类型如刷卡活动、扫码账户段活动（微信、支付宝、银联二维码）、扫码自营活动等。"
                )
              ])
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.loading
                      }
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("\n                    取消\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }