













































import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
interface ManageForm {
  form: any;
}
let id = Math.random();
let timeId =  Math.random();
let nextKeysTemp =[];
let nextDataKeysTemp =[];
@Component<ManageForm>({
    components: {
    },
    computed: {
        
        
    }
})

class ManageForm extends Vue {
    @Prop()
    public value!: any;
    @Prop()
    public activityId!:any;
    @Prop()
    public disabled!: any;

    @Prop()
    public size!: any;
    public form;

    public linkListData !:any;

    public data() {
        return {
          daterange:[],
          form: this.$form.createForm(this),
            formItemLayoutWithOutLabel: {
                wrapperCol: {
                sm: { span: 15, offset: 0 },
                },
            }
        };
    }
    public created() {
        this.form = this.$form.createForm(this);
        nextKeysTemp = [];nextDataKeysTemp = [];
        if(this.value){
          this.linkListData = this.value;
             for(let i=0;i<this.value.length;i++){
               console.log("fdfd:"+this.value[i].key);
                nextDataKeysTemp.push(i);
             }
             this.form.setFieldsValue({
                 keys: nextDataKeysTemp,
             });
             this.form.getFieldDecorator('keys', { initialValue: nextDataKeysTemp, preserve: true });
        }else{
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
        }

    }
    //类型改变后 url置位空
   public channelChange(k:any){
     const { form } = this;
     let linkData = this.linkListData[k];
     linkData.url = "";
     this.$emit('change',  this.linkListData);
   }

  public urlChange(k:any){
     this.$emit('change',  this.linkListData);
   }
  public create(k:any){
    const { form } = this;
    let linkListData =this.linkListData[k];
    let channel = linkListData.channel;
    let url = "";
    if('SHT' == channel){
      url = "https://i.lakala.com/zs/merApp/"+this.activityId+"/detail.html";
    }else if('SHT_MINI' == channel){
      url = "https://i.lakala.com/zs/merSApp/"+this.activityId+"/detail.html";
    }else if('CMMP' == channel){
      url = "https://i.lakala.com/zs/recApp/"+this.activityId+"/detail.html";
    }else{
      url = "https://i.lakala.com/zs/wxOAccounts/"+this.activityId+"/detail.html";
    }
    linkListData.url = url;
    this.$emit('change',this.linkListData);
  }

    public remove  (k:any) {
      const { form } = this;
      const keys = form.getFieldValue('keys').map((item:number, index:number)=> {return index});
      if (keys.length === 0) {
        return;
      }
      keys.splice(k,1)
      form.setFieldsValue({
        keys: keys.map((item:number, index:number)=> {return index}),
      });
      this.linkListData.splice(k, 1);
      this.$emit('change',  this.linkListData);
    }

    public add  () {
      const { form } = this;
      const keys = form.getFieldValue('keys');
      if(keys.length > 3){
        this.$message.warning('最多添加4条')
        return;
      }
      const nextKeys = keys.concat(Math.random());
      form.setFieldsValue({
        keys: nextKeys.map((item:number, index:number)=> {return index}),
      });
      this.linkListData.push({
        key: nextKeys.length - 1,
        url:"",
        channel:""
      });
    }
    public copyHandler(k:any) {
      // const { form } = this;
      // let urlKey = 'url'+k;
      // const url: any = (this.$refs[urlKey] as any).$el;
      // url.select();
      // window.document.execCommand('Copy');
      // this.$message.success('已复制');
      let data = this.linkListData.filter((item: any) => item.key === k);
      
      const url: any = data[0].url;
      const textarea = document.createElement('input');
      textarea.value=data[0].url;
      document.body.appendChild(textarea);
      textarea.select();
      if(document.execCommand('Copy')){
        this.$message.success('已复制');
      }
      // url.select();
      // window.document.execCommand('Copy');
      // this.$message.success('已复制');
      /*
      let url= url+k;
      form.validateFields(async (error: any, values: any) => {
        const url: any =values[url];
        url.select();
        window.document.execCommand('Copy');
        this.$message.success('已复制');
      });
      */
  }
}
export default ManageForm;
