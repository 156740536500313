










































































































import {Modal} from 'ant-design-vue';
import {Component, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import UploadImage from '@/components/custom-form-components/UploadImage.vue';
import ImageCrop from '@/components/custom-form-components/ImageCrop/index.vue';
import config from '@/config';
import {DisableDatePicker} from '@/components/custom-form-components';
import moment from 'moment';
import {SignUpActivityStatus, SignUpActivityType,districtCodeTransfer} from '@/components/custom-form-components';
import VueUeditorWrap from 'vue-ueditor-wrap';
import DatePicker from '@/components/custom-form-components/DatePicker.vue';
interface SearchForm {
    form: any;
    hasLogo: boolean;
}

@Component({
    components: {
        UploadImage,
        ImageCrop,
        DisableDatePicker,
        districtCodeTransfer,
        SignUpActivityStatus,
        SignUpActivityType,
        VueUeditorWrap,
        DatePicker
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.bizDistrictCreatePage.loading,
            formData(state: any) {
                return state.activitySignUpPage.data && this.formatFormData(state.activitySignUpPage.data);
            }
        }),
        editorProps() {
            return {
                key: 'key_' + SearchForm.inc++,
                config: {
                    serverUrl: config.BASE_URL + '/console/ad/ueditor',
                    autoHeightEnabled: false,
                    initialFrameHeight:
                        this.bodyClientHeight -
                        this.toolBarHeight -
                        this.bottomHeight -
                        5,
                    initialFrameWidth: '100%',
                    UEDITOR_HOME_URL: process.env.BASE_URL + 'UEditor/',
                    imagePopup: false,
                    toolbars: [
                        [
                            'source',
                            '|',
                            'undo',
                            'redo',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            'fontborder',
                            'strikethrough',
                            'superscript',
                            'subscript',
                            'removeformat',
                            'formatmatch',
                            'autotypeset',
                            'blockquote',
                            'pasteplain',
                            '|',
                            'forecolor',
                            'backcolor',
                            'insertorderedlist',
                            'insertunorderedlist',
                            'selectall',
                            '|',
                            'rowspacingtop',
                            'rowspacingbottom',
                            'lineheight',
                            '|',
                            'paragraph',
                            'fontfamily',
                            'fontsize',
                            '|',
                            'indent',
                            '|',
                            'justifyleft',
                            'justifycenter',
                            'justifyright',
                            'justifyjustify',
                            '|',
                            'touppercase',
                            'tolowercase',
                            '|',
                            'link',
                            'unlink',
                            '|',
                            'imagenone',
                            'imageleft',
                            'imageright',
                            'imagecenter',
                            '|',
                            'simpleupload',
                            'emotion',
                            'insertvideo',
                            'map',
                            'pagebreak',
                            '|',
                            'horizontal',
                            'spechars',
                            '|',
                            'inserttable',
                            'deletetable',
                            'insertparagraphbeforetable',
                            'insertrow',
                            'deleterow',
                            'insertcol',
                            'deletecol',
                            'mergecells',
                            'mergeright',
                            'mergedown',
                            'splittocells',
                            'splittorows',
                            'splittocols',
                            '|',
                            'print',
                            'searchreplace'
                        ]
                    ]
                }
            };
        }
    }
})
class SearchForm extends Vue {
    public districtCodeList=[];
    public imageFile: any = null;
    public activityId;
    public activityImage;
    public remark='';
    public statusName='';
    public firstStartTime='';
    public data() {
        return {
            config,
            form: this.$form.createForm(this),
            hasLogo: false,
            districtCodeList:[]
        };
    }
    public created() {
        
    }
    
    public handleCreate(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                if('已失效' == this.statusName){
                    let firstTime = new Date(this.firstStartTime);
                    let lastTime = new Date(values.signUpTime[0]);
                    if(firstTime.getTime() !=lastTime.getTime() ){
                        this.$message.error('当前活动已失效，不允许修改报名开始时间，请重新选择报名开始时间为'+this.firstStartTime);
                        return;
                    }
                }
                if(!this.remark || this.remark.length>8048){
                    this.$message.error('请输入8048字以内的活动描述');
                    return;
                }
                let applyEndTime = new Date(values.signUpTime[1]);
                let endTIme = new Date(values.activityTime[1]);
                if(applyEndTime.getTime()>endTIme.getTime()){
                    this.$message.error('报名结束时间不能大于活动时间的结束时间');
                    return;
                }
                console.log('this.districtCodeList',this.districtCodeList);
                if(null != this.districtCodeList && this.districtCodeList.length >5 ){
                    this.$message.error('最多勾选5个城市');
                    return;
                }
                if(values.initCount && parseFloat(values.initCount) >= parseFloat( values.totalCount)){
                    this.$message.error('初始名额必须小于总名额');
                    return;
                }
                console.log('23',!this.imageFile || !this.imageFile[0] || !this.imageFile[0].url);
                if(!this.imageFile || !this.imageFile[0] || !this.imageFile[0].url){
                    this.$message.error('请上传图片');
                    return;
                }
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => {
                        this.$store.dispatch(
                            'activitySignUpPage/editAct',
                            this.formatParams(values)
                        );
                    },
                    onCancel() {
                        // do nothing
                    }
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    @Watch('$store.state.activitySignUpPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$message.success('提交成功');
            this.$emit('onCancel');
        }
    }
    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                this.$emit('onCancel');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    private changeDistrictCodeData(rawData: any) {
        console.log('n',rawData)
        this.districtCodeList = rawData;
    }
    /**
     * 将表单数据格式化为请求参数
     */
    private formatParams(formData: any) {
        console.log("formData",formData);
        
        console.log("000");
        let imgage = this.activityImage;
        if(this.imageFile && this.imageFile[0] && this.imageFile[0].url){
            imgage = this.imageFile[0].url;
        }
        let initCount = 0;
        if(formData.initCount){
            initCount = formData.initCount;
        }
        const formValue =  {
            activityId:this.activityId,
            activityName: formData.name,
            startTime: formData.activityTime[0].format(
            'YYYY-MM-DD HH:mm:ss'
            ),
            endTime: formData.activityTime[1].format(
                'YYYY-MM-DD HH:mm:ss'
            ),
            applyStartTime: formData.signUpTime[0].format(
                'YYYY-MM-DD HH:mm:ss'
            ),
            applyEndTime: formData.signUpTime[1].format(
                'YYYY-MM-DD HH:mm:ss'
            ),
            activityType: formData.activityType,
            activityImage: this.imageFile &&this.imageFile[0] && this.imageFile[0].url,
            totalCount: formData.totalCount,
            initCount: initCount,
            districtCodeList: this.districtCodeList,
            remark: this.remark
        };
        console.log(formValue);
        return formValue;
    }

    public changeHandler(key: string, value: any) {
        if (key === 'image') {
            this.imageFile = value;
            if (value.length === 0) {
                value = '';
            } else if (value[0].status === 'done') {
                value = value[0].url;
            } else {
                return;
            }
        };
    }
    private formatFormData(rawData: any) {
        if (rawData && rawData.activityImage) {
            this.imageFile = [{
                uid: -1,
                url: rawData.activityImage,
                status: 'done',
                thumbUrl: rawData.activityImage,
                name: 'image',
                lastModified: Date.now(),
                lastModifiedDate: new Date(),
                size: 0,
                type: 'image/jpeg',
                response: null
            }];
        }
        this.remark = rawData.remark;
        this.activityId = rawData.activityId;
        this.activityImage = rawData.activityImage;
        this.districtCodeList = rawData.districtCodeList;
        this.firstStartTime = rawData.applyStartTime;
        this.statusName = rawData.statusName;
        return {
            ...rawData,
            activityTime: [
                rawData.startTime && moment(rawData.startTime),
                rawData.endTime && moment(rawData.endTime)
            ],
            signUpTime:[
                rawData.applyStartTime && moment(rawData.applyStartTime),
                rawData.applyEndTime && moment(rawData.applyEndTime)
            ],
            logo: rawData.activityImage
                ? [
                    {
                        uid: '-1',
                        name: '加载失败',
                        url: rawData.activityImage,
                        status: 'done'
                    }
                ]
                : [],
        }
    }
}

export default SearchForm;
