var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-tabs",
    {
      staticStyle: { overflow: "visible" },
      attrs: { activeKey: _vm.activeKey, animated: false },
      on: { change: _vm.handleChange }
    },
    [
      _c(
        "a-tab-pane",
        { key: "statistics-amount", attrs: { tab: "金额笔数统计" } },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.HOME.STATISTICS_AMOUNT] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c("AmountSearchPage", {
                      attrs: { active: _vm.activeKey === "statistics-amount" }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-tab-pane",
        { key: "statistics-coupon", attrs: { tab: "用券统计" } },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.HOME.STATISTICS_COUPON] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c("CouponSearchPage", {
                      attrs: { active: _vm.activeKey === "statistics-coupon" }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }