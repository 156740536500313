








































import {Modal} from 'ant-design-vue';
import {mapState} from 'vuex';
import {Component, Inject, Vue} from 'vue-property-decorator';
import columns from './columns';
import * as services from './services';
import {check} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import {normColumns,calcTableScrollX, getTop} from '@/utils';
import ClearText from '@/components/ClearText.vue';
@Component({
    components: {
        ClearText,
    },
    computed: {
        ...mapState({
            lists: (state: any) =>
                state.StatementInfoPage.list.map((order: any, idx: any) => ({
                    idx: (state.StatementInfoPage.page - 1) * 10 + idx + 1,
                    ...order
                })),
            total: (state: any) => state.StatementInfoPage.total,
            pageSize: (state: any) => state.StatementInfoPage.pageSize,
            page: (state: any) => state.StatementInfoPage.page,
            loading: (state: any) => state.StatementInfoPage.loading,
            fields: (state: any) => normColumns(
                columns, state.StatementInfoPage.fields, state.StatementInfoPage.sortFields),
            selectedRowKeys: (state: any) => state.StatementInfoPage.selectedRows,
           
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public privileges!: any;

    @Inject()
    public commit!: any;

    @Inject()
    public routerPush!: any;

    public fixed = 0;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    
    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + (-top) + 'px)';
        } else {
            this.style = '';
        }
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            columns,
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public handleSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('StatementInfoPage/setSelectedRows', [...selectedRowKeys]);
    }

    public handlePageChange(page: number) {
        this.commit('setPagination', {page});
        this.routerPush();
        this.dispatch('initQuery', this.$route.query)
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.commit('setPagination', {pageSize, page: 1});
        this.routerPush();
        this.dispatch('initQuery', this.$route.query)
    }
    
    public handleDrawerOpenClick( id: any,adjustFlag:any) {
       const list = check([PRIVILEGE.STATEMENT.RGTZTC], this.privileges);
        if (list.length > 0) {
            return this.$message.error(
                '需要权限: [' + list.map((l: any) => l.text).join(', ') + ']'
            );
        } else {
            
            if ( '0' !== adjustFlag) {
                this.$message.error('已触发过人工头寸调整，请知晓！');
                return
            }

            Modal.confirm({
                title: '确认人工头寸调整?',
                onOk: async () => {
                    try {
                        this.dispatch('adjustment',id)
                    } catch (e) {
                        this.$message.error(e.message);
                    }
                }
            });
        }
       
    }
}

export default SearchTable;
