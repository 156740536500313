var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout",
        [
          _c(
            "a-layout-header",
            { staticClass: "header" },
            [
              _c("img", {
                staticStyle: { margin: "22px 0", float: "left" },
                attrs: {
                  alt: "logo",
                  height: "20",
                  src: require("@/assets/pam_logo@2x.png")
                }
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    height: "64px",
                    "line-height": "64px",
                    "font-size": "10px",
                    "margin-left": "5px",
                    float: "left"
                  }
                },
                [_vm._v("v" + _vm._s(_vm.version))]
              ),
              _c(
                "a-dropdown",
                { attrs: { trigger: ["click"] } },
                [
                  _c(
                    "a",
                    { staticClass: "ant-dropdown-link", attrs: { href: "#" } },
                    [
                      _c("a-icon", {
                        staticStyle: { "margin-right": "5px" },
                        attrs: { type: "user" }
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$store.state.login.session.name) +
                          "\n                    "
                      ),
                      _c("a-icon", { attrs: { type: "down" } })
                    ],
                    1
                  ),
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      _c(
                        "a-menu-item",
                        { key: "updatePassword" },
                        [
                          _c("UpdatePasswordModal", {
                            scopedSlots: _vm._u([
                              {
                                key: "trigger",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { display: "block" } },
                                      [_vm._v("修改密码")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "logout", on: { click: _vm.handleLogout } },
                        [_vm._v("退出")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-layout",
        { staticStyle: { "min-height": "100vh" } },
        [
          _c(
            "a-layout-sider",
            {
              staticStyle: { background: "#fff" },
              attrs: { collapsible: "" },
              model: {
                value: _vm.collapsed,
                callback: function($$v) {
                  _vm.collapsed = $$v
                },
                expression: "collapsed"
              }
            },
            [
              _c("SideMenu", {
                attrs: { collapsed: _vm.collapsed },
                on: {
                  collapse: function() {
                    return (_vm.collapsed = !_vm.collapsed)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-layout-content",
            {
              ref: "content",
              style: {
                position: "relative",
                textAlign: "left",
                overflowY: "scroll",
                background: "#fff",
                padding: "24px",
                margin: 0,
                "margin-left": "15px"
              },
              attrs: { id: "console-content" }
            },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }