












































import {Modal} from 'ant-design-vue';
import {Component, Inject, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import * as DICTS from '@/common/dicts';
import columns from './columns';
import {normColumns, calcTableScrollX, getTop} from '@/utils';
import {check, Show, Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import RefuseDetail from './auditOpinion.vue';
import Detail from './Detail.vue';
interface SearchTable {
    selectedRowKeys: any;
}

@Component({
    components: {
        Click,
        Show,
        RefuseDetail,
        Detail
    },
    computed: {
        
        ...mapState({
            list: (state: any) =>
                state.actShenheSearchPage.list.map((item: any, idx: any) => ({
                    idx: (state.actShenheSearchPage.page - 1) * state.actShenheSearchPage.pageSize + idx + 1,
                    ...item
                })),
            total: (state: any) => state.actShenheSearchPage.total,
            pageSize: (state: any) => state.actShenheSearchPage.pageSize,
            page: (state: any) => state.actShenheSearchPage.page,
            loading: (state: any) => state.actShenheSearchPage.loading,
            fields: (state: any) => normColumns(
                columns, state.actShenheSearchPage.fields, state.actShenheSearchPage.sortFields),
            updatingStatus: (state: any) => state.actShenheSearchPage.updatingStatus,
            rowSelection(state: any) {
                const self: any = this;
                const selectedRowKeys = state.actShenheSearchPage.selectedRows;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            },
        })
    }
})
class SearchTable extends Vue {

    @Inject()
    public dispatch!: any;
    public fixed = 0;
    public PRIVILEGE = PRIVILEGE;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + (-top) + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }
    
    public data() {
        return {
            DICTS,
            columns,
            selectedRowKeys: [],
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('actShenheSearchPage/setSelectedRows', selectedRowKeys);
    }

    public handlePageChange(page: number) {
        this.$store.commit('actShenheSearchPage/setPagination', {page});
        this.$router.push({
            path: '/console/actShenhe/search' + this.$store.getters['actShenheSearchPage/queryString']()
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('actShenheSearchPage/setPagination', {pageSize, page: 1});
        this.$router.push({
            path: '/console/actShenhe/search' + this.$store.getters['actShenheSearchPage/queryString']()
        });
    }

    public handleOpenDetailDrawer(record: any) {
        this.$refs.detailRef.open(record);
    }
}

export default SearchTable;
