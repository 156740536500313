















































































































import moment from 'moment';
import { Component, Prop,Watch, Vue } from 'vue-property-decorator';

interface FixedRate {
  form: any;
}
let nextKeysTemp =[];
let nextDataKeysTemp =[];
@Component<FixedRate>({
    components: {
    },
    computed: {
        
        
    }
})

class FixedRate extends Vue {
   @Prop()
    public value!: any;
    @Prop()
    public accModeType!:any;
    @Prop()
    public isDisabled!: any;
    public isDisabledFlag!: any;

    public form;
    public ruleListData =[];

    public data() {
        return {
          ruleListData:[],
          form: this.$form.createForm(this),
            formItemLayoutWithOutLabel: {
                wrapperCol: {
                sm: { span: 15, offset: 0 },
                },
            }
        };
    }
    public created() {
        this.form = this.$form.createForm(this);
        nextKeysTemp = [];nextDataKeysTemp = [];
        this.isDisabledFlag = this.isDisabled;
         console.log(this.value)
        if(this.value){
          this.ruleListData = this.value;
             for(let i=0;i<this.value.length;i++){
                nextDataKeysTemp.push(i);
             }
             this.form.setFieldsValue({
                 keys: nextDataKeysTemp,
             });
             this.form.getFieldDecorator('keys', { initialValue: nextDataKeysTemp, preserve: true });
        }else{
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
            
        }
    }
    @Watch('value')
    private changeValue(){
      console.log(this.value);
      this.form = this.$form.createForm(this);
        nextKeysTemp = [];nextDataKeysTemp = [];
        
        if(this.value){
          this.ruleListData = this.value;
             for(let i=0;i<this.value.length;i++){
                nextDataKeysTemp.push(i);
             }
             this.form.setFieldsValue({
                 keys: nextDataKeysTemp,
             });
             this.form.getFieldDecorator('keys', { initialValue: nextDataKeysTemp, preserve: true });
             console.log(this.ruleListData)
        }else{
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
        }
    }
    @Watch('isDisabled')
    private isDisabledChange(){
      console.log(this.isDisabled);
      this.isDisabledFlag = this.isDisabled;
    }
    public remove  (k:any) {
      const { form } = this;
      const keys = form.getFieldValue('keys').map((item:number, index:number)=> {return index});
      if (keys.length === 0) {
        return;
      }
      keys.splice(k,1)
      form.setFieldsValue({
        keys: keys.map((item:number, index:number)=> {return index}),
      });
      this.ruleListData.splice(k, 1);
      this.$emit('change',  this.ruleListData);
    }

    public add  () {
      const { form } = this;
      const keys = form.getFieldValue('keys');
      if(keys.length > 4){
        this.$message.warning('最多添加5条')
        return;
      }
      const nextKeys = keys.concat(Math.random());
      form.setFieldsValue({
        keys: nextKeys.map((item:number, index:number)=> {return index}),
      });
      this.ruleListData.push({
        key: nextKeys.length - 1,realSubRate:'0',subAmtGtTrans: '2', subAmtGtFee: '2',subsidyType:'1',falg:'new'
      });
    }
   
}
export default FixedRate;
