














import {Modal} from 'ant-design-vue';
import {mapState} from 'vuex';
import {Component, Inject, Vue} from 'vue-property-decorator';
import {Click} from '@/components/Auth';
import ActivitiesStatusCount from '@/components/ActivitiesStatusCount.vue';
import PRIVILEGE from '@/common/privileges';

interface Actions {
    selectedRows: any;
}

@Component({
    components: {
        Click,
        ActivitiesStatusCount
    },
    computed: {
        ...mapState({
            loading() {
                return this.state().loading;
            },
            selectedRows: (state: any) => state.activitiesStopSearchPage.selectedRows
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;
    public roleName: any = [];
    public data() {
        return {
            PRIVILEGE
        };
    }
    public created() {
     if(this.$store.state.activityManage.payType === 'wx'){
         this.roleName.push(PRIVILEGE.ACTIVITIES_STOP.OPEN);
     }else{
         this.roleName .push(PRIVILEGE.UNION_ACTIVITIES_STOP.OPEN);
     }
}
    public handleRefresh() {
        this.dispatch('search');
    }



    public openActivities() {
        let flag = false;
        this.selectedRows.forEach((item:any) => {
            this.$store.state.activitiesStopSearchPage.list.forEach((obj:any) => {
                if(item === obj.id && obj.status != '暂停'){
                    flag = true
                }
            })
        })
        if (flag) {
            this.$message.error('只有暂停状态才能重启');
            return
        }
        Modal.confirm({
            title: '提示',
            content: '确定重启选中批次吗？',
            onOk: () => {
               this.dispatch('updateStatus', {
                    ids: this.selectedRows.join(','),
                    status: '1'
                });
            },
            onCancel() {
                // do nothing
            }
        });
    }
     public DetailDownload() {
        this.$router.push('/console/activity/detailDownload');
    }
}

export default Actions;
