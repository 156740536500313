


























import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
import Actions from './Actions.vue';
import FieldsFilter from '@/components/FieldsFilter.vue';
import SearchForm from './SearchForm.vue';
import SearchTable from './SearchTable.vue';
import { Component, Prop, Watch, Provide, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import columns from './columns';
import columnsUnion from './columnsUnion';
import * as Constant from '@/common/constant';
import store from './newstore';
import { queryToHumanReadable } from '@/utils';
import preferences from './preferences';
const humanReadableQuerySchema = {
    name: '活动名称',
    platActivity: '批次Id',
    createdBy: '创建人',
    sponsorName: '出资方',
    queryStartTime: {
        name: '创建时间',
        range: 'from'
    },
    queryEndTime: {
        name: '创建时间',
        range: 'to'
    },
    activityStartTime: {
        name: '活动时间',
        range: 'from'
    },
    activityEndTime: {
        name: '活动时间',
        range: 'to'
    }
};
@Component({
    components: {
        Actions,
        SearchForm,
        SearchTable,
        SearchCommonLayout,
        FieldsFilter
    },
    computed: {
        ...mapState({
            fields: (state: any) => state.activitiesDraftSearchPage.fields,
            sortArr: (state: any) => state.activitiesDraftSearchPage.sortFields,
            humanReadableQuery(state: any) {
                return queryToHumanReadable(
                    state.activitiesDraftSearchPage.query,
                    humanReadableQuerySchema
                );
            }
        })
    }
})
export default class DraftPage extends Vue {
    @Prop()
    public active!: boolean;

    public storeName = 'activitiesDraftSearchPage';

    @Prop()
    public paytype!: String;

    public currentColumns = columnsUnion;

    public data() {
        return {
            columns
        };
    }

    @Provide()
    public state() {
        return this.$store.state[this.storeName];
    }

    @Provide()
    public getters(name: string) {
        return this.$store.getters[this.storeName + '/' + name];
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(this.storeName + '/' + action, payload);
    }

    @Provide()
    public commit(mutation: string, payload?: any) {
        this.$store.commit(this.storeName + '/' + mutation, payload);
    }

    @Provide()
    public routerPush() {
        this.$router.push({
            path:
                '/console/activity/draft/search' + this.getters('queryString')()
        });
    }

    public created() {
        if (this.paytype == Constant.WX_TYPE) {
            this.currentColumns = columns;
        } else {
            this.currentColumns = columnsUnion;
        }
       
    }

    public registerStore() {
        if (!this.state()) {
            this.$store.registerModule([this.storeName], store);
            this.$watch(function() {
                return this.$store.state[this.storeName].error;
            }, this.errorListener);
        }
    }

    public errorListener(error: any) {
        if (this.paytype !== this.$store.state.activityManage.payType){
            return
        }
        return error && !error.silent && this.$message.error(error.message);
    }

    @Watch('$store.state.activityManage.payType')
    public payTypeChange(newVal: String, oldVal: String) {
        this.$store.commit(
            'activitiesDraftSearchPage/setColumns',
            this.currentColumns
        );
        this.$store.commit('activitiesDraftSearchPage/resetFields');
        this.$store.commit('activitiesDraftSearchPage/clear');
        this.$store.commit('activitiesDraftSearchPage/setQuery',{
             payType: this.$store.state.activityManage.payType
        });
    }

    // 路由驱动页面查询
    // @Watch('$route')
    // public routeListener() {
    //     if (!this.active) {
    //         return;
    //     }
    //     this.dispatch('initQuery', this.$route.query);
    //     // const {
    //     //     page,
    //     //     page_size,
    //     //     ...rest
    //     // }: any =  this.$route.query;

    //     // this.commit('setPagination', {
    //     //     page: (page && parseInt(page, 10)) || 1,
    //     //     pageSize: (page_size && parseInt(page_size, 10)) || 20
    //     // });
    //     // this.commit('resetQuery', rest);
    //     // this.dispatch('search');
    // }

    private handleFieldChange(e: any) {
        this.$store.commit('activitiesDraftSearchPage/setFields', e);
    }
    private handleFieldSort(e: any) {
        preferences.setSortFields(e);
        this.$store.commit('activitiesDraftSearchPage/sortFields', e);
    }
}
