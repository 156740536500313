





































import {mapState} from 'vuex';
import {Component, Vue} from 'vue-property-decorator';
import {
    normColumns,
    calcTableScrollX,
    getTop
} from '@/utils';
import ClearText from '@/components/ClearText.vue';
import columns from './columns';

const sortFieldMap: any = {
    'store.name': 'name',
    'store.mall_name': 'store__mallcoo_mall__id',
    'store.biz_district_name': 'store__biz_district__id',
    'customer.nick_name': 'nick_name',
    'customer.mobile': 'mobile'
};

interface SearchTable {
    selectedRowKeys: any[];
}

@Component<SearchTable>({
    components: {
        ClearText
    },
    computed: {
        // fixed() {
        //     if (this.full) ? this.contentEl.offsetHeight - this.thHeight - this.bottomHeight : 0;
        // },
        rowSelection() {
            const self: any = this;
            const {selectedRowKeys} = self;
            return {
                selectedRowKeys,
                onChange: self.onSelectChange,
                hideDefaultSelections: true
            };
        },
        ...mapState({
            orders: (state: any) =>
                state.orderSearchPage.list.map((order: any, idx: any) => ({
                    idx: (state.orderSearchPage.page - 1) * state.orderSearchPage.pageSize + idx + 1,
                    ...order
                })),
            total: (state: any) => state.orderSearchPage.total,
            pageSize: (state: any) => state.orderSearchPage.pageSize,
            page: (state: any) => state.orderSearchPage.page,
            loading: (state: any) => state.orderSearchPage.loading,
            fields: (state: any) => {
                const {
                    sort_name,
                    sequence
                } = state.orderSearchPage.query;
                columns.forEach((col: any) => {
                    if (sortFieldMap[col.dataIndex] === sort_name) {
                        col.sortOrder = String(sequence) === 'true' ? 'ascend' : 'descend';
                    } else {
                        col.sortOrder = false;
                    }
                });
                return normColumns(columns, state.orderSearchPage.fields, state.orderSearchPage.sortFields);
            }
        })
    }
})
class SearchTable extends Vue {

    public fixed = 0;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + (-top) + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            selectedRowKeys: [],
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public handleTableChange(pagination: any, filters: any, sorter: any) {
        const {
            sort_name,
            sequence
        } = this.$store.state.orderSearchPage.query;
        const {
            field,
            order
        } = sorter;
        
        function isFieldEqual() {
            if ((field === undefined || field === 'idx') && sort_name === undefined) {
                return true;
            }
            if (sortFieldMap[field] === sort_name) {
                return true;
            }
            return false;
        }

        function isOrderEqual() {
            if ((order === undefined || field === 'idx') && sequence === undefined) {
                return true;
            }
            if (
                (order === 'descend' && sequence === 'false')
                || (order === 'ascend' && sequence === 'true')
            ) {
                return true;
            }
            return false;
        }

        if (isOrderEqual() && isFieldEqual()) {
            return;
        }

        const newQuery: any = {};

        if (field === undefined || order === undefined) {
            newQuery.sort_name = undefined;
            newQuery.sequence = undefined;
        } else {
            newQuery.sort_name = sortFieldMap[field];
            newQuery.sequence = order === 'descend' ? 'false' : 'true';
        }

        this.$store.commit('orderSearchPage/setQuery', newQuery);
        this.$store.commit('orderSearchPage/setPagination', {page: 1});
        this.$router.push({
            path: `/console/order/search` + this.$store.getters['orderSearchPage/queryString']()
        });


        // if (field && order && sortFieldMap[field]) {
        //     const sortName = sortFieldMap[field];
        //     const sequence = order === 'descend' ? 'false' : 'true';
        //     if (query.sort_name === sortName && query.sequence === sequence) {
        //         return;
        //     }
        //     this.$store.commit('orderSearchPage/setQuery', {
        //         sort_name: sortName,
        //         sequence
        //     });

        // } else if (query.sort_name || query.sequence) {
        //     this.$store.commit('orderSearchPage/setQuery', {
        //         sort_name: undefined,
        //         sequence: undefined
        //     });
        //     this.$store.commit('orderSearchPage/setPagination', {
        //         page: 1
        //     });
        //     this.$router.push({
        //         path: `/console/order/search` + this.$store.getters['orderSearchPage/queryString']()
        //     });
        // }
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.selectedRowKeys = selectedRowKeys;
    }

    public handlePageChange(page: number) {
        this.$store.commit('orderSearchPage/setPagination', {page});
        this.$router.push({
            path: '/console/order/search' + this.$store.getters['orderSearchPage/queryString']()
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('orderSearchPage/setPagination', {pageSize, page: 1});
        this.$router.push({
            path: '/console/order/search' + this.$store.getters['orderSearchPage/queryString']()
        });
    }
}

export default SearchTable;
