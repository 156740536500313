
















import { mapState } from 'vuex';
import { Modal } from 'ant-design-vue';
import { Click } from '@/components/Auth';
import { Component, Inject, Vue } from 'vue-property-decorator';
import PRIVILEGE from '@/common/privileges';

interface Actions {
    selectedRowKeys: any;
}

@Component<Actions>({
    components: {
        Click
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.activitySignUpPage.loading,
            selectedRowKeys: (state: any) => state.activitySignUpPage.selectedRowKeys,
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;


    public data() {
        return {
            PRIVILEGE
        };
    }

    public handleCreateSuccess() {
        this.dispatch('search');
    }

    public handleRefresh() {
        this.dispatch('search');
    }
    public stopActivities() {
        let flag = false;
        console.log('this.selectedRowKeys:',this.$store.state.activitySignUpPage.list);
        this.selectedRowKeys.forEach((item:any) => {
            this.$store.state.activitySignUpPage.list.forEach((obj:any) => {
                if(item === obj.activityId && (obj.statusName != '已生效') ){
                    flag = true
                }
            })
        })
        if (flag) {
            this.$message.error('只有已生效状态数据才能改为失效');
            return;
        }
        Modal.confirm({
            title: '提示',
            content: '确认失效该活动？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRowKeys.join(','),
                    status: 'INVALID'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public deleteActivities() {
         let flag = false;
            this.selectedRowKeys.forEach((item:any) => {
                this.$store.state.activitySignUpPage.list.forEach((obj:any) => {
                    if(item === obj.activityId && (obj.statusName != '未生效') ){
                        flag = true
                    }
                })
            })
            if (flag) {
                this.$message.error('只有未生效状态数据才能删除');
                return;
            }
        Modal.confirm({
            title: '提示',
            content: '确认删除该活动？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRowKeys.join(','),
                    status: 'DELETED'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    
    public handleAddAdPlan() {
        this.$router.push('/console/config/signUpConfig');
    }
}

export default Actions;
