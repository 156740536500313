
























































































































import {Component, Inject, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import {deleteEmpty} from '@/utils';
import {DatePicker,policyBank} from '@/components/custom-form-components';
import Owner from '@/components/custom-form-components/Owner.vue';
interface SearchForm {
    form: any;
}
interface QueryParams {
    expenseAccount	:string
}
@Component({
    components: {
        DatePicker,policyBank,Owner
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.actManageSearchPage.query);
            },
            loading: (state: any) => state.actManageSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.dispatch('initQuery', query);
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any) {
        let bankCodes = values.bank;
        console.log(bankCodes);
        if(bankCodes){
            bankCodes = bankCodes.join(',');
        }
        console.log(bankCodes)
         return {
            name: values.name,
            bank: bankCodes,
            ownerId:values.ownerId,
            activityId:values.activityId,
            templetId:values.templetId,
            templetType:values.templetType,
            activityStatus:values.activityStatus
            // createStartTime: 
            //     values.createTime &&
            //     values.createTime[0] &&
            //     values.createTime[0].format('YYYY-MM-DD HH:mm:ss'),
            // createEndTime:
            //     values.createTime &&
            //     values.createTime[1] &&
            //     values.createTime[1].format('YYYY-MM-DD HH:mm:ss'),
            // createdBy: values.createdBy
        };
    }

    private formatFormData(query: any) {

        return {
            ...query,
            // createTime: [
            //     query.createStartTime && moment(query.createStartTime),
            //     query.createEndTime && moment(query.createEndTime)
            // ]
        };
    }
}

export default SearchForm;
