





















import {Component, Vue, Inject} from 'vue-property-decorator';
import SearchTable from '@/components/SearchTable.vue';
import {mapState} from 'vuex';
import {priceCustomRender, defaultCustomRender} from '@/utils';
import ClearText from '@/components/ClearText.vue';

@Component({
    components: {
        SearchTable,
        'c-clear-text': ClearText
    },
    computed: {
        ...mapState({
            list() {
                return this.state().list;
            },
            total() {
                return this.state().total;
            },
            page() {
                return this.state().page;
            },
            pageSize() {
                return this.state().pageSize;
            },
            loading() {
                return this.state().loading;
            }
        })
    }
})
class EmployeeSearchTable extends Vue {

    @Inject()
    public state!: any;
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            columns: [{
                title: '姓名',
                dataIndex: 'name',
                customRender: defaultCustomRender
            }, {
                title: '手机号',
                dataIndex: 'mobile',
                scopedSlots: {customRender: 'mobile'},
            }, {
                title: '角色',
                dataIndex: 'role',
                customRender: defaultCustomRender
            }]
        };
    }

    public handlePageChange(page: number) {
        this.dispatch('pageChange', page);
    }

    public handlePageSizeChange(size: number) {
        this.dispatch('pageSizeChange', size);
    }
}

export default EmployeeSearchTable;
