


































import {
    Component,
    Provide,
    Prop,
    Vue,
    Inject,
    Watch
} from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import ActivityDetailSearchPage from './ActivityDetail/SearchPage/index.vue';
import ActivityDetailSearchPageUnion from './ActivityDetailUnion/SearchPage/index.vue';
import SponsorDetailSearchPage from './SponsorDetail/SearchPage/index.vue';
import { Show } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import { deleteEmpty } from '@/utils';

@Component({
    components: {
        ActivityDetailSearchPage,
        SponsorDetailSearchPage,
        ActivityDetailSearchPageUnion,
        Show
    },
    computed: {}
})
class Index extends Vue {
    @Prop()
    public title!: string | number;

    public activeKey = 'activityDetail';
    public cacheStoreId!: any;
    public PRIVILEGE = PRIVILEGE;
    public roleName: any = [];
    public created() {
        if(this.$store.state.activityManage.payType === 'wx'){
             this.roleName.push(PRIVILEGE.DETAIL_DOWNLOAD.ACTIVITY_DETAIL);
         }else{
             this.roleName .push(PRIVILEGE.UNION_DETAIL_DOWNLOAD.ACTIVITY_DETAIL);
         }
        if (this.$route.path === '/console/activity/activityDetail') {
            this.activeKey = 'activityDetail';
        } else if (
            this.$route.path === '/console/activity/sponsorDetailUnion'
        ) {
            this.activeKey = 'activityDetailUnion';
        } else if (this.$route.path === '/console/activity/sponsorDetail') {
            this.activeKey = 'sponsorDetail';
        }
    }
    @Provide()
    public commit(action: string, payload?: any) {
        console.log(payload);
        this.$store.dispatch('activityDetailSearchPage/' + action, payload);
    }
    @Provide()
    public commitSend(action: string, payload?: any) {
        this.$store.dispatch('sponsorDetailSearchPage/' + action, payload);
    }

    @Provide()
    public commitUnion(action: string, payload?: any) {
        this.$store.dispatch('activityDetailSearchPage/' + action, payload);
    }

    public handleChange(key: string) {
        this.activeKey = key;
        if (key === 'activityDetail') {
            this.commit('search');
        } else if (key === 'activityDetailUnion') {
            this.commitUnion('search');
        } else {
            this.commitSend('search');
        }
    }
}

export default Index;
