var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menuBox" },
    [
      _c(
        "a-menu",
        {
          style: {
            height: "100%",
            borderRight: 0,
            paddingTop: "20px",
            textAlign: "left",
            margin: "0 auto",
            paddingRight: "2px"
          },
          attrs: {
            mode: "inline",
            "open-keys": _vm.openKeys,
            selectedKeys: _vm.selectedKey
          },
          on: { click: _vm.scrolTop, openChange: _vm.onOpenChange }
        },
        [
          _c(
            "a-menu-item",
            { key: "home" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to:
                      "/console/home/statistics-amount/search?page=1&r=" +
                      Math.random()
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "home" } }),
                  _c("span", [_vm._v("首页")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "bizDistrict" },
            [
              _c(
                "router-link",
                { attrs: { to: "/console/bizDistrict/search?page=1" } },
                [
                  _c("a-icon", { attrs: { type: "environment" } }),
                  _c("span", [_vm._v("商圈管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "mall" },
            [
              _c(
                "router-link",
                { attrs: { to: "/console/mall/search?page=1" } },
                [
                  _c("a-icon", { attrs: { type: "shopping" } }),
                  _c("span", [_vm._v("商场管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-sub-menu",
            { key: "shopManage" },
            [
              _c(
                "span",
                { attrs: { slot: "title" }, slot: "title" },
                [
                  _c("a-icon", { attrs: { type: "shop" } }),
                  _c("span", [_vm._v("商户管理")])
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "shop" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/console/shop/search?page=1" } },
                    [_c("span", [_vm._v("商户管理")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "shopWhiteList" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/console/shopWhiteList/search?page=1" } },
                    [_c("span", [_vm._v("店铺白名单管理")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "order" },
            [
              _c(
                "router-link",
                { attrs: { to: "/console/order/search?page=1" } },
                [
                  _c("a-icon", { attrs: { type: "file-done" } }),
                  _c("span", [_vm._v("订单管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "account" },
            [
              _c(
                "router-link",
                { attrs: { to: "/console/account/user/search?page=1" } },
                [
                  _c("a-icon", { attrs: { type: "team" } }),
                  _c("span", [_vm._v("用户管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-sub-menu",
            { key: "statistics" },
            [
              _c(
                "span",
                { attrs: { slot: "title" }, slot: "title" },
                [
                  _c("a-icon", { attrs: { type: "bar-chart" } }),
                  _c("span", [_vm._v("数据统计管理")])
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "marketing" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/marketing/newbie-data/search?discount_coupon_type=NEWBIE&r=" +
                          Math.random()
                      }
                    },
                    [_c("span", [_vm._v("商户营销记录")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-sub-menu",
            { key: "system" },
            [
              _c(
                "span",
                { attrs: { slot: "title" }, slot: "title" },
                [
                  _c("a-icon", { attrs: { type: "setting" } }),
                  _c("span", [_vm._v("系统管理")])
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "operate-log" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/operate-log/search?page=1&r=" +
                          Math.random()
                      }
                    },
                    [_c("span", [_vm._v("操作日志管理")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "blacklist" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/console/blacklist/search?page=1&r=" + Math.random()
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "file-text" } }),
                  _c("span", [_vm._v("风控黑名单管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "q_whitelist" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/console/q_whitelist/search?page=1&r=" + Math.random()
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "bars" } }),
                  _c("span", [_vm._v("Q码机具白名单管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "advertisement" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to:
                      "/console/advertisement/search?_search=0&page=1&r=" +
                      Math.random()
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "tablet" } }),
                  _c("span", [_vm._v("广告管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "short-message" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to:
                      "/console/short-message/search?page=1&r=" + Math.random()
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "message" } }),
                  _c("span", [_vm._v("短信包配置管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-sub-menu",
            { key: "activities" },
            [
              _c(
                "span",
                { attrs: { slot: "title" }, slot: "title" },
                [
                  _c("a-icon", { attrs: { type: "container" } }),
                  _c("span", [_vm._v("营销管理")])
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "accountInfo" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/accountInfo/search?page=1&r=" +
                          Math.random()
                      }
                    },
                    [_c("span", [_vm._v("机构头寸管理")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "subject" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/console/subject/search?page=1&r=" + Math.random()
                      }
                    },
                    [_c("span", [_vm._v("主题活动管理")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "activity" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/activity/open/search?page=1&r=" +
                          Math.random()
                      }
                    },
                    [_c("span", [_vm._v("营销活动管理")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "hexiao" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/console/hexiao/searchPage" } },
                    [_c("span", [_vm._v("核销文件上传管理")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "statement" },
                [
                  _c("router-link", { attrs: { to: "/console/statement" } }, [
                    _c("span", [_vm._v("对账单")])
                  ])
                ],
                1
              ),
              _c(
                "a-sub-menu",
                { key: "signUp" },
                [
                  _c(
                    "span",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c("a-icon", { attrs: { type: "book" } }),
                      _c("span", [_vm._v("活动报名")])
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "signUpConfig" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to:
                              "/console/config/search?page=1&r=" + Math.random()
                          }
                        },
                        [_c("span", [_vm._v("报名配置管理")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "signUpInspection" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to:
                              "/console/inspection/search?page=1&r=" +
                              Math.random()
                          }
                        },
                        [_c("span", [_vm._v("报名审核管理")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-sub-menu",
            { key: "bankActivities" },
            [
              _c(
                "span",
                { attrs: { slot: "title" }, slot: "title" },
                [
                  _c("a-icon", { attrs: { type: "container" } }),
                  _c("span", [_vm._v("银行营销管理")])
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "policy" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/console/policy/search?page=1&r=" + Math.random()
                      }
                    },
                    [_c("span", [_vm._v("政策管理")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "actManage" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/actManage/search?page=1&r=" + Math.random()
                      }
                    },
                    [_c("span", [_vm._v("活动管理")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "bankRule" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/bankRule/merSubsidyRule/search?page=1&r=" +
                          Math.random()
                      }
                    },
                    [_c("span", [_vm._v("规则管理")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "activityMer" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/activityMer/search?page=1&r=" +
                          Math.random()
                      }
                    },
                    [_c("span", [_vm._v("活动商户管理")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "actShenhe" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/actShenhe/search?page=1&r=" + Math.random()
                      }
                    },
                    [_c("span", [_vm._v("报名审核管理")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "subsidyDetail" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/subsidyDetail/search?page=1&r=" +
                          Math.random()
                      }
                    },
                    [_c("span", [_vm._v("补贴明细")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "activityMerBatch" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/activityMerBatch/search?page=1&r=" +
                          Math.random()
                      }
                    },
                    [_c("span", [_vm._v("商户补贴限额数据导出")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "bankMerchantInit" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/console/bankMerchant/init?r=" + Math.random()
                      }
                    },
                    [_c("span", [_vm._v("银行机构商户初始化")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "companyQueryConf" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/companyQueryConf/search?page=1&r=" +
                          Math.random()
                      }
                    },
                    [_c("span", [_vm._v("分公司查询数据配置")])]
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "sharedQuotaConf" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/console/sharedQuotaConf/search?page=1&r=" +
                          Math.random()
                      }
                    },
                    [_c("span", [_vm._v("共享额度配置")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "boothCode" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/console/boothCode/download?r=" + Math.random()
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "download" } }),
                  _c("span", [_vm._v("线下码申请下载")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "payexchange" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/console/payexchange/search?page=1&r=" + Math.random()
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "dollar" } }),
                  _c("span", [_vm._v("支付转发管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "gray" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/console/gray/search?page=1&r=" + Math.random()
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "sync" } }),
                  _c("span", [_vm._v("重构灰度切换管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "districtCode" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/console/districtCode/search?page=1&r=" + Math.random()
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "global" } }),
                  _c("span", [_vm._v("地区码维护管理")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "shtManage" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/console/shtManage/search?page=1&r=" + Math.random()
                  }
                },
                [
                  _c("a-icon", { attrs: { type: "interation" } }),
                  _c("span", [_vm._v("商户通商户推送管理")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }