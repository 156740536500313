
































import {Component, Vue} from 'vue-property-decorator';
import UserSearchPage from './User/SearchPage/index.vue';
import RoleSearchPage from './Role/SearchPage/index.vue';
import WhitelistSearchPage from './Whitelist/SearchPage/index.vue';
import CustomerSearchPage from './Customer/SearchPage/index.vue';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        UserSearchPage,
        RoleSearchPage,
        WhitelistSearchPage,
        CustomerSearchPage,
        Show
    },
    computed: {}
})
class Index extends Vue {

    public activeKey = 'user';

    public PRIVILEGE = PRIVILEGE;

    public created() {
        if (this.$route.path === '/console/account/user/search') {
            this.activeKey = 'user';
        } else if (this.$route.path === '/console/') {
            this.activeKey = 'role';
        } else if (this.$route.path === '/console/account/whitelist/search') {
            this.activeKey = 'whitelist';
        } else if (this.$route.path === '/console/account/customer/search') {
            this.activeKey = 'customer';
        }
    }

    public handleChange(key: string) {
        this.activeKey = key;

        if (key === 'user') {
            this.$router.push({
                path: '/console/account/user/search?'
                    + this.$store.getters['userSearchPage/queryString'](),
            });
        } else if (key === 'role') {
            if (this.$store.getters['roleSearchPage/queryString']) {
                this.$router.push({
                    path: '/console/account/role/search?'
                        + this.$store.getters['roleSearchPage/queryString'](),
                });
            } else {
                this.$router.push({
                    path: '/console/account/role/search?r='
                        + Math.random(),
                });
            }
        } else if (key === 'whitelist') {
            if (this.$store.getters['whitelistSearchPage/queryString']) {
                this.$router.push({
                    path: '/console/account/whitelist/search?'
                        + this.$store.getters['whitelistSearchPage/queryString'](),
                });
            } else {
                this.$router.push({
                    path: '/console/account/whitelist/search?r='
                        + Math.random(),
                });
            }
        } else {
            if (this.$store.getters['customerSearchPage/queryString']) {
                this.$router.push({
                    path: '/console/account/customer/search?'
                        + this.$store.getters['customerSearchPage/queryString'](),
                });
            } else {
                this.$router.push({
                    path: '/console/account/customer/search?r='
                        + Math.random(),
                });
            }
        }
    }
}

export default Index;
