





























import { Component, Vue, Inject } from 'vue-property-decorator';
import { mapState } from 'vuex';
import SearchFormWrapper from '@/components/SearchFormWrapper.vue';
import { DatePicker } from '@/components/custom-form-components';
import moment from 'moment';

interface Query {
    createBy: string;
    startTime: string;
    endTime: string;
}

interface FormData {
    createBy: string;
    startTime: string;
    endTime: string;
}

@Component({
    components: {
        SearchFormWrapper,
        DatePicker
    },
    computed: {
        ...mapState<{ companyQueryConfSearchPage: any }>({
            loading() {
                return this.state().loading;
            },
            formData() {
                return this.queryToFormData(this.state().query);
            }
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public state!: any;

    @Inject()
    public dispatch!: any;

    public data() {
        return {
            collapsed: false
        };
    }

    public format(values: FormData): Query {
        let [startTime, endTime] = values.ruleTime || [];
        return {
            createBy: values.createBy,
            startTime: startTime && startTime.format('YYYY-MM-DD HH:mm:ss'),
            endTime: endTime && endTime.format('YYYY-MM-DD HH:mm:ss')
        };
    }
   
    public queryToFormData(query: Query): FormData {
        return {
            createBy: query.createBy,
            ruleTime: [
                query.startTime && moment(query.startTime),
                query.endTime && moment(query.endTime)
            ]
        };
    }

    public handleSearch(query: Query) {
        this.dispatch('queryChange', query);
    }
}

export default SearchForm;
