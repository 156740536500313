var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _vm.formData
        ? _c(
            "a-form",
            { staticClass: "ad-create-form", attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                { attrs: { label: "活动名称" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "activityName",
                          { initialValue: _vm.formData.activityName }
                        ],
                        expression:
                          "['activityName', {initialValue: formData.activityName}]"
                      }
                    ],
                    attrs: { disabled: true }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "活动时间" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "activityTime",
                          {
                            initialValue:
                              _vm.formData.startTime +
                              "—" +
                              _vm.formData.endTime
                          }
                        ],
                        expression:
                          "[\n                            'activityTime',\n                            {\n                               initialValue: formData.startTime +'—'+formData.endTime\n                            }\n                        ]"
                      }
                    ],
                    attrs: { disabled: true, placeholder: "-" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "报名时间" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "activityApplyTime",
                          {
                            initialValue:
                              _vm.formData.applyStartTime +
                              "—" +
                              _vm.formData.applyEndTime
                          }
                        ],
                        expression:
                          "[\n                            'activityApplyTime',\n                            {\n                               initialValue: formData.applyStartTime +'—'+formData.applyEndTime\n                            }\n                        ]"
                      }
                    ],
                    attrs: { disabled: true, placeholder: "-" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "活动类型" } },
                [
                  _c("SignUpActivityType", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "activityType",
                          {
                            initialValue: _vm.formData.activityType
                          }
                        ],
                        expression:
                          "[\n                            'activityType',\n                            { \n                                initialValue:formData.activityType\n                            },\n                        ]"
                      }
                    ],
                    attrs: { disabled: true, placeholder: "请选择活动类型" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "总名额" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "totalCount",
                          {
                            initialValue: _vm.formData.totalCount
                          }
                        ],
                        expression:
                          "['totalCount', {   \n                                initialValue:formData.totalCount\n                            }]"
                      }
                    ],
                    attrs: { disabled: true, placeholder: "-" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "初始名额" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "initCount",
                          {
                            initialValue: _vm.formData.initCount
                          }
                        ],
                        expression:
                          "['initCount',{\n                           initialValue:formData.initCount\n                        }]"
                      }
                    ],
                    attrs: {
                      disabled: true,
                      placeholder: "请输入初始名额，低于总名额限制"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "实际已报名数" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "initCount",
                          {
                            initialValue: _vm.formData.passCount
                          }
                        ],
                        expression:
                          "['initCount',{\n                           initialValue:formData.passCount\n                        }]"
                      }
                    ],
                    attrs: {
                      disabled: true,
                      placeholder: "请输入初始名额，低于总名额限制"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "开展地区" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "initCount",
                          {
                            initialValue: _vm.formData.supportCity
                          }
                        ],
                        expression:
                          "['initCount',{\n                                initialValue:formData.supportCity\n                            }]"
                      }
                    ],
                    attrs: { disabled: true, placeholder: "" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "活动宣传图" } },
                [
                  _c("ImageCrop", {
                    attrs: {
                      disabled: true,
                      noPreview: true,
                      noCrop: true,
                      minWidth: 750,
                      value: _vm.imageFile
                    },
                    on: {
                      change: function($event) {
                        return _vm.changeHandler("image", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "活动描述" } },
                [
                  _c("vue-ueditor-wrap", {
                    attrs: { disabled: true, config: _vm.editorProps.config },
                    model: {
                      value: _vm.remark,
                      callback: function($$v) {
                        _vm.remark = $$v
                      },
                      expression: "remark"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "状态" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "statusName",
                          {
                            initialValue: _vm.formData.statusName
                          }
                        ],
                        expression:
                          "['statusName',{\n                                initialValue:formData.statusName\n                            }]"
                      }
                    ],
                    attrs: { disabled: true, placeholder: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }