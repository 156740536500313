var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "biz-district-edit-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "biz-district-edit-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleSubmit }
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "商圈ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "district_id",
                      {
                        rules: [{ required: true, message: "请填写必填项!" }],
                        initialValue: _vm.formData.district_id
                      }
                    ],
                    expression:
                      "['district_id', {rules: [{required: true, message: '请填写必填项!',}], initialValue: formData.district_id}]"
                  }
                ],
                attrs: { disabled: true, placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商圈名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name",
                      {
                        rules: [
                          { required: true, message: "请填写必填项!" }
                        ].concat(_vm.BIZ_DISTRICT_RULES.NAME),
                        initialValue: _vm.formData.name
                      }
                    ],
                    expression:
                      "['name', {rules: [\n                    {required: true, message: '请填写必填项!'},\n                    ...BIZ_DISTRICT_RULES.NAME\n                ], initialValue: formData.name}]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "英文名" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name_english",
                      {
                        initialValue: _vm.formData.name_english,
                        rules: [].concat(_vm.BIZ_DISTRICT_RULES.NAME_ENGLISH)
                      }
                    ],
                    expression:
                      "['name_english', {\n                    initialValue: formData.name_english,\n                    rules: [...BIZ_DISTRICT_RULES.NAME_ENGLISH]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商圈归属" } },
            [
              _c("DistrictBelong", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "belong",
                      {
                        rules: [{ required: true, message: "请填写必填项!" }],
                        initialValue: _vm.formData.belong
                      }
                    ],
                    expression:
                      "['belong', {rules: [{required: true, message: '请填写必填项!'}], initialValue: formData.belong}]"
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "H5_URL" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "link_url",
                      {
                        initialValue: _vm.formData.link_url,
                        rules: [].concat(_vm.BIZ_DISTRICT_RULES.LINK_URL)
                      }
                    ],
                    expression:
                      "['link_url', {\n                    initialValue: formData.link_url,\n                    rules: [...BIZ_DISTRICT_RULES.LINK_URL]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "协议标题" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "agreement_title",
                      {
                        initialValue: _vm.formData.agreement_title,
                        rules: [].concat(_vm.BIZ_DISTRICT_RULES.AGREEMENT_TITLE)
                      }
                    ],
                    expression:
                      "['agreement_title', {\n                    initialValue: formData.agreement_title,\n                    rules: [...BIZ_DISTRICT_RULES.AGREEMENT_TITLE]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "协议URL" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "agreement_url",
                      {
                        initialValue: _vm.formData.agreement_url,
                        rules: [].concat(_vm.BIZ_DISTRICT_RULES.AGREEMENT_URL)
                      }
                    ],
                    expression:
                      "['agreement_url', {\n                    initialValue: formData.agreement_url,\n                    rules: [...BIZ_DISTRICT_RULES.AGREEMENT_URL]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商圈Logo" } },
            [
              _c("ImageCrop", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "logo",
                      {
                        initialValue: _vm.formData.logo,
                        rules: [{ required: true, message: "请填写必填项!" }]
                      }
                    ],
                    expression:
                      "['logo', {initialValue: formData.logo, rules: [{required: true, message: '请填写必填项!'}]}]"
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        disabled: _vm.auditing,
                        loading: _vm.saving
                      }
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.saving },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("\n                    取消\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }