var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "biz-district-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate }
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name",
                      {
                        initialValue: _vm.name,
                        rules: [
                          { required: true, message: "请填写必填项!" },
                          { max: 64, message: "长度超限，请检查后重新输入!" }
                        ]
                      }
                    ],
                    expression:
                      "['name', { initialValue: name,\n                        rules: [\n                            {required: true, message: '请填写必填项!'},\n                            {max: 64, message: '长度超限，请检查后重新输入!'}\n                        ]\n                    }]"
                  }
                ],
                attrs: { placeholder: "请填名称" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "业主方" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["ownerId", { initialValue: _vm.ownerId }],
                    expression: "['ownerId', { initialValue: ownerId}]"
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "费用账户" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "expenseAccount",
                      {
                        initialValue: _vm.expenseAccount,
                        rules: [
                          { required: true, message: "请填写必填项!" },
                          { max: 64, message: "长度超限，请检查后重新输入!" }
                        ]
                      }
                    ],
                    expression:
                      "['expenseAccount', { initialValue: expenseAccount,\n                        rules: [\n                            {required: true, message: '请填写必填项!'},\n                            {max: 64, message: '长度超限，请检查后重新输入!'}\n                        ]\n                    }]"
                  }
                ],
                attrs: { disabled: true, placeholder: "请填写费用账户" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "备注" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "remark",
                      {
                        initialValue: _vm.remark,
                        rules: [
                          { required: true, message: "请填写必填项!" },
                          { pattern: /^.{0,256}$/, message: "必填，限制256字" }
                        ]
                      }
                    ],
                    expression:
                      "[\n                        'remark',\n                        {  initialValue: remark,\n                            rules: [\n                                {required: true, message: '请填写必填项!'},\n                                {pattern: /^.{0,256}$/, message: '必填，限制256字'}\n                            ]\n                        }\n                    ]"
                  }
                ],
                staticStyle: { width: "95%" },
                attrs: {
                  placeholder: "其他信息记录填写",
                  autosize: { minRows: 6, maxRows: 6 },
                  maxlength: "256"
                }
              })
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.loading
                      }
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("\n                    取消\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }