


























































































































































import {Modal} from 'ant-design-vue';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';


import Mall from '@/components/custom-form-components/Mall';
import BizDistrict from '@/components/custom-form-components/BizDistrict.vue';
import ImageCrop from '@/components/custom-form-components/ImageCrop/index.vue';
import StoreStatus from '@/components/custom-form-components/StoreStatus';

import config from '@/config';
import {STORE as STORE_RULES} from '@/common/formItemRules';

interface EditForm {
    imgEditing: boolean;
    form: any;
    hasLogo: boolean;
    hasAd: boolean;
}

interface Params {
    store_id: string;
    // refund_open: string;
    mallcoo_mall: string;
    mallcoo_shop_id: string;
    ad_image: string;
    ad_link: string;
    // status: string;
    // floor_door_no: string;
    phone: string;
    logo_url: string;
    name: string;
    biz_district_id: string;
}


@Component({
    components: {
        Mall,
        BizDistrict,
        ImageCrop,
        StoreStatus
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.shopEditPage.data);
            },
            saving: state => state.shopEditPage.saving,
            auditing: state => state.common.auditing
        })
    }
})
class EditForm extends Vue {
    @Watch('$store.state.common.auditStatus')
    public auditStatusChange(as: any) {
        if (as && as.status !== 'PIC_PASS') {
            this.$message.error('审核图片未通过，请检查图片后重新上传');
        }
        this.imgEditing = true;
    }

    public data() {
        return {
            imgEditing: false,
            STORE_RULES,
            config,
            form: this.$form.createForm(this),
            hasLogo: false,
            hasAd: false,
            showLeaveConfirmModal: false
        };
    }

    public validatePhone(rule: any, value: string, callback: any) {
        if (this.$store.state.shopEditPage.data.phone === value) {
            callback();
        } else {
            if (!/^[-0-9]{0,20}$/.test(value)) {
                callback([{
                    phone: {
                        errors: [{
                            message: '必须为数字或-，长度不能超过20',
                            filed: 'phone'
                        }]
                    }
                }]);
            } else {
                callback();
            }
        }
    }

    public handleSubmit(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                if (this.imgEditing) {
                    // 图片审核结果是否通过？
                    const auditStatus = this.$store.state.common.auditStatus;
                    if (auditStatus && auditStatus.status === 'PIC_PASS') {
                        Modal.confirm({
                            title: '提示',
                            content: '是否确认提交?',
                            onOk: () => {
                                this.$store.dispatch(
                                    'shopEditPage/save',
                                    this.formatParams(values)
                                );
                            },
                            onCancel() {
                                // do nothing
                            }
                        });
                    } else {
                        this.$message.error('审核图片未通过，请检查图片后重新上传');
                    }
                } else {// 如果图片没改动
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'shopEditPage/save',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                }
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public handleReset() {
        this.form.resetFields();
    }

    public handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters['shopSearchPage/queryString']();
                this.$router.push({
                    path: '/console/shop/search' + queryString
                });
            },
            onCancel() {
                // do nothing
            }
        });
    }

    private formatFormData(rawData: any) {
        // function formatInteger(value: string) {
        //     return /^\d+$/.test(value) && parseInt(value, 10) || undefined;
        // }
        const formData = {
            ...rawData,
            mallcoo: rawData.mallcoo && rawData.mallcoo.mallcoo_mall_id,
            biz_district_id: rawData.biz_district && rawData.biz_district.id
        };

        if (rawData.logo_url) {
            formData.logo = [
                {
                    uid: '-1',
                    status: 'done',
                    name: 'logo',
                    url: rawData.logo_url
                }
            ];
            this.hasLogo = true;
        }

        if (rawData.ad_image) {
            formData.ad = [
                {
                    uid: '-1',
                    status: 'done',
                    name: 'ad',
                    url: rawData.ad_image
                }
            ];
            this.hasAd = true;
        }

        return formData;
    }

    /**
     * 将表单数据格式化为请求参数
     */
    private formatParams(formData: any): Params {
        const params = {
            store_id: formData.store_id,
            // refund_open: formData.refund_open === 'true' ? 'True' : 'False',
            mallcoo_mall: formData.mallcoo,
            mallcoo_shop_id: formData.mallcoo_shop_id,
            ad_image: formData.ad && formData.ad[0] && formData.ad[0].url,
            ad_link: formData.link_ad,
            // status: formData.status,
            // floor_door_no: formData.floor_door_no,
            phone: formData.phone,
            logo_url: formData.logo && formData.logo[0] && formData.logo[0].url,
            name: formData.name,
            biz_district_id: formData.biz_district_id
        };

        if (params.phone === this.$store.state.shopEditPage.data.phone) {
            delete params.phone;
        }
        return params;
    }
}

export default EditForm;
