































import {Component, Vue} from 'vue-property-decorator';
import {Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import {deleteEmpty} from '@/utils';
import * as services from './services';
import {SmsControlStatus} from '@/components/custom-form-components';
import {Modal} from 'ant-design-vue';

interface TransModal {
    form: any;
    loading: boolean;
    visible: boolean;
}

@Component({
    components: {
        Click,
        SmsControlStatus
    }
})
class TransModal extends Vue {
    public visible = false;
    public PRIVILEGE = PRIVILEGE;
    public all_user!: any;

    public dispatch!: any;
    public data() {
        return {
            loading: false,
            form: this.$form.createForm(this),
            all_user: 0
        };
    }

    public handleClose() {
        if (this.loading) {
            return;
        }
        this.visible = false;
    }

    public onClick() {
        if (this.loading) {
            return;
        }
        this.visible = true;
    }

    public handleSubmit(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                let contentText = '选择的用户将被限制单周短信接收数量，确定提交吗?';
                if('0' == values.weekly_limit){
                    contentText = '选择的用户将不限制单周短信接收数量，确定提交吗?';
                }
                Modal.confirm({
                    title: '提示',
                    content: contentText,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async () => {
                        this.loading = true;
                        const all_user = this.all_user;
                        const batch_user_id  = this.$store.state.customerSearchPage.selectedRows;
                        const ret = await services.transfer(deleteEmpty({
                            ...values,
                            batch_user_id,
                            all_user
                        }));
                         console.log(ret);
                        this.loading = false;
                        if(ret ===  null){
                            this.$message.success("提交成功");
                            this.$emit("success");
                            this.visible = false;
                            this.$store.dispatch('customerSearchPage/search');
                        }
                       
                        
                    }
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    
    public onChange(e:any) {
        this.all_user = e.target.checked ? 1 : 0;
    }
}

export default TransModal;
