










import { mapState } from 'vuex';
import { Modal } from 'ant-design-vue';
import { Click } from '@/components/Auth';
import { Component, Inject, Watch,Vue } from 'vue-property-decorator';
import PRIVILEGE from '@/common/privileges';

interface Actions {
    selectedRows: any;
    ownloading:any;
}

@Component<Actions>({
    components: {
        Click
    },
    computed: {
        ...mapState({
            loading() {
                return this.state().loading;
            },
            selectedRows: (state: any) =>
                state.activityInspectionPage.selectedRows,
            downloading(){
                return this.state().downloading;
            }
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;

    public data() {
        return {
            PRIVILEGE
        };
    }

    public handleCreateSuccess() {
        this.dispatch('search');
    }

    public handleRefresh() {
        this.dispatch('search');
    }
    @Watch('$store.state.activityInspectionPage.downloading')
    public hexiaoDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }
    @Watch('$store.state.activityInspectionPage.downloadError')
    public hexiaoDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error('导出失败，请联系技术人员!');
        }
    }

    @Watch('$store.state.activityInspectionPage.lastDownloadTime')
    public hexiaoDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }
    public handleDownload() {
        this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
        this.$store.dispatch('activityInspectionPage/download');
    }
    
}

export default Actions;
