


















import {Component, Vue} from 'vue-property-decorator';
import AmountSearchPage from './Amount/SearchPage/index.vue';
import CouponSearchPage from './Coupon/SearchPage/index.vue';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        AmountSearchPage,
        CouponSearchPage,
        Show
    },
    computed: {}
})
class Index extends Vue {

    public activeKey = 'statistics-amount';

    public PRIVILEGE = PRIVILEGE;

    public created() {
        if (this.$route.path === '/console/home/statistics-amount/search') {
            this.activeKey = 'statistics-amount';
        } else if (this.$route.path === '/console/home/statistics-coupon/search') {
            this.activeKey = 'statistics-coupon';
        }
    }

    public handleChange(key: string) {
        this.activeKey = key;

        if (key === 'statistics-amount') {
            this.$router.push({
                path: '/console/home/statistics-amount/search?r='
                    + Math.random(),
            });
        } else if (key === 'statistics-coupon') {
            this.$router.push({
                path: '/console/home/statistics-coupon/search?r='
                    + Math.random(),
            });
        }
    }
}

export default Index;
