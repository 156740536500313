



















import DetailForm from './DetailForm.vue';
import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Show,
        DetailForm
    }
})
export default class index extends Vue {
    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public title!: string | number;
    @Prop()
    public activityId !: string;
    @Prop()
    public paytype !: string;
    public created() {
    }

    @Watch('activityId') 
    public async initQueryData(error: any) {
        console.info("union ", this.activityId)
        if(this.activityId == null){
            return; 
        }
        await this.$store.dispatch('activityEditPageUnion/initData', this.activityId);
    }

    @Watch('$store.state.activityEditPageUnion.error')
    public errorListener(error: any) {
        if (this.paytype !== this.$store.state.activityManage.payType) {
            return;
        }
        return error && !error.silent && this.$message.error(error.message);
    }

    public onClose() {
        this.$emit('close');
    }
}
