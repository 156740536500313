







import {Component, Prop, Vue} from 'vue-property-decorator';
import {request_yx} from '@/utils';
import { Modal } from 'ant-design-vue';

@Component({
    components: {}
})
class Role extends Vue {

    @Prop()
    public value!: string;
    @Prop()
    public type!: string;
    public items = [];
    public flag = false;
    public loading = false;
    public tmpValue!: string;
    public policyData = [];

    public async created() {
        this.fetch();
    }

    public async fetch() {
        try {
            this.items = [];
            this.loading = true;
            
            const ret = await request_yx({
                api: '/console/activity/subject/queryOwners',
                data: null
            });
            const list = ret;
            this.items = list.map((i: any) => ({
                ownerId: i.ownerId,
                ownerName:i.ownerName,
                name:this.type=='1'?i.ownerId:i.ownerId+'-'+i.ownerName,
                
            }));
            this.policyData = list
        } catch (e) {
                // this.$message.error('获取列表失败, 请联系技术人员!');
        } finally {
            this.loading = false;
        }
    }

    public dropdownVisibleChange(open: number) {
        console.log('on dropdownVisibleChange:', open);
        if (open) {
            this.fetch();
        }
    }

    public data() {
        return {
            dataSource: []
        };
    }


    public onInputKeyDown(value:String){
    }

    public handleChange(value: string) {
        const that = this
        that.changeVal(value, that);
    }

    public async changeVal(value: string, that: any) {
        let formData = {expenseAccount:''};
        
        if(this.type != '1'){
            if (value == undefined) {
                formData = {expenseAccount:''};
            } else {
                const ret = await request_yx({
                api: '/console/activity/account/queryByOwner',
                data: {ownerId:value}
            });
                formData = ret;
                that.flag = true;
            }
        }else{
            for(const item of this.items){
                if(value == item.ownerId){
                    formData = item;
                }
            }
        }
        that.tmpValue = value;
        that.$emit('initAccountData', formData);
        that.$emit('change', value);
    }

    public filterOption(input: string, option: any) {
        return (
            option.componentOptions.children[0].text
                .toUpperCase()
                .indexOf(input.toUpperCase()) >= 0
        );
    }
}

export default Role;
