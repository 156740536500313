












































































import {Component,Inject , Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import moment from 'moment';
import qs from 'querystringify';
import {deleteEmpty} from '@/utils';
import {DatePicker} from '@/components/custom-form-components';
import formRules from '@/common/formRules';
import {InspectionStatus} from '@/components/custom-form-components';

interface SearchForm {
    form: any;
}

interface QueryParams {
    activityName: string;
    status: string;
    merchantNo: string;
    activityPlanId: string
    startTime: string;
    endTime: string;
}

@Component({
    components: {
        DatePicker,
        InspectionStatus
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.activityInspectionPage.query);
            },
            loading: (state: any) => state.activityInspectionPage.loading
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.dispatch('initQuery', query);
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any): QueryParams {
        return {
            activityName: values.activityName,
            status: values.status,
            activityPlanId: values.activityPlanId,
            merchantNo: values.merchantNo,
            startTime:
            values.queryTime &&
            values.queryTime[0] &&
            values.queryTime[0].format('YYYY-MM-DD HH:mm:ss'),
            endTime:
                values.queryTime &&
                values.queryTime[1] &&
                values.queryTime[1].format('YYYY-MM-DD HH:mm:ss')

        };
    }

    private formatFormData(query: any) {
        return {
            ...query,
            queryTime: [
            query.startTime && moment(query.startTime),
            query.endTime && moment(query.endTime)
        ]
        };
    }
}

export default SearchForm;
