var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-dropdown",
        { on: { visibleChange: _vm.onUpdate } },
        [
          _c(
            "a",
            { attrs: { href: "javascript:;" } },
            [
              _vm._v("\n            筛选\n            "),
              _c("a-icon", {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "filter" }
              })
            ],
            1
          ),
          _c(
            "a-menu",
            {
              directives: [{ name: "sortable", rawName: "v-sortable" }],
              ref: "menu",
              attrs: { slot: "overlay" },
              slot: "overlay"
            },
            _vm._l(_vm.fieldList, function(field) {
              return _c(
                "a-menu-item",
                { key: field[0] },
                [
                  _c(
                    "a-checkbox",
                    {
                      staticStyle: { display: "inline-block" },
                      attrs: {
                        disabled: field[2] === false,
                        value: field[0],
                        checked: _vm.fields[field[0]]
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.noop($event)
                        },
                        change: _vm.handleChange
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "checkbox",
                        attrs: { "data-key": field[0] }
                      }),
                      _vm._v(_vm._s(field[1]) + "\n                ")
                    ]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }