














































































import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import SearchTable from '@/components/SearchTable.vue';
import { mapState } from 'vuex';
import { defaultCustomRender } from '@/utils';
@Component({
    components: {
        SearchTable
    },
    computed: {
        ...mapState({
            list() {
                return this.state().list;
            },
            total() {
                return this.state().total;
            },
            page() {
                return this.state().page;
            },
            pageSize() {
                return this.state().pageSize;
            },
            loading() {
                return this.state().loading;
            }
        }),
        formatPayType() {
            const payType = {
                '00': '刷卡借记',
                '01': '刷卡贷记',
                '02': '微信',
                '03': '支付宝',
                '81': '银联二维码借记',
                '82': '银联二维码贷记',
                '05': '外卡DCC',
                '06': '外卡EDC'
            };
            return (type) => payType[type]
        }
    }
})
class ConfigSearchTable extends Vue {
    @Inject()
    public state!: any;
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            visible: false,
            beforInfoData: {},
            afterInfoData: {},
            columns: [
                {
                    title: '修改人',
                    dataIndex: 'createBy',
                    customRender: defaultCustomRender
                },
                {
                    title: '修改时间',
                    dataIndex: 'gmtCreate',
                    customRender: defaultCustomRender
                },
                {
                    title: '操作',
                    dataIndex: 'op',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    customRender: (text: any, record: any, idx: any) => {
                        return this.$createElement(
                            'a-button',
                            {
                                props: {
                                    size: 'small'
                                },
                                on: {
                                    click: () => this.handlePreviewInfo(record)
                                }
                            },
                            '详情'
                        );
                    }
                }
            ]
        };
    }

    public async handlePreviewInfo(record: any) {
       this.beforInfoData = record.beforeDetail && JSON.parse(record.beforeDetail)
       this.afterInfoData = record.afterDetail && JSON.parse(record.afterDetail)
       this.visible = true;
    }

    private handleInfoCancel () {
        this.visible = false;
    }

    public handlePageChange(page: number) {
        this.dispatch('pageChange', page);
    }

    public handlePageSizeChange(pageSize: number) {
        this.dispatch('pageSizeChange', pageSize);
    }
}

export default ConfigSearchTable;
