var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "coupon" },
    [
      _c(
        "div",
        { staticClass: "refresh-btn" },
        [
          _c(
            "a-button",
            {
              attrs: { disabled: _vm.loading, loading: _vm.loading },
              on: { click: _vm.handleRefresh }
            },
            [_vm._v("刷新")]
          ),
          _c(
            "a-tooltip",
            {
              attrs: {
                slot: "action",
                title:
                  "今日数据每小时根据跑批任务进行更新(页面停留过长时请按刷新按钮进行更新)",
                placement: "topRight"
              },
              slot: "action"
            },
            [_c("a-icon", { attrs: { type: "info-circle-o" } })],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { attrs: { span: "12" } },
            [
              _c(
                "a-card",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: {
                    "body-style": { padding: "10px", position: "relative" },
                    title: "用券金额",
                    bordered: false
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { staticClass: "card-item", attrs: { span: "6" } },
                        [
                          _c("card-item", {
                            attrs: {
                              title: "今日用券金额",
                              total: _vm._f("RMB")(
                                _vm._f("floatFormat")(
                                  _vm._f("dividedBy")(
                                    _vm.statistics.today_use_coupons_amount_sum,
                                    100
                                  )
                                )
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "card-item", attrs: { span: "6" } },
                        [
                          _c("card-item", {
                            attrs: {
                              title: "昨日用券金额",
                              total: _vm._f("RMB")(
                                _vm._f("floatFormat")(
                                  _vm._f("dividedBy")(
                                    _vm.statistics
                                      .yesterday_use_coupons_amount_sum,
                                    100
                                  )
                                )
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "card-item", attrs: { span: "6" } },
                        [
                          _c("card-item", {
                            attrs: {
                              title: "最近7天用券金额",
                              total: _vm._f("RMB")(
                                _vm._f("floatFormat")(
                                  _vm._f("dividedBy")(
                                    _vm.statistics
                                      .seven_days_use_coupons_amount_sum,
                                    100
                                  )
                                )
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "card-item", attrs: { span: "6" } },
                        [
                          _c("card-item", {
                            attrs: {
                              title: "最近30天用券金额",
                              total: _vm._f("RMB")(
                                _vm._f("floatFormat")(
                                  _vm._f("dividedBy")(
                                    _vm.statistics
                                      .thirty_days_use_coupons_amount_sum,
                                    100
                                  )
                                )
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-table", {
                    attrs: {
                      scroll: {
                        x: _vm.calcTableScrollX(_vm.columns1, 0),
                        y: undefined
                      },
                      bordered: true,
                      "row-key": "index",
                      size: "small",
                      columns: _vm.columns1,
                      dataSource: _vm.tableData1,
                      pagination: false,
                      colSpan: "2"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: "12" } },
            [
              _c(
                "a-card",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: {
                    "body-style": { padding: "10px" },
                    title: "用券笔数",
                    bordered: false
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { staticClass: "card-item", attrs: { span: "6" } },
                        [
                          _c("card-item", {
                            attrs: {
                              title: "今日用券笔数",
                              total: _vm._f("integerFormat")(
                                _vm.statistics.today_use_coupons_count_sum
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "card-item", attrs: { span: "6" } },
                        [
                          _c("card-item", {
                            attrs: {
                              title: "昨日用券笔数",
                              total: _vm._f("integerFormat")(
                                _vm.statistics.yesterday_use_coupons_count_sum
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "card-item", attrs: { span: "6" } },
                        [
                          _c("card-item", {
                            attrs: {
                              title: "最近7天用券笔数",
                              total: _vm._f("integerFormat")(
                                _vm.statistics.seven_days_use_coupons_count_sum
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "card-item", attrs: { span: "6" } },
                        [
                          _c("card-item", {
                            attrs: {
                              title: "最近30天用券笔数",
                              total: _vm._f("integerFormat")(
                                _vm.statistics.thirty_days_use_coupons_count_sum
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("a-table", {
                    attrs: {
                      scroll: {
                        x: _vm.calcTableScrollX(_vm.columns2, 0),
                        y: undefined
                      },
                      bordered: true,
                      "row-key": "index+'1'",
                      size: "small",
                      columns: _vm.columns2,
                      dataSource: _vm.tableData2,
                      pagination: false
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: "24" } },
            [
              _c(
                "a-card",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: {
                    title: "",
                    "body-style": { height: "600px", position: "relative" }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "select-type" },
                    [
                      _c("ChartDataType", {
                        staticClass: "type-item",
                        attrs: { value: _vm.dataType },
                        on: { change: _vm.onTypeChange }
                      }),
                      _c("DateRangeType", {
                        staticClass: "type-item",
                        attrs: { value: _vm.dateRangeType },
                        on: { change: _vm.onDateRangeTypeChange }
                      })
                    ],
                    1
                  ),
                  _c("v-chart", {
                    staticStyle: { width: "100%" },
                    attrs: { options: _vm.option, autoresize: "" },
                    on: { legendselectchanged: _vm.onLegendSelectChange }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-modal",
            {
              attrs: { title: "请选择日期" },
              on: { ok: _vm.handleCustomTime },
              model: {
                value: _vm.custom_time,
                callback: function($$v) {
                  _vm.custom_time = $$v
                },
                expression: "custom_time"
              }
            },
            [
              _c(
                "div",
                [
                  _c("a-date-picker", {
                    attrs: {
                      disabledDate: _vm.disabledStartDate,
                      placeholder: "开始时间",
                      format: "YYYY-MM-DD"
                    },
                    on: {
                      openChange: _vm.handleStartOpenChange,
                      change: _vm.onChangeStart
                    },
                    model: {
                      value: _vm.startValue,
                      callback: function($$v) {
                        _vm.startValue = $$v
                      },
                      expression: "startValue"
                    }
                  }),
                  _c("a-date-picker", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      disabledDate: _vm.disabledEndDate,
                      placeholder: "结束时间",
                      open: _vm.endOpen
                    },
                    on: {
                      openChange: _vm.handleEndOpenChange,
                      change: _vm.onChangeEnd
                    },
                    model: {
                      value: _vm.endValue,
                      callback: function($$v) {
                        _vm.endValue = $$v
                      },
                      expression: "endValue"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }