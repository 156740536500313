
































































import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DatePicker from './DatePicker.vue';
const plainOptions = ['1', '2', '3', '4', '5', '6', '7'];
interface ScheduleTimePicker {
  endOpen: any;
  endValue: any;
  startValue: any;
   form: any;
}

let timeId =  Math.random();
let nextKeysTemp =[];
@Component<ScheduleTimePicker>({
    components: {
        DatePicker
    },
    computed: {
        days() {
            return this.value && this.value.days;
        },
        indeterminate() {
            return this.value && this.value.days && this.value.days.length > 0 && this.value.days.length < 7;
        },
        checkAll() {
            return this.value && this.value.days && this.value.days.length === 7;
        }
    }
})
class ScheduleTimePicker extends Vue {
    @Prop()
    public value!: any;

    @Prop()
    public disabled!: any;

    @Prop()
    public size!: any;

    public timerange!: any;

    public data() {
        return {
          timerange:[],
          formItemLayout: {
                labelCol: {
                sm: { span: 15 },
                },
                wrapperCol: {
                sm: { span: 15 },
                },
            },
            formItemLayoutWithOutLabel: {
                wrapperCol: {
                sm: { span: 15, offset: 0 },
                },
            },
        }
     }
    public created() {
        this.form = this.$form.createForm(this);
        // timeId = 0;
        nextKeysTemp = [];
        console.log(this.value.timerange)
        if(this.value && this.value.timerange){
             this.timerange = this.value.timerange;
            //  console.log( this.timerange)
            //  timeId = this.timerange.length/2 -1;
             for(let i=0;i<this.timerange.length/2;i++){
                nextKeysTemp.push(i);
             }
            //  console.log('nextKeysTemp', nextKeysTemp);
             this.form.setFieldsValue({
                 timeKeys: nextKeysTemp,
             });
             this.form.getFieldDecorator('timeKeys', { initialValue: nextKeysTemp, preserve: true });
        }else{
            this.form.getFieldDecorator('timeKeys', { initialValue: [], preserve: true });
        }
    }
    public removeTime  (k:any) {
      const { form } = this;
      const keys = form.getFieldValue('timeKeys').map((item:number, index:number)=> {return index});
      // console.log('start-removetimekeys',keys);
      // console.log('删除前',this.timerange);
      if (keys.length === 1) {
        return;
      }
      // timeId--;
      keys.splice(k,1)
      form.setFieldsValue({
        timeKeys: keys.map((item:number, index:number)=> {return index}),
      });
      this.timerange.splice(2*k, 2);
      // let temArr = this.timerange.filter((key:number) => key !== k)
      // this.timerange = temArr
      this.$emit('change', {days: this.value && this.value.days, timerange: this.timerange});
      // console.log('删除后',this.timerange);
      // console.log('end-removetimekeys',form.getFieldValue('timeKeys'));
    }

    public addTime  () {
      const { form } = this;
      const keys = form.getFieldValue('timeKeys');
      
      //添加不能超过3个
      if(keys.length >2){
        this.$message.warning('最多添加3条')
        return;
      }
      // console.log('start-addtimekeys',keys, typeof keys);
      const nextKeys = keys.concat(Math.random());
      form.setFieldsValue({
        timeKeys: nextKeys.map((item:number, index:number)=> {return index}),
      });
      // console.log('end-addtimekeys',form.getFieldValue('timeKeys'));
    }
    public handleSubmit  (e:any) {
      e.preventDefault();
      this.form.validateFields((err:any, values:any) => {
        if (!err) {
          console.log('Received values of form: ', values);
        }
      });
    }


    public daysChangeHandler(days: any) {
        this.$emit('change', {timerange: this.value && this.value.timerange, days});
    }

    public onCheckAllChange(e: any) {
        const days = e.target.checked ? plainOptions : [];
        this.$emit('change', {timerange: this.value && this.value.timerange, days});
    }

    public timerangeChange(time: any, timeString: string) {
        let temTimerange = this.timerange.filter((item: any) => item !== undefined)
        this.timerange = temTimerange
        this.$emit('change', {days: this.value && this.value.days, timerange: this.timerange});
        console.log('this.timerange', this.timerange)
    }
}
export default ScheduleTimePicker;
