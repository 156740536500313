





































































import { Modal } from 'ant-design-vue';
import { mapState } from 'vuex';
import * as services from './services';

import { check } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import { normColumns, calcTableScrollX, getTop } from '@/utils';
import RefuseModal from '../../inspection/RefuseModal/index.vue';
import { request_yx } from '@/utils';
import columns from './columns';
import MerDetail from '../../inspection/MerDetail/index.vue';
import RefuseDetail from '../../inspection/RefuseModal/RefuseDetailIndex.vue';
import CallQuestions from '../../inspection/RefuseModal/CallQuestions.vue';
import {
    Component,
    Prop,
    Watch,
    Provide,
    Vue,
    Inject
} from 'vue-property-decorator';
import * as Constant from '@/common/constant';

interface SearchTable {}

@Component({
    components: {
        MerDetail,
        RefuseModal,
        RefuseDetail,
        CallQuestions
    },
    computed: {
        ...mapState({
            lists: (state: any) =>
                state.activityInspectionPage.list.map(
                    (order: any, idx: any) => ({
                        idx:
                            (state.activityInspectionPage.page - 1) * 10 +
                            idx +
                            1,
                        ...order
                    })
                ),
            total: (state: any) => state.activityInspectionPage.total,
            pageSize: (state: any) => state.activityInspectionPage.pageSize,
            page: (state: any) => state.activityInspectionPage.page,
            loading: (state: any) => state.activityInspectionPage.loading,
            selectedRowKeys: (state: any) =>
                state.activityInspectionPage.selectedRows,
            fields: (state: any) =>
                normColumns( columns,
                    state.activityInspectionPage.fields,
                    state.activityInspectionPage.sortFields
                )
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public state!: any;
    @Inject()
    public dispatch!: any;
    @Inject()
    public commit!: any;
    @Inject()
    public getters!: any;
    @Inject()
    public routerPush!: any;
    @Inject('privileges')
    public ownPrivileges!: any;

    @Prop()
    public paytype!: String;
    public fixed = 0;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            columns,
            calcTableScrollX,
            loadingIcon: (
                <span>
                    <a-icon type="loading" /> 加载中
                </span>
            )
        };
    }

    public created() {
    }

    public handleSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('activityInspectionPage/setSelectedRows', [
            ...selectedRowKeys
        ]);
    }

    public handlePageChange(page: number) {
        this.commit('setPagination', { page });
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.commit('setPagination', { pageSize, page: 1 });
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }

    public handleAssignSuccess() {
        this.dispatch('search');
    }

    public async handlePassConfirm(id: any) {
            const list = check([PRIVILEGE.ROLE.DELETE], this.ownPrivileges);
            if (list.length > 0) {
                return this.$message.error(
                    '需要权限: [' + list.map((l: any) => l.text).join(', ') + ']'
                );
            } else {
                Modal.confirm({
                    title: '确认审核通过?',
                    onOk: async () => {

                        try {
                            await services.updateStatus({
                                applyId: id,
                                status:'PASS'
                            });
                            this.dispatch('search');
                        } catch (e) {
                            this.$message.error(e.message);
                        }
                    }
                });
            }
        }
}

export default SearchTable;
