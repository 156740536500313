var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.STORE.DAILY_LIMIT] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [_vm._t("trigger")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            title: "短信限制",
            visible: _vm.visible,
            confirmLoading: _vm.loading,
            form: _vm.form
          },
          on: { ok: _vm.handleSubmit, cancel: _vm.handleClose }
        },
        [
          _c(
            "a-form",
            {
              staticClass: "account-create-form",
              attrs: { form: _vm.form },
              on: { submit: _vm.handleSubmit }
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "短信状态" } },
                [
                  _c("SmsControlStatus", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "sms_switch",
                          {
                            rules: [
                              { required: true, message: "请输入必填项!" }
                            ]
                          }
                        ],
                        expression:
                          "[\n                        'sms_switch',\n                        {rules: [{required: true, message: '请输入必填项!'}]}\n                        ]"
                      }
                    ],
                    ref: "headerChild"
                  })
                ],
                1
              ),
              _vm.form.getFieldValue("sms_switch") === "True"
                ? _c(
                    "a-form-item",
                    { attrs: { label: "单日限制数量" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "daily_limit",
                              {
                                rules: [
                                  {
                                    required: true,
                                    pattern: /^[0-9]*$/,
                                    message: "请输入正确条数"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n                        'daily_limit',\n                        {rules: [ {required: true, pattern: /^[0-9]*$/,  message: '请输入正确条数'}]}\n                    ]"
                          }
                        ],
                        attrs: { placeholder: "仅限输入数字，输入0为不限制" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }