import {RouteConfig} from 'vue-router';
import Search from './SearchPage/AuthWrapper.vue';
import Create from './createPage/index.vue';
const route: RouteConfig[] = [
    {
        path: 'search',
        name: '搜索',
        component: Search,
    },
    {
        path: 'signUpConfig',
        name: '创建银联活动',
        component: Create,
    }
];

export default route;
