var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-form-item-wrapper" },
    [
      _c(
        "a-form",
        { staticClass: "ad-create-form", attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "审核意见" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "auditOpinion",
                      { initialValue: _vm.formData.auditOpinion }
                    ],
                    expression:
                      "[\n                    'auditOpinion',{initialValue: formData.auditOpinion}\n                ]"
                  }
                ],
                attrs: { autosize: { minRows: 6, maxRows: 6 } }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }