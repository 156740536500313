



















import DetailForm from './DetailForm.vue';
 import {Component,Prop, Vue,Provide, Watch} from 'vue-property-decorator';
 import {Show} from '@/components/Auth';
 import PRIVILEGE from '@/common/privileges';

 @Component({
     components: {
         Show,
         DetailForm
     }
 })
 export default class index extends Vue {
     public PRIVILEGE = PRIVILEGE;
     @Prop()
     public title!: string | number;
     @Prop()
     public detail !: string;
     @Prop()
     public paytype !: string;
     public name='';
     public ownerId='';
     public expenseAccount='';
     public description='';
     public created() {
     }

     @Watch('detail')
     public initQueryData(error: any) {
         if(this.detail == null){
             return; 
         }
        this.name = this.detail.name;
        this.ownerId= this.detail.ownerId;
        this.expenseAccount = this.detail.expenseAccount;
        this.description = this.detail.description;

     }
     @Provide()
     public dispatch(action: string, payload?: any) {
         this.$store.dispatch(this.storeName + '/' + action, payload);
     }
     
     public onClose() {
         this.$emit('close');
     }
 }
