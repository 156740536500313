


























import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
import SearchForm from './SearchForm.vue';
import FieldsFilter from '@/components/FieldsFilter.vue';
import Actions from './Actions.vue';
import SearchTable from './SearchTable.vue';
import { Component, Prop, Watch, Provide, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import columns from './columns';
import columnsUnion from './columnsUnion';
import { queryToHumanReadable } from '@/utils';
import preferences from './preferences';
import * as Constant from '@/common/constant';
const humanReadableQuerySchema = {};

@Component({
    components: {
        Actions,
        SearchForm,
        SearchTable,
        SearchCommonLayout,
        FieldsFilter
    },
    computed: {
        ...mapState({
            fields: (state: any) => state.activitiesStopSearchPage.fields,
            humanReadableQuery(state: any) {
                return queryToHumanReadable(
                    state.activitiesStopSearchPage.query,
                    humanReadableQuerySchema
                );
            },
            sortArr: (state: any) => state.activitiesStopSearchPage.sortFields
        })
    }
})
export default class WhitelistPage extends Vue {
    @Prop()
    public active!: boolean;

    public storeName = 'activitiesStopSearchPage';

    @Prop()
    public paytype!: String;

    public currentColumns = columnsUnion;

    @Provide()
    public state() {
        return this.$store.state[this.storeName];
    }

    @Provide()
    public getters(name: string) {
        return this.$store.getters[this.storeName + '/' + name];
    }

    @Provide()
    public commit(mutation: string, payload?: any) {
        this.$store.commit(this.storeName + '/' + mutation, payload);
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(this.storeName + '/' + action, payload);
    }

    @Provide()
    public routerPush() {
        this.$router.push({
            path:
                '/console/activity/stop/search' + this.getters('queryString')()
        });
    }

    public data() {
        return {
            columns
        };
    }

    public created() {
        
        console.info("stop Paytype",this.paytype)
        if (this.paytype == Constant.WX_TYPE) {
            this.currentColumns = columns;
        } else {
            this.currentColumns = columnsUnion;
        }
    }

    @Watch('$store.state.activitiesStopSearchPage.error')
    public errorListener(error: any) {
        if (this.paytype !== this.$store.state.activityManage.payType){
            return
        }
        return error && !error.silent && this.$message.error(error.message);
    }

    @Watch('$store.state.activityManage.payType')
    public payTypeChange(newVal: String, oldVal: String) {
        this.$store.commit(
            'activitiesStopSearchPage/setColumns',
            this.currentColumns
        );
        this.$store.commit('activitiesStopSearchPage/resetFields');
        this.$store.commit('activitiesStopSearchPage/clear');
        this.$store.commit('activitiesStopSearchPage/setQuery',{
             payType: this.$store.state.activityManage.payType
        });
    }

    // 路由驱动页面查询
    // @Watch('$route')
    // public routeListener(route: any) {
    //     if (!this.active) {
    //         return;
    //     }
    //     this.dispatch('initQuery', this.$route.query);
    // }

    private handleFieldChange(e: any) {
        this.$store.commit('activitiesStopSearchPage/setFields', e);
    }
    private handleFieldSort(e: any) {
        preferences.setSortFields(e);
        this.$store.commit('activitiesStopSearchPage/sortFields', e);
    }
}
