








































































































import Canvas from './Canvas.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import config from '@/config';
import {normFile} from '@/utils';
import qs from 'querystringify';
import {mapState} from 'vuex';
import {Modal} from 'ant-design-vue';
import Move from './Anchors/Move.vue';
import Resize from './Anchors/Resize.vue';
import Rotate from './Anchors/Rotate.vue';

@Component<ImageCrop>({
    components: {
        Canvas,
        Move,
        Resize,
        Rotate
    },
    computed: {
        hasImage() {
            return this.value && this.value.length > 0;
        },
        ...mapState({
            token: (state: any) => state.login.session.token
        }),
        displayAngle() {
            const angle = this.angle;
            return angle > 180 ? angle - 360 : angle;
        },
        resizeCursors() {
            const angle = this.angle;
            // 左上 上 右上 右 右下 下 左下 左
            const cursorNames = [
                'nw-resize', 'n-resize', 'ne-resize', 'e-resize', 'se-resize', 's-resize', 'sw-resize', 'w-resize'
            ];
            if (angle > 315 || angle < 45) {
                return {
                    lt: cursorNames[0],
                    rt: cursorNames[2],
                    rb: cursorNames[4],
                    lb: cursorNames[6]
                };
            }

            if (angle > 45 && angle < 135) {
                return {
                    lt: cursorNames[2],
                    rt: cursorNames[4],
                    rb: cursorNames[6],
                    lb: cursorNames[0]
                };
            }

            if (angle > 135 && angle < 225) {
                return {
                    lt: cursorNames[4],
                    rt: cursorNames[6],
                    rb: cursorNames[0],
                    lb: cursorNames[2]
                };
            }

            if (angle > 135 && angle < 225) {
                return {
                    lt: cursorNames[4],
                    rt: cursorNames[6],
                    rb: cursorNames[0],
                    lb: cursorNames[2]
                };
            }

            if (angle > 225 && angle < 315) {
                return {
                    lt: cursorNames[6],
                    rt: cursorNames[0],
                    rb: cursorNames[2],
                    lb: cursorNames[4]
                };
            }

            if (angle === 45) {
                return {
                    lt: cursorNames[1],
                    rt: cursorNames[3],
                    rb: cursorNames[5],
                    lb: cursorNames[7]
                };
            }

            if (angle === 135) {
                return {
                    lt: cursorNames[3],
                    rt: cursorNames[5],
                    rb: cursorNames[7],
                    lb: cursorNames[1]
                };
            }

            if (angle === 225) {
                return {
                    lt: cursorNames[5],
                    rt: cursorNames[7],
                    rb: cursorNames[1],
                    lb: cursorNames[3]
                };
            }

            if (angle === 315) {
                return {
                    lt: cursorNames[7],
                    rt: cursorNames[1],
                    rb: cursorNames[3],
                    lb: cursorNames[5]
                };
            }
        },
        showLoadingCanvasImage() {
            return this.value && this.value.length > 0
                && this.value[0].status === 'done'
                && !this.canvasImageLoaded
                && !this.canvasImageLoadError;
        },
        showLoadingCropArea() {
            return this.value && this.value.length > 0
                && this.value[0].status === 'done'
                && this.canvasImageLoaded
                && this.selectorX === -999
                && !this.canvasImageLoadError;
        },
    }
})
class ImageCrop extends Vue {
    @Prop()
    public value!: any;

    @Prop()
    public noCrop!: any;

    @Prop()
    public minWidth!: any;

    @Prop()
    public noPreview!: any;

    @Prop()
    public disabled!: any;

    private selectorW = 0;
    private selectorH = 0;
    private selectorX = -999;
    private selectorY = -999;
    private imageNaturalWidth = 0;
    private imageNaturalHeight = 0;
    private canvasW = 0;
    private canvasH = 0;

    private resizeOrigin = {x: 0, y: 0};
    private rotateOrigin = {x: 0, y: 0};
    private angle = 0;
    private rotating = false;

    private moveIntervals = {deltaX: [0, 0], deltaY: [0, 0]};
    private rotateIntervals = [-Infinity, Infinity];
    private resizeIntervals = [0, 0];

    private snapshot: any = {};

    private scale = 1;
    private oriImageUrl = '';
    private cropping = false;
    private longer = 'width';
    private canvasImageLoaded = false;
    private canvasImageLoadError: Error | null = null;

    public init() {
        this.selectorW = 100;
        this.selectorH = 100;
        this.selectorX = -999;
        this.selectorY = -999;
        this.imageNaturalWidth = 0;
        this.imageNaturalHeight = 0;
        this.canvasW = 0;
        this.canvasH = 0;
        this.resizeOrigin = {x: 0, y: 0};
        this.rotateOrigin = {x: 0, y: 0};
        this.angle = 0;
        this.rotating = false;
        this.snapshot = {};
        this.moveIntervals = {deltaX: [0, 0], deltaY: [0, 0]};
        this.rotateIntervals = [-Infinity, Infinity];
        this.resizeIntervals = [0, 0];

        this.scale = 1;
        this.oriImageUrl = '';
        this.cropping = false;
        this.canvasImageLoaded = false;
        this.canvasImageLoadError = null;
        this.longer = 'width';
    }

    public data() {
        return {
            config,
            borderImageSource:
            // tslint:disable-next-line:max-line-length
                'url(\"data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==\")'
        };
    }

    public async beforeUpload(file: any) {
        if (['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].indexOf(file.type) === -1) {
            this.$message.error('只支持jpg,gif,jpeg,png格式');
            throw new Error('只支持jpg,gif,jpeg,png格式');
        }

        if (!await this.checkImageWidth(file)) {
            this.$message.error(`图片宽度不能小于${this.minWidth}px`);
            throw new Error(`图片宽度不能小于${this.minWidth}px`);
        }
    }

    public handleRemoveClick(e: any) {
        if (this.disabled) {
            return false;
        }
        return new Promise((resolve, reject) => {
            Modal.confirm({
                title: '提示',
                content: '是否确认删除?',
                onOk: () => {
                    resolve();
                    // 强制重置fileList, 用于修复当图片无权访问时，删除无效问题
                    this.$emit('change', []);
                },
                onCancel() {
                    resolve(false);
                }
            });
        });
    }

    /**
     * 尝试解析图片url来还原裁剪信息
     * 符合条件的还原裁剪框
     * 不符合条件的，裁剪框最大化居中
     */
    public initCropInfo() {
        const url = new URL(this.value[0].url);
        const search: any = qs.parse(url.search);
        const regexp = /^image\/resize,p_(\d+)\/crop,x_(\d+),y_(\d+),w_(\d+),h_(\d+)$/;
        const regexpWithRotate = /^image\/resize,p_(\d+)\/rotate,(\d+)\/crop,x_(\d+),y_(\d+),w_(\d+),h_(\d+)$/;

        // 有且仅有一个x-oss-process参数时
        if (
            search &&
            search['x-oss-process'] &&
            Object.keys(search).length === 1
        ) {
            const match = search['x-oss-process'].match(regexp);
            const matchWithRotate = search['x-oss-process'].match(regexpWithRotate);

            if (match || matchWithRotate) {
                if (!this.oriImageUrl) {
                    this.oriImageUrl = url.origin + url.pathname;
                    return;
                }
            }

            if (matchWithRotate) {
                const [_, p, rotate, x, y, width, height] = matchWithRotate;
                if (parseInt(p, 10) === Math.round(this.scale * 100)) {
                    this.parseUrl(
                        parseInt(rotate, 10),
                        parseInt(x, 10),
                        parseInt(y, 10),
                        parseInt(width, 10),
                        parseInt(height, 10)
                    );
                    this.oriImageUrl = url.origin + url.pathname;

                    this.emitChange();
                    return;
                }
            } else if (match) {
                const [_, p, x, y, width, height] = match;
                if (parseInt(p, 10) === Math.round(this.scale * 100)) {
                    this.parseUrl(
                        360,
                        parseInt(x, 10),
                        parseInt(y, 10),
                        parseInt(width, 10),
                        parseInt(height, 10)
                    );
                    this.oriImageUrl = url.origin + url.pathname;

                    this.emitChange();
                    return;
                }
            }
        }

        // 如果走到这里说明url没有解析成功
        if (this.longer === 'width') {
            // 高度占满，水平居中
            this.selectorW = this.selectorH = this.canvasH;
            this.selectorX = (this.canvasW - this.selectorW) / 2;
            this.selectorY = 0;
        } else if (this.longer === 'height') {
            // 宽度占满，垂直居中
            this.selectorW = this.selectorH = this.canvasW;
            this.selectorY = (this.canvasH - this.selectorH) / 2;
            this.selectorX = 0;
        } else {
            // 相等时占满整个画布
            this.selectorW = this.selectorH = this.canvasW;
            this.selectorX = this.selectorY = 0;
        }
        this.oriImageUrl = url.origin + url.pathname;

        this.emitChange();
    }

    public created() {
        window.addEventListener('mouseup', this.stopCrop);
    }

    public destroyed() {
        window.removeEventListener('mouseup', this.stopCrop);
    }

    public stopCrop() {
        if (this.cropping) {
            this.cropping = false;
            this.rotating = false;
            window.document.body.style.cursor = 'auto';
            this.emitChange();
        }
    }

    public rotate(origin: any, target: any, angle: number) {
        // 围绕的旋转点
        const oX = origin.x;
        const oY = origin.y;

        // 待计算的点
        let targetX = target.x;
        let targetY = target.y;

        // 计算target围绕旋转点旋转deg角度后的canvas坐标
        targetX = targetX - oX;
        targetY = targetY - oY;

        const l = angle * Math.PI / 180;
        const tmpX = Math.cos(l) * targetX - Math.sin(l) * targetY;
        const tmpY = Math.sin(l) * targetX + Math.cos(l) * targetY;
        targetX = tmpX + oX;
        targetY = tmpY + oY;
        return {
            x: targetX,
            y: targetY
        };
    }

    public toUrl() {

        // 画布中心点
        const canvasOrigin = {x: this.canvasW / 2, y: this.canvasH / 2};
        const angle = 360 - Math.round(this.angle);
        const lt = this.rotate(canvasOrigin, {x: 0, y: 0}, angle);
        const rt = this.rotate(canvasOrigin, {x: this.canvasW, y: 0}, angle);
        const rb = this.rotate(canvasOrigin, {x: this.canvasW, y: this.canvasH}, angle);
        const lb = this.rotate(canvasOrigin, {x: 0, y: this.canvasH}, angle);

        const minX = Math.min(lt.x, rt.x, rb.x, lb.x);
        const minY = Math.min(lt.y, rt.y, rb.y, lb.y);

        const odx = -minX;
        const ody = -minY;

        const selectorLT = this.rotate(
            canvasOrigin,
            this.calcLTCoordinate(this.selectorX, this.selectorY, this.selectorW, this.selectorH, this.angle),
            angle
        );
        const sdx = selectorLT.x - this.selectorX;
        const sdy = selectorLT.y - this.selectorY;

        return `${this.oriImageUrl}?x-oss-process=image/resize,p_${Math.round(
            this.scale * 100
        )}/rotate,${angle}/crop,x_${Math.round(this.selectorX + odx + sdx)},y_${Math.round(
            this.selectorY + ody + sdy
        )},w_${Math.round(this.selectorW)},h_${Math.round(this.selectorH)}`;
    }

    public parseUrl(angle: number, x: number, y: number, w: number, h: number) {
        const canvasOrigin = {x: this.canvasW / 2, y: this.canvasH / 2};
        this.angle = 360 - angle;
        const lt = this.rotate(canvasOrigin, {x: 0, y: 0}, angle);
        const rt = this.rotate(canvasOrigin, {x: this.canvasW, y: 0}, angle);
        const rb = this.rotate(canvasOrigin, {x: this.canvasW, y: this.canvasH}, angle);
        const lb = this.rotate(canvasOrigin, {x: 0, y: this.canvasH}, angle);

        const minX = Math.min(lt.x, rt.x, rb.x, lb.x);
        const minY = Math.min(lt.y, rt.y, rb.y, lb.y);

        const odx = -minX;
        const ody = -minY;

        const center = this.rotate({
            x: canvasOrigin.x + odx,
            y: canvasOrigin.y + ody
        }, {x: x + w / 2, y: y + h / 2}, this.angle);
        const centerDx = center.x - (x + w / 2);
        const centerDy = center.y - (y + h / 2);
        this.selectorX = x + centerDx - odx;
        this.selectorY = y + centerDy - ody;
        this.selectorW = w;
        this.selectorH = h;
    }

    public async handleImageChange(e: any) {

        if (e.fileList.length === 0) {
            this.init();
        }

        this.$emit('change', normFile(e));
    }

    public checkImageWidth(fileObject: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (typeof reader.result === 'string') {
                    const img = new Image();
                    img.onload = () => {
                        if (img.naturalWidth < this.minWidth) {
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    };
                    img.src = reader.result;
                }
            });
            reader.readAsDataURL(fileObject);
        });
    }

    public imageLoaded(img: any) {
        this.imageNaturalWidth = img.width;
        this.imageNaturalHeight = img.height;
        this.canvasW = img.width * img.r;
        this.canvasH = img.height * img.r;
        this.scale = img.r;
        this.longer = this.canvasW > this.canvasH ? 'width' : 'height';
        this.canvasImageLoaded = true;
        this.initCropInfo();
    }

    public imageLoadError(err: Error) {
        this.canvasImageLoadError = err;
    }

    // 碰撞检测
    // 在应用变换前探测四个顶点是否碰撞到画布边缘
    // 计算出位移的区间: deltaX: [min, max], deltaY: [min, max]、可以缩放的区间: delta: [min , max]、可以旋转的区间: delta: [min, max]
    public calcMoveIntervals() {

        const x = this.selectorX;
        const y = this.selectorY;
        const w = this.selectorW;
        const h = this.selectorH;

        const lb = this.calcLBCoordinate(x, y, w, h, this.angle);
        const lt = this.calcLTCoordinate(x, y, w, h, this.angle);
        const rb = this.calcRBCoordinate(x, y, w, h, this.angle);
        const rt = this.calcRTCoordinate(x, y, w, h, this.angle);

        const minX = Math.min(lb.x, lt.x, rb.x, rt.x);
        const maxX = Math.max(lb.x, lt.x, rb.x, rt.x);
        const minY = Math.min(lb.y, lt.y, rb.y, rt.y);
        const maxY = Math.max(lb.y, lt.y, rb.y, rt.y);

        const deltaX = [-minX, this.canvasW - maxX];
        const deltaY = [-minY, this.canvasH - maxY];
        this.moveIntervals = {deltaX, deltaY};
    }

    public calcRotateIntervals() {
        const diagonal = this.selectorW * Math.SQRT2;
        const halfDiagonal = diagonal / 2;
        const center = {
            x: this.selectorX + this.selectorW / 2,
            y: this.selectorY + this.selectorH / 2
        };
        const angles = [];

        if (halfDiagonal > center.y) {
            // 碰触上边
            const l = Math.asin(center.y / halfDiagonal);
            angles.push(l * 180 / Math.PI - 45);
        }
        if (halfDiagonal > this.canvasW - center.x) {
            // 碰触右边
            const l = Math.asin((this.canvasW - center.x) / halfDiagonal);
            angles.push(l * 180 / Math.PI - 45);
        }
        if (halfDiagonal > this.canvasH - center.y) {
            // 碰触下边
            const l = Math.asin((this.canvasH - center.y) / halfDiagonal);
            angles.push(l * 180 / Math.PI - 45);
        }
        if (halfDiagonal > center.x) {
            // 碰触左边
            const l = Math.asin(center.x / halfDiagonal);
            angles.push(l * 180 / Math.PI - 45);
        }

        const minAngle = Math.min(...angles);
        let target = this.angle;
        while (target > 45) {
            target -= 90;
        }
        while (target < -45) {
            target += 90;
        }

        this.rotateIntervals = [-minAngle - target, minAngle - target];
    }

    public calcRBResizeIntervals() {

        const x = this.selectorX;
        const y = this.selectorY;
        const w = this.selectorW;
        const h = this.selectorH;
        const angle = Math.round(this.angle);
        const l = angle * Math.PI / 180; // 弧度

        const lt = this.calcLTCoordinate(x, y, w, h, angle);

        // 拖拽 rb时，各个边长最大值
        let topE = 0;
        let rightE = 0;
        let bottomE = 0;
        let leftE = 0;
        if (angle >= 0 && angle < 90) {
            topE = (this.canvasW - lt.x) / Math.sin(Math.PI / 2 - l);
            rightE = ((this.canvasH - lt.y) / Math.abs(Math.sin(Math.PI / 4 + l))) / Math.SQRT2;
            bottomE = ((this.canvasH - lt.y) / Math.abs(Math.sin(Math.PI / 4 + l))) / Math.SQRT2;
            leftE = angle === 0 ? (this.canvasH - lt.y) : (lt.x / Math.cos(Math.PI / 2 - l));
        } else if (angle >= 90 && angle < 180) {
            topE = (this.canvasH - lt.y) / Math.sin(Math.PI - l);
            rightE = (lt.x / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI / 2)))) / Math.SQRT2;
            bottomE = (lt.x / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI / 2)))) / Math.SQRT2;
            leftE = angle === 90 ? lt.x : (lt.y / Math.cos(Math.PI - l));
        } else if (angle >= 180 && angle < 270) {
            topE = lt.x / Math.sin(3 * Math.PI / 2 - l);
            rightE = (lt.y / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI)))) / Math.SQRT2;
            bottomE = (lt.y / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI)))) / Math.SQRT2;
            leftE = angle === 180 ? lt.y : ((this.canvasW - lt.x) / Math.cos(3 * Math.PI / 2 - l));
        } else if (angle >= 270 && angle < 360) {
            topE = lt.y / Math.sin(2 * Math.PI - l);
            rightE = ((this.canvasW - lt.x) / Math.abs(Math.cos(Math.PI / 4 - (l - 3 * Math.PI / 2)))) / Math.SQRT2;
            bottomE = ((this.canvasW - lt.x) / Math.abs(Math.cos(Math.PI / 4 - (l - 3 * Math.PI / 2)))) / Math.SQRT2;
            leftE = angle === 270 ? (this.canvasW - lt.x) : ((this.canvasH - lt.y) / Math.cos(2 * Math.PI - l));
        }

        const minE = Math.min(topE, rightE, bottomE, leftE);
        this.resizeIntervals = [-w, minE - w];
    }

    public calcLBResizeIntervals() {

        const x = this.selectorX;
        const y = this.selectorY;
        const w = this.selectorW;
        const h = this.selectorH;
        const angle = Math.round(this.angle);
        const l = angle * Math.PI / 180; // 弧度

        const rt = this.calcRTCoordinate(x, y, w, h, angle);

        // 拖拽 rt时，各个边长最大值
        let topE = 0;
        let rightE = 0;
        let bottomE = 0;
        let leftE = 0;
        if (angle >= 0 && angle < 90) {
            topE = angle === 0 ? rt.x : (rt.y / Math.sin(l));
            rightE = (this.canvasH - rt.y) / Math.cos(l);
            bottomE = (rt.x / Math.abs(Math.cos(Math.PI / 4 - l))) / Math.SQRT2;
            leftE = (rt.x / Math.abs(Math.cos(Math.PI / 4 - l))) / Math.SQRT2;
        } else if (angle >= 90 && angle < 180) {
            topE = angle === 90 ? rt.y : ((this.canvasW - rt.x) / Math.cos(Math.PI - l));
            rightE = rt.x / Math.sin(Math.PI - l);
            bottomE = (rt.y / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI / 2)))) / Math.SQRT2;
            leftE = (rt.y / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI / 2)))) / Math.SQRT2;
        } else if (angle >= 180 && angle < 270) {
            topE = angle === 180 ? (this.canvasW - rt.x) : ((this.canvasH - rt.y) / Math.cos(3 * Math.PI / 2 - l));
            rightE = rt.y / Math.sin(3 * Math.PI / 2 - l);
            bottomE = ((this.canvasW - rt.x) / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI)))) / Math.SQRT2;
            leftE = ((this.canvasW - rt.x) / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI)))) / Math.SQRT2;
        } else if (angle >= 270 && angle < 360) {
            topE = angle === 270 ? (this.canvasH - rt.y) : (rt.x / Math.cos(2 * Math.PI - l));
            rightE = (this.canvasW - rt.x) / Math.sin(2 * Math.PI - l);
            bottomE = ((this.canvasH - rt.y) / Math.abs(Math.cos(Math.PI / 4 - (l - 3 * Math.PI / 2)))) / Math.SQRT2;
            leftE = ((this.canvasH - rt.y) / Math.abs(Math.cos(Math.PI / 4 - (l - 3 * Math.PI / 2)))) / Math.SQRT2;
        }

        const minE = Math.min(topE, rightE, bottomE, leftE);
        this.resizeIntervals = [-w, minE - w];
    }

    public calcLTResizeIntervals() {

        const x = this.selectorX;
        const y = this.selectorY;
        const w = this.selectorW;
        const h = this.selectorH;
        const angle = Math.round(this.angle);
        const l = angle * Math.PI / 180; // 弧度

        const rb = this.calcRBCoordinate(x, y, w, h, angle);

        // 拖拽 lt时，各个边长最大值
        let topE = 0;
        let rightE = 0;
        let bottomE = 0;
        let leftE = 0;
        if (angle >= 0 && angle < 90) {
            topE = (rb.y / Math.abs(Math.cos(Math.PI / 4 - l))) / Math.SQRT2;
            rightE = angle === 0 ? rb.y : ((this.canvasW - rb.x) / Math.cos(Math.PI / 2 - l));
            bottomE = rb.x / Math.sin(Math.PI / 2 - l);
            leftE = (rb.y / Math.abs(Math.cos(Math.PI / 4 - l))) / Math.SQRT2;
        } else if (angle >= 90 && angle < 180) {
            topE = ((this.canvasW - rb.x) / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI / 2)))) / Math.SQRT2;
            rightE = angle === 90 ? (this.canvasW - rb.x) : ((this.canvasH - rb.y) / Math.cos(Math.PI - l));
            bottomE = rb.y / Math.sin(Math.PI - l);
            leftE = ((this.canvasW - rb.x) / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI / 2)))) / Math.SQRT2;
        } else if (angle >= 180 && angle < 270) {
            topE = ((this.canvasH - rb.y) / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI)))) / Math.SQRT2;
            rightE = angle === 180 ? (this.canvasH - rb.y) : (rb.x / Math.cos(3 * Math.PI / 2 - l));
            bottomE = (this.canvasW - rb.x) / Math.sin(3 * Math.PI / 2 - l);
            leftE = ((this.canvasH - rb.y) / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI)))) / Math.SQRT2;
        } else if (angle >= 270 && angle < 360) {
            topE = (rb.x / Math.abs(Math.cos(Math.PI / 4 - (l - 3 * Math.PI / 2)))) / Math.SQRT2;
            rightE = angle === 270 ? rb.x : (rb.y / Math.cos(2 * Math.PI - l));
            bottomE = (this.canvasH - rb.y) / Math.sin(2 * Math.PI - l);
            leftE = (rb.x / Math.abs(Math.cos(Math.PI / 4 - (l - 3 * Math.PI / 2)))) / Math.SQRT2;
        }

        const minE = Math.min(topE, rightE, bottomE, leftE);
        this.resizeIntervals = [-w, minE - w];
    }

    public calcRTResizeIntervals() {

        const x = this.selectorX;
        const y = this.selectorY;
        const w = this.selectorW;
        const h = this.selectorH;
        const angle = Math.round(this.angle);
        const l = angle * Math.PI / 180; // 弧度

        const lb = this.calcLBCoordinate(x, y, w, h, angle);

        // 拖拽 rt时，各个边长最大值
        let topE = 0;
        let rightE = 0;
        let bottomE = 0;
        let leftE = 0;
        if (angle >= 0 && angle < 90) {
            topE = ((this.canvasW - lb.x) / Math.abs(Math.cos(Math.PI / 4 - l))) / Math.SQRT2;
            rightE = ((this.canvasW - lb.x) / Math.abs(Math.cos(Math.PI / 4 - l))) / Math.SQRT2;
            bottomE = angle === 0 ? (this.canvasW - lb.x) : ((this.canvasH - lb.y) / Math.cos(Math.PI / 2 - l));
            leftE = lb.y / Math.sin(Math.PI / 2 - l);
        } else if (angle >= 90 && angle < 180) {
            topE = ((this.canvasH - lb.y) / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI / 2)))) / Math.SQRT2;
            rightE = ((this.canvasH - lb.y) / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI / 2)))) / Math.SQRT2;
            bottomE = angle === 90 ? (this.canvasH - lb.y) : (lb.x / Math.cos(Math.PI - l));
            leftE = (this.canvasW - lb.x) / Math.sin(Math.PI - l);
        } else if (angle >= 180 && angle < 270) {
            topE = (lb.x / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI)))) / Math.SQRT2;
            rightE = (lb.x / Math.abs(Math.cos(Math.PI / 4 - (l - Math.PI)))) / Math.SQRT2;
            bottomE = angle === 180 ? lb.x : (lb.y / Math.cos(3 * Math.PI / 2 - l));
            leftE = (this.canvasH - lb.y) / Math.sin(3 * Math.PI / 2 - l);
        } else if (angle >= 270 && angle < 360) {
            topE = (lb.y / Math.abs(Math.cos(Math.PI / 4 - (l - 3 * Math.PI / 2)))) / Math.SQRT2;
            rightE = (lb.y / Math.abs(Math.cos(Math.PI / 4 - (l - 3 * Math.PI / 2)))) / Math.SQRT2;
            bottomE = angle === 270 ? lb.y : ((this.canvasW - lb.x) / Math.cos(2 * Math.PI - l));
            leftE = lb.x / Math.sin(2 * Math.PI - l);
        }

        const minE = Math.min(topE, rightE, bottomE, leftE);
        this.resizeIntervals = [-w, minE - w];
    }

    public handleRotateMouseDown(mouse: any) {
        this.snapshot = {
            angle: this.angle
        };
        this.rotateOrigin = {
            x: this.selectorX + this.selectorW / 2,
            y: this.selectorY + this.selectorH / 2
        };
        this.rotating = true;
        this.calcRotateIntervals();
    }

    public handleRotateChange(delta: any) {
        const newAngle = this.snapshot.angle
            + Math.min(this.rotateIntervals[1], Math.max(this.rotateIntervals[0], delta));
        this.angle = Math.round((Math.abs(Math.min(Math.floor(newAngle / 360), 0)) * 360 + newAngle)) % 360;
        this.cropping = true;
    }

    public handleMoveMouseDown(mouse: any) {
        this.snapshot = {
            selectorX: this.selectorX,
            selectorY: this.selectorY
        };
        this.calcMoveIntervals();
    }

    public handleMoveChange(delta: any) {
        this.selectorX = this.snapshot.selectorX
            + Math.min(this.moveIntervals.deltaX[1], Math.max(this.moveIntervals.deltaX[0], delta.x));
        this.selectorY = this.snapshot.selectorY
            + Math.min(this.moveIntervals.deltaY[1], Math.max(this.moveIntervals.deltaY[0], delta.y));
        this.cropping = true;
    }

    // 右下
    public handleRBResizeMouseDown(mouse: any) {
        this.resizeOrigin = {
            ...this.calcLTCoordinate(this.selectorX, this.selectorY, this.selectorW, this.selectorH, this.angle)
        };
        this.snapshot = {
            selectorW: this.selectorW,
            selectorH: this.selectorH
        };
        this.calcRBResizeIntervals();
    }

    public handleRBResizeChange(d: any) {
        this.selectorW = this.snapshot.selectorW
            + Math.min(this.resizeIntervals[1], Math.max(this.resizeIntervals[0], d));
        this.selectorH = this.snapshot.selectorH
            + Math.min(this.resizeIntervals[1], Math.max(this.resizeIntervals[0], d));

        const lt = this.calcLTCoordinate(this.selectorX, this.selectorY, this.selectorW, this.selectorH, this.angle);
        this.selectorX += this.resizeOrigin.x - lt.x;
        this.selectorY += this.resizeOrigin.y - lt.y;
        this.cropping = true;
    }

    // 左下
    public handleLBResizeChange(d: any) {
        this.selectorW = this.snapshot.selectorW
            + Math.min(this.resizeIntervals[1], Math.max(this.resizeIntervals[0], d));
        this.selectorH = this.snapshot.selectorH
            + Math.min(this.resizeIntervals[1], Math.max(this.resizeIntervals[0], d));

        const rt = this.calcRTCoordinate(this.selectorX, this.selectorY, this.selectorW, this.selectorH, this.angle);
        this.selectorX += this.resizeOrigin.x - rt.x;
        this.selectorY += this.resizeOrigin.y - rt.y;
        this.cropping = true;
    }

    public handleLBResizeMouseDown(mouse: any) {
        this.resizeOrigin = {
            ...this.calcRTCoordinate(this.selectorX, this.selectorY, this.selectorW, this.selectorH, this.angle)
        };
        this.snapshot = {
            selectorW: this.selectorW,
            selectorH: this.selectorH
        };
        this.calcLBResizeIntervals();
    }

    // 左上
    public handleLTResizeChange(d: any) {
        this.selectorW = this.snapshot.selectorW
            + Math.min(this.resizeIntervals[1], Math.max(this.resizeIntervals[0], d));
        this.selectorH = this.snapshot.selectorH
            + Math.min(this.resizeIntervals[1], Math.max(this.resizeIntervals[0], d));

        const rb = this.calcRBCoordinate(this.selectorX, this.selectorY, this.selectorW, this.selectorH, this.angle);
        this.selectorX += this.resizeOrigin.x - rb.x;
        this.selectorY += this.resizeOrigin.y - rb.y;
        this.cropping = true;
    }

    public handleLTResizeMouseDown(mouse: any) {
        this.resizeOrigin = {
            ...this.calcRBCoordinate(this.selectorX, this.selectorY, this.selectorW, this.selectorH, this.angle)
        };
        this.snapshot = {
            selectorW: this.selectorW,
            selectorH: this.selectorH
        };
        this.calcLTResizeIntervals();
    }

    // 右上
    public handleRTResizeChange(d: any) {
        this.selectorW = this.snapshot.selectorW
            + Math.min(this.resizeIntervals[1], Math.max(this.resizeIntervals[0], d));
        this.selectorH = this.snapshot.selectorH
            + Math.min(this.resizeIntervals[1], Math.max(this.resizeIntervals[0], d));

        const lb = this.calcLBCoordinate(this.selectorX, this.selectorY, this.selectorW, this.selectorH, this.angle);
        this.selectorX += this.resizeOrigin.x - lb.x;
        this.selectorY += this.resizeOrigin.y - lb.y;
        this.cropping = true;
    }

    public handleRTResizeMouseDown(mouse: any) {
        this.resizeOrigin = {
            ...this.calcLBCoordinate(this.selectorX, this.selectorY, this.selectorW, this.selectorH, this.angle)
        };
        this.snapshot = {
            selectorW: this.selectorW,
            selectorH: this.selectorH
        };
        this.calcRTResizeIntervals();
    }

    // 计算左上角顶点坐标
    public calcLTCoordinate(x: number, y: number, w: number, h: number, deg: any) {

        // 围绕的旋转点
        const oX = x + w / 2;
        const oY = y + h / 2;

        // 待计算的点
        let targetX = x;
        let targetY = y;

        // 计算target围绕旋转点旋转deg角度后的canvas坐标
        targetX = targetX - oX;
        targetY = targetY - oY;

        const l = deg * Math.PI / 180;
        const tmpX = Math.cos(l) * targetX - Math.sin(l) * targetY;
        const tmpY = Math.sin(l) * targetX + Math.cos(l) * targetY;
        targetX = tmpX + oX;
        targetY = tmpY + oY;
        return {
            x: targetX,
            y: targetY
        };
    }

    public calcLBCoordinate(x: number, y: number, w: number, h: number, deg: any) {

        // 围绕的旋转点
        const oX = x + w / 2;
        const oY = y + h / 2;

        // 待计算的点
        let targetX = x;
        let targetY = y + h;

        // 计算target围绕旋转点旋转deg角度后的canvas坐标
        targetX = targetX - oX;
        targetY = targetY - oY;

        const l = deg * Math.PI / 180;
        const tmpX = Math.cos(l) * targetX - Math.sin(l) * targetY;
        const tmpY = Math.sin(l) * targetX + Math.cos(l) * targetY;
        targetX = tmpX + oX;
        targetY = tmpY + oY;
        return {
            x: targetX,
            y: targetY
        };
    }

    public calcRTCoordinate(x: number, y: number, w: number, h: number, deg: any) {

        // 围绕的旋转点
        const oX = x + w / 2;
        const oY = y + h / 2;

        // 待计算的点
        let targetX = x + w;
        let targetY = y;

        // 计算target围绕旋转点旋转deg角度后的canvas坐标
        targetX = targetX - oX;
        targetY = targetY - oY;

        const l = deg * Math.PI / 180;
        const tmpX = Math.cos(l) * targetX - Math.sin(l) * targetY;
        const tmpY = Math.sin(l) * targetX + Math.cos(l) * targetY;
        targetX = tmpX + oX;
        targetY = tmpY + oY;
        return {
            x: targetX,
            y: targetY
        };
    }

    public calcRBCoordinate(x: number, y: number, w: number, h: number, deg: any) {

        // 围绕的旋转点
        const oX = x + w / 2;
        const oY = y + h / 2;

        // 待计算的点
        let targetX = x + w;
        let targetY = y + h;

        // 计算target围绕旋转点旋转deg角度后的canvas坐标
        targetX = targetX - oX;
        targetY = targetY - oY;

        const l = deg * Math.PI / 180;
        const tmpX = Math.cos(l) * targetX - Math.sin(l) * targetY;
        const tmpY = Math.sin(l) * targetX + Math.cos(l) * targetY;
        targetX = tmpX + oX;
        targetY = tmpY + oY;
        return {
            x: targetX,
            y: targetY
        };
    }

    public emitChange() {
        this.$emit('change', [
            {
                uid: '-1',
                status: 'done',
                name: '裁剪图像',
                url: this.toUrl()
            }
        ]);
    }
}

export default ImageCrop;
