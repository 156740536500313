






























































































import { Modal } from 'ant-design-vue';
import { mapState } from 'vuex';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { Click } from '@/components/Auth';
import BindSnModal from './BindSnModal/index.vue';
import TransferWxModal from './TransferWxModal/index.vue';
import PRIVILEGE from '@/common/privileges';
import ShopDailyLimitModal from './ShopDailyLimitModal/index.vue'
import ShopSmsStatusCount from '@/components/ShopSmsStatusCount.vue';
import ShopUpload from './ShopUpload.vue';
import qs from 'querystringify';
interface Actions {
    selectedRows: any;
    hideMessage: any;
    PRIVILEGE: any;
    visible: any;
    confirmLoading: any;
    daily_limit: any;
    smsSwitchMark: boolean;
}

@Component({
    components: {
        Click,
        BindSnModal,
        TransferWxModal,
        ShopDailyLimitModal,
        ShopSmsStatusCount,
        ShopUpload
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.shopSearchPage.loading,
            downloading: (state: any) => state.shopSearchPage.downloading,
            selectedRows: (state: any) => state.shopSearchPage.selectedRows,
            openRefundIng: (state: any) => state.shopSearchPage.openRefundIng,
            
        })
    }
})
class Actions extends Vue {
    public data() {
        return {
            hideMessage: null,
            PRIVILEGE,
            visible: false,
            confirmLoading: false,
            daily_limit: '',
            smsSwitchMark: sessionStorage.getItem("sms_switch") === 'OFF'
        };
    }
    public handleRefresh() {
        this.$router.push({
            path:
                '/console/shop/search' +
                this.$store.getters['shopSearchPage/queryString']()
        });
    }

    public destroyed() {
        sessionStorage.removeItem('sms_switch')
        return this.hideMessage && this.hideMessage();
    }

    @Watch('$store.state.shopSearchPage.downloading')
    public watchDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }

    @Watch('$store.state.shopSearchPage.downloadError')
    public watchDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error('导出失败，请联系技术人员!');
        }
    }

    @Watch('$store.state.shopSearchPage.lastDownloadTime')
    public watchLastDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }

    public handleDownload() {
        this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
        this.$store.dispatch('shopSearchPage/download');
    }
    public openRefundOpen() {
        Modal.confirm({
            title: '提示',
            content: '确认开通退款?',
            onOk: () => {
                this.$store.dispatch('shopSearchPage/openRefund', true);
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public openRefundClose() {
        Modal.confirm({
            title: '提示',
            content: '确认关闭退款?',
            onOk: () => {
                this.$store.dispatch('shopSearchPage/openRefund', false);
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public smsSwitch(e:any){
        const query = {
            dailyLimit:0,
            open:false
        };
        if(e.key === '2'){
            Modal.confirm({
                title: '提示',
                content: '确定关闭所有商户的短信发送功能吗?',
                okText: '确定',
                cancelText: '取消',
                onOk: async () => {
                    this.visible = false;
                    sessionStorage.setItem('sms_switch', 'OFF');
                    await this.$store.dispatch('shopSearchPage/smsSwitch', query);
                    await (this.$refs.shopSmsStatusCount as any).fetch()
                    this.smsSwitchMark = (sessionStorage.getItem("sms_switch") === 'OFF')
                }
            });
        }
    }

    public showModal() {
      this.visible = true
    }
    public async handleOk(e:any) {
        this.confirmLoading = true;
        const query = {
            dailyLimit: this.daily_limit,
            open:true
        };
        await this.$store.dispatch('shopSearchPage/smsSwitch', query);
        this.confirmLoading = false;
        this.visible = false
        this.$emit("success");
        sessionStorage.setItem('sms_switch', 'ON');
        await (this.$refs.shopSmsStatusCount as any).fetch()
        this.smsSwitchMark = (sessionStorage.getItem("sms_switch") === 'OFF')

    }
    public handleCancel(e:any) {
      console.log('Clicked cancel button');
      this.visible = false
    }
}
export default Actions;
