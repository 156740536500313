
































































































import {Modal} from 'ant-design-vue';
import {Component, Watch, Vue} from 'vue-property-decorator';
import ImageCrop from '@/components/custom-form-components/ImageCrop/index.vue';
import DistrictBelong from '@/components/custom-form-components/DistrictBelong';
import {mapState} from 'vuex';
import {BIZ_DISTRICT as BIZ_DISTRICT_RULES} from '@/common/formItemRules';
import {SaveParams} from './services';
import config from '@/config';

interface EditForm {
    imgEditing: boolean;
    form: any;
}

interface FormData {
    district_id: string;
    name: string;
    name_english: string;
    logo: any[];
    agreement_title: string;
    agreement_url: string;
    link_url: string;
    belong: string;
}

interface Detail {
    agreement_url: string;
    name_english: string;
    link_url: string;
    belong: string;
    agreement_title: string;
    logo: string;
    id: string;
    name: string;
}

@Component({
    components: {
        ImageCrop,
        DistrictBelong
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.bizDistrictEditPage.data);
            },
            saving: state => state.bizDistrictEditPage.saving,
            auditing: state => state.common.auditing
        })
    }
})
class EditForm extends Vue {
    @Watch('$store.state.common.auditStatus')
    public auditStatusChange(as: any) {
        if (as && as.status !== 'PIC_PASS') {
            this.$message.error('审核图片未通过，请检查图片后重新上传');
        }
        this.imgEditing = true;
    }

    public data() {
        return {
            imgEditing: false,
            BIZ_DISTRICT_RULES,
            form: this.$form.createForm(this),
            config
        };
    }

    public handleSubmit(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                if (this.imgEditing) {
                    // 图片审核结果是否通过？
                    const auditStatus = this.$store.state.common.auditStatus;
                    if (auditStatus && auditStatus.status === 'PIC_PASS') {
                        Modal.confirm({
                            title: '提示',
                            content: '是否确认提交?',
                            onOk: () => {
                                this.$store.dispatch(
                                    'bizDistrictEditPage/save',
                                    this.formatParams(values)
                                );
                            },
                            onCancel() {
                                // do nothing
                            }
                        });
                    } else {
                        this.$message.error('审核图片未通过，请检查图片后重新上传');
                    }
                } else {// 如果图片没改动
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'bizDistrictEditPage/save',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                }
            } else {
                this.$message.error('请检正确填写表单信息');
            }
        });
    }

    public handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters['bizDistrictSearchPage/queryString']();
                this.$router.push({
                    path: '/console/bizDistrict/search' + queryString
                });
            },
            onCancel() {
                // do nothing
            }
        });
    }

    private formatFormData(detail: Detail): FormData {
        return {
            district_id: detail.id,
            name: detail.name,
            name_english: detail.name_english,
            logo: detail.logo
                ? [
                    {
                        uid: '-1',
                        name: '加载失败',
                        url: detail.logo,
                        status: 'done'
                    }
                ]
                : [],
            agreement_title: detail.agreement_title,
            agreement_url: detail.agreement_url,
            link_url: detail.link_url,
            belong: detail.belong
        };
    }

    /**
     * 将表单数据格式化为请求参数
     */
    private formatParams(formData: FormData): SaveParams {
        return {
            district_id: formData.district_id,
            name: formData.name,
            name_english: formData.name_english,
            logo: formData.logo && formData.logo[0] && formData.logo[0].url,
            agreement_title: formData.agreement_title,
            agreement_url: formData.agreement_url,
            link_url: formData.link_url,
            belong: formData.belong
        };
    }
}

export default EditForm;
