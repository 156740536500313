



















import EditActForm from './EditActForm.vue';
import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Show,
       EditActForm
    }
})
export default class CreatePage extends Vue {
    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public title!: string | number;
    @Prop()
    public detail !: string;
    public activityId='' ;
    public name='';
    public ownerId='';
    public expenseAccount='';
    public description='';
    public created() {
    }

    @Watch('detail')
    public initQueryData(error: any) {
        if(this.detail == null){
            return; 
        }
        this.activityId = this.detail.activityId;
        this.name = this.detail.name;
       this.ownerId= this.detail.ownerId;
       this.expenseAccount = this.detail.expenseAccount;
       this.description = this.detail.description;
    }

    @Watch('$store.state.subjectActivitySearchPage.error')
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    public onClose() {
        this.$emit('close');
    }
    public onCancel() {
        this.$emit('cancel');
    }
}
