












    import {Modal} from 'ant-design-vue';
    import {Component, Watch, Vue, Inject} from 'vue-property-decorator';
	import SmStatusCount from '@/components/SmStatusCount.vue';
    import {mapState} from 'vuex';
    import PRIVILEGE from '@/common/privileges';
    import {Click} from '@/components/Auth';
    
    interface Actions {
        selectedRows: any;
        hideMessage: any;
    }
	@Component({
        components: {
           SmStatusCount,
           Click
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.shtSearchPage.loading,
                downloading: (state: any) => state.shtSearchPage.downloading,
                selectedRows: (state: any) => state.shtSearchPage.selectedRows,
            })
        }
    })
   class Actions extends Vue {
	   
       @Inject()
        public dispatch!: any;

        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        public handleRefresh() {
           this.dispatch('search');
        }
        
        public handleOffClick() {
            let shopNo ='';
            this.selectedRows.forEach((item:any) => {
                this.$store.state.shtSearchPage.list.forEach((obj:any) => {
                    shopNo = obj.shopNo;
                })
            })
            Modal.confirm({
                title: '解绑',
                content: '确认将选中的信息进行解绑操作吗?',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    
                    this.dispatch('unbind', {
                        openId: this.selectedRows.join(','),
                        shopNo: shopNo
                    });
                }
            });
        }
       
        

        @Watch('$store.state.shtSearchPage.downloading')
        public shortMessageDownloading(downloading: any) {
            if (!downloading && this.hideMessage) {
                this.hideMessage();
            }
        }

        @Watch('$store.state.shtSearchPage.downloadError')
        public shortMessageDownloadError(error: any) {
            if (error && !error.silent) {
                this.$message.error('导出失败，请联系技术人员!');
            }
        }

        @Watch('$store.state.shtSearchPage.lastDownloadTime')
        public shortMessageDownloadTime(lastDownloadTime: any) {
            this.$message.success('导出完成!');
        }

        public handleDownload() {
            this.hideMessage = this.$message.loading('导出中,请稍后...', 0);
            this.$store.dispatch('shtSearchPage/download');
        }
    }

    export default Actions;
