var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Show", {
    attrs: { requires: [_vm.PRIVILEGE.AD.MANAGE] },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "advertisement" },
              [
                _c("SearchCommonLayout", {
                  attrs: { humanReadableQuery: _vm.humanReadableQuery },
                  scopedSlots: _vm._u([
                    {
                      key: "search-form",
                      fn: function() {
                        return [
                          _c("SearchForm", { on: { search: _vm.handleSearch } })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function() {
                        return [_c("Actions")]
                      },
                      proxy: true
                    },
                    {
                      key: "fields-filter-btn",
                      fn: function() {
                        return [
                          _c("FieldsFilter", {
                            attrs: {
                              fields: _vm.fields,
                              sortArr: _vm.sortArr,
                              excludeFields: ["idx", "store.name"],
                              columns: _vm.columns
                            },
                            on: {
                              change: _vm.handleFieldChange,
                              sort: _vm.handleFieldSort,
                              fixed: _vm.handleFixed
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "table",
                      fn: function() {
                        return [_c("SearchTable")]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }