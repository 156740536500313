







import {Component, Watch,Prop, Vue} from 'vue-property-decorator';
import {request_yx} from '@/utils';
import { Modal } from 'ant-design-vue';

@Component({
    components: {}
})
class Role extends Vue {

    @Prop()
    public value!: string;
    @Prop()
    public ruleList!:any;
    public items = [];
    public flag = false;
    public loading = false;
    public tmpValue!: string;
    public policyData = [];

    public async created() {
        this.fetch();
    }
    
    @Watch('ruleList')
    public async fetch() {
        try {
            this.items = [];
            this.loading = true;
            // if(!this.activityId){
            //     this.$message.error('请先选择活动ID！');
            //     return;
            // }
            // const ret =  await request_yx({
            //     api: '/console/activity/subsidyRuleNew/queryRuleList?activityId'+this.activityId,
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            // });
            // const list = ret;
            console.log(this.ruleList)
            const list = this.ruleList;
            this.items = list.map((i: any) => ({
                ruleId: i.ruleId,
                ruleName:i.ruleId+'-'+i.name
            }));
            this.policyData = list
        } catch (e) {
        } finally {
            this.loading = false;
        }
    }

    public dropdownVisibleChange(open: number) {
        console.log('on dropdownVisibleChange:', open);
        if (open) {
            this.fetch();
        }
    }

    public data() {
        return {
            dataSource: []
        };
    }


    public onInputKeyDown(value:String){
        console.log(this.flag)
        // if(this.flag){
        //     Modal.confirm({
        //         title: '提示',
        //         content: '更新活动后页面将重新赋值',
        //         onOk: () => {
                   
        //         },
        //         onCancel() {
        //             // do nothing
        //         }
        //     });
        // }
    }

    public handleChange(value: string) {
        const that = this
        // if (that.flag) {
        //     Modal.confirm({
        //         title: '提示',
        //         content: '活动ID改变会初始化页面参数',
        //         onOk: () => {
        //             that.changeVal(value, that);
        //         },
        //         onCancel() {
        //             that.value = that.tmpValue
        //         }
        //     });
        // } else {
            that.changeVal(value, that);
        // }
    }

    public async changeVal(value: string, that: any) {
        that.$emit('change', value);
    }

    public filterOption(input: string, option: any) {
        return (
            option.componentOptions.children[0].text
                .toUpperCase()
                .indexOf(input.toUpperCase()) >= 0
        );
    }
}

export default Role;
