
















































































































































































































import {Modal} from 'ant-design-vue';
import {Component,Inject, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import config from '@/config';
import {DisableDatePicker,AwardRule,districtCodeTransfer} from '@/components/custom-form-components';
import moment from 'moment';
import PolicyInfo from '@/components/custom-form-components/PolicyInfo.vue';
import AccountInfoNew from '@/components/custom-form-components/AccountInfoNew.vue';
import policyBank from '@/components/custom-form-components/policyBanks.vue';
import OwnerNew from '@/components/custom-form-components/OwnerNew.vue';
import ImageUpload from '@/components/custom-form-components/ImageUpload.vue';
import { findRepeatData, isRepeatArr } from '@/utils';
interface EditActForm {
    form: any;
    otherForm:any
}

@Component({
        components: {
            DisableDatePicker,
            districtCodeTransfer,
            AwardRule,
            policyBank,
            PolicyInfo,
            ImageUpload,
            AccountInfoNew,
            OwnerNew
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.actManageSearchPage.loading,
                configData(state: any) {
                    return (
                        state.actManageSearchPage.ruleConfigData 
                    )
                },
                formData(state: any) {
                    return this.formatFormData();
                },
                
            }),
            
        }
    })
    class createForm extends Vue {
        @Inject()
        public dispatch!: any;
        public districtCodeList=[];
        public accountList!:any;
        public awardRuleData= [];
        public templetId:any ;
        form: any;
        otherForm: any;
        formData: any;
        validObj: any;
        configData: any;
        configListData:any;
        orgLimit='unlimited';
        public data() {
            return {
                config,
                form: this.$form.createForm(this),
                otherForm: this.$form.createForm(this),
                hasLogo: false,
                districtCodeList:[],
                validObj: {},
                awardRuleData:[{Key:0}]
            };
        }
        public created() {
            
        }
        
        public handleCreate(e: any) {
            
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (!error) {
                    // if(null != this.districtCodeList && this.districtCodeList.length >5 ){
                    //     this.$message.error('最多勾选5个城市');
                    //     return;
                    // }
                    const cardbins = values.orgLimitValue;
                    if (values.orgLimit && values.orgLimit != 'unlimited' && !cardbins) {
                            this.$message.error('活动商户机构限制值不能为空！');
                            return false;
                        }
                    // 指定卡bin校验
                    if (cardbins) {
                        let bins = cardbins.split(',');
                        if (isRepeatArr(bins)) {
                            this.$message.error(
                                `动商户机构数据 "${findRepeatData(
                                    bins
                                )}" 重复，请检查！`
                            );
                            return false;
                        }
                        if (cardbins.length > 500) {
                            this.$message.error(
                                '活动商户机构仅可填写数字,英文逗号分隔，最多500字符长度'
                            );
                            return false;
                        }
                        var reg = /^[0-9]*$/;
                        for (let bin of bins) {
                            if (!bin) {
                                this.$message.error(
                                    '请删除活动商户机构最后的英文逗号！'
                                );
                                return false;
                            }
                            if (reg.test(bin) == false) {
                                this.$message.error(
                                    `${bin}不符合规则，活动商户机构仅可填写数字,英文逗号分隔，最多500字符长度`
                                );
                                return false;
                            }
                        }
                    }
                    if(values.description && values.description.length> 8048){
                        this.$message.error('活动描述不超过8048长度');
                        return;
                    }
                    let mccs = values.merMcc;
                    if (mccs) {
                        let bins = mccs.split(',');
                        if (isRepeatArr(bins)) {
                            this.$message.error(
                                `MCC数据 "${findRepeatData(
                                    bins
                                )}" 重复，请检查！`
                            );
                            return false;
                        }
                        if (mccs.length > 64) {
                            this.$message.error(
                                '不符合规则，mcc填的是4位mccCode小类，不允许填中文和1~2位大类，最多64字符长度'
                            );
                            return false;
                        }
                        var reg = /^[0-9]*$/;
                        for (let bin of bins) {
                            if (!bin) {
                                this.$message.error(
                                    '请删除MCC最后的英文逗号！'
                                );
                                return false;
                            }
                            if (reg.test(bin) == false) {
                                this.$message.error(
                                    `${bin}不符合规则，mcc填的是4位mccCode小类，不允许填中文和1~2位大类`
                                );
                                return false;
                            }
                            if (bin.length !=4) {
                                this.$message.error(
                                    `${bin}不符合规则，mcc填的是4位mccCode小类，不允许填中文和1~2位大类`
                                );
                                return false;
                            }
                        }
                    }
                    let ortherFlag = false;
                    this.otherForm.validateFields((error: any, values: any) => {
                        if(!error){
                            const cardbins = values.cardBinLimitValue;
                            if (values.merSettleCardBound != '0' && values.cardBinLimit != '0' && !cardbins) {
                                    this.$message.error('结算卡bin限制值不能为空！');
                                    return false;
                                }
                            // 指定卡bin校验
                            if (values.merSettleCardBound != '0' && values.cardBinLimit != '0' && cardbins) {
                                let bins = cardbins.split(',');
                                if (isRepeatArr(bins)) {
                                    this.$message.error(
                                        `结算卡bin数据 "${findRepeatData(
                                            bins
                                        )}" 重复，请检查！`
                                    );
                                    return false;
                                }
                                if (cardbins.length > 500) {
                                    this.$message.error(
                                        '结算卡卡BIN长度支持6位或8位数字，英文逗号分隔，最多500字符长度'
                                    );
                                    return false;
                                }
                                var reg = /^[0-9]*$/;
                                for (let bin of bins) {
                                    if (!bin) {
                                        this.$message.error(
                                            '请删除结算卡bin最后的英文逗号！'
                                        );
                                        return false;
                                    }
                                    if (reg.test(bin) == false) {
                                        this.$message.error(
                                            `${bin}不符合规则，卡BIN长度支持6位或8位数字，英文逗号分隔，最多500字符长度`
                                        );
                                        return false;
                                    }
                                    if (bin.length != 6 && bin.length != 8) {
                                        this.$message.error(
                                            `${bin}不符合长度规则，卡BIN长度支持6位或8位数字`
                                        );
                                        return false;
                                    }
                                }
                            }

                            // 报名是否交易结算卡提示
                            if (values.cardExist === '1') {
                                this.$message.warning('此活动为校验结算卡活动，请前往联合营销平台配置结算卡卡bin！');
                            }
                            ortherFlag = true;
                        }
                    });
                    if(ortherFlag){
                        this.formatParams(values);
                        Modal.confirm({
                            title: '提示',
                            content: '是否确认提交?',
                            onOk: () => {
                                this.$store.dispatch(
                                    'actManageSearchPage/create',
                                    this.formatParams(values)
                                );
                            },
                            onCancel() {
                                // do nothing
                            }
                        });
                    }
                    
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
        @Watch('$store.state.actManageSearchPage.updatedSuccess')
        public updatedSuccess(newVal: boolean, oldVal: boolean) {
            if (newVal) {
                console.log(123);
                this.$message.success('提交成功');
                const queryString = this.$store.getters[
                    'actManageSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/actManage/search' + queryString,
                });
                this.dispatch('search');
            }
        }
        private showOrgLimit(e: any) {
            this.orgLimit = e.target.value;
            this.form.setFieldsValue({
                orgLimitValue: undefined
            });
        }
        public async handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                    const queryString = this.$store.getters['actManageSearchPage/queryString']();
                    this.$router.push({
                        path: '/console/actManage/search' + queryString
                    });
                },
                onCancel() {
                    // do nothing
                }
            });
        }
        private changeDistrictCodeData(rawData: any) {
            console.log('n',rawData)
            this.districtCodeList = rawData;
        }
        /**
         * 将表单数据格式化为请求参数
         */
        private formatParams(formData: any) {
            let template:any;
            this.otherForm.validateFields((error: any, values: any) => {
                console.log("otherForm",values);
                template = {
                    ...values,
                }
            });
            for(let i in this.configData ){
                const obj = this.configData[i];
                if('radio' == obj.type && template.hasOwnProperty(obj.key) && template[obj.key]){
                    let intVal = parseInt(template[obj.key]);
                    template = {
                        ...template,
                        [obj.key] : intVal
                    }
                }
                if('checkbox' == obj.type && template.hasOwnProperty(obj.key) && template[obj.key]){
                    let newArray=[];
                    let array:any = template[obj.key];
                    for(let j in array){
                        let intVal = parseInt(array[j]);
                        newArray.push(intVal);
                    }
                    template = {
                        ...template,
                        [obj.key] : newArray
                    }
                }
                if('image' == obj.type){
                    template = {
                        ...template,
                        [obj.key] : this.$refs[obj.key][0].oriImageUrl
                    }
                }
                
            }
            let cardBinLimit = template.cardBinLimit;
            const cardBinLimitValue = template.cardBinLimitValue;
            if('0' == cardBinLimit){
                cardBinLimit = 'unlimited';
            }else if('1' == cardBinLimit){
                cardBinLimit = 'whiteList';
            }else if('2' == cardBinLimit){
                cardBinLimit = 'blackList';
            }
            template.cardBinLimit = undefined;
            template.cardBinLimitValue = undefined;

            const templateJson = JSON.stringify(template);
            let bankCodes = formData.actBank;
            console.log(bankCodes);
            if(Object.prototype.toString.call(bankCodes) === '[object Array]'){
                bankCodes = formData.actBank.join(',');
            }
            console.log(bankCodes)
            return {
                templetId:formData.templetId,
                activityLevel:formData.activityLevel,
                name: formData.name,
                ownerId: formData.ownerId,
                expenseAccount:formData.expenseAccount,
                bank:bankCodes,
                imgUrl:this.$refs.imgUrlChild.oriImageUrl,
                mcc:formData.merMcc,
                signUp:formData.signUp,
                districtCodeList:this.districtCodeList,
                startTime: formData.activity_time[0].format(
                    'YYYYMMDDHHmmss'
                ),
                endTime: formData.activity_time[1].format(
                    'YYYYMMDDHHmmss'
                ),
                description:formData.description,
                extJson:templateJson,
                cardBinLimit:cardBinLimit,
                cardBinLimitValue:cardBinLimitValue,
                orgLimit:formData.orgLimit,
                orgLimitValue:formData.orgLimitValue
            };
        }

        
        private formatFormData(rawData: any) {
            console.log(rawData)
            let template:any;
            if(!rawData){
                for(let i in this.configData ){
                    const obj = this.configData[i];
                    template = {
                        ...template,
                        [obj.key] : obj.value
                    }
                    // if(!obj.disabled){
                    //     this.validObj[obj.key] = 'Y';
                    // }
                    // if(obj.disabled){
                    //     this.validObj[obj.key] = 'N';
                    // }
                }
                return {
                    activity_time: [],
                    merMcc:'',
                    actBank:'',
                    actRegionArea:[],
                    name: '',
                    templetType: '',
                    description:'',
                    templetJson:'',
                    ...template,
                }
            }
            
            if (rawData && rawData.templetJson) {
                template = JSON.parse(rawData.templetJson);
                template = JSON.parse(template);
            }
            this.templetId = rawData.templetId;
            if(template){
                for(let i in this.configData ){
                    const obj = this.configData[i];
                        if('radio' == obj.type  && template.hasOwnProperty(obj.key) && 'undefined' != template[obj.key]){
                            template = {
                                ...template,
                                [obj.key] : template[obj.key]+''
                            }
                        }
                        if('checkbox' == obj.type && template.hasOwnProperty(obj.key) &&  template[obj.key]){
                            let newArray=[];
                            let array:any = template[obj.key];
                            for(let j in array){
                                let strval =array[j]+"";
                                newArray.push(strval);
                            }
                            template = {
                                ...template,
                                [obj.key] : newArray
                            }
                        }
                }
            }
            let cardBinLimit=rawData.cardBinLimit;
            if('unlimited' == cardBinLimit){
                cardBinLimit = '0';
            }else if('whiteList' == cardBinLimit){
                cardBinLimit = '1';
            }else if('blackList' == cardBinLimit){
                cardBinLimit = '2';
            }
            return {
                activity_time: [
                    rawData.startTime && moment(rawData.startTime),
                    rawData.endTime && moment(rawData.endTime)
                ],
                ...template,
                ...rawData,
                cardBinLimit:cardBinLimit
            }
        }

        private initFormData(rawData: any) {
            console.log(rawData)
            Object.keys(this.otherForm.getFieldsValue()).forEach((key) => {
                this.otherForm.setFieldsValue({
                    [key]: undefined
                });
            });
            Object.keys(this.form.getFieldsValue()).forEach((key) => {
                this.form.setFieldsValue({
                    [key]: undefined
                });
            });
            let formData = this.formatFormData(rawData);
            this.districtCodeList = formData.actRegionArea;
            let data = {
                ...formData,
                signUp:'1',
                activityLevel:'0',
                orgLimit:'unlimited',
                orgLimitValue:''
            }
            
               
            //动态初始化input is show
            for(let i in this.configData ){
                const obj = this.configData[i];
                
                if(obj.disabled && formData.hasOwnProperty(obj.key) && formData[obj.key]){
                    console.log(obj.key);
                    console.log(formData[obj.key]);
                    this.validObj[obj.key] = 'Y';
                }
                if(obj.disabled && formData.hasOwnProperty(obj.key) && !formData[obj.key]){
                    this.validObj[obj.key] = 'N';
                }
                //特殊操作！！！！
                if(data.merSettleCardBound != '1'){
                    this.validObj.cardBinLimit = 'N';
                }
                //初始化其他规则默认值，在没有配置的前提下
                if(!data.hasOwnProperty(obj.key)){
                    data = {
                        ...data,
                        [obj.key] : obj.value
                    }
                }
                if('image' == obj.type && data[obj.key]){
                    let imageFile = [{
                        uid: -1,
                        url: data[obj.key],
                        status: 'done',
                        thumbUrl: data[obj.key],                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                        name: 'image',
                        lastModified: Date.now(),
                        lastModifiedDate: new Date(),
                        size: 0,
                        type: 'image/jpeg',
                        response: null
                    }];
                    data = {
                        ...data,
                        [obj.key] : imageFile
                    }
                }
            }
            console.log(data);
                
            this.form.setFieldsValue(data);
            this.otherForm.setFieldsValue(data)
        }
        private changeCheck(checkedValues: any, options: any) {
            if (checkedValues.length == 0) {
                for (var k=0;k<options.length;k++) {
                    console.log('options.enableKeys',options[k].enableKeys)
                    for (var a=0;a<options[k].enableKeys.length;a++) {
                        const validkey = options[k].enableKeys[a];
                        this.otherForm.setFieldsValue({[validkey]: undefined});
                        this.formData[validkey]=undefined;
                        this.validObj[validkey] = undefined
                    }
                }
                return
            }
            for (var i=0;i<checkedValues.length;i++) {
                const checkVal = options.filter(item => item.value == checkedValues[i])[0]
                const enableKeys = checkVal.enableKeys
                for (var j=0;j<enableKeys.length;j++) {
                    this.validObj[enableKeys[j]] = 'Y'
                }
            }
            const notchecks = options.filter(item => checkedValues.indexOf(item.value+'') == -1)
            for (var k=0;k<notchecks.length;k++) {
                for (var a=0;a<notchecks[k].enableKeys.length;a++) {
                    const validkey = notchecks[k].enableKeys[a];
                    this.otherForm.setFieldsValue({[validkey]: undefined});
                    this.formData[validkey]=undefined;
                    this.validObj[validkey] = undefined
                }
            }
        }
        private changeRadioCheck(checkedValues: any, options: any) {
            console.log(checkedValues)
            console.log(options)
            
            const redioValue = checkedValues.target.value;
            //选中
            const checkVal = options.filter(item => item.value == redioValue)[0]
            const enableKeys = checkVal.enableKeys
            if(enableKeys){
                for (var j=0;j<enableKeys.length;j++) {
                    this.validObj[enableKeys[j]] = 'Y'
                    //初始化值
                    for(let i in this.configData ){
                        const obj = this.configData[i];
                        if(enableKeys[j] == obj.key){
                            console.log(enableKeys[j],obj.value)
                            this.otherForm.setFieldsValue({[enableKeys[j]]:obj.value});
                            continue;
                        }
                    }
                }
            }
            //没选中
            const notchecks = options.filter(item => item.value != redioValue)
            console.log(notchecks);
            for (var k=0;k<notchecks.length;k++) {
                if(notchecks[k].enableKeys){
                    for (var a=0;a<notchecks[k].enableKeys.length;a++) {
                        const validkey = notchecks[k].enableKeys[a];
                        console.log('没选中的validkey',validkey);
                        if(enableKeys){
                            const aaa = enableKeys.filter(item => item == validkey)
                            if(aaa){
                                break;
                            }
                        }
                        this.otherForm.setFieldsValue({[validkey]: undefined});
                        this.validObj[validkey] = undefined;
                        //判断没选中的空间下有没有绑定数据 有则清空并隐藏
                        for(let i in this.configData ){
                            const obj = this.configData[i];
                            if(validkey == obj.key && 'radio' == obj.type){
                                for (var b=0;b<obj.options.length;b++) {
                                    const enableKeysChild = obj.options[b].enableKeys;
                                    if(enableKeysChild){
                                        console.log('没选中的validkeydata',obj.key);
                                        for (var c=0;c<enableKeysChild.length;c++) {
                                            const validkeyChild = enableKeysChild[c];
                                            console.log('没选中的validkeyChild',validkeyChild);
                                            console.log('validkeyChild',validkeyChild);
                                            this.validObj[validkeyChild] = undefined;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        private initAccountData(data:any){
            console.log(data)
            this.form.setFieldsValue({expenseAccount:''});
            if(data){
                this.accountList = data;
            }
        }
    }

    export default createForm;
