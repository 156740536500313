






















































import {Modal} from 'ant-design-vue';
import {mapState} from 'vuex';
import {Component, Prop, Inject, Vue} from 'vue-property-decorator';
import RoleChangeModal from './RoleChangeModal.vue';
import columns from './columns';
import * as services from './services';
import {check} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import {calcTableScrollX, getTop} from '@/utils';
import ClearText from '@/components/ClearText.vue';

interface SearchTable {
    selectedRowKeys: any[];
}

@Component({
    components: {
        RoleChangeModal,
        ClearText
    },
    computed: {
        rowSelection() {
            const self: any = this;
            const {selectedRowKeys} = self;
            return {
                selectedRowKeys,
                onChange: self.onSelectChange,
                hideDefaultSelections: true
            };
        },
        ...mapState({
            users: (state: any) =>
                state.userSearchPage.list.map((order: any, idx: any) => ({
                    idx: (state.userSearchPage.page - 1) * 10 + idx + 1,
                    ...order
                })),
            total: (state: any) => state.userSearchPage.total,
            pageSize: (state: any) => state.userSearchPage.pageSize,
            page: (state: any) => state.userSearchPage.page,
            loading: (state: any) => state.userSearchPage.loading
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public privileges!: any;

    @Inject()
    public commit!: any;

    @Inject()
    public routerPush!: any;

    public fixed = 0;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + (-top) + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            selectedRowKeys: [],
            columns,
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.selectedRowKeys = selectedRowKeys;
    }

    public handlePageChange(page: number) {
        this.commit('setPagination', {page});
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.commit('setPagination', {pageSize, page: 1});
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }

    public async handleDeleteConfirm(userId: any) {
        const list = check([PRIVILEGE.USER.DELETE], this.privileges);
        if (list.length > 0) {
            return this.$message.error(
                '需要权限: [' + list.map((l: any) => l.text).join(', ') + ']'
            );
        } else {
            Modal.confirm({
                title: '确认删除?',
                onOk: async () => {
                    try {
                        await services.deleteUser({
                            user_id: userId
                        });
                        this.dispatch('search');
                    } catch (e) {
                        this.$message.error(e.message);
                    }
                }
            });
        }
    }
}

export default SearchTable;
