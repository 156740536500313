



































































































































import {Component, Prop, Watch, Vue} from 'vue-property-decorator';

import ServicePlatform from '@/components/custom-form-components/ServicePlatform';
import Region from '@/components/custom-form-components/Region.vue';
import ImageCrop from '@/components/custom-form-components/ImageCrop/index.vue';
import {mapState} from 'vuex';
import {MALL as MALL_RULES} from '@/common/formItemRules';
import {SaveParams} from './services';
import config from '@/config';
import {Modal} from 'ant-design-vue';

interface EditForm {
    imgEditing: boolean;
    form: any;
}

@Component({
    components: {
        ImageCrop,
        Region,
        ServicePlatform
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.mallEditPage.data);
            },
            saving: state => state.mallEditPage.saving,
            auditing: state => state.common.auditing,
        })
    }
})
class EditForm extends Vue {
    @Watch('$store.state.common.auditStatus')
    public auditStatusChange(as: any) {
        if (as && as.status !== 'PIC_PASS') {
            this.$message.error('审核图片未通过，请检查图片后重新上传');
        }
        this.imgEditing = true;
    }

    public data() {
        return {
            imgEditing: false,
            MALL_RULES,
            form: this.$form.createForm(this),
            config
        };
    }

    public handleSubmit(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {

            if (!error) {
                if (this.imgEditing) {
                    // 图片审核结果是否通过？
                    const auditStatus = this.$store.state.common.auditStatus;
                    if (auditStatus && auditStatus.status === 'PIC_PASS') {
                        Modal.confirm({
                            title: '提示',
                            content: '是否确认提交?',
                            onOk: () => {
                                this.$store.dispatch(
                                    'mallEditPage/save',
                                    this.formatParams(values)
                                );
                            },
                            onCancel() {
                                // do nothing
                            }
                        });
                    } else {
                        this.$message.error('审核图片未通过，请检查图片后重新上传');
                    }
                } else {// 如果图片没改动
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'mallEditPage/save',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                }
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters['mallSearchPage/queryString']();
                this.$router.push({
                    path: '/console/mall/search' + queryString
                });
            },
            onCancel() {
                // do nothing
            }
        });
    }

    private formatFormData(rawData: any) {
        if (!rawData) {
            return;
        }
        return {
            ...rawData,
            logo: rawData.logo ? [{
                uid: '-1',
                name: '加载失败',
                status: 'done',
                url: rawData.logo
            }] : [],
            region: [rawData.province, rawData.city, rawData.district]
        };
    }

    /**
     * 将表单数据格式化为请求参数
     */
    private formatParams(formData: any) {
        const {
            // 排除region字段
            region,
            ...rest
        } = formData;

        const params: SaveParams = {
            ...rest
        };
        params.province = formData.region[0];
        params.city = formData.region[1];
        params.district = formData.region[2];
        params.logo = formData.logo[0].url;
        return params;
    }
}

export default EditForm;
