var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mall_edit" },
    [
      _vm.loading
        ? _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [_c("a-icon", { attrs: { type: "loading" } })],
            1
          )
        : _c("EditForm")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }