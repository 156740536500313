import * as DICTS from '@/common/dicts';
import blankCell from '@/common/blankCell';

const customRender = (v: any) => v || '-';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        fixed: 'left',
        width: 80,
        align: 'center',
    },
    {
        title: '活动ID',
        dataIndex: 'activityId',
        fixed: 'left',
        width: 200,
        align: 'center',
        customRender
    },
    {
        title: '活动名称',
        dataIndex: 'activityName',
        width: 250,
        align: 'center',
        fixed: 'left',
        customRender
    },
    {
        title: '活动开始时间',
        dataIndex: 'startTime',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '活动结束时间',
        dataIndex: 'endTime',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '报名开始时间',
        dataIndex: 'applyStartTime',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '报名结束时间',
        dataIndex: 'applyEndTime',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '初始名额',
        dataIndex: 'initCount',
        align: 'center',
        width: 120,
        customRender
    },
    {
        title: '总名额',
        dataIndex: 'totalCount',
        align: 'center',
        width: 120,
        customRender
    },
    {
        title: '开展地区',
        dataIndex: 'supportCity', // MALLCOO
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '活动类型',
        dataIndex: 'activityType',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '报名URL',
        dataIndex: 'url',
        align: 'center',
        width: 200,
        scopedSlots: {customRender: 'url'},
    },
    {
        title: '活动状态',
        dataIndex: 'statusName',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '创建时间',
        dataIndex: 'createdTime',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '最后修改时间',
        dataIndex: 'updatedTime',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '创建人',
        dataIndex: 'createdUser',
        align: 'center',
        width: 200,
        // sorter: true,
        customRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        fixed: 'right',
        width: 150,
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
