














import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import SearchTable from '@/components/SearchTable.vue';
import { mapState } from 'vuex';
import { defaultCustomRender } from '@/utils';
import * as services from './services';

@Component({
    components: {
        SearchTable
    },
    computed: {
        ...mapState({
            list() {
                return this.state().list;
            },
            total() {
                return this.state().total;
            },
            page() {
                return this.state().page;
            },
            pageSize() {
                return this.state().pageSize;
            },
            loading() {
                return this.state().loading;
            }
        })
    }
})
class ConfigSearchTable extends Vue {
    @Inject()
    public state!: any;
    @Inject()
    public dispatch!: any;

    @Prop()
    public id!: string;

    public data() {
        return {
            columns: [
                {
                    title: '政策名称',
                    dataIndex: 'templetName',
                    fixed: 'left',
                    width: 200,
                    customRender: defaultCustomRender
                },
                {
                    title: '政策ID',
                    dataIndex: 'templetId',
                    customRender: defaultCustomRender
                },
                {
                    title: '政策状态',
                    dataIndex: 'status',
                    width: 120,
                    customRender: (status: string) => {
                        return status == 'ON' ? '已生效' : '已失效';
                    }
                },
                {
                    title: '政策是否绑定账号',
                    dataIndex: 'isEmpower',
                    width: 140,
                    customRender: (isEmpower: string) => {
                        return isEmpower == '0' ? '已绑定' : '未绑定';
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'op',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    customRender: (text: any, record: any, idx: any) => {
                        return this.$createElement(
                            'a-button',
                            {
                                props: {
                                    size: 'small'
                                },
                                on: {
                                    click: () => this.handleEmp(record)
                                }
                            },
                            record.isEmpower == '0' ? '解绑政策' : '绑定政策'
                        );
                    }
                }
            ]
        };
    }

    public async handleEmp(record: any) {
        this.$message.loading('', 0);
        if (record.isEmpower == '0') {
            await services.empDel({
                userId: this.id,
                templetId: record.templetId
            });
        } else {
            await services.empInsert({
                userId: this.id,
                templetId: record.templetId,
                templetName: record.templetName
            });
        }

        this.$message.destroy();
        let msg = record.isEmpower == '0' ? '解绑成功!' : '绑定成功!'
        this.$message.success(msg);
        this.dispatch('pageChange', 1);
    }

    public handlePageChange(page: number) {
        this.dispatch('pageChange', page);
    }

    public handlePageSizeChange(pageSize: number) {
        this.dispatch('pageSizeChange', pageSize);
    }
}

export default ConfigSearchTable;
