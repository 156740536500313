





import {Component, Prop, Inject, Vue} from 'vue-property-decorator';
import check from './check';

@Component({
    components: {},
    computed: {}
})
class Click extends Vue {
    @Prop() // 需要的权限列表
    public requires!: any;

    @Inject()
    public privileges!: any;

    public handleClick() {
        const needList: any = check(this.requires, this.privileges);

        if (needList.length > 0) {
            this.$message.error(
                '需要权限: [' + needList.map((n: any) => n.text).join(',') + ']'
            );
        } else {
            this.$emit('pass');
        }
    }
}

export default Click;
