





import CreateForm from './CreateForm.vue';
import {Component, Vue, Watch} from 'vue-property-decorator';

@Component({
    components: {
        CreateForm
    }
})
export default class CreatePage extends Vue {

    @Watch('$store.state.mallCreatePage.created')
    public createdListener() {
        this.$message.success('提交成功');
        this.$router.push({
            path: '/console/mall/search' + this.$store.getters['mallSearchPage/queryString']()
        });
    }

    @Watch('$store.state.mallCreatePage.error')
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }
}
