




































import {Component,Watch, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import DisableDatePicker from '@/components/custom-form-components/DisableDatePicker.vue';
import {deleteEmpty} from '@/utils';
import qs from 'querystringify';
import moment from 'moment';

interface SearchForm {
    form: any;
    hideMessage: any;
}

export interface Query {
    start_time: string;
    end_time: string;
}

export interface FormData {
    created_time: any[];
}

@Component({
    components: {
        DisableDatePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.boothCodeSearchPage.query);
            },
            loading: (state: any) => state.boothCodeSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this),
            hideMessage: null,
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            console.log(values)
            console.log(values.created_time)
            console.log(values.created_time[0])
            if(!values.created_time || !values.created_time[0]){
                return this.$message.error('请选择时间');
            }
            const query = {
                ...this.formatQuery(values)
            };
            sessionStorage.setItem('boothDownloadParamsStartTime', query.start_time)
            sessionStorage.setItem('boothDownloadParamsEndTime', query.end_time)
            this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
            this.$store.dispatch('boothCodeSearchPage/download');
        });
    }
    @Watch('$store.state.boothCodeSearchPage.downloading')
    public boothCodeDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }

    @Watch('$store.state.boothCodeSearchPage.downloadError')
    public boothCodeDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error('导出失败，请联系技术人员!');
        }
    }

    @Watch('$store.state.boothCodeSearchPage.lastDownloadTime')
    public boothCodeDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }

    public handleDownload() {
        this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
        this.$store.dispatch('boothCodeSearchPage/download');
    }
    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: FormData): Query {
        return {
            start_time: values.created_time &&
                values.created_time[0] &&
                values.created_time[0].format('YYYY-MM-DD'),
            end_time: values.created_time &&
                values.created_time[1] &&
                values.created_time[1].format('YYYY-MM-DD')
        };
    }

    private formatFormData(query: Query): FormData {
        return {
            created_time: [
                query.start_time && moment(query.start_time),
                query.end_time && moment(query.end_time)
            ]
        };
    }    
}

export default SearchForm;
