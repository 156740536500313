























import {Component, Vue, Watch} from 'vue-property-decorator';
import {requestStream, readStream} from '@/utils';

@Component<AssignProgressModal>({})
class AssignProgressModal extends Vue {

    public visible = false;

    public assignPercent = 0;

    public assignFailList: any = [];

    public assignLoading = false;

    public assignCursor = 0;

    public status = '';

    public async assign(planId: string, assignments: any, conflictReplaceMerchants: any, merchantTotal: number) {
        this.assignLoading = true;
        this.visible = true;
        this.assignCursor = 0;
        this.assignPercent = 0;
        this.assignFailList = [];
        try {
            readStream(await requestStream({
                method: 'POST',
                api: '/console/ad/plan/assign',
                data: {
                    plan_id: planId,
                    assign: assignments,
                    conflict_replace: conflictReplaceMerchants
                }
            }), {
                onProgress: (lines: any) => {
                    this.assignCursor += lines.length;
                    this.assignPercent = Math.round(this.assignCursor / merchantTotal * 100);
                    this.assignFailList = this.assignFailList.concat(lines.filter((line: any) => !line.success));
                },
                onFinished: () => {
                    this.assignLoading = false;
                },
                onError: (e: any) => {
                    this.assignLoading = false;
                    this.$message.error(e.message);
                }
            });
        } catch (e) {
            const error: any = new Error('提交失败, 请联系技术人员!');
            error.silent = e.is403;
            if (!error.silent) {
                this.$message.error(e.message);
            }
        }
    }

    public handlerConfirm() {
        this.visible = false;
        const queryString = this.$store.getters['advertisementSearchPage/queryString']();
        this.$router.push({
            path: '/console/advertisement/search' + queryString
        });
    }
}
export default AssignProgressModal;
