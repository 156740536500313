





































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import ClearText from '@/components/ClearText.vue';
import {calcTableScrollX} from '@/utils';

@Component<SearchTable>({
    components: {
        ClearText
    },
    computed: {
        dataSourceWithIdx() {
            return this.dataSource.map((item: any, idx: any) => ({
                idx: (this.page - 1) * this.pageSize + idx + 1,
                ...item
            }));
        },
        columnsWithIdx() {
            return [{
                title: '序号',
                dataIndex: 'idx',
                fixed: 'left',
                align: 'center',
                width: 80,
            }, ...this.columns.map((c: any) => ({
                align: 'center',
                ...c,
            }))
            ];
        }
    }
})
class SearchTable extends Vue {

    @Prop()
    public columns!: any;

    @Prop()
    public rowKey!: string;

    @Prop()
    public dataSource!: any;

    @Prop()
    public total!: any;

    @Prop()
    public pagination!: any;

    @Prop()
    public page!: any;

    @Prop()
    public pageSize!: any;

    @Prop()
    public loading!: any;

    @Prop()
    public scroll!: any;

    @Prop()
    public clearTexts!: any;

    public fixed = 0;
    public bottomHeight = 57 + 24;
    public thHeight = 37;

    // public mounted() {
    //     setTimeout(() => {
    //         this.fixed = document.body.offsetHeight - this.thHeight - this.bottomHeight;
    //         window.addEventListener('resize', this.handleWindowResize);
    //     });
    // }

    // public handleWindowResize() {
    //     this.fixed = document.body.offsetHeight - this.thHeight - this.bottomHeight;
    // }

    // public destroyed() {
    //     window.removeEventListener('resize', this.handleWindowResize);
    // }

    public data() {
        return {
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    @Emit('pageChange')
    public handlePageChange(page: number) {
        // do nothing
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$emit('pageSizeChange', pageSize);
        // do nothing
    }
}

export default SearchTable;
