





















































import { Component, Vue, Inject } from 'vue-property-decorator';
import { mapState } from 'vuex';
import SearchFormWrapper from '@/components/SearchFormWrapper.vue';

interface Query {
    activityId: string;
    activityName: string;
    status: string;
}

interface FormData {
    activityId: string;
    activityName: string;
    status: string;
}

@Component({
    components: {
        SearchFormWrapper
    },
    computed: {
        ...mapState<{ companyQueryConfSearchPage: any }>({
            loading() {
                return this.state().loading;
            },
            formData() {
                return this.queryToFormData(this.state().query);
            }
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public state!: any;

    @Inject()
    public dispatch!: any;

    public data() {
        return {
            collapsed: false
        };
    }

    public format(values: FormData): Query {
        return {
            activityId: values.activityId,
            activityName: values.activityName,
            status: values.status,
        };
    }

    public queryToFormData(query: Query): FormData {
        return {
            activityId: query.activityId,
            activityName: query.activityName,
            status: query.status,
        };
    }

    public handleSearch(query: Query) {
        this.dispatch('queryChange', query);
    }
}

export default SearchForm;
