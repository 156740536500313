var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stop" },
    [
      _c("SearchCommonLayout", {
        attrs: { humanReadableQuery: _vm.humanReadableQuery },
        scopedSlots: _vm._u([
          {
            key: "search-form",
            fn: function() {
              return [_c("SearchForm")]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [_c("Actions")]
            },
            proxy: true
          },
          {
            key: "fields-filter-btn",
            fn: function() {
              return [
                _c("FieldsFilter", {
                  attrs: {
                    fields: _vm.fields,
                    excludeFields: ["idx", "id", "op"],
                    columns: _vm.currentColumns,
                    sortArr: _vm.sortArr
                  },
                  on: {
                    change: _vm.handleFieldChange,
                    sort: _vm.handleFieldSort
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "table",
            fn: function() {
              return [_c("SearchTable", { attrs: { paytype: _vm.paytype } })]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }