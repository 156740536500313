





























import {Component, Provide,Prop,Vue,Inject,Watch} from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import SendTaskSearchPage from './SendTask/SearchPage/index.vue';
import BuyRecordSearchPage from './BuyRecord/SearchPage/index.vue';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import {deleteEmpty} from '@/utils';

@Component({
    components: {
        SendTaskSearchPage,
        BuyRecordSearchPage,
        Show
    },
    computed: {}
})
class Index extends Vue {
    @Prop()
    // tslint:disable-next-line:variable-name
    public store_id!: any;

    @Prop()
    public title!: string | number;

    public activeKey = 'sendTask';
    public cacheStoreId!: any;
    public PRIVILEGE = PRIVILEGE;


    public created() {
        const store_id = this.store_id;   
        if (this.$route.path === '/console/shop/sms/send/task') {
            this.activeKey = 'sendTask';
        } else if (this.$route.path === '/console/store/sms/buying/record') {
            this.activeKey = 'buyRecord';
        }
    }
    @Provide()
    public commit(action: string, payload?: any) {
        console.log(payload);
        this.$store.dispatch('buyRecordSearchPage/' + action, payload);
    }
    @Provide()
    public commitSend(action: string, payload?: any) {
        this.$store.dispatch('sendTaskSearchPage/' + action, payload);
    }
    @Watch('store_id')
    public handleChange(key: string) {
        if(this.store_id == null){
            return;
        }
        this.activeKey = key;
        const store_id = this.store_id;    
        sessionStorage.setItem('store_id', store_id)
        if (key === 'buyRecord') {
            // this.$store.commit('buyRecordSearchPage/setStoreId', store_id);
            this.commit('search', this.store_id);
        } else{
            this.commitSend('search', this.store_id);
        }
    }
    public onClose() {
        this.$emit('close');
        console.log('close')
        this.$store.dispatch('sendTaskSearchPage/resetQuery');
        this.$store.dispatch('buyRecordSearchPage/resetQuery');
    }

}

export default Index;
