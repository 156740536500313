
























import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
import SearchForm from './SearchForm.vue';
import Actions from './Actions.vue';
import SearchTable from './SearchTable.vue';
import { Component, Prop, Watch, Provide, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import columns from './columns';
import columnsUnion from './columnsUnion';
import FieldsFilter from '@/components/FieldsFilter.vue';
import { queryToHumanReadable } from '@/utils';
import preferences from './preferences';

const humanReadableQuerySchema = {
    name: '活动名称',
    platActivity: '批次Id',
    startTime: {
        name: '消耗时间',
        range: 'from'
    },
    endTime: {
        name: '消耗时间',
        range: 'to'
    }
};
@Component({
    components: {
        SearchForm,
        Actions,
        SearchTable,
        SearchCommonLayout,
        FieldsFilter
    },
    computed: {
        ...mapState({
            fields: (state: any) => state.activityDetailSearchPage.fields,
            sortArr: (state: any) => state.activityDetailSearchPage.sortFields,
            humanReadableQuery(state: any) {
                return queryToHumanReadable(
                    state.activityDetailSearchPage.query,
                    humanReadableQuerySchema
                );
            }
        })
    }
})
export default class RolePage extends Vue {
    @Prop()
    public active!: boolean;

    public storeName = 'activityDetailSearchPage';

    public currentColumns = columnsUnion;

    public data() {
        return {
            columns
        };
    }

    @Provide()
    public state() {
        return this.$store.state[this.storeName];
    }

    @Provide()
    public getters(name: string) {
        return this.$store.getters[this.storeName + '/' + name];
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(this.storeName + '/' + action, payload);
    }

    @Provide()
    public commit(mutation: string, payload?: any) {
        this.$store.commit(this.storeName + '/' + mutation, payload);
    }

    @Provide()
    public routerPush() {
        this.$router.push({
            path:
                '/console/activities/activityDetail' +
                this.getters('queryString')()
        });
    }
    public created() {
        console.info('detail created clearQuery');
        this.commit('clearList');
        
        if (this.$store.state.activityManage.payType == 'wx') {
            this.currentColumns = columns;
        } else {
            this.currentColumns = columnsUnion;
        }
        this.$store.commit(
            'activityDetailSearchPage/setColumns',
            this.currentColumns
        );
        this.$store.commit('activityDetailSearchPage/resetFields');
        this.$store.commit('activityDetailSearchPage/clear');
        this.$store.commit('activityDetailSearchPage/setQuery', {
            payType: this.$store.state.activityManage.payType
        });
        // this.registerStore();

        // this.dispatch('initQuery', {
        //     ...this.$route.query
        // });
    }

    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    // @Watch('$store.state.activityManage.payType')
    // public payTypeChange(newVal: String, oldVal: String) {
    //     if (this.$store.state.activityManage.payType == 'wx') {
    //         this.currentColumns = columns;
    //     } else {
    //         this.currentColumns = columnsUnion;
    //     }
    //     this.$store.commit(
    //         'activityDetailSearchPage/setColumns',
    //         this.currentColumns
    //     );
    //     this.$store.commit('activityDetailSearchPage/resetFields');
    //     this.$store.commit('activityDetailSearchPage/clear');
    //     this.$store.commit('activityDetailSearchPage/setQuery', {
    //         payType: this.$store.state.activityManage.payType
    //     });
    // }

    private handleFieldChange(e: any) {
        this.$store.commit('activityDetailSearchPage/setFields', e);
    }
    private handleFieldSort(e: any) {
        preferences.setSortFields(e);
        this.$store.commit('activityDetailSearchPage/sortFields', e);
    }
}
