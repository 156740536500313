












import {AutoComplete} from 'ant-design-vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {request} from '@/utils';

interface payType {
    options: any[];
    loading: boolean;
}

@Component({
    components: {
        [AutoComplete.name]: AutoComplete
    }
})
class payType extends Vue {
    @Prop()
    public value!: string;

    @Prop({default: false})
    public showTip!: boolean;

    public async created() {
        this.loading = true;
        try {
            const ret = await request({
                api: '/console/order/pay_type/list',
                data: {page: 1, page_size: 1000}
            });
            this.options = ret.map((data: any) => {
                return {
                    value: data[0],
                    label: data[1]
                };

            });
        // arr1 = arr.map(item => item[1])
        } catch (e) {
            // do nothing
        } finally {
            this.loading = false;
        }
    }
    public data() {
        return {
            options: [],
            loading: false
        };
    }

    public handleChange(value: string) {
        this.$emit('change', value);
    }
}

export default payType;
