







































































import {Component,Inject ,Watch, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import moment from 'moment';
import qs from 'querystringify';
import {deleteEmpty} from '@/utils';
import {DatePicker} from '@/components/custom-form-components';
import formRules from '@/common/formRules';
import {SignUpActivityStatus, SignUpActivityType} from '@/components/custom-form-components';

interface SearchForm {
    form: any;
}

interface QueryParams {
    name: string;
    status: string;
    activityType: string;
    createName: string
    
}

@Component({
    components: {
        DatePicker,
        SignUpActivityStatus,
        SignUpActivityType
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.activitySignUpPage.query);
            },
            loading: (state: any) => state.activitySignUpPage.loading
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.dispatch('initQuery', query);
        });
    }
    
    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any): QueryParams {
        return {
            activityName: values.name,
            status: values.status,
            activityType: values.activityType,
            createdBy: values.createName
        };
    }

    private formatFormData(query: any) {
        return {
            ...query
        };
    }
}

export default SearchForm;
