var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("DetailDrawerUnion", {
        attrs: { activityId: _vm.detailUnion, title: "详情" },
        on: {
          close: function($event) {
            return _vm.handleDrawerClose("detailUnion")
          }
        }
      }),
      _c("EditActDrawerUnion", {
        attrs: { activityId: _vm.editActUnion, title: "修改活动" },
        on: {
          close: function($event) {
            return _vm.handleDrawerClose("editActUnion")
          }
        }
      }),
      _c("a-table", {
        ref: "table",
        staticClass: "user-search-table",
        attrs: {
          size: "middle",
          rowKey: "promotionId",
          bordered: "",
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys || [],
            onChange: _vm.handleSelectChange
          },
          columns: _vm.fields,
          dataSource: _vm.lists,
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 0),
            y: _vm.fixed || undefined
          },
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: function(total) {
              return "总计 " + total + " 条记录"
            },
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function(value, record) {
              return [
                _c(
                  "a-button",
                  {
                    attrs: { size: "small", href: "javascript:;" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.handleDrawerOpenClick(
                          "detailUnion",
                          record.promotionId
                        )
                      }
                    }
                  },
                  [_vm._v("明细")]
                ),
                record.status === 1
                  ? _c(
                      "span",
                      { style: { paddingLeft: "5px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { size: "small", href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleDrawerOpenClick(
                                  "editActUnion",
                                  record.promotionId
                                )
                              }
                            }
                          },
                          [_vm._v("修改活动")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }