
























import { AutoComplete } from 'ant-design-vue';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { request } from '@/utils';

@Component<BulkSelector>({})
class BulkSelector extends Vue {
    @Prop()
    public value!: any;

    @Prop()
    public columns!: any[];

    @Prop()
    public action!: string;

    @Prop()
    public rowKey!: string;

    @Prop()
    public query!: any;

    private page = 1;
    private pageSize = 20;
    private list = [];
    private loading = false;
    private total = 0;
    private error: any = null;

    public created() {
        this.search();
    }

    @Watch('error')
    public errorListener(e: any) {
        if (!e.is403) {
            this.$message.error(e.message);
        }
    }

    @Watch('query')
    public watchQuery(query: any) {
        this.search(1);
    }

    public async search(page?: any) {
        if (page) {
            this.page = page;
        }
        try {
            this.loading = true;
            this.list = [];
            const { list, total } = await request({
                api: this.action,
                data: {
                    page: this.page,
                    page_size: this.pageSize,
                    ...this.query
                }
            });
            this.list = list;
            this.total = total;
        } catch (e) {
            this.error = e;
        } finally {
            this.loading = false;
        }
    }

    public handlePageChange(page: number) {
        this.page = page;
        this.search();
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.pageSize = pageSize;
        this.search();
    }

    public handleSelectChange(selectedRowKeys: any[]) {
        this.$emit('input', [...selectedRowKeys]);
    }
}

export default BulkSelector;
