var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "a-row",
        [
          _c(
            "a-collapse",
            {
              staticStyle: { margin: "-24px" },
              attrs: { defaultActiveKey: "1", bordered: false },
              scopedSlots: _vm._u([
                {
                  key: "expandIcon",
                  fn: function(props) {
                    return [
                      _c("a-icon", {
                        attrs: {
                          type: "caret-right",
                          rotate: props.isActive ? 90 : 0
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "a-collapse-panel",
                {
                  key: "1",
                  staticStyle: {
                    background: "#f7f7f7",
                    "border-radius": "4px",
                    "margin-bottom": "24px",
                    border: "0"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function(props) {
                        return [
                          !props.isActive &&
                          _vm.humanReadableQuery &&
                          _vm.humanReadableQuery.length > 0
                            ? _c(
                                "span",
                                _vm._l(_vm.humanReadableQuery, function(q) {
                                  return _c(
                                    "a-tag",
                                    {
                                      key: q.name,
                                      staticStyle: { margin: "3px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(q.name + ":" + q.value) +
                                          "\n                        "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n                        查询条件\n                    "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [_vm._t("search-form")],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticStyle: {
            "margin-left": "-8px",
            "margin-right": "0px",
            "margin-top": "6px"
          }
        },
        [_vm._t("result")],
        2
      ),
      _c(
        "a-row",
        { staticStyle: { margin: "13px -10px" } },
        [
          _c(
            "a-col",
            {
              staticClass: "search-common-layout-actions",
              attrs: { span: "22" }
            },
            [_vm._t("actions")],
            2
          ),
          _c(
            "a-col",
            {
              staticClass: "search-common-layout-fields-filter-btn",
              attrs: { span: "2" }
            },
            [_vm._t("fields-filter-btn")],
            2
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticStyle: {
            "margin-left": "-24px",
            "margin-right": "-24px",
            "margin-bottom": "-24px"
          }
        },
        [_vm._t("table")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }