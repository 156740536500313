var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Show", {
    attrs: { requires: [_vm.PRIVILEGE.DISTRICT_CODE.UPDATE] },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "ad_detail" },
              [_c("EditActForm", { on: { onCancel: _vm.onClose } })],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }