var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper" },
    [
      _c("DetailDrawer", {
        attrs: { activityId: _vm.detail, title: "详情" },
        on: {
          close: function($event) {
            return _vm.handleDrawerClose("detail")
          }
        }
      }),
      _c("EditDrawer", {
        attrs: { activityId: _vm.editAct, title: "修改活动" },
        on: {
          close: function($event) {
            return _vm.handleDrawerClose("editAct")
          }
        }
      }),
      _c("a-table", {
        ref: "table",
        staticClass: "user-search-table",
        attrs: {
          size: "middle",
          rowKey: "activityId",
          bordered: "",
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys || [],
            onChange: _vm.handleSelectChange
          },
          columns: _vm.fields,
          dataSource: _vm.lists,
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 0),
            y: _vm.fixed || undefined
          },
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: function(total) {
              return "总计 " + total + " 条记录"
            },
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "url",
            fn: function(text, record) {
              return _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    width: "88%",
                    margin: "0 auto",
                    "text-align": "left"
                  }
                },
                [
                  _c("SignUpUrl", {
                    attrs: {
                      activityId: record.activityId,
                      linkList: record.activityLinks
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "trigger",
                          fn: function(_) {
                            return [
                              _c("a-button", { attrs: { size: "small" } }, [
                                _vm._v("查看")
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            }
          },
          {
            key: "action",
            fn: function(value, record) {
              return [
                _c(
                  "a-button",
                  {
                    attrs: { size: "small", href: "javascript:;" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.handleDrawerOpenClick(
                          "detail",
                          record.activityId
                        )
                      }
                    }
                  },
                  [_vm._v("查看")]
                ),
                record.statusName === "未生效" || record.statusName === "已失效"
                  ? _c(
                      "span",
                      { style: { paddingLeft: "5px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { size: "small", href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleDrawerOpenClick(
                                  "editAct",
                                  record.activityId
                                )
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }