































































































import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import { mapState } from 'vuex';
import columns from './columns';
import { check, Show, Click } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import './store';
import config from '@/config';

@Component({
    components: {
        Drawer,
        Show,
        Click
    },
    computed: {
        ...mapState({
            list: (state: any) =>
                state.actMerShenhe.list.map((item: any, idx: any) => ({
                    idx:
                        (state.actMerShenhe.page - 1) *
                            state.actMerShenhe.pageSize +
                        idx +
                        1,
                    ...item
                })),
            total: (state: any) => state.actMerShenhe.total,
            pageSize: (state: any) => state.actMerShenhe.pageSize,
            page: (state: any) => state.actMerShenhe.page,
            loading: (state: any) => state.actMerShenhe.loading,
            token: (state: any) => {
                return state.login.session && state.login.session.token;
            },
            downloadingErrorExcel: (state: any) =>
                state.actMerShenhe.downloadingErrorExcel,
            refreshing: (state: any) => state.actMerShenhe.refreshing
        }),
        uploadAction() {
            const Action: any = {
                act_check_enroll:
                    '/console/activity/bankAudit/apply/file/upload',
                act_enroll: '/console/activity/bankAudit/file/upload',
                act_enroll_update:
                    '/console/activity/bankAudit/enroll/update/upload'
            };
            return `${config.XY_BASE_URL}${Action[this.uploadType]}`;
        }
    }
})
class Store extends Vue {
    @Prop()
    public uploadType?: string;
    @Inject()
    public privileges!: any;
    public fileList: any = [];
    public PRIVILEGE = PRIVILEGE;
    private timer: any;
    private uploading = false;
    public data() {
        return {
            columns,
            config
        };
    }
    public created() {}
    @Provide()
    public state() {
        return this.$store.state['actMerShenhe'];
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch('actMerShenhe/' + action, payload);
    }

    public handleRefreshClick(record: any) {
        this.dispatch('refresh', {
            taskId: record.taskId,
            type: this.uploadType
        });
    }

    public handleDownloadErrorClick(record: any) {
        this.dispatch('downloadErrorExcel', {
            taskId: record.taskId,
            type: this.uploadType
        });
    }

    public handleFileUpload({ file, fileList, event }: any) {
        this.fileList = [
            {
                ...file,
                name: ({
                    uploading: '正在上传',
                    done: '上传完成',
                    error: '上传失败',
                    removed: ''
                } as any)[file.status]
            }
        ];
        this.uploading = true;
        if (file && file.status === 'done' && file.response) {
            if (file.response.status.code !== 0) {
                this.$message.error(file.response.status.desc);
            } else {
                this.dispatch('search');
            }
        }

        if (file.status !== 'uploading') {
            this.uploading = false;
        }
    }

    public disabledUpload() {
        if (
            'act_enroll' == this.uploadType &&
            check([this.PRIVILEGE.BANK_SIGNUP_AUTIO.UPLOAD], this.privileges)
                .length > 0
        ) {
            return true;
        } else if (
            'act_enroll_update' == this.uploadType &&
            check(
                [this.PRIVILEGE.BANK_SIGNUP_AUTIO.BATCH_BANK_AUTIO_UPDATE],
                this.privileges
            ).length > 0
        ) {
            return true;
        } else if (
            'act_check_enroll' == this.uploadType &&
            check(
                [this.PRIVILEGE.BANK_SIGNUP_AUTIO.UPLOAD_APPLY],
                this.privileges
            ).length > 0
        ) {
            return true;
        }
        return false;
    }

    public handleDownloadTemplateClick() {
        if ('act_enroll' == this.uploadType) {
            window.open(
                process.env.BASE_URL + '导入审核名单模板.xlsx',
                '_blank'
            );
        } else if ('act_enroll_update' == this.uploadType) {
            window.open(
                process.env.BASE_URL + '导入修改报名信息模板.xlsx',
                '_blank'
            );
        } else {
            window.open(
                process.env.BASE_URL + '导入报名名单模板.xlsx',
                '_blank'
            );
        }
    }

    public handlePageChange(page: number) {
        this.dispatch('pageChange', page);
    }

    public handleDrawerOpen() {
        this.$store.state.actMerShenhe.list = [];
        this.$store.state.actMerShenhe.total = 0;
        if ("act_enroll" == this.uploadType) {
            if (
                check([this.PRIVILEGE.BANK_SIGNUP_AUTIO.UPLOAD], this.privileges)
                .length > 0
            ) {
                return;
            } else {
                this.$store.dispatch("actMerShenhe/initQuery", {
                    type: this.uploadType,
                });
            }
        } else if ("act_check_enroll" == this.uploadType) {
            if (
                check([this.PRIVILEGE.BANK_SIGNUP_AUTIO.UPLOAD_APPLY], this.privileges)
                .length > 0
            ) {
                return;
            } else {
                this.$store.dispatch("actMerShenhe/initQuery", {
                    type: this.uploadType,
                });
            }
        } else {
            if (
                check([this.PRIVILEGE.BANK_SIGNUP_AUTIO.BATCH_BANK_AUTIO_UPDATE], this.privileges)
                .length > 0
            ) {
                return;
            } else {
                this.$store.dispatch("actMerShenhe/initQuery", {
                    type: this.uploadType,
                });
            }
        }
    }
}

export default Store;
