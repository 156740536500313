









































































































































































































































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {
    DisableDatePicker,
    ScheduleTimePicker,
    IrregularScheduleTimePicker
} from '@/components/custom-form-components';
import CreateModal from './CreateModal.vue';
import ShopUpload from './ShopUpload.vue';
import * as validators from '../../validators';

interface CreateForm {
    baseForm: any;
    otherForm: any;
}

@Component<CreateForm>({
    components: {
        DisableDatePicker,
        CreateModal,
        ShopUpload,
        ScheduleTimePicker,
        IrregularScheduleTimePicker
    },
    computed: {
        ...mapState({})
    }
})
class CreateForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;

    public baseForm;

    public otherForm;

    public baseData: any;
    public activeTab: any;
    public isShowAllDiscount: boolean = false;
    public timeRuleData: any;
    public timeRulevisible: boolean = false;
    public noTimeRuleData: any;
    public noTimeRulevisible: boolean = false;
    public shopData: any;
    public bankData: any;
    public activitySponsors: any;
    public budgetLimitCheck: any;
    public paywayCheck: any;
    public discountRuleCheck: any;

    public isDefault: boolean = false;

    public conflictReplaceList: any;
    public showSettleRate: boolean = true;
    public showSettleAmount: boolean = true;
    public merchantTotal = 0;
     public settleType: any;
    public validators = validators;
    public timeArrayTemp = [];
    public dateArrayTemp: any = [];
    public isShowFlag:any;
    public isSettleFlag:any;
    public onlyMer: boolean = false;
    public created() {};

    public data() {
        return {
            baseData: {},
            baseForm: this.$form.createForm(this),
            otherForm: this.$form.createForm(this),
            activeTab: '1',
            isShowFlag: '1',
            isSettleFlag:'1',
            isShowAllDiscount: false,
            timeRuleData: {},
            timeRulevisible: false,
            noTimeRuleData: {},
            settleType: 'settleRate',
            noTimeRulevisible: false,
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
                marginLeft: '120px'
            },
            budgetLimit: '2',
            showSettleRate: true,
            showSettleAmount: true,
            budgetInput: false,
            discountRuleCheck: '1',
            budgetLimitCheck: '2',
            paywayCheck: '0',
            shopData: [],
            bankData: [],
            activitySponsors: [],
            timeArrayTemp: [],
            ateArrayTemp: [],
            hasTermNocolumns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo'
                },
                {
                    title: '终端号',
                    dataIndex: 'termNo'
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ],
            noTermNocolumns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo'
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ]
        };
    }

    public discountRuleChange(e: any) {
        this.discountRuleCheck = e.target.value;
    }

    public timeRuleChange(e: any) {
        if (e.target.value == '1') {
            //.timeRuleData = {};
            this.timeRulevisible = true;
        } else if (e.target.value == '2') {
            //.noTimeRuleData = {};
            this.noTimeRulevisible = true;
        }
    }

    public initTimeRuleData(data: any) {
        this.timeRuleData = data;
    }

    public showAllDiscount(data: any) {
        console.info('showAllDiscount', data);
        this.isShowFlag = data.target.value;
        if ('1' == data.target.value) {
            this.isShowAllDiscount = false;
        } else {
            this.isShowAllDiscount = true;
        }
    }
    
    
    public showSettleFlag(data: any) {
        this.isSettleFlag = data.target.value;
        this.baseForm.setFieldsValue({
            settleRate: undefined,
            settleAmount: undefined,
        });
    }
    public selectSettleType(data: any) {
        this.settleType = data.target.value;
        this.baseForm.setFieldsValue({
            settleRate: undefined,
            settleAmount: undefined,
        });
    }
   

    public handleTimeRuleHideCancel() {
        this.timeRulevisible = false;
    }

    public initNoTimeRuleData(data: any) {
        this.noTimeRuleData = data;
    }

    
    public handleNoTimeRuleHideCancel() {
        this.noTimeRulevisible = false;
    }
    public tabChange(key: any) {
        console.log(key)
        if (key === '2') {
            this.baseForm.validateFields((error: any, values: any) => {
                if (!error) {
                    const promoAmount = parseFloat(values.promoAmount);
                    const orderMinAmt = parseFloat(values.orderMinAmt);
                    console.log(promoAmount, orderMinAmt)
                    if (+promoAmount >= +orderMinAmt) {
                        this.$message.error('使用门槛金额需大于券面额');
                        return;
                    }
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        } else {
            this.activeTab = key;
        }
    }

    // public budgetLimitChange(value: any, options: any) {
    //     this.budgetLimitCheck = value;
    //     if (value === '2') {
    //         this.otherForm.setFieldsValue({ budgetLimitInput: '' });
    //     }
    // }

    public paywayChange(e: any) {
        if (e.target.value == '1') {
            this.paywayCheck = '1';
        } else {
            this.paywayCheck = '0';
            this.otherForm.setFieldsValue({ bankName: '' });
            this.otherForm.setFieldsValue({ cardType: '' });
            this.otherForm.setFieldsValue({ cardBin: '' });
        }
    }

    public handleNext(e: any) {
        
        e.preventDefault();
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                console.log('handleNext')
                const promoAmount = parseFloat(values.promoAmount);
                const orderMinAmt = parseFloat(values.orderMinAmt);

                if (+promoAmount >= +orderMinAmt) {
                    this.$message.error('减价金额必须小于使用门槛金额');
                    return;
                }
                if (values.settleFlag === '1') {
                    if (
                        this.settleType === 'settleRate' &&
                        !values.settleRate
                    ) {
                        this.$message.error('请输入结算比');
                        return false;
                    }
                    if (
                        this.settleType === 'settleAmount' &&
                        !values.settleAmount
                    ) {
                        this.$message.error('请输入结算金额');
                        return false;
                    }
                    if (
                        values.settleRate &&
                        (!/^[1-9]\d*$/.test(values.settleRate) ||
                            values.settleRate > 100)
                    ) {
                        this.$message.error(
                            '结算比必须是1到100之间的整数（包含1和100）'
                        );
                        return false;
                    }
                    if (
                        values.settleAmount &&
                        !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(
                            values.settleAmount
                        )
                    ) {
                        this.$message.error(
                            '结算金额只能精确到分，不能为0'
                        );
                        return false;
                    }
                    if (
                        parseFloat(values.settleAmount) >
                        parseFloat(values.promoAmount)
                    ) {
                        this.$message.error(
                            '结算金额不得大于固定立减金额'
                        );
                        return false;
                    }
                }
                
                this.formatBaseForm(values);
                this.activeTab = '2';
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public changeShopData(value: any) {
        if (this.shopData.length === 3000) {
            this.$message.error('店铺最多3000家上限');
            return;
        }
        console.log(value)
        value.forEach((data: any) => {
            if (this.onlyMer) {
                let flag = false;
                for (let shop of this.shopData) {
                    if (shop.merNo === data) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    const objData = {
                        index: this.shopData.length + 1,
                        merNo: data
                    };
                    this.shopData.push(objData);
                }
            } else {
                let flag = false;
                for (let shop of this.shopData) {
                    if (shop.merNo === data[0] && shop.termNo === data[1]) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    const objData = {
                        index: this.shopData.length + 1,
                        merNo: data[0],
                        termNo: data[1]
                    };
                    this.shopData.push(objData);
                }
            }
            
        });
    }

    public deleteShopData(record: any) {
        this.shopData = (this.shopData as object[]).filter(
            (data: any) => data.index !== record.index
        );
    }

    public handleCreate(e: any) {
        e.preventDefault();
        this.otherForm.validateFields((error: any, values: any) => {
            if (!error) {
                // console.log('budgetAmount', values.budget);
                // console.log('dailyAmountLimit', values.budgetLimitInput);
                // console.log(
                //     null != values.budgetLimitInput &&
                //         '' != values.budgetLimitInput
                // );
                // if (
                //     null != values.budgetLimitInput &&
                //     '' != values.budgetLimitInput &&
                //     parseFloat(values.budgetLimitInput) >
                //         parseFloat(values.budget)
                // ) {
                //     this.$message.error('当天消耗金额上限必须小于活动预算金额');
                //     return;
                // }
                if (null == this.shopData || this.shopData.length < 1) {
                    this.$message.error('请添加活动商户');
                    return;
                }
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => this.handleSubmitConfirm(values)
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public async handleSubmitConfirm(values: any) {
        try {
            let baseData = this.baseData;
            console.log(this.baseData);
            if (null == baseData) {
                baseData = this.formatBaseForm(values);
            }
            const otherData = this.formatOtherForm(values);
            const formData = { ...baseData, ...otherData };
            console.log(formData);
            await this.$store.dispatch('unionCouponSearchPage/create', formData)
            console.log(this.$store.state.unionCouponSearchPage.updatedSuccess)
            
            if (this.$store.state.unionCouponSearchPage.updatedSuccess) {
                this.$message.success('提交成功');
                const queryString = this.$store.getters[
                    'unionCouponSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/activity/unionCoupon/search' + queryString
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters[
                    'unionCouponSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/activity/unionCoupon/search' + queryString
                });
            }
        });
    }

    public handleBack() {
        this.activeTab = '1';
    }

    @Watch('$store.state.adCreatePage.id')
    public watchCreateSuccess(id: string) {}

    @Watch('onlyMer')
    public watchOnlyMerChange(onlyMer: boolean) {
        console.log(onlyMer)
        this.shopData = []
    }

    private formatBaseForm(formData: any) {
        let timeArray = [];
        let dateArray: any = [];
        
        if (formData.settleFlag == '1') {
            this.baseData = {
                name: formData.name,                                    // 活动名称
                budgetActivity: formData.budgetActivity,                // 活动id
                orgId: 'lakala',                                  // 机构号
                stockId: formData.stockId + '',                         // 批次号
                promoAmount: (formData.promoAmount * 100).toFixed(0),   // 面额
                orderMinAmt: (formData.orderMinAmt * 100).toFixed(0),   // 使用门槛
                description: formData.description,                      // 描述说明
                remark : formData.remark,
                settleFlag: formData.settleFlag,                        // 是否结算
                settleRate: formData.settleRate,                        // 结算比例
                validType: 'ABSOLUTE',                          // 选择时效类型
                //validDays: formData.validDays,                          // 用户领取多少天内可用
                validStartTime: formData.range_valiable_time[0].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                validEndTime: formData.range_valiable_time[1].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
            }

            if (formData.settleAmount)
                    this.baseData.settleAmount = (
                        formData.settleAmount * 100
                    ).toFixed(0);
        } else {
            this.baseData = {
                name: formData.name,                                    // 活动名称
                budgetActivity: formData.budgetActivity,                // 活动id
                orgId: formData.orgId,                                  // 机构号
                stockId: formData.stockId + '',                         // 批次号
                promoAmount: (formData.promoAmount * 100).toFixed(0),   // 面额
                orderMinAmt: (formData.orderMinAmt * 100).toFixed(0),   // 使用门槛
                description: formData.description,                      // 描述说明
                remark:formData.remark,
                settleFlag: formData.settleFlag,                        // 是否结算
                validType: 'ABSOLUTE',                          // 选择时效类型
                validDays: formData.validDays,                          // 用户领取多少天内可用
                validStartTime: formData.range_valiable_time[0].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                validEndTime: formData.range_valiable_time[1].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                
            }
        }
    }

    private formatOtherForm(formData: any) {
        
        const otherData = {
           
            merchantList: this.shopData,
            listType: formData.listType,
            merLimit: formData.listType === 'merId' ? '1' : '2' // 商户/商终限制标识：0-不限制(暂时不用);1-商户列表;2-商终列表
            // bankCardList: this.bankData,
        };
        return otherData;
    }

    private chooseMerchantType(e:any) {
        console.log(e.target.value)
        if(e.target.value === 'merId') {
            this.onlyMer = true
        } else {
            this.onlyMer = false
        }
    }
}

export default CreateForm;
