var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-tabs",
        {
          attrs: { activeKey: _vm.activeTab, type: "card" },
          on: { change: _vm.tabChange }
        },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "基础规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.baseForm }
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "主题活动ID" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "budgetActivity",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "活动总金额不能为空"
                                  }
                                ],
                                initialValue: _vm.formData.budgetActivity
                              }
                            ],
                            expression:
                              "[\n                            'budgetActivity',\n                            {\n                                rules: [\n                                    {required: true, message: '活动总金额不能为空'}\n                                ],\n                                initialValue: formData.budgetActivity\n                            }\n                        ]"
                          }
                        ],
                        attrs: { disabled: true, placeholder: "-" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动名称" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "name",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "活动名称不能为空"
                                  }
                                ],
                                initialValue: _vm.formData.name
                              }
                            ],
                            expression:
                              "[\n                            'name',\n                            {\n                                rules: [\n                                    {required: true, message: '活动名称不能为空'}\n                                    \n                                ],\n                                initialValue: formData.name\n                            }\n                        ]"
                          }
                        ],
                        attrs: { disabled: true, placeholder: "-" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "活动有效期" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "startTime",
                              {
                                rules: [
                                  { required: true, message: "ID不能为空" }
                                ],
                                initialValue:
                                  _vm.formData.validStartTime +
                                  "—" +
                                  _vm.formData.validEndTime
                              }
                            ],
                            expression:
                              "[\n                            'startTime',\n                            {\n                                rules: [{required: true, message: 'ID不能为空'}],\n                               initialValue: formData.validStartTime +'—'+formData.validEndTime\n                            }\n                        ]"
                          }
                        ],
                        attrs: { disabled: true, placeholder: "-" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "撤销后券状态" } },
                    [
                      _vm._v("\n                        "),
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "refundedStatus",
                                {
                                  rules: [
                                    { required: true, message: "请选择必填项" }
                                  ],
                                  initialValue: _vm.formData.refundedStatus
                                }
                              ],
                              expression:
                                "['refundedStatus', { rules: [{required: true, message: '请选择必填项'}],initialValue: formData.refundedStatus}]"
                            }
                          ],
                          attrs: { disabled: true }
                        },
                        [
                          _c("a-radio", { attrs: { value: "1" } }, [
                            _vm._v(
                              "\n                            生效\n                        "
                            )
                          ]),
                          _c("a-radio", { attrs: { value: "2" } }, [
                            _vm._v(
                              "\n                            失效\n                        "
                            )
                          ]),
                          _c("a-radio", { attrs: { value: "3" } }, [
                            _vm._v(
                              "\n                            已删除\n                        "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "优惠规则" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ad-create-form",
                  attrs: { form: _vm.discountForm }
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "订单最小金额" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "orderMinAmt",
                              {
                                rules: [{ required: false, message: "" }],
                                initialValue: _vm.formData.orderMinAmt / 100
                              }
                            ],
                            expression:
                              "[\n                            'orderMinAmt',\n                            {rules: [{required: false, message: ''}],\n                                initialValue: formData.orderMinAmt/100}\n                        ]"
                          }
                        ],
                        staticStyle: { width: "95%" },
                        attrs: { disabled: true }
                      }),
                      _vm._v(" 元\n                ")
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "优惠类型" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "promoType",
                                {
                                  initialValue: _vm.promoType
                                }
                              ],
                              expression:
                                "['promoType', {\n                    initialValue: promoType}]"
                            }
                          ],
                          staticStyle: { marginTop: "10px" }
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.promoType == "voucher",
                                  expression: "promoType == 'voucher'"
                                }
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "voucher" }
                            },
                            [
                              _vm._v(
                                "\n                            固定立减\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "76.5px"
                                  }
                                },
                                [_vm._v("减")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "promoAmount",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.promoType == "voucher",
                                                message: "金额不能为空"
                                              },
                                              {
                                                pattern: /^[1-9]+(\.\d{1,2})?$/,
                                                message: "只能填数字且不能为0"
                                              }
                                            ],
                                            initialValue:
                                              _vm.formData.promoAmount / 100
                                          }
                                        ],
                                        expression:
                                          "[\n                            'promoAmount',\n                            {rules: [{required: (promoType == 'voucher'), message: '金额不能为空'}, {pattern: /^[1-9]+(\\.\\d{1,2})?$/, message: '只能填数字且不能为0'}]\n                             ,initialValue:formData.promoAmount/100}\n                        ]"
                                      }
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" }
                                  }),
                                  _vm._v(" 元\n                            ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.promoType == "discount",
                                  expression: "promoType == 'discount'"
                                }
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "discount" }
                            },
                            [
                              _vm._v(
                                "\n                            固定立折\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "78px"
                                  }
                                },
                                [_vm._v("打")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "ratio",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.promoType == "discount",
                                                message: "打折费率不能为空"
                                              },
                                              {
                                                pattern: /^[1-9]+(\.\d{1,2})?$/,
                                                message: "只能填数字"
                                              }
                                            ],
                                            initialValue: _vm.formData.ratio
                                          }
                                        ],
                                        expression:
                                          "[\n                            'ratio',\n                            {rules: [{required: (promoType == 'discount'), message: '打折费率不能为空'}, {pattern: /^[1-9]+(\\.\\d{1,2})?$/, message: '只能填数字'}]\n                            ,initialValue:formData.ratio}\n                        ]"
                                      }
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" }
                                  }),
                                  _vm._v(" 折\n                            ")
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "52px"
                                  }
                                },
                                [_vm._v("封顶")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "ratioMaxAmt",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.promoType == "discount",
                                                message: "金额不能为空"
                                              },
                                              {
                                                pattern: /^[1-9]+(\.\d{1,2})?$/,
                                                message: "只能填数字"
                                              }
                                            ],
                                            initialValue:
                                              _vm.formData.ratioMaxAmt / 100
                                          }
                                        ],
                                        expression:
                                          "[\n                            'ratioMaxAmt',\n                            {rules: [{required: (promoType == 'discount'), message: '金额不能为空'}, {pattern: /^[1-9]+(\\.\\d{1,2})?$/, message: '只能填数字'}]\n                             ,initialValue:formData.ratioMaxAmt/100}\n                        ]"
                                      }
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" }
                                  }),
                                  _vm._v(" 元\n                            ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.promoType == "randomReduce",
                                  expression: "promoType == 'randomReduce'"
                                }
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "randomReduce" }
                            },
                            [
                              _vm._v(
                                "\n                            随机立减\n                            "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "36.5px"
                                  }
                                },
                                [_vm._v("金额下限")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "rangeMinAmt",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.promoType ==
                                                  "randomReduce",
                                                message: "金额不能为空"
                                              },
                                              {
                                                pattern: /^[1-9]+(\.\d{1,2})?$/,
                                                message: "只能填数字"
                                              }
                                            ],
                                            initialValue:
                                              _vm.formData.rangeMinAmt / 100
                                          }
                                        ],
                                        expression:
                                          "[\n                            'rangeMinAmt',\n                            {rules: [{required: (promoType == 'randomReduce'), message: '金额不能为空'}, {pattern: /^[1-9]+(\\.\\d{1,2})?$/, message: '只能填数字'}]\n                            ,initialValue:formData.rangeMinAmt/100}\n                        ]"
                                      }
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" }
                                  }),
                                  _vm._v(" 元\n                            ")
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "20px"
                                  }
                                },
                                [_vm._v("金额上限")]
                              ),
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "rangeMaxAmt",
                                          {
                                            rules: [
                                              {
                                                required:
                                                  _vm.promoType ==
                                                  "randomReduce",
                                                message: "金额不能为空"
                                              },
                                              {
                                                pattern: /^[1-9]+(\.\d{1,2})?$/,
                                                message: "只能填数字"
                                              }
                                            ],
                                            initialValue:
                                              _vm.formData.rangeMaxAmt / 100
                                          }
                                        ],
                                        expression:
                                          "[\n                            'rangeMaxAmt',\n                            {rules: [{required: (promoType == 'randomReduce'), message: '金额不能为空'}, {pattern: /^[1-9]+(\\.\\d{1,2})?$/, message: '只能填数字'}]\n                             ,initialValue:formData.rangeMaxAmt/100}\n                        ]"
                                      }
                                    ],
                                    staticStyle: { width: "100px" },
                                    attrs: { disabled: true, placeholder: "" }
                                  }),
                                  _vm._v(" 元\n                            ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "是否结算" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "settleFlag",
                                { initialValue: _vm.formData.settleFlag }
                              ],
                              expression:
                                "['settleFlag', { initialValue: formData.settleFlag }]"
                            }
                          ],
                          attrs: { disabled: true }
                        },
                        [
                          _c(
                            "a-radio",
                            { attrs: { value: "1", checked: true } },
                            [_vm._v("是")]
                          ),
                          _c(
                            "a-radio",
                            { attrs: { value: "0", checked: true } },
                            [_vm._v("否")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formData.settleFlag == "1",
                          expression: "formData.settleFlag == '1'"
                        }
                      ],
                      attrs: { label: "结算类型" }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "settleType",
                                {
                                  initialValue: _vm.formData.settleRate
                                    ? "settleRate"
                                    : "settleAmount"
                                }
                              ],
                              expression:
                                "['settleType', { initialValue: formData.settleRate ? 'settleRate' : 'settleAmount'}]"
                            }
                          ],
                          staticStyle: { "margin-top": "10px" },
                          attrs: { disabled: true }
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.formData.settleRate,
                                  expression: "formData.settleRate"
                                }
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "settleRate" }
                            },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _vm._v(
                                    "\n                                结算比：    \n                                "
                                  ),
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "settleRate",
                                          {
                                            initialValue:
                                              _vm.formData.settleRate
                                          }
                                        ],
                                        expression:
                                          "[\n                                        'settleRate',\n                                        { initialValue: formData.settleRate }\n                                    ]"
                                      }
                                    ],
                                    staticStyle: { with: "100px" },
                                    attrs: {
                                      suffix: "%",
                                      type: "tel",
                                      placeholder: "请输入1到100的整数",
                                      maxLength: "3",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.formData.settleAmount,
                                  expression: "formData.settleAmount"
                                }
                              ],
                              staticClass: "radioStyle",
                              attrs: { value: "settleAmount" }
                            },
                            [
                              _c(
                                "a-form-item",
                                {
                                  staticClass: "radioInputStyle",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _vm._v(
                                    "\n                                结算金额：\n                                "
                                  ),
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "settleAmount",
                                          {
                                            initialValue:
                                              _vm.formData.settleAmount / 100
                                          }
                                        ],
                                        expression:
                                          "[\n                                        'settleAmount',\n                                        { initialValue: formData.settleAmount / 100}\n                                    ]"
                                      }
                                    ],
                                    staticStyle: { with: "100px" },
                                    attrs: {
                                      disabled: true,
                                      suffix: "RMB",
                                      placeholder: "大于0且小于等于固定立减金额"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "参与次数" } },
                    [
                      _vm._v(
                        "\n                    活动期间每个卡号可参与\n                    "
                      ),
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "eachCanUse",

                              {
                                rules: [
                                  { required: true, message: "请填写必填项!" }
                                ],
                                initialValue: _vm.formData.eachCanUse
                              }
                            ],
                            expression:
                              "[\n                            'eachCanUse',\n                            \n                            {rules: [{required: true, message: '请填写必填项!'}],initialValue:formData.eachCanUse}\n                        ]"
                          }
                        ],
                        staticStyle: { width: "15%" },
                        attrs: { disabled: true, placeholder: "-" }
                      }),
                      _vm._v(" 次活动\n                ")
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "指定银行" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "orgId",
                              { initialValue: _vm.formData.orgId }
                            ],
                            expression:
                              "[\n                            'orgId',\n                            {initialValue:formData.orgId}\n                        ]"
                          }
                        ],
                        staticStyle: { width: "95%" },
                        attrs: {
                          disabled: true,
                          placeholder: "不能为空/不超过255个字"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "指定APP" } },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["app", { initialValue: _vm.formData.app }],
                            expression:
                              "[\n                            'app',\n                            { initialValue:formData.app }\n                        ]"
                          }
                        ],
                        staticStyle: { width: "95%", "text-align": "left" },
                        attrs: {
                          disabled: true,
                          placeholder:
                            "请输入指定APP的ISSCODE，最短4位，最长12位，英文逗号分隔，最多可输入5个",
                          autosize: { minRows: 6, maxRows: 6 }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "指定卡bin" } },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "cardBin",
                              {
                                rules: [
                                  { required: false, message: "请填写必填项!" },
                                  {
                                    pattern: /^.{0,5000}$/,
                                    message:
                                      "卡bin为银行卡前几位数字，最短6位，最长9位，英文逗号分隔，最多5000字符长度"
                                  }
                                ],
                                initialValue: _vm.formData.cardBin
                              }
                            ],
                            expression:
                              "[\n                            'cardBin',\n                            {rules: [{required: false, message: '请填写必填项!'},{pattern: /^.{0,5000}$/, message: '卡bin为银行卡前几位数字，最短6位，最长9位，英文逗号分隔，最多5000字符长度'}],initialValue:formData.cardBin}\n                        ]"
                          }
                        ],
                        staticStyle: { width: "95%" },
                        attrs: {
                          disabled: true,
                          placeholder:
                            "卡bin为银行卡前几位数字，最短6位，最长9位，英文逗号分隔，最多5000字符长度",
                          autosize: { minRows: 6, maxRows: 6 }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "商户规则" } },
            [
              _c("a-form-item", { attrs: { label: "活动店铺" } }),
              _vm.formData.merchantList.length > 0
                ? _c(
                    "a-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("a-table", {
                        attrs: {
                          columns: _vm.columns,
                          dataSource: _vm.formData.merchantList,
                          size: "small"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }