







































































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import AdAssign from '@/components/custom-form-components/AdAssign.vue';
import AdConflictModal from '@/components/AdConflictModal.vue';
import AdAssignProgress from '@/components/AdAssignProgress.vue';
import RichEditorDrawer from '@/components/RichEditorDrawer.vue';
import * as validators from '../validators';
import {
    SchedulePicker,
    AdPositionSelector,
    AdMaterialTplSelector,
    AdMaterialList,
    ImageTextLink,
    MiniApp
} from '@/components/custom-form-components';
const tplClassMap: any = {
    IMAGE_TEXT_LINK: ImageTextLink,
    MINI_APP: MiniApp
};
const tplClassMaxNumMap: any = {
    IMAGE_TEXT_LINK: 2,
    MINI_APP: 1
};
interface CreateForm {
    form: any;
}

@Component<CreateForm>({
    components: {
        SchedulePicker,
        AdPositionSelector,
        AdMaterialTplSelector,
        AdMaterialList,
        AdAssign,
        AdConflictModal,
        AdAssignProgress,
        RichEditorDrawer
    },
    computed: {
        ...mapState({
            formDisabled: (state: any) =>
                state.adCreatePage.loading || state.adCreatePage.id !== null
        })
    }
})
class CreateForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;

    public form;

    public isDefault: boolean = false;

    public conflictReplaceList: any;

    public merchantTotal = 0;

    public validators = validators;

    public disabledPositions: string[] = ['C_HOME_BOTTOM'];

    public created() {
        this.$store.commit('adCreatePage/init');
        this.form = this.$form.createForm(this, {
            onFieldsChange: (props, fields) => {
                if (fields.materialTpl) {
                    this.handleMaterialTplTypeChange();
                }

                if (fields.isDefault) {
                    this.handleIsDefaultChange();
                }
            }
        });
    }

    public handleMaterialTplTypeChange() {
        const { getFieldValue, setFieldsValue } = this.form;
        const tplType = getFieldValue('materialTpl');
        this.tplClass = tplClassMap[tplType];
        this.tplClassMaxNumber = tplClassMaxNumMap[tplType];
    }

    public handleIsDefaultChange() {
        const { getFieldValue, setFieldsValue } = this.form;
        const isDefault = getFieldValue('isDefault');
        this.isDefault = isDefault;
        if (!isDefault) {
            this.disabledPositions = ['C_HOME_BOTTOM'];
            if (getFieldValue('position') === 'C_HOME_BOTTOM') {
                setFieldsValue({
                    position: undefined
                });
            }
        } else {
            this.disabledPositions = [];
        }
    }

    public handleCreate(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            console.log('values:', values)
            if (!error) {
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => this.handleSubmitConfirm(values)
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public handleSubmitConfirm(values: any) {
        
        const params = this.formatParams(values);
        const { assign, ...createParams } = params;
        if (!values.isDefault) {
            (this.$refs.conflict as AdConflictModal).count(
                {
                    position: params.position,
                    start_time: params.schedule.start_time,
                    end_time: params.schedule.end_time,
                    ...assign
                },
                (conflictReplaceList: any, merchantTotal: number) => {
                    if (merchantTotal === 0) {
                        return Modal.error({
                            title: '分配错误',
                            content: '所选分配未匹配到任何商户，请重新选择。'
                        });
                    }
                    this.conflictReplaceList = conflictReplaceList;
                    this.merchantTotal = merchantTotal;
                    this.$store.dispatch('adCreatePage/create', createParams);
                }
            );
        } else {
            this.$store.dispatch('adCreatePage/create', createParams);
        }
    }

    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters[
                    'advertisementSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/advertisement/search' + queryString
                });
            }
        });
    }

    @Watch('$store.state.adCreatePage.id')
    public watchCreateSuccess(id: string) {
        if (id && !this.form.getFieldValue('isDefault')) {
            (this.$refs.assign as AdAssignProgress).assign(
                id,
                this.form.getFieldValue('assign'),
                this.conflictReplaceList,
                this.merchantTotal
            );
        } else if (id) {
            this.$message.success('创建成功');
            const queryString = this.$store.getters[
                'advertisementSearchPage/queryString'
            ]();
            this.$router.push({
                path: '/console/advertisement/search' + queryString
            });
        }
    }

    private formatParams(formData: any) {
        return {
            title: formData.title,
            is_default: formData.isDefault,
            position: formData.position,
            materials: formData.material_content_terminal_list.map(
                (item: any) => ({
                    content: item.content,
                    tpl: formData.materialTpl
                })
            ),
            materials_terminals: formData.material_content_terminal_list.map(
                (item: any) => item.terminals
            ),
            schedule: {
                start_time: formData.schedule.range[0].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                end_time: formData.schedule.range[1].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                monday: formData.schedule.days.indexOf('monday') >= 0,
                tuesday: formData.schedule.days.indexOf('tuesday') >= 0,
                wednesday: formData.schedule.days.indexOf('wednesday') >= 0,
                thursday: formData.schedule.days.indexOf('thursday') >= 0,
                friday: formData.schedule.days.indexOf('friday') >= 0,
                saturday: formData.schedule.days.indexOf('saturday') >= 0,
                sunday: formData.schedule.days.indexOf('sunday') >= 0
            },
            assign: formData.assign
        };
    }
}

export default CreateForm;
