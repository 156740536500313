








import EditForm from './EditForm.vue';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';

@Component({
    components: {
        EditForm
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.shopEditPage.loading
        })
    }
})
export default class EditPage extends Vue {
    public created() {
        this.$store.dispatch('shopEditPage/initData', this.$route.params.id);
    }

    @Watch('$store.state.shopEditPage.lastSaveTime')
    public lastSaveTimeListener(lastSaveTime: string) {
        this.$message.success('提交成功!');
        setTimeout(() => {
            const queryString = this.$store.getters['shopSearchPage/queryString']();
            this.$router.push({
                path: '/console/shop/search' + queryString
            });
        }, 500);
    }

    @Watch('$store.state.shopEditPage.error')
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }
}
