






import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Forbidden extends Vue {
    public handleClick() {
        this.$router.go(-1);
    }
}
