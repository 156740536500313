var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "HexiaoUpload",
        [
          _c(
            "template",
            { slot: "trigger" },
            [
              _c("a-button", { attrs: { disabled: _vm.loading } }, [
                _vm._v("上传")
              ])
            ],
            1
          )
        ],
        2
      ),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.UNION_HEXIAO.DOWNLOAD_EXCEL] },
        on: { pass: _vm.handleDownload },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(slotProps) {
              return [
                _c(
                  "a-button",
                  { attrs: { disabled: _vm.loading || _vm.downloading } },
                  [_vm._v("导出excel")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }