


























import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
import Actions from './Actions.vue';
import FieldsFilter from '@/components/FieldsFilter.vue';
import SearchForm from './SearchForm.vue';
import SearchTable from './SearchTable.vue';
import { Component, Prop, Watch, Provide, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import columns from './columns';
import * as Constant from '@/common/constant';
import store from './newstore';
import { queryToHumanReadable } from '@/utils';
import preferences from './preferences';
const humanReadableQuerySchema = {
    name: '名称',
    status: '状态',
    activityType: '活动类型',
    createName: '创建人'
};
@Component({
    components: {
        Actions,
        SearchForm,
        SearchTable,
        SearchCommonLayout,
        FieldsFilter
    },
    computed: {
        ...mapState({
            fields: (state: any) => state.activitySignUpPage.fields,
            sortArr: (state: any) => state.activitySignUpPage.sortFields,
            humanReadableQuery(state: any) {
                return queryToHumanReadable(
                    state.activitySignUpPage.query,
                    humanReadableQuerySchema
                );
            },

        })
    }
})
 export default class DraftPage extends Vue {
    @Prop()
    public active!: boolean;

    public storeName = 'activitySignUpPage';

     
    public data() {
        return {
            columns
        };
    }

    @Provide()
    public state() {
        return this.$store.state[this.storeName];
    }

    @Provide()
    public getters(name: string) {
        return this.$store.getters[this.storeName + '/' + name];
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(this.storeName + '/' + action, payload);
    }

    @Provide()
    public commit(mutation: string, payload?: any) {
        this.$store.commit(this.storeName + '/' + mutation, payload);
    }

    @Provide()
    public routerPush() {
        this.$router.push({
            path:
                '/console/config/search' + this.getters('queryString')()
        });
    }

    public created() {
        this.$store.dispatch('activitySignUpPage/queryDistrictCode');
        this.$store.dispatch('activitySignUpPage/initQuery', {
                ...this.$route.query
            });
    }

    public registerStore() {
        if (!this.state()) {
            this.$store.registerModule([this.storeName], store);
            this.$watch(function() {
                return this.$store.state[this.storeName].error;
            }, this.errorListener);
        }
    }
    @Watch('$store.state.activitySignUpPage.updatedUrlSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.dispatch('search');
        }
    }
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    private handleFieldChange(e: any) {
        this.$store.commit('activitySignUpPage/setFields', e);
    }
    private handleFieldSort(e: any) {
        preferences.setSortFields(e);
        this.$store.commit('activitySignUpPage/sortFields', e);
    }
}
