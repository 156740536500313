






























import {mapState} from 'vuex';
import {Component, Inject, Vue} from 'vue-property-decorator';
import columns from './columns';
import {calcTableScrollX} from '@/utils';

interface SearchTable {
    selectedRowKeys: any[];
}

@Component({
    components: {},
    computed: {
        rowSelection() {
            const self: any = this;
            const {selectedRowKeys} = self;
            return {
                selectedRowKeys,
                onChange: self.onSelectChange,
                hideDefaultSelections: true
            };
        },
        ...mapState({
            lists: (state: any) =>
                state.otherSearchPage.list.map((order: any, idx: any) => ({
                    idx: (state.otherSearchPage.page - 1) * 10 + idx + 1,
                    ...order
                })),
            total: (state: any) => state.otherSearchPage.total,
            pageSize: (state: any) => state.otherSearchPage.pageSize,
            page: (state: any) => state.otherSearchPage.page,
            loading: (state: any) => state.otherSearchPage.loading
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public privileges!: any;

    @Inject()
    public commit!: any;

    @Inject()
    public routerPush!: any;

    public fixed = 0;
    public bottomHeight = 57;
    public thHeight = 46;
    public contentEl: any = null;

    public mounted() {
        setTimeout(() => {
            this.contentEl = document.getElementById('console-content');
            this.fixed = this.contentEl.offsetHeight - this.thHeight - this.bottomHeight;
            window.addEventListener('resize', this.handleWindowResize);
        });
    }

    public handleWindowResize() {
        this.contentEl = document.getElementById('console-content');
        this.fixed = this.contentEl.offsetHeight - this.thHeight - this.bottomHeight;
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    public data() {
        return {
            selectedRowKeys: [],
            columns,
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.selectedRowKeys = selectedRowKeys;
    }

    public handlePageChange(page: number) {
        this.commit('setPagination', {page});
        this.routerPush();
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.commit('setPagination', {pageSize, page: 1});
        this.routerPush();
    }
}

export default SearchTable;
