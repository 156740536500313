



















import ChangeForm from './ChangeForm.vue';
import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';    

@Component({
    components: {
        Show,
        ChangeForm
    }
})
export default class CreatePage extends Vue {
    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public title!: string | number;
    @Prop()
    public detail !: any;
    @Prop()
    public paytype !: string;
    public created() {
    }

    @Watch('detail')
    public initQueryData(error: any) {
        sessionStorage.removeItem('sht_openId');
        sessionStorage.removeItem('sht_shopNo');
        sessionStorage.removeItem('sht_remark');
        sessionStorage.removeItem('sht_officialAccount');
        sessionStorage.removeItem('sht_tradeNotify');
        sessionStorage.removeItem('sht_billNotify');
        if(this.detail == null){
            return; 
        }
        sessionStorage.setItem('sht_openId', this.detail.openId);
        sessionStorage.setItem('sht_shopNo', this.detail.shopNo);
        sessionStorage.setItem('sht_remark', this.detail.remark);
        sessionStorage.setItem('sht_officialAccount', this.detail.officialAccount);
        sessionStorage.setItem('sht_tradeNotify', this.detail.tradeNotify);
        sessionStorage.setItem('sht_billNotify', this.detail.billNotify);
    }

    

    @Watch('$store.state.shtSearchPage.error') 
    public errorCreateListener(error: any) {
       
        return error && !error.silent && this.$message.error(error.message);
    }

    public onClose() {
        this.$emit('close');
    }
}
