















import {Component, Prop, Inject,Provide, Watch, Vue} from 'vue-property-decorator';
import {Click} from '@/components/Auth';
import ManageForm from './ManageForm.vue';
import PRIVILEGE from '@/common/privileges';
import store from './store';
import {getValueByPath} from '@/utils';

interface SNModal {
    visible: boolean;
}

@Component({
    components: {
        Click,
        ManageForm
    }
})
class SNModal extends Vue {
    @Prop()
    public activityId!: string;
    @Prop()
    public linkList!:any;

    public visible = false;

    public cacheStoreId!: any;
    public linkListData:any;
    public lastLinkList:any;
    @Inject()
    public dispatch!: any;
    public PRIVILEGE = PRIVILEGE;
    public data() {
        return {
            linkListData: [],
        };
    }
    @Watch('activityId')
    public initQueryData() {
        this.linkListData=[];
        for(let i=0;i<this.linkList.length;i++){
            let data = this.linkList[i];
            data = {
              ...data,
              key:i
            }
            this.linkListData.push(data);
            console.log("data:"+data.key);
         }
        console.log("linkList:"+this.linkListData);
        //this.linkListData = this.linkList;
    }
    public changeLinkListData(data: any){
        console.log('changeLinkListData')
        this.lastLinkList = data;
    }
   
    public errorListener(error: any) {
        if (error) {
            this.$message.error(error.message);
        }
    }
    public handleClose() {
        this.initQueryData();
        this.visible = false;
    }

    public handleOK() {
        console.log('ok');
        const list = [];
        if(!this.lastLinkList){
            this.$message.error('没做任何改动');
            this.visible = false;
            return;
        }
        for (var i = 0; i < this.lastLinkList.length; i++) {
            const link = this.lastLinkList[i];
            console.log(link.url+","+link.channel);
            if(link.url && link.channel){
                //判重复
                for (var j = 0; j < list.length; j++) {
                    if(link.channel == list[j].channel){
                        this.$message.error('入口通道不能重复,填写失败');
                        return;
                    }
                }
                link = {
                    ...link,
                    activityId:this.activityId
                }
                list.push(link);
            }else{
                this.$message.error('请填写完整,填写失败');
                return;
            }
        }
        let formData = {
            linkDTO:list,
            activityId:this.activityId

        }
        this.$store.dispatch('activitySignUpPage/createUrl',formData);
        this.visible = false;
    }

    // @Watch('$store.state.activitySignUpPage.updatedSuccess')
    // public updatedSuccess(newVal: boolean, oldVal: boolean) {
    //     if (newVal) {
    //         this.$message.success('提交成功');
    //         const queryString = this.$store.getters[
    //             'activitySignUpPage/queryString'
    //         ]();
    //         this.$router.push({
    //             path: '/console/config/search' + queryString,
    //         });
    //     }
    // }

    public onClick() {
        this.initQueryData();
        this.visible = true;
    }
}

export default SNModal;
