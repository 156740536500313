































































import {Component, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import {deleteEmpty} from '@/utils';
import qs from 'querystringify';
import formRules from '@/common/formRules';

interface SearchForm {
    form: any;
}

export interface Query {
    shopNo: string;
    status: number;
}

export interface FormData {
    shopNo: string;
    status: number;
}

@Component({
    components: {
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.shtSearchPage.query);
            },
            loading: (state: any) => state.shtSearchPage.loading
        })
    }
})
class SearchForm extends Vue {

    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this),
            formRules
        };
    }
   
    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/shtManage/search?${qs.stringify(query)}`
            });

        });
    }

    public handleReset() {
        // this.form.resetFields();
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
        this.form.getFieldDecorator('status', { initialValue:'1', preserve: true });
    }

    private formatQuery(values: FormData): Query {
        return {
            shopNo: values.shopNo,
            status:values.status
        };
    }

    private formatFormData(query: Query): FormData {
         return {
            shopNo: query.shopNo,
            status:query.status
        }
    }
}
export default SearchForm;
