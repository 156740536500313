
































import {mapState} from 'vuex';
import {Component, Watch, Inject, Vue} from 'vue-property-decorator';
import {check, Show, Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import {Modal} from 'ant-design-vue';
import config from '@/config';
import GrayUpload from './GrayUpload.vue';

interface Actions {
    selectedRows: any;
    hideMessage: any;
}

@Component({
    components: {
        Click,
        GrayUpload
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.graySearchPage.loading,
            downloading: (state: any) => state.graySearchPage.downloading,
            selectedRows: (state: any) => state.graySearchPage.selectedRows,
            token: (state: any) => {
                return state.login.session && state.login.session.token;
            },
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    public merchants: string[] = [];

    public data() {
        return {
            hideMessage: null,
            PRIVILEGE,
            config,
            merchants:[],
        };
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }

    public handleRefresh() {
        this.$router.push({
            path: '/console/gray/search' + this.$store.getters['graySearchPage/queryString']()
        });
    }
    public handleOpenClick() {
        Modal.confirm({
            title: '开启',
            content: '是否开启商终?',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRows,
                    status: true
                });
            }
        });
    } 
    public handleCloseClick() {
        
        Modal.confirm({
            title: '关闭',
            content: '是否关闭商终?',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRows,
                    status: false
                });
            }
        });
    }

    public handleDeleteClick() {
        this.merchants=[];
        Modal.confirm({
            title: '删除',
            content: '是否删除商终?',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.selectedRows.forEach((item:any) => {
                    this.$store.state.graySearchPage.list.forEach((obj:any) => {
                        if(item === obj.id){
                            this.merchants.push(`${obj.merchantNo}:${obj.terminalId}`) 
                        }
                    })
                })
                this.dispatch('deleteMerTerm', {
                    merchants: this.merchants,
                });
            }
        });
    }
    
}

export default Actions;
