var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-select", {
        attrs: {
          options: _vm.options,
          placeholder: "请选择",
          value: _vm.options.length === 0 ? null : _vm.value,
          getPopupContainer: function(trigger) {
            return trigger.parentNode
          },
          allowClear: ""
        },
        on: { change: _vm.handleChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }