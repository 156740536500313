








































import {Modal} from "ant-design-vue";
import {mapState} from "vuex";
import {Component, Inject, Vue} from "vue-property-decorator";
import columns from "./columns";
import * as services from "./services";
import {check} from "@/components/Auth";
import PRIVILEGE from "@/common/privileges";
import {calcTableScrollX, getTop} from "@/utils";
import ClearText from "@/components/ClearText.vue";

@Component({
    components: {
        ClearText
    },
    computed: {
        ...mapState({
            lists: (state: any) =>
                state.blacklistSearchPage.list.map((order: any, idx: any) => ({
                    idx: (state.blacklistSearchPage.page - 1) * 10 + idx + 1,
                    ...order
                })),
            total: (state: any) => state.blacklistSearchPage.total,
            pageSize: (state: any) => state.blacklistSearchPage.pageSize,
            page: (state: any) => state.blacklistSearchPage.page,
            loading: (state: any) => state.blacklistSearchPage.loading,
            rowSelection(state: any) {
                const self: any = this;
                const selectedRowKeys: any[] = [];
                state.blacklistSearchPage.selectedRows.forEach((item: any) => selectedRowKeys.push(item.id));
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            }
        })
        
    }
})
class SearchTable extends Vue {
    @Inject()
    public state!: any;

    @Inject()
    public dispatch!: any;

    @Inject()
    public privileges!: any;

    @Inject()
    public commit!: any;

    @Inject()
    public routerPush!: any;

    public fixed = 0;
    public PRIVILEGE = PRIVILEGE;

    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + (-top) + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            columns,
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public onSelectChange(selectedRowKeys: any[], selectedRows: any[]) {
        this.commit("setSelectedRows", selectedRows);
    }

    public handlePageChange(page: number) {
        this.commit("setPagination", {page});
        this.routerPush();
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.commit("setPagination", {pageSize, page: 1});
        this.routerPush();
    }
}
export default SearchTable;
