
































































































import {Component, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import {deleteEmpty} from '@/utils';
import qs from 'querystringify';
import moment from 'moment';
import formRules from '@/common/formRules';
import {DatePicker} from '@/components/custom-form-components';

interface SearchForm {
    form: any;
}
@Component({
    components: {
        DatePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.sharedQuotaConfSearchPage.query);
            },
            loading: (state: any) => state.sharedQuotaConfSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this),
            formRules
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/sharedQuotaConf/search?${qs.stringify(query)}`
            });

        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any){
        return {
            merGroupId: values.merGroupId,
            merGroupName: values.merGroupName,
            activityId: values.activityId?values.activityId.trim():'',
            status: values.status,
            ruleId:values.ruleId?values.ruleId.trim():'',
            templateId:values.templateId?values.templateId.trim():'',
            startTime: 
                values.ruleTime &&
                values.ruleTime[0] &&
                values.ruleTime[0].format('YYYY-MM-DD HH:mm:ss'),
            endTime:
                values.ruleTime &&
                values.ruleTime[1] &&
                values.ruleTime[1].format('YYYY-MM-DD HH:mm:ss'),
        };
    }

    private formatFormData(query: any) {
        return {
            ...query,
            ruleTime: [
                query.startTime && moment(query.startTime),
                query.endTime && moment(query.endTime)
            ]
        };
    }
}

export default SearchForm;
