



















import EditActForm from './EditActForm.vue';
import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Show,
       EditActForm
    }
})
export default class CreatePage extends Vue {
    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public title!: string | number;
    @Prop()
    public activityId !: string;
    public created() {
    }

    @Watch('activityId')
    public initQueryData(error: any) {
        if(this.activityId == null){
            return; 
        }
        this.$store.dispatch('activitySignUpPage/initData', this.activityId);
    }

    @Watch('$store.state.activitySignUpPage.error')
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    public onClose() {
        this.$emit('close');
    }
}
