var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        loading: _vm.loading,
        "body-style": { padding: "2px" },
        bordered: false
      }
    },
    [
      _c("div", { staticClass: "chart-card-header" }, [
        _c("div", { staticClass: "meta" }, [
          _c(
            "span",
            { staticClass: "chart-card-title" },
            [
              _vm._t("title", [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
              ])
            ],
            2
          )
        ]),
        _c(
          "div",
          { staticClass: "total" },
          [
            _vm._t("total", [
              _c(
                "a-tooltip",
                {
                  attrs: {
                    title:
                      (typeof _vm.total === "function" && _vm.total()) ||
                      _vm.total
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (typeof _vm.total === "function" && _vm.total()) ||
                          _vm.total
                      )
                    )
                  ])
                ]
              )
            ])
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }