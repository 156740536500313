var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Show", {
    attrs: {
      requires: [
        {
          $or: [
            _vm.PRIVILEGE.HEXIAO.CONFIGURE,
            _vm.PRIVILEGE.UNION_HEXIAO.CONFIGURE
          ]
        }
      ]
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function(slotProps) {
          return [_c("Index")]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }