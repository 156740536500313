var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning } },
        [
          _c(
            "a-tabs",
            {
              attrs: { activeKey: _vm.activeTab, type: "card" },
              on: { change: _vm.tabChange }
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "基础规则" } },
                [
                  _vm.formData
                    ? _c(
                        "a-form",
                        {
                          staticClass: "ad-create-form",
                          attrs: { form: _vm.baseForm }
                        },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "活动名称" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "name",
                                      {
                                        initialValue: _vm.formData.name
                                      }
                                    ],
                                    expression:
                                      "[\n                                'name',\n                                {\n                                    initialValue: formData.name\n                                }\n                            ]"
                                  }
                                ],
                                attrs: { readonly: true, placeholder: "" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "主题活动ID" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "budgetActivity",
                                      {
                                        initialValue:
                                          _vm.formData.budgetActivity,
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写必填项"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                                'budgetActivity',\n                                {\n                                    initialValue: formData.budgetActivity,\n                                    rules: [\n                                        {required: true, message: '请填写必填项'}\n                                    ]\n                                }\n                            ]"
                                  }
                                ],
                                attrs: { placeholder: "请输入主题活动ID" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "机构号" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "orgId",
                                      {
                                        initialValue: _vm.formData.orgId
                                      }
                                    ],
                                    expression:
                                      "[\n                                'orgId',\n                                {\n                                    initialValue: formData.orgId\n                                }\n                            ]"
                                  }
                                ],
                                attrs: {
                                  readonly: true,
                                  placeholder: "请输入机构号"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "银联活动号" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "stockId",
                                      {
                                        initialValue: _vm.formData.stockId
                                      }
                                    ],
                                    expression:
                                      "[\n                                'stockId',\n                                {\n                                    initialValue: formData.stockId\n                                }\n                            ]"
                                  }
                                ],
                                attrs: { readonly: true, placeholder: "" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "面额" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "promoAmount",
                                      {
                                        initialValue: (
                                          _vm.formData.promoAmount / 100
                                        ).toFixed(2)
                                      }
                                    ],
                                    expression:
                                      "[\n                                'promoAmount',\n                                {\n                                    initialValue: (formData.promoAmount / 100).toFixed(2)\n                                }\n                            ]"
                                  }
                                ],
                                attrs: { readonly: true, placeholder: "" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "使用门槛" } },
                            [
                              _vm._v(
                                "\n                        消费满 \n                        "
                              ),
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "orderMinAmt",
                                      {
                                        initialValue: (
                                          _vm.formData.orderMinAmt / 100
                                        ).toFixed(2)
                                      }
                                    ],
                                    expression:
                                      "[\n                                'orderMinAmt',\n                                {\n                                    initialValue: (formData.orderMinAmt / 100).toFixed(2)\n                                }\n                            ]"
                                  }
                                ],
                                staticStyle: { width: "70%" },
                                attrs: { readonly: true, placeholder: "" }
                              }),
                              _vm._v(
                                "\n                         元可使用\n                    "
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "活动有效期" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "startTime",
                                      {
                                        initialValue:
                                          _vm.formData.validStartTime +
                                          "—" +
                                          _vm.formData.validEndTime
                                      }
                                    ],
                                    expression:
                                      "[\n                                'startTime',\n                                {\n                                initialValue: formData.validStartTime +'—'+formData.validEndTime\n                                }\n                            ]"
                                  }
                                ],
                                attrs: { readonly: true, placeholder: "" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "券状态" } },
                            [
                              _vm._v(
                                "\n                            \n                        "
                              ),
                              _c(
                                "a-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "status",
                                        {
                                          initialValue: _vm.formData.status
                                        }
                                      ],
                                      expression:
                                        "['status',\n                            {\n                                initialValue: formData.status\n                            }]"
                                    }
                                  ],
                                  attrs: { disabled: true }
                                },
                                [
                                  _c("a-radio", { attrs: { value: "1" } }, [
                                    _vm._v(
                                      "\n                                生效\n                            "
                                    )
                                  ]),
                                  _c("a-radio", { attrs: { value: "2" } }, [
                                    _vm._v(
                                      "\n                                失效\n                            "
                                    )
                                  ]),
                                  _c("a-radio", { attrs: { value: "3" } }, [
                                    _vm._v(
                                      "\n                                已删除\n                            "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "是否结算" } },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "settleFlag",
                                        {
                                          initialValue: _vm.formData.settleFlag
                                        }
                                      ],
                                      expression:
                                        "['settleFlag', {initialValue: formData.settleFlag}]"
                                    }
                                  ],
                                  attrs: { disabled: true }
                                },
                                [
                                  _c(
                                    "a-radio",
                                    { attrs: { value: "1", checked: true } },
                                    [_vm._v("是")]
                                  ),
                                  _c(
                                    "a-radio",
                                    { attrs: { value: "0", checked: true } },
                                    [_vm._v("否")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.formData.settleFlag == "1",
                                  expression: "formData.settleFlag == '1'"
                                }
                              ],
                              attrs: { label: "结算比：" }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "settleRate",
                                      { initialValue: _vm.formData.settleRate }
                                    ],
                                    expression:
                                      "[\n                                'settleRate',\n                                {initialValue: formData.settleRate}\n                            ]"
                                  }
                                ],
                                staticStyle: { width: "60%" },
                                attrs: { type: "number", readonly: true }
                              }),
                              _vm._v(
                                " %\n                        \n                    "
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.formData.settleFlag == "1",
                                  expression: "formData.settleFlag == '1'"
                                }
                              ],
                              attrs: { label: "结算金额：" }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "settleAmount	",
                                      {
                                        initialValue:
                                          _vm.formData.settleAmount / 100
                                      }
                                    ],
                                    expression:
                                      "[\n                                'settleAmount\t',\n                                {initialValue: formData.settleAmount/100}\n                            ]"
                                  }
                                ],
                                staticStyle: { width: "60%" },
                                attrs: { type: "number", readonly: true }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "使用说明" } },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "remark",
                                      {
                                        initialValue: _vm.formData.remark
                                      }
                                    ],
                                    expression:
                                      "[\n                                'remark',\n                                {\n                                    initialValue: formData.remark\n                                }\n                            ]"
                                  }
                                ],
                                attrs: { readonly: true, placeholder: "" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { attrs: { label: "备注" } },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "description",
                                      {
                                        initialValue: _vm.formData.description
                                      }
                                    ],
                                    expression:
                                      "[\n                                'description',\n                                {\n                                    initialValue: formData.description\n                                }\n                            ]"
                                  }
                                ],
                                attrs: { readonly: true, placeholder: "" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "其他规则" } },
                [
                  _vm.formData
                    ? _c(
                        "a-form",
                        {
                          staticClass: "ad-create-form",
                          attrs: { form: _vm.baseForm }
                        },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "上传类型" } },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "listType",
                                        { initialValue: _vm.formData.listType }
                                      ],
                                      expression:
                                        "['listType', {initialValue: formData.listType}]"
                                    }
                                  ],
                                  attrs: { disabled: true }
                                },
                                [
                                  _c(
                                    "a-radio",
                                    {
                                      attrs: {
                                        value: "merIdAndTermId",
                                        checked: true
                                      }
                                    },
                                    [_vm._v("商户号和终端号")]
                                  ),
                                  _c(
                                    "a-radio",
                                    {
                                      attrs: { value: "merId", checked: true }
                                    },
                                    [_vm._v("商户号")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("a-form-item", { attrs: { label: "活动店铺" } }),
                          _vm.formData.merchantList.length > 0
                            ? _c(
                                "a-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c("a-table", {
                                    attrs: {
                                      columns:
                                        _vm.formData.listType == "merId"
                                          ? _vm.noTermNocolumns
                                          : _vm.hasTermNocolumns,
                                      dataSource: _vm.formData.merchantList,
                                      size: "small"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("div", [_vm._v("暂无数据")])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }