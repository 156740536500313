




















































































































































































































































































































































































































































































































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {
    DisableDatePicker,
    ScheduleTimePicker,
    IrregularScheduleTimePicker,
} from '@/components/custom-form-components';
import CreateModal from './CreateModal.vue';
import ShopUpload from './ShopUpload.vue';
import * as validators from '../../validators';
import { findRepeatData, isRepeatArr } from '@/utils';

interface CreateForm {
    baseForm: any;
    discountForm: any;
    merchantForm: any;
}

@Component<CreateForm>({
    components: {
        DisableDatePicker,
        CreateModal,
        ShopUpload,
        ScheduleTimePicker,
        IrregularScheduleTimePicker,
    },
    computed: {
        ...mapState({}),
    },
})
class CreateForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    public validators = validators;
    //页面tab
    public baseForm;
    public discountForm;
    public merchantForm;
    public activeTab: any;
    public isSettleFlag: any;
    public settleType: any;
    public baseData: any;
    public shopData: any;
    public activitySponsors: any;
    public promoType: any;
    public refundedStatus: any;
    public showSettleRate: boolean = true;
    public showSettleAmount: boolean = true;

    public data() {
        return {
            showSettleRate: true,
            showSettleAmount: true,
            settleType: 'settleRate',
            isSettleFlag: '1',
            activeTab: '1',
            baseData: {},
            //第一页数据
            baseForm: this.$form.createForm(this),
            activitySponsors: [],
            //第二页数据
            discountForm: this.$form.createForm(this),
            promoType: 'voucher',
            refundedStatus: '2',

            //第三页数据
            merchantForm: this.$form.createForm(this),
            shopData: [],
            columns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo',
                },
                {
                    title: '终端号',
                    dataIndex: 'termNo',
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action',
                    },
                },
            ],
        };
    }
    public showSettleFlag(data: any) {
        this.isSettleFlag = data.target.value;
        this.discountForm.setFieldsValue({
            settleRate: undefined,
            settleAmount: undefined,
        });
    }
    public selectSettleType(data: any) {
        this.settleType = data.target.value;
        this.discountForm.setFieldsValue({
            settleRate: undefined,
            settleAmount: undefined,
        });
    }
    public promoAmountChange(e: any) {
        console.log('promoAmountChange', e.target.value);
    }
    public tabChange(key: any) {
        if (key === '2') {
            this.handleNextAction();
        } else if (key === '3') {
            this.handleNextAction2();
        } else {
            console.info('activeTab', key);
            this.activeTab = '1';
        }
    }

    public handleNext(e: any) {
        e.preventDefault();
        this.handleNextAction();
    }

    public handleNext2(e: any) {
        e.preventDefault();
        this.handleNextAction2();
    }
    public handleNext3(e: any) {
        e.preventDefault();
        this.handleNextAction3();
    }

    public handleNextAction() {
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                this.formatBaseForm(values, '1');
                this.activeTab = '2';
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    public handleNextAction2() {
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                this.formatBaseForm(values, '1');
                this.discountForm.validateFields((error: any, values: any) => {
                    if (!error) {
                        const orderMinAmt = values.orderMinAmt;
                        if (orderMinAmt) {
                            var reg = /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/;
                            if (reg.test(orderMinAmt) == false) {
                                this.$message.error(
                                    '订单最小金额只能填数字且不能为0'
                                );
                                return false;
                            }
                            if (this.promoType == 'voucher' && orderMinAmt * 100 <= values.promoAmount * 100 ) {
                                this.$message.error(
                                    '订单最小金额必须大于立减金额'
                                );
                                return false;
                            }
                            if (this.promoType == 'randomReduce'&& orderMinAmt * 100 <= values.rangeMinAmt * 100 ) {
                                this.$message.error(
                                    '订单最小金额必须大于随机立减 金额下限'
                                );
                                return false;
                            }
                        }

                        const rangeMaxAmt = values.rangeMaxAmt;
                        const rangeMinAmt = values.rangeMinAmt;
                        if (
                            rangeMaxAmt &&
                            rangeMinAmt &&
                            rangeMaxAmt * 100 < rangeMinAmt * 100
                        ) {
                            this.$message.error('随机立减 上限金额需大于下限金额');
                            return false;
                        }

                        if (values.ratio && values.ratio > 100) {
                            this.$message.error('折扣率不能大于100');
                            return false;
                        }

                        if (values.settleFlag === '1') {
                            if (
                                this.settleType === 'settleRate' &&
                                !values.settleRate
                            ) {
                                this.$message.error('请输入结算比');
                                return false;
                            }
                            if (
                                this.settleType === 'settleAmount' &&
                                !values.settleAmount
                            ) {
                                this.$message.error('请输入结算金额');
                                return false;
                            }
                            if (
                                values.settleRate &&
                                (!/^[1-9]\d*$/.test(values.settleRate) ||
                                    values.settleRate > 100)
                            ) {
                                this.$message.error(
                                    '结算比必须是1到100之间的整数（包含1和100）'
                                );
                                return false;
                            }
                            if (
                                values.settleAmount &&
                                !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(
                                    values.settleAmount
                                )
                            ) {
                                this.$message.error(
                                    '结算金额只能精确到分，不能为0'
                                );
                                return false;
                            }
                            if (
                                parseFloat(values.settleAmount) >
                                parseFloat(values.promoAmount)
                            ) {
                                this.$message.error(
                                    '结算金额不得大于固定立减金额'
                                );
                                return false;
                            }
                        }

                        console.log('values.settleRate', values.settleRate);
                        const eachCanUse = values.eachCanUse;
                        var reg1 = /^[1-9]\d*$/;

                        if (reg1.test(eachCanUse) == false || eachCanUse > 60) {
                            this.$message.error(
                                '优惠次数必须是≥1的整数/不能大于60'
                            );
                            return false;
                        }

                        const appisscodes = values.app;
                        const cardbins = values.cardBin;
                        console.log('cardbins', cardbins);
                        console.log('appisscodes', appisscodes);
                        if (!appisscodes && !cardbins) {
                            this.$message.error('APP和卡bin至少填写其一');
                            return false;
                        }
                        // 指定APP校验
                        if (appisscodes) {
                            let appisscodesArr = appisscodes.split(',');
                            console.log('appisscodesArr', appisscodesArr);
                            if (isRepeatArr(appisscodesArr)) {
                                this.$message.error(
                                    `指定app的ISSCODE "${findRepeatData(
                                        appisscodesArr
                                    )}" 重复，请检查！`
                                );
                                return false;
                            }
                            if (appisscodesArr.length > 5) {
                                this.$message.error(
                                    '指定app的ISSCODE最多支持输入5条！'
                                );
                                return false;
                            }
                            const reg = /^[0-9]*$/;
                            for (let isscode of appisscodesArr) {
                                if (!isscode) {
                                    this.$message.error(
                                        '请删除指定APP最后的英文逗号！'
                                    );
                                    return false;
                                }
                                if (reg.test(isscode) == false) {
                                    this.$message.error(
                                        `${isscode}不符合规则，指定APP的ISSCODE只能为数字，英文逗号分隔`
                                    );
                                    return false;
                                }
                                if (isscode.length < 4 || isscode.length > 12) {
                                    this.$message.error(
                                        `${isscode}不符合长度规则，指定APP的ISSCODE，最短4位，最长12位`
                                    );
                                    return false;
                                }
                            }
                        }
                        // 指定卡bin校验
                        if (cardbins) {
                            let bins = cardbins.split(',');
                            if (isRepeatArr(bins)) {
                                this.$message.error(
                                    `卡bin数据 "${findRepeatData(
                                        bins
                                    )}" 重复，请检查！`
                                );
                                return false;
                            }
                            if (cardbins.length > 5000) {
                                this.$message.error(
                                    '卡bin为银行卡前几位数字，最短6位，最长9位，英文逗号分隔，最多5000字符长度'
                                );
                                return false;
                            }
                            var reg = /^[0-9]*$/;
                            for (let bin of bins) {
                                if (!bin) {
                                    this.$message.error(
                                        '请删除指定卡bin最后的英文逗号！'
                                    );
                                    return false;
                                }
                                if (reg.test(bin) == false) {
                                    this.$message.error(
                                        `${bin}不符合规则，卡bin为银行卡前几位数字，最短6位，最长9位，英文逗号分隔，最多5000字符长度`
                                    );
                                    return false;
                                }
                                if (bin.length < 6 || bin.length > 9) {
                                    this.$message.error(
                                        `${bin}不符合长度规则，卡bin为银行卡前几位数字，最短6位，最长9位`
                                    );
                                    return false;
                                }
                            }
                        }
                        this.formatBaseForm(values, '2');
                        this.activeTab = '3';
                    } else {
                        this.$message.error('请填写优惠的正确信息');
                    }
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    public handleNextAction3() {
        this.merchantForm.validateFields((error: any, values: any) => {
            if (!error) {
                console.info('merchantForm start');
                this.formatBaseForm(values, '3');
                if (null == this.shopData || this.shopData.length < 1) {
                    this.$message.error('请添加活动商户');
                    return;
                }
                console.info('handleSubmitConfirm start');
                this.handleSubmitConfirm(values);
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public changeDiscountType(e: any) {
        this.settleType = 'settleRate';
        this.promoType = e.target.value;
        console.log('this.promoType', this.promoType);
        if (this.promoType === 'voucher') {
            this.showSettleRate = true;
            this.showSettleAmount = true;
        } else {
            this.showSettleRate = true;
            this.showSettleAmount = false;
            this.discountForm.setFieldsValue({
                settleType: 'settleRate',
                settleAmount: undefined,
            });
        }
    }

    public changeShopData(value: any) {
        if (this.shopData.length === 3000) {
            this.$message.error('店铺最多3000家上限');
            return;
        }
        value.forEach((data: any) => {
            let flag = false;
            for (let shop of this.shopData) {
                if (shop.merNo === data[0] && shop.termNo === data[1]) {
                    flag = true;
                    break;
                }
            }
            if (!flag) {
                const objData = {
                    index: this.shopData.length + 1,
                    merNo: data[0],
                    termNo: data[1],
                };
                this.shopData.push(objData);
            }
        });
    }

    public deleteShopData(record: any) {
        this.shopData = (this.shopData as object[]).filter(
            (data: any) => data.index !== record.index
        );
    }

    public handleSubmitConfirm(values: any) {
        Modal.confirm({
            title: '提示',
            content: '是否确认提交?',
            onOk: () => {
                let baseData = this.baseData;

                const formData = { ...baseData };
                console.log(formData);
                this.$store.dispatch('unionb2cSearchPage/create', formData);
            },
        });
    }

    @Watch('$store.state.unionb2cSearchPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$message.success('提交成功');
            const queryString = this.$store.getters[
                'unionb2cSearchPage/queryString'
            ]();
            this.$router.push({
                path: '/console/activity/unionb2c/search' + queryString,
            });
        }
    }

    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters[
                    'unionb2cSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/activity/unionb2c/search' + queryString,
                });
            },
        });
    }

    private formatBaseForm(formData: any, activeTab: any) {
        if (activeTab == '1') {
            this.baseData = {
                name: formData.name,
                budgetActivity: formData.budgetActivity,
                validType: 'ABSOLUTE',
                validStartTime: formData.range_valiable_time[0].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                validEndTime: formData.range_valiable_time[1].format(
                    'YYYY-MM-DD HH:mm:ss'
                ),
                refundedStatus: formData.refundedStatus,
            };
        } else if (activeTab == '2') {
            if (formData.orderMinAmt) {
                this.baseData.orderMinAmt = (
                    formData.orderMinAmt * 100
                ).toFixed(0);
            }
            this.baseData.promoType = formData.promoType;
            if ('voucher' === formData.promoType) {
                this.baseData.promoAmount = (
                    formData.promoAmount * 100
                ).toFixed(0);
            } else if ('discount' === formData.promoType) {
                this.baseData.ratio = formData.ratio;
                this.baseData.ratioMaxAmt = (
                    formData.ratioMaxAmt * 100
                ).toFixed(0);
            } else {
                this.baseData.rangeMinAmt = (
                    formData.rangeMinAmt * 100
                ).toFixed(0);
                this.baseData.rangeMaxAmt = (
                    formData.rangeMaxAmt * 100
                ).toFixed(0);
            }
            this.baseData.settleFlag = formData.settleFlag;
            if (this.baseData.settleFlag === '1') {
                if (formData.settleRate)
                    this.baseData.settleRate = formData.settleRate;
                if (formData.settleAmount)
                    this.baseData.settleAmount = (
                        formData.settleAmount * 100
                    ).toFixed(0);
            } else {
                delete this.baseData['settleRate'];
                delete this.baseData['settleAmount'];
            }

            this.baseData.eachCanUse = formData.eachCanUse;
            this.baseData.orgId = 'lakala';
            this.baseData.app = formData.app;
            this.baseData.cardBin = formData.cardBin;
        } else if (activeTab == '3') {
            this.baseData.merchantList = this.shopData;
        }
    }

    @Watch('$store.state.adCreatePage.id')
    public watchCreateSuccess(id: string) {}
}

export default CreateForm;
