var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm._v("\n    " + _vm._s(_vm.clearText || "-") + "\n    "),
      _c("Click", {
        attrs: { requires: _vm.requires },
        on: { pass: _vm.handleClick },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.clearText
                  ? _c(
                      "a",
                      {
                        staticStyle: { float: "right" },
                        attrs: { disabled: _vm.loading }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.clearText === _vm.text ? "查看" : "隐藏")
                        )
                      ]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }