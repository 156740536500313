



















































































































































import {Modal} from 'ant-design-vue';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import moment from 'moment';
import AdAssign from '@/components/custom-form-components/AdAssign.vue';
import AdConflictModal from '@/components/AdConflictModal.vue';
import AdAssignProgress from '@/components/AdAssignProgress.vue';
import * as validators from '../validators';
import {
    SchedulePicker,
    AdPositionSelector,
    AdMaterialTplSelector,
    AdMaterialList,
    ImageTextLink,
    MiniApp,
} from '@/components/custom-form-components';
const tplClassMap: any = {
    'IMAGE_TEXT_LINK': ImageTextLink,
    'MINI_APP': MiniApp
};
const tplClassMaxNumMap: any = {
    'IMAGE_TEXT_LINK': 2,
    'MINI_APP': 1
};
interface EditForm {
    form: any;
}

@Component<EditForm>({
    components: {
        SchedulePicker,
        AdPositionSelector,
        AdMaterialTplSelector,
        AdMaterialList,
        AdAssign,
        AdConflictModal,
        AdAssignProgress
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return state.adEditPage.data && this.formatFormData(state.adEditPage.data);
            },
            created_time(state: any) {
                return state.adEditPage.data && state.adEditPage.data.created_time;
            },
            updated_time(state: any) {
                return state.adEditPage.data && state.adEditPage.data.updated_time;
            },
            formDisabled: (state: any) => state.adEditPage.loading  || state.adEditPage.updated !== null
        })
    }
})
class EditForm extends Vue {

    public tplClass: any = null;

    public tplClassMaxNumber = 0;

    public form;

    public conflictReplaceList: any;

    public merchantTotal: any;

    public merchantAssignRemoveTotal: any;

    public validators = validators;

    public created() {
        this.form = this.$form.createForm(this, {
            onFieldsChange: (props, fields) => {
                if (fields.materialTpl) {
                    this.handleMaterialTplTypeChange();
                }
            },
        });
    }

    @Watch('$store.state.adEditPage.data')
    public init(data: any) {
        this.tplClass = tplClassMap[data.materials[0].tpl];
        this.tplClassMaxNumber = tplClassMaxNumMap[data.materials[0].tpl];
    }

    public handleMaterialTplTypeChange() {
        const {getFieldValue} = this.form;
        const tplType = getFieldValue('materialTpl');
        this.tplClass = tplClassMap[tplType];
        this.tplClassMaxNumber = tplClassMaxNumMap[tplType];
    }

    public handleSubmit(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => this.handleSubmitConfirm(values)
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public handleSubmitConfirm(values: any) {
        const params = this.formatParams(values);
        const {
            assign,
            ...updateParams
        } = params;
        if (!values.isDefault) {

            (this.$refs.conflict as AdConflictModal).count({
                plan_id: params.id,
                position: params.position,
                start_time: params.schedule.start_time,
                end_time: params.schedule.end_time,
                ...assign
            }, (conflictReplaceList: any, merchantTotal: number) => {
                if (merchantTotal === 0) {
                    return Modal.error({
                        title: '分配错误',
                        content: '所选分配未匹配到任何商户，请重新选择。'
                    });
                }
                this.conflictReplaceList = conflictReplaceList;
                this.merchantTotal = merchantTotal;
                this.$store.dispatch(
                    'adEditPage/update',
                    updateParams
                );
            });
        } else {
            this.$store.dispatch(
                'adEditPage/update',
                updateParams
            );
        }
    }

    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters['advertisementSearchPage/queryString']();
                this.$router.push({
                    path: '/console/advertisement/search' + queryString
                });
            }
        });
    }

    @Watch('$store.state.adEditPage.updated')
    public watchUpdateSuccess() {
        if (!this.form.getFieldValue('isDefault')) {
            (this.$refs.assign as AdAssignProgress).assign(
                this.form.getFieldValue('id'),
                this.form.getFieldValue('assign'),
                this.conflictReplaceList,
                this.merchantTotal
            );
        } else {
            this.$message.success('更新成功');
            const queryString = this.$store.getters['advertisementSearchPage/queryString']();
            this.$router.push({
                path: '/console/advertisement/search' + queryString
            });
        }
    }

    private formatFormData(rawData: any) {
        const {
            start_time,
            end_time,
            ...days
        } = rawData.schedule;

        if (rawData.assign && rawData.assign.malls) {
            rawData.assign.malls = rawData.assign.malls.map((id: any) => '' + id);
        }
        if (rawData.assign && rawData.assign.biz_districts) {
            rawData.assign.biz_districts = rawData.assign.biz_districts.map((id: any) => '' + id);
        }
        return {
            id: rawData.id,
            title: rawData.title,
            isDefault: rawData.is_default,
            schedule: {
                range: [moment(start_time), moment(end_time)],
                days: Object.keys(days).filter((key) => days[key])
            },
            position: rawData.position,
            materialTpl: rawData.materials[0].tpl,
            material_content_terminal_list: rawData.materials.map((item: any, idx: number) => ({content: item.content, terminals: rawData.materials_terminals[idx]})),
            assign: rawData.assign
        }
    }

    private formatParams(formData: any) {
        return {
            id: formData.id,
            title: formData.title,
            is_default: formData.isDefault,
            position: formData.position,
            materials: formData.material_content_terminal_list.map((item: any) => ({
                content: item.content,
                tpl: formData.materialTpl
            })),
            materials_terminals: formData.material_content_terminal_list.map((item: any) => item.terminals),
            schedule: {
                start_time: formData.schedule.range[0].format('YYYY-MM-DD HH:mm:ss'),
                end_time: formData.schedule.range[1].format('YYYY-MM-DD HH:mm:ss'),
                monday: formData.schedule.days.indexOf('monday') >= 0,
                tuesday: formData.schedule.days.indexOf('tuesday') >= 0,
                wednesday: formData.schedule.days.indexOf('wednesday') >= 0,
                thursday: formData.schedule.days.indexOf('thursday') >= 0,
                friday: formData.schedule.days.indexOf('friday') >= 0,
                saturday: formData.schedule.days.indexOf('saturday') >= 0,
                sunday: formData.schedule.days.indexOf('sunday') >= 0
            },
            assign: formData.assign
        };
    }
}

export default EditForm;
