var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "advertisement-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          columns: _vm.fields,
          dataSource: _vm.list,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
          },
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 0),
            y: _vm.fixed || undefined
          },
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys || [],
            onChange: _vm.handleSelectChange
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: function(total) {
              return "总计 " + total + " 条记录"
            },
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange
          }
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function(value, record) {
              return [
                _c(
                  "a-button",
                  {
                    attrs: { slot: "action", size: "small" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push({
                          path: "/console/advertisement/" + record.id
                        })
                      }
                    },
                    slot: "action"
                  },
                  [_vm._v("编辑")]
                )
              ]
            }
          },
          {
            key: "assign",
            fn: function(value, record) {
              return [
                record.is_default
                  ? _c("span", [_vm._v("\n                默认\n            ")])
                  : _vm._l(value, function(type) {
                      return _c(
                        "span",
                        { key: type, staticStyle: { margin: "0px 5px" } },
                        [
                          _c("AdAssignDetailModal", {
                            attrs: { type: type, adPlanId: record.id }
                          })
                        ],
                        1
                      )
                    })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }