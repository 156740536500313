










import { mapState } from 'vuex';
import { Component, Inject, Watch, Vue } from 'vue-property-decorator';
import CreateModal from './CreateModal.vue';
import PRIVILEGE from '@/common/privileges';
import { Click } from '@/components/Auth';
interface Actions {
    hideMessage: any;
}
@Component({
    components: {
        Click
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.sponsorDetailSearchPage.loading,
            downloading: (state: any) =>
                state.sponsorDetailSearchPage.downloading
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;
    public data() {
        return {
            hideMessage: null,
            PRIVILEGE
        };
    }
    public handleCreateSuccess() {
        this.dispatch('search');
    }
    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }
    public handleRefresh() {
        this.dispatch('search');
    }
    @Watch('$store.state.activityDetailSearchPageUnion.downloading')
    public activityDetailDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }
    @Watch('$store.state.activityDetailSearchPageUnion.downloadError')
    public activityDetailDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error('导出失败，请联系技术人员!');
        }
    }

    @Watch('$store.state.activityDetailSearchPageUnion.lastDownloadTime')
    public activityDetailDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }
    public handleDownload() {
        this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
        this.$store.dispatch('activityDetailSearchPageUnion/download');
    }
}

export default Actions;
