








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import config from '@/config';
import {mapState} from 'vuex';
import {downloadRequest_yx,downloadRequestPOST_yx} from '@/utils';
import qs from 'querystringify';
const fakeDoneFile = {
    name: '上传完成',
    status: 'done',
    uid: 'uid'
};

@Component<AdAssign>({
    computed: {
        ...mapState({
            token: (state: any) => {
                return state.login.session && state.login.session.token;
            }
        })
    }
})
class AdAssign extends Vue {

    @Prop()
    public value!: any;

    public visible = false;

    public config = config;

    public uploading = false;

    public fileList: any = [];
    public merInfo: any = [];

    public error: any = [];

    public created() {
        if (this.value && this.value.length > 0) {
            this.fileList = [fakeDoneFile];
        }
        this.error = [];
    }

    public handleTriggerClick() {
        this.visible = true;
        this.error = [];
        this.fileList = [];
    }

    public handlerRemove() {
        this.fileList = [];
    }

    public handleFileUpload({file, fileList, event}: any) {
        this.fileList = [{
            ...file,
            name: ({
                uploading: '正在上传',
                done: '上传完成，请稍后',
                error: '上传失败',
                removed: ''
            } as any)[file.status]
        }];
        this.uploading = true;
        if (file && file.status === 'done' && file.response) {
            if (file.response.status.code !== 0) {
                this.$message.error(file.response.status.desc);
            } else {
                // debugger
                const flag = file.response.result.flag;
                const merchants = file.response.result.data;
                if(!flag && !merchants){
                    let str = [];
                    str.push('文件数据不合规或暂无回盘数据')
                    this.error = str;
                    return;
                }
                if(0 == merchants.length){
                    let str = [];
                    str.push('文件数据不合规或暂无回盘数据')
                    this.error = str;
                    return;
                }
                if('0' === flag){
                    const errors = this.checkAus(merchants);
                    if (errors.length > 0) {
                        this.fileList = [];
                        this.error = errors;
                        return;
                    } else {
                        this.error = [];
                    }
                    this.$emit('change', merchants);
                    this.visible = false;
                }else{
                    this.error = [];
                    // window.open(config.XY_BASE_URL + '/console/store/merTermfile/download?data='+merchants);
                    this.merInfo = file.response.result.data;
                   this.download(merchants)
                }
               
            }
        }

        if (file.status !== 'uploading') {
            this.uploading = false;
        }
    }
    public async download(merchants:any) {
        const data = await downloadRequestPOST_yx({
            data: merchants,
            prefix: 'merInfo',
            api: '/console/store/merTermfile/download',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
   
    public handleDownloadTemplateClick() {
        // window.open(config.BASE_URL + '/static/activityMerchants.xlsx', '__blank');
        window.open(process.env.BASE_URL + 'merTerm.xlsx', '_blank')
    }

    private checkAus(aus: string[]): string[] {
        const errors = aus.reduce((memo: string[], item: string, idx: number) => {
            if (item.length !== 2) {
                return memo.concat( item);
            }else {
                return memo;
            }
        }, []);
        return errors;
    }
}
export default AdAssign;
