var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        "show-search": "",
        notFoundContent: _vm.loading ? "加载中" : "无数据",
        allowClear: "",
        value: _vm.value,
        placeholder: "请选择活动",
        getPopupContainer: function(trigger) {
          return trigger.parentNode
        }
      },
      on: { onInputKeyDown: _vm.onInputKeyDown, change: _vm.handleChange }
    },
    _vm._l(_vm.items, function(item) {
      return _c(
        "a-select-option",
        { key: item.activityId, attrs: { value: item.activityId } },
        [_vm._v(_vm._s(item.name))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }