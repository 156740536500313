




















































import {
    Component,
    Prop,
    Vue,
    Watch,
    Inject,
    Provide,
} from 'vue-property-decorator';
import { invoiceServices } from './services';
@Component({
    components: {},
})
class InvoiceModal extends Vue {
    @Prop() public merData: any;
    public invoiceAccountId: string = '';
    public spinning: boolean = false;
    public confirmLoading: boolean = false;

    // public mounted() {
    //     console.log('this.merData', this.merData);
    //     this.invoiceQueryData();
    // }

    public setInvoiceAccount(e: any) {
        console.log(e.target.value);
        this.invoiceAccountId = e.target.value;
    }
    // 开票信息查询
    public invoiceQueryData() {
        this.spinning = true;
        const params = {
            merchantNo: this.merData.merchant_no,
            termId: this.merData.offline_terminal_id,
        };
        invoiceServices
            .invoiceQuery(params)
            .then((res) => {
                this.spinning = false;
                console.log(res);
                if (res) {
                    this.invoiceAccountId = res;
                }
            })
            .catch(() => {
                this.spinning = false;
            });
    }
    // 开票注册
    public onInvoiceRegister() {
        if (!this.invoiceAccountId) {
            this.$message.error('注册账号不能为空');
            return;
        }
        const reg = /^[0-9a-zA-Z\u4e00-\u9fa5 ]+$/
        if (!reg.test(this.invoiceAccountId)) {
            this.$message.error('只允许输入数字、字母、汉字');
            return
        }
        this.confirmLoading = true;
        const params = {
            merchantNo: this.merData.merchant_no,
            termId: this.merData.offline_terminal_id,
            invoiceAccountId: this.invoiceAccountId,
        };
        invoiceServices
            .invoiceRegister(params)
            .then((res) => {
                this.confirmLoading = false;
                console.log(res);
                 this.onClose();
                this.$message.success(`注册成功!`);
            })
            .catch((e) => {
                this.$message.error('注册失败，请联系技术人员！');
                this.confirmLoading = false;
            });
    }

    @Watch('merData')
    public watchMerDataChange(data: any): void {
        if (data) {
            this.invoiceQueryData();
        }
        console.log(1111111, data);
    }
    public onClose() {
        console.log('onClose');
        this.invoiceAccountId = '';
        this.$emit('close');
    }
}
export default InvoiceModal;
