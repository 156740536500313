



























































































import { Modal } from 'ant-design-vue';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import * as DICTS from '@/common/dicts';
import columns, { sharedQuotaColumns } from './columns';
import { normColumns, calcTableScrollX, getTop } from '@/utils';
import { check, Show, Click } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import DetailDrawer from '../DetailPage/index.vue';
import EditActDrawer from '../EditPage/index.vue';
import ModifyLogDrawer from './ModifyLogDrawer/index.vue';
interface SearchTable {
    selectedRowKeys: any;
}

@Component({
    components: {
        Click,
        Show,
        DetailDrawer,
        EditActDrawer,
        ModifyLogDrawer
    },
    computed: {
        ...mapState({
            list: (state: any) =>
                state.activityMerSearchPage.list.map((item: any, idx: any) => ({
                    idx:
                        (state.activityMerSearchPage.page - 1) *
                            state.activityMerSearchPage.pageSize +
                        idx +
                        1,
                    ...item
                })),
            total: (state: any) => state.activityMerSearchPage.total,
            pageSize: (state: any) => state.activityMerSearchPage.pageSize,
            page: (state: any) => state.activityMerSearchPage.page,
            loading: (state: any) => state.activityMerSearchPage.loading,
            fields: (state: any) => {
                const { isMerGroupLimit } = state.activityMerSearchPage.query;
                const listColumns = normColumns(
                    columns,
                    state.activityMerSearchPage.fields,
                    state.activityMerSearchPage.sortFields
                );

                if (isMerGroupLimit === 'true') {
                    listColumns.splice(
                        listColumns.length - 1,
                        0,
                        ...sharedQuotaColumns
                    );
                    return listColumns;
                }
                return listColumns;
            },
            updatingStatus: (state: any) =>
                state.activityMerSearchPage.updatingStatus,
            rowSelection(state: any) {
                const self: any = this;
                const selectedRowKeys =
                    state.activityMerSearchPage.selectedRows;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            }
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public dispatch!: any;
    public fixed = 0;
    public type = null;
    public PRIVILEGE = PRIVILEGE;
    public style = '';
    public detail = null;
    public detailUnion = null;
    public editAct = null;
    public editActUnion = null;
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            DICTS,
            columns,
            selectedRowKeys: [],
            calcTableScrollX,
            loadingIcon: (
                <span>
                    <a-icon type="loading" /> 加载中
                </span>
            )
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.$store.commit(
            'activityMerSearchPage/setSelectedRows',
            selectedRowKeys
        );
    }

    public handlePageChange(page: number) {
        this.$store.commit('activityMerSearchPage/setPagination', { page });
        this.$router.push({
            path:
                '/console/activityMer/search' +
                this.$store.getters['activityMerSearchPage/queryString']()
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('activityMerSearchPage/setPagination', {
            pageSize,
            page: 1
        });
        this.$router.push({
            path:
                '/console/activityMer/search' +
                this.$store.getters['activityMerSearchPage/queryString']()
        });
    }
    public handleDrawerOpenClick(
        type: 'detailUnion' | 'editActUnion',
        id: any
    ) {
        this[type] = id;
    }

    public handleDrawerClose(type: 'detailUnion' | 'editActUnion') {
        this[type] = null;
    }
    public toInvalid(record: any) {
        Modal.confirm({
            title: '提示',
            content: '确定将所选商户失效吗？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: record.id,
                    status: 'offline'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public tovalid(record: any) {
        Modal.confirm({
            title: '提示',
            content: '确定将所选商户生效吗？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: record.id,
                    status: 'online'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
}

export default SearchTable;
