var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "el",
      on: {
        mousedown: function($event) {
          $event.stopPropagation()
          return _vm.handleMouseDown($event)
        }
      }
    },
    [_c("div", { style: _vm.style })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }