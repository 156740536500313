var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        color: "gray",
        "font-size": "50px",
        height: "300px",
        "font-weight": "bold",
        "padding-top": "100px"
      }
    },
    [
      _vm._v("\n    404 "),
      _c("span", { staticStyle: { "font-weight": "100" } }, [
        _vm._v("页面不存在")
      ]),
      _c("br"),
      _c(
        "span",
        { staticStyle: { "font-size": "14px", "font-weight": "normal" } },
        [_vm._v("点击回到")]
      ),
      _c(
        "router-link",
        { attrs: { to: "/console/mall/search?page=1&r=" + Math.random() } },
        [_vm._v("首页")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }