















































































































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {DisableDatePicker,AwardRule,districtCodeTransfer} from '@/components/custom-form-components';
import ImageUpload from '@/components/custom-form-components/ImageUpload.vue';
interface DetailForm {
    form: any;
    otherForm:any;
}

@Component<DetailForm>({
    components: {
        DisableDatePicker,
        districtCodeTransfer,
        AwardRule,
        ImageUpload
    },
    computed: {
        ...mapState({
            configData(state: any) {
                return (
                    state.actManageSearchPage.ruleConfigData 
                );
            },
            formData(state: any) {
                return (
                    state.actManageSearchPage.data &&
                    this.formatFormData(state.actManageSearchPage.data)
                );
            },
            
            spinning(state) {
                return(
                    state.actManageSearchPage.loading
                )
            }
        })
    }
})
class DetailForm extends Vue {
    public tplClass: any = null;
    public imageFile: any = null;
    public tplClassMaxNumber = 0;
    //页面tab
    public form;
    public districtCodeList = [];
    public awardRuleData= [];
  configData: any;
  validObj: any;
    public data() {
        return {
            districtCodeList:[],
            validObj: {},
            //第一页数据
            form: this.$form.createForm(this),
        };
    }
    public created() {}



    private formatFormData(rawData: any) {
        let template;
        if (rawData && rawData.extJson) {
            template = JSON.parse(rawData.extJson);
            template = JSON.parse(template)
        }
        if(0 == template.merSettleCardBound){
            rawData.cardBinLimit = undefined;
            rawData.cardBinLimitValue = undefined;
        }
        if (rawData && rawData.imgUrl) {
            this.imageFile = [{
                uid: -1,
                url: rawData.imgUrl,
                status: 'done',
                thumbUrl: rawData.imgUrl,
                name: 'image',
                lastModified: Date.now(),
                lastModifiedDate: new Date(),
                size: 0,
                type: 'image/jpeg',
                response: null
            }];
        }
        if(rawData.extJson && template){
            for(let i in this.configData ){
                const obj = this.configData[i];
                if('checkbox' == obj.type && template.hasOwnProperty(obj.key) && template[obj.key]){
                    let newArray=[];
                    console.log(template[obj.key])
                    let array:any = template[obj.key];
                    for(let j in array){
                        let strVal = array[j]+'';
                        newArray.push(strVal);
                    }
                    template = {
                        ...template,
                        [obj.key] : newArray
                    }
                }
                if(obj.disabled){
                    if (
                        (rawData.hasOwnProperty(obj.key) && rawData[obj.key]) || 
                        (template.hasOwnProperty(obj.key) && (template[obj.key] || template[obj.key] === 0))
                    ) {
                        this.validObj[obj.key] = 'Y';
                    }
                }
            }
        }
        for(let i in this.configData ){
            const obj = this.configData[i];
            if('image' == obj.type && template[obj.key]){
                let imageFile1 = [{
                    uid: -1,
                    url: template[obj.key],
                    status: 'done',
                    thumbUrl: template[obj.key],
                    name: 'image',
                    lastModified: Date.now(),
                    lastModifiedDate: new Date(),
                    size: 0,
                    type: 'image/jpeg',
                    response: null
                }];
                template = {
                    ...template,
                    [obj.key] : imageFile1
                }
            }
            //初始化其他规则默认值，在没有配置的前提下
            if(!template.hasOwnProperty(obj.key)){
                template = {
                    ...template,
                    [obj.key] : obj.value
                }
            }
            
        }
        let cardBinLimit=rawData.cardBinLimit;
        if('unlimited' == cardBinLimit){
            cardBinLimit = '0';
        }else if('whiteList' == cardBinLimit){
            cardBinLimit = '1';
        }else if('blackList' == cardBinLimit){
            cardBinLimit = '2';
        }
        return {
            ...rawData,
            ...template,
            cardBinLimit:cardBinLimit
        }
    }
}

export default DetailForm;
