

























































































import {Modal} from 'ant-design-vue';
import {Component, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import config from '@/config';
import moment from 'moment';
import {DatePicker} from '@/components/custom-form-components';

interface EditActForm {
    form: any;
    otherForm:any
}

@Component({
        components: {
            DatePicker
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.sharedQuotaConfSearchPage.loading,
                formData(state: any) {
                    return state.sharedQuotaConfSearchPage.data && this.formatFormData(state.sharedQuotaConfSearchPage.data);
                },
                
            }),
            
        }
    })
    class createForm extends Vue {
        public districtCodeList=[];
        public awardRuleData= [];
        public merGroupId:any ;
        public ruleId:any;
        public oldStartTime='';
        form: any;
        public data() {
            return {
                config,
                form: this.$form.createForm(this)
            };
        }
        public created() {
            
        }

        public handleCreate(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (!error) {
                    if(values.ruleId){
                        if(!values.ruleTime || !values.ruleTime[0]|| !values.ruleTime[1]){
                            this.$message.error('请选择规则时间！');
                            return false;
                        }
                        if(values.ruleTime && values.ruleTime[0]){
                            const newStartTime= values.ruleTime[0].format('YYYYMMDDHHmmss');
                            if(newStartTime != this.oldStartTime){
                                this.$message.error('规则起始时间不能修改');
                                return false;   
                            }
                        }
                    }
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'sharedQuotaConfSearchPage/editAct',
                                this.formatParams(values)
                            );
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
        @Watch('$store.state.sharedQuotaConfSearchPage.updatedSuccess')
        public updatedSuccess(newVal: boolean, oldVal: boolean) {
            if (newVal) {
                this.$emit('onCancel');
            }
        }
        public async handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                    this.$emit('onCancel');
                }
            });
        }
       
        /**
         * 将表单数据格式化为请求参数
         */
        private formatParams(formData: any) {
            
            return {
                ...formData,
                operateType: '2',
                merGroupId:this.merGroupId,
                ruleId:this.ruleId,
                startTime: 
                    formData.ruleTime &&
                    formData.ruleTime[0] &&
                    formData.ruleTime[0].format('YYYYMMDDHHmmss'),
                endTime:
                    formData.ruleTime &&
                    formData.ruleTime[1] &&
                    formData.ruleTime[1].format('YYYYMMDDHHmmss'),
            };
        }

        
        private formatFormData(rawData: any) {
            this.merGroupId = rawData.merGroupId;
            this.ruleId = rawData.ruleId;
            this.oldStartTime = rawData.startTime && moment(rawData.startTime).format('YYYYMMDDHHmmss');
            return {
                ...rawData,
                ruleTime: [
                    rawData.startTime && moment(rawData.startTime),
                    rawData.endTime && moment(rawData.endTime)
                ]
            }
        }
    }

    export default createForm;
