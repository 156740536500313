













import {mapState} from 'vuex';
import {Component, Inject, Vue, Watch} from 'vue-property-decorator';
import {Click} from '@/components/Auth';
import moment from 'moment';
import DateDrift from 'date-drift';
import PRIVILEGE from '@/common/privileges';

function todayTime() {
    const d = new Date();
    return new Date(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`);
}

interface Actions {
    hideMessage: any;
}

@Component({
    components: {Click},
    computed: {
        ...mapState({
            loading() {
                return this.state().loading;
            },
            downloading() {
                return this.state().downloading;
            }
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;

    public data() {
        return {
            hideMessage: null,
            PRIVILEGE
        };
    }

    public handleRefresh() {
        this.dispatch('search');
    }

    public handleDateClick(type: string) {
        let start;
        let end;
        if (type === 'last7') {
            start = moment(new DateDrift(todayTime()).driftDays(-7).getDate());
            end = moment(new DateDrift(todayTime()).driftDays(0).getDate());
        } else if (type === 'last30') {
            start = moment(new DateDrift(todayTime()).driftDays(-30).getDate());
            end = moment(new DateDrift(todayTime()).driftDays(0).getDate());
        }
        this.$emit('dateChange', {start, end});
        this.dispatch('queryChange', {
            discount_coupon_type: 'OTHER',
            start_time: start && start.format('YYYY-MM-DD HH:mm:ss'),
            end_time: end && end.format('YYYY-MM-DD HH:mm:ss')
        });
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }

    @Watch('$store.state.otherSearchPage.downloading')
    public watchDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }

    @Watch('$store.state.otherSearchPage.downloadError')
    public watchDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error('导出失败，请联系技术人员!');
        }
    }

    @Watch('$store.state.otherSearchPage.lastDownloadTime')
    public watchLastDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }

    public handleDownload() {
        this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
        this.$store.dispatch('otherSearchPage/download');
    }
}

export default Actions;
