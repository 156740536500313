var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", [_vm._v("输入代码:")]),
      _c("a-textarea", {
        attrs: {
          disabled: _vm.disabled,
          value: _vm.data && _vm.data.code,
          placeholder: "请输入",
          autosize: { minRows: 2, maxRows: 6 }
        },
        on: { change: _vm.changeHandler }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }