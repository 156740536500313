











































import {Component, Inject, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import * as DICTS from '@/common/dicts';
import columns from './columns';
import {normColumns, calcTableScrollX, getTop} from '@/utils';
import { Show, Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import ChangeStatusModal from './ChangeStatusModal/index.vue';

interface SearchTable {
    selectedRowKeys: any;
}

@Component({
    components: {
        Click,
        Show,
        ChangeStatusModal
    },
    computed: {
        
        ...mapState({
            list: (state: any) =>
                state.shtSearchPage.list.map((item: any, idx: any) => ({
                    idx: (state.shtSearchPage.page - 1) * state.shtSearchPage.pageSize + idx + 1,
                    ...item
                })),
            total: (state: any) => state.shtSearchPage.total,
            pageSize: (state: any) => state.shtSearchPage.pageSize,
            page: (state: any) => state.shtSearchPage.page,
            loading: (state: any) => state.shtSearchPage.loading,
            fields: (state: any) => normColumns(
                columns, state.shtSearchPage.fields, state.shtSearchPage.sortFields),
            updatingStatus: (state: any) => state.shtSearchPage.updatingStatus,
            rowSelection(state: any) {
                const self: any = this;
                const selectedRowKeys = state.shtSearchPage.selectedRows;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            },
        })
    }
})
class SearchTable extends Vue {

    @Inject()
    public dispatch!: any;
    public fixed = 0;
    public PRIVILEGE = PRIVILEGE;
    public changeStatus =null;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + (-top) + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }
    
    public data() {
        return {
            DICTS,
            columns,
            selectedRowKeys: [],
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('shtSearchPage/setSelectedRows', selectedRowKeys);
    }

    public handlePageChange(page: number) {
        this.$store.commit('shtSearchPage/setPagination', {page});
        this.$router.push({
            path: '/console/shtManage/search' + this.$store.getters['shtSearchPage/queryString']()
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('shtSearchPage/setPagination', {pageSize, page: 1});
        this.$router.push({
            path: '/console/shtManage/search' + this.$store.getters['shtSearchPage/queryString']()
        });
    }
    public handleDrawerOpenClick(type: 'changeStatus', id: any) {
        this[type] = id;
    }

    public handleDrawerClose(type: 'changeStatus') {
        this[type] = null;
    }
}

export default SearchTable;
