




































































import { mapState } from 'vuex';
import { normColumns, calcTableScrollX, getTop } from '@/utils';
import DetailDrawer from '../DetailPage/index.vue';
import EditActDrawer from '../EditPage/index.vue';
import RuleDrawer from '../RulePage/index.vue';
import columns from './columns';
import {
    Component,
    Prop,
    Watch,
    Provide,
    Vue,
    Inject
} from 'vue-property-decorator';
import * as Constant from '@/common/constant';

interface SearchTable {}

@Component({
    components: {
        DetailDrawer,
        EditActDrawer,
        RuleDrawer
    },
    computed: {
        ...mapState({
            lists: (state: any) =>
                state.merSubsidyActSearchPage.list.map(
                    (order: any, idx: any) => ({
                        idx:
                            (state.merSubsidyActSearchPage.page - 1) * 20 +
                            idx +
                            1,
                        ...order
                    })
                ),
            total: (state: any) => state.merSubsidyActSearchPage.total,
            pageSize: (state: any) => state.merSubsidyActSearchPage.pageSize,
            page: (state: any) => state.merSubsidyActSearchPage.page,
            loading: (state: any) => state.merSubsidyActSearchPage.loading,
            selectedRowKeys: (state: any) =>
                state.merSubsidyActSearchPage.selectedRowKeys,
            fields: (state: any) =>
                normColumns( columns,
                    state.merSubsidyActSearchPage.fields,
                    state.merSubsidyActSearchPage.sortFields
                )
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public state!: any;
    @Inject()
    public dispatch!: any;
    @Inject()
    public commit!: any;
    @Inject()
    public getters!: any;
    @Inject()
    public routerPush!: any;
    @Inject('privileges')
    public ownPrivileges!: any;

    public currentColumns = columns;
    @Prop()
    public paytype!: String;
    public fixed = 0;
    public style = '';
    public detail = null;
    public detailUnion = null;
    public editAct = null;
    public editActUnion = null;
    public RuleConfig = null;
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            columns,
            calcTableScrollX,
            loadingIcon: (
                <span>
                    <a-icon type="loading" /> 加载中
                </span>
            )
        };
    }

    public created() {
    }

    public handleSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('merSubsidyActSearchPage/setSelectedRows', [
            ...selectedRowKeys
        ]);
    }

    public handlePageChange(page: number) {
        this.commit('setPagination', { page });
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.commit('setPagination', { pageSize, page: 1 });
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }

    public handleAssignSuccess() {
        this.dispatch('search');
    }
    public handleDrawerOpenClick(type: 'detailUnion' | 'editActUnion' | 'RuleConfig', id: any) {
        this[type] = id;
    }

    public handleDrawerClose(type: 'detailUnion' | 'editActUnion' |'RuleConfig') {
        this[type] = null;
    }
}

export default SearchTable;
