









import CreateForm from './CreateForm.vue';
import {Component,computed, Vue, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import { mapState } from 'vuex';
@Component({
    components: {
        Show,
        CreateForm
    },
})
export default class CreatePage extends Vue {
    public PRIVILEGE = PRIVILEGE;
    public created() {
        //  this.$store.dispatch('activitySignUpPage/queryDistrictCode');
    }
    

    @Watch('$store.state.activitySignUpPage.created')
    public createdListener() {
        this.$message.success('提交成功');
    }

    @Watch('$store.state.activitySignUpPage.error')
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }
}
