










import EditActForm from './EditForm.vue';
import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Show,
        EditActForm
    }
})
export default class CreatePage extends Vue {
    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public title!: string | number;
    @Prop()
    public id !: string;
    public created() {
        this.$store.dispatch('districtCodeSearchPage/initData',  this.$route.params.id);
    }

    

    @Watch('$store.state.districtCodeSearchPage.error')
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    public onClose() {
        this.$emit('close');
    }
}
