var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.STORE.TRANSFER] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [_vm._t("trigger")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            title: "转移微信号",
            visible: _vm.visible,
            confirmLoading: _vm.loading,
            form: _vm.form
          },
          on: { ok: _vm.handleSubmit, cancel: _vm.handleClose }
        },
        [
          _c(
            "a-form",
            {
              staticClass: "account-create-form",
              attrs: { form: _vm.form },
              on: { submit: _vm.handleSubmit }
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "手机号" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "mobile",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                              {
                                pattern: /^\d{11}$/,
                                message: "请输入正确手机号"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                        'mobile',\n                        {rules: [{required: true, message: '请填写必填项!'}, {pattern: /^\\d{11}$/,  message: '请输入正确手机号'}]}\n                    ]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }