var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-col",
    { attrs: { span: 12 } },
    [
      _c(
        "a-card",
        {
          staticClass: "activity-card",
          staticStyle: { margin: "10px 0" },
          attrs: { title: _vm.ACTIVITY_MAP[_vm.item.activity.activity_type] }
        },
        [
          _c("a", { attrs: { slot: "extra", href: "#" }, slot: "extra" }, [
            _vm._v(
              _vm._s(
                _vm.item.activity_status === "ACTIVE"
                  ? _vm.item.activity.active
                    ? "进行中"
                    : "暂停中"
                  : _vm.ACTIVITY_STATUS[_vm.item.activity_status]
              )
            )
          ]),
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            _vm._l(_vm.LABEL_MAP[_vm.item.activity.activity_type], function(
              statItem
            ) {
              return _c("a-col", { key: statItem.key, attrs: { span: 6 } }, [
                _c("div", {}, [_vm._v(_vm._s(statItem.label))]),
                _c("div", { staticStyle: { color: "#EC4A3B" } }, [
                  _vm._v(
                    _vm._s(
                      statItem.unit === "元"
                        ? (_vm.item.statistic[statItem.key] / 100).toFixed(2)
                        : _vm.item.statistic[statItem.key]
                    ) +
                      _vm._s(statItem.unit) +
                      "\n                "
                  )
                ])
              ])
            }),
            1
          ),
          _c("a-divider", { staticStyle: { margin: "15px 0" } }),
          _c("p", [
            _vm._v(
              "活动类型: " +
                _vm._s(_vm.ACTIVITY_TYPE_MAP[_vm.item.activity.activity_type])
            )
          ]),
          _c("p", [
            _vm._v("活动规则:\n            "),
            _vm.item.activity.activity_type === "SPREAD_PRIZE"
              ? _c("span", [_vm._v("无门槛")])
              : _vm._e(),
            _vm.item.activity.activity_type === "NEWBIE"
              ? _c("span", [
                  _vm._v(
                    "满" +
                      _vm._s(_vm.item.use_line / 100) +
                      "元减" +
                      _vm._s(_vm.item.discount_amount / 100) +
                      "元"
                  )
                ])
              : _vm._e(),
            _vm.item.activity.activity_type === "LUCKY_PACKET"
              ? _c("span", [
                  _vm._v(
                    "满" +
                      _vm._s(_vm.item.use_line / 100) +
                      "元减" +
                      _vm._s(_vm.item.discount_min / 100) +
                      " - " +
                      _vm._s(_vm.item.discount_max / 100) +
                      "元"
                  )
                ])
              : _vm._e()
          ]),
          _c("p", [
            _vm._v(
              "活动时间: " +
                _vm._s(
                  _vm.item.activity.start_time.split(" ")[0] +
                    " 至 " +
                    _vm.item.activity.end_time.split(" ")[0]
                )
            )
          ]),
          _c("p", [
            _vm._v("创建日期: " + _vm._s(_vm.item.activity.created_time))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }