




























    import {Modal} from 'ant-design-vue';
    import {Component, Watch, Vue, Inject} from 'vue-property-decorator';
	import SmStatusCount from '@/components/SmStatusCount.vue';
    import {mapState} from 'vuex';
    import PRIVILEGE from '@/common/privileges';
    import {Click} from '@/components/Auth';
    import CreateModal from "./CreateModal.vue";
    
    interface Actions {
        selectedRowKeys: any;
        hideMessage: any;
    }
	@Component({
        components: {
           SmStatusCount,
           Click,
           CreateModal
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.shortMessageSearchPage.loading,
                downloading: (state: any) => state.shortMessageSearchPage.downloading,
                selectedRowKeys: (state: any) => state.shortMessageSearchPage.selectedRowKeys,
            })
        }
    })
   class Actions extends Vue {
	   
       @Inject()
        public dispatch!: any;

        public data() {
            return {
                hideMessage: null,
                PRIVILEGE
            };
        }

        public destroyed() {
            return this.hideMessage && this.hideMessage();
        }

        public handleRefresh() {
           this.dispatch('search');
        }
        public handleOnClick() {
            let flag = false;
            this.selectedRowKeys.forEach((item:any) => {
                this.$store.state.shortMessageSearchPage.list.forEach((obj:any) => {
                    if(item === obj.id && (obj.status != '待上架'&& obj.status != '已下架') ){
                        flag = true
                    }
                })
            })
            if (flag) {
                this.$message.error('只有待上架和已下架状态数据才能上架');
                return
            }
            Modal.confirm({
                title: '上架',
                content: '确认将选中的短信包进行上架操作吗?',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.dispatch('updateStatus', {
                        ids: this.selectedRowKeys.join(','),
                        status: 'ON'
                    });
                }
            });
        } 
        public handleOffClick() {
            let flag = false;
            this.selectedRowKeys.forEach((item:any) => {
                this.$store.state.shortMessageSearchPage.list.forEach((obj:any) => {
                    if(item === obj.id && obj.status != '上架中'){
                        flag = true
                    }
                })
            })
            if (flag) {
                this.$message.error('只有上架中状态数据才能下架');
                return
            }
            Modal.confirm({
                title: '下架',
                content: '确认将选中的短信包进行下架操作吗?',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    
                    this.dispatch('updateStatus', {
                        ids: this.selectedRowKeys.join(','),
                        status: 'OFF'
                    });
                }
            });
        }
        public handleDeleteClick() {
            Modal.confirm({
                title: '删除',
                content: '确认删除选择的短信包配置吗?',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.dispatch('updateStatus', {
                        ids: this.selectedRowKeys.join(','),
                        status: false
                    });
                }
            });
        }

        

        @Watch('$store.state.shortMessageSearchPage.downloading')
        public shortMessageDownloading(downloading: any) {
            if (!downloading && this.hideMessage) {
                this.hideMessage();
            }
        }

        @Watch('$store.state.shortMessageSearchPage.downloadError')
        public shortMessageDownloadError(error: any) {
            if (error && !error.silent) {
                this.$message.error('导出失败，请联系技术人员!');
            }
        }

        @Watch('$store.state.shortMessageSearchPage.lastDownloadTime')
        public shortMessageDownloadTime(lastDownloadTime: any) {
            this.$message.success('导出完成!');
        }

        public handleDownload() {
            this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
            this.$store.dispatch('shortMessageSearchPage/download');
        }
    }

    export default Actions;
