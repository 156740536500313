var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "el",
      on: {
        mousedown: function($event) {
          $event.stopPropagation()
          return _vm.handleMouseDown($event)
        }
      }
    },
    [
      _c("div", {
        style:
          "width:" +
          _vm.width +
          "px;height:" +
          _vm.width +
          "px;background:none;position:absolute;margin:auto;right:0;left:0;top:0;bottom:0"
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }