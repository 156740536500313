






























































































import {Component, Vue, Inject} from 'vue-property-decorator';
import {mapState} from 'vuex';
import moment, {Moment} from 'moment';
import {timeRangeFormat, isTrueEmpty} from '@/utils';

import PriceRange from '@/components/custom-form-components/PriceRange.vue';
import PayChannel from '@/components/custom-form-components/PayChannel';

import OrderStatus from '@/components/custom-form-components/OrderStatus';

import DatePicker from '@/components/custom-form-components/DatePicker.vue';
import SearchFormWrapper from '@/components/SearchFormWrapper.vue';
import Actions from './Actions.vue';

export interface Query {
    store_id: string;
    pay_channel: string;
    status: string;
    start_trade_time: string;
    end_trade_time: string;
    start_refund_time: string;
    end_refund_time: string;
    nick_name: string;
    user_name: string;
    order_id: string;
    mobile: string;
    min_total_amount: any;
    max_total_amount: any;
    min_pay_amount: any;
    max_pay_amount: any;
}

export interface FormData {
    store_id: string;
    pay_channel: string;
    status: string;
    nick_name: string;
    user_name: string;
    order_amount: any[];
    pay_amount: any[];
    order_id: string;
    mobile: string;
    range_trade_time: Moment[];
    range_refund_time: Moment[];
}

@Component({
    components: {
        SearchFormWrapper,
        PriceRange,
        PayChannel,
        OrderStatus,
        DatePicker,
        Actions
    },
    computed: {
        ...mapState({
            loading(state) {
                return this.state().loading;
            },
            formData() {
                return this.queryToFormData(this.state().query);
            }
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public state!: any;

    @Inject()
    public dispatch!: any;

    public form!: any;

    public data() {
        return {
            collapsed: false
        };
    }

    public handleDateChange(e: any) {
        return this.form && this.form.setFieldsValue({
            range_trade_time: [e.start, e.end]
        });
    }

    public setForm(form: any) {
        this.form = form;
    }

    public format(values: FormData): Query {
        const {
            start_time: start_trade_time,
            end_time: end_trade_time,
        } = timeRangeFormat(values.range_trade_time);
        const {
            start_time: start_refund_time,
            end_time: end_refund_time,
        } = timeRangeFormat(values.range_refund_time);
        return {
            store_id: values.store_id,
            status: values.status,
            order_id: values.order_id,
            pay_channel: values.pay_channel,
            nick_name: values.nick_name,
            user_name: values.user_name,
            mobile: values.mobile,
            min_total_amount: isTrueEmpty(values.order_amount && values.order_amount[0])
                ? null
                : Math.round(values.order_amount[0] * 100),
            max_total_amount: isTrueEmpty(values.order_amount && values.order_amount[1])
                ? null
                : Math.round(values.order_amount[1] * 100),
            min_pay_amount: isTrueEmpty(values.pay_amount && values.pay_amount[0])
                ? null
                : Math.round(values.pay_amount[0] * 100),
            max_pay_amount: isTrueEmpty(values.pay_amount && values.pay_amount[1])
                ? null
                : Math.round(values.pay_amount[1] * 100),
            start_trade_time,
            end_trade_time,
            start_refund_time,
            end_refund_time,
        };
    }

    public queryToFormData(query: Query) {
        return {
            store_id: query.store_id,
            pay_channel: query.pay_channel,
            status: query.pay_channel,
            nick_name: query.pay_channel,
            order_amount: [query.min_total_amount, query.max_total_amount].map(
                (v: any) => v ? parseFloat(v) : undefined),
            pay_amount: [query.min_pay_amount, query.max_pay_amount].map((v: any) => v ? parseFloat(v) : undefined),
            order_id: query.order_id,
            mobile: query.mobile,
            range_trade_time: [
                query.start_trade_time, query.end_trade_time].map((datetime: string) => datetime && moment(datetime)),
            range_refund_time: [
                query.start_refund_time, query.end_refund_time].map((datetime: string) => datetime && moment(datetime))
        };
    }

    public handleSearch(query: Query) {
        this.dispatch('queryChange', query);
    }
}

export default SearchForm;
