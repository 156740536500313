var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticStyle: { color: "gray", padding: "5px", background: "#f7f7f7" } },
    [
      _c("span", { staticStyle: { "margin-right": "8px" } }, [
        _vm._v("运营中: " + _vm._s(_vm.loading ? "-" : _vm.open))
      ]),
      _c("span", { staticStyle: { "margin-right": "8px" } }, [
        _vm._v("草稿箱: " + _vm._s(_vm.loading ? "-" : _vm.draft))
      ]),
      _c("span", { staticStyle: { "margin-right": "8px" } }, [
        _vm._v("已停止: " + _vm._s(_vm.loading ? "-" : _vm.stop))
      ]),
      _c("a", { attrs: { disabled: _vm.loading }, on: { click: _vm.fetch } }, [
        _vm._v("刷新")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }