






































































import {Component, Vue, Inject} from 'vue-property-decorator';
import {mapState} from 'vuex';
import moment from 'moment';
import {deleteEmpty} from '@/utils';
import qs from 'querystringify';
import {
    DatePicker
} from '@/components/custom-form-components';

interface SearchForm {
    form: any;
}

@Component({
    components: {
        DatePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.customerSearchPage.query);
            },
            loading: (state: any) => state.customerSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    @Inject()
    public dispatch!: any;

    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this)
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {

            if (error) {
                return this.$message.error('请填写正确信息');
            }

            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            // this.$router.push({
            //     path: `/console/account/customer/search?${qs.stringify(query)}`
            // });
            this.dispatch('initQuery', query);
            console.log(query,5566)
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any) {
        return {
            mobile: values.mobile,
            user_name: values.user_name,
            weekly_limit: values.weekly_limit,
            start_time:
                values.range_register_time &&
                values.range_register_time[0] &&
                values.range_register_time[0].format('YYYY-MM-DD HH:mm:ss'),
            end_time:
                values.range_register_time &&
                values.range_register_time[1] &&
                values.range_register_time[1].format('YYYY-MM-DD HH:mm:ss'),
        };
    }

    private formatFormData(query: any) {
        return {
            ...query,
            range_register_time: [
                query.start_time && moment(query.start_time),
                query.end_time && moment(query.end_time)
            ]
        };
    }
}

export default SearchForm;
