
















import {mapState} from 'vuex';
import {Component, Inject,Watch, Vue} from 'vue-property-decorator';
import CreateModal from './CreateModal.vue';
import PRIVILEGE from '@/common/privileges';
import {Click} from '@/components/Auth';
import { Modal } from 'ant-design-vue';
interface Actions {
    hideMessage: any;
    selectedRows: any;
}
@Component({
    components: {
        Click,
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.policyActivitySearchPage.loading,
            selectedRows: (state: any) =>state.policyActivitySearchPage.selectedRowKeys
        })
    }
})
class Actions extends Vue {

    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;
    public data() {
        return {
            hideMessage: null,
            PRIVILEGE
        };
    }
    public handleCreateSuccess() {
        this.dispatch('search');
    }
    public mounted() {
        console.log(this.$store)
    }
    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }
    public handleRefresh() {
        this.dispatch('search');
    }
    
    public onTemplate() {
        console.log(this.selectedRows)
        Modal.confirm({
            title: '提示',
            content: '确定将所选数据生效吗？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRows.join(','),
                    status: 'ON'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public offTemplate() {
        Modal.confirm({
            title: '提示',
            content: '确定将所选数据失效吗？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRows.join(','),
                    status: 'OFF'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public handleAddAdPlan() {
        this.$router.push('/console/policy/createPolicy');
    }
}

export default Actions;
