








import {Component, Prop, Vue} from 'vue-property-decorator';
import {request} from '@/utils';

@Component({
    components: {}
})
class Role extends Vue {

    @Prop()
    public value!: string;

    public items = [];

    public loading = false;

    public async created() {
        this.fetch();
    }

    public async fetch() {
        try {
            this.items = [];
            this.loading = true;
            const ret = await request({
                api: '/console/role/simple_list',
                data: null
            });
            const list = ret.list;
            this.items = list.map((i: any) => ({
                name: i.name,
                id: i.id
            }));
        } catch (e) {
            if (!e.is403) {
                this.$message.error('获取角色列表失败, 请联系技术人员!');
            }
        } finally {
            this.loading = false;
        }
    }

    public dropdownVisibleChange(open: number) {
        console.log('on dropdownVisibleChange:', open);
        if (open) {
            this.fetch();
        }
    }

    public data() {
        return {
            dataSource: []
        };
    }

    public handleChange(value: string) {
        this.$emit('change', value);
    }

    public filterOption(input: string, option: any) {
        return (
            option.componentOptions.children[0].text
                .toUpperCase()
                .indexOf(input.toUpperCase()) >= 0
        );
    }
}

export default Role;
