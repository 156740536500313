var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "shop-edit-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "shop-edit-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleSubmit }
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "店铺ID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["store_id", { initialValue: _vm.formData.id }],
                    expression: "['store_id', {initialValue: formData.id}]"
                  }
                ],
                attrs: { disabled: true, placeholder: "-" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "LKL商户号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merchant_no",
                      { initialValue: _vm.formData.merchant_no }
                    ],
                    expression:
                      "['merchant_no', {initialValue: formData.merchant_no}]"
                  }
                ],
                attrs: { disabled: true, placeholder: "-" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "猫酷ShopID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "mallcoo_shop_id",
                      { initialValue: _vm.formData.mallcoo_shop_id }
                    ],
                    expression:
                      "['mallcoo_shop_id', {initialValue: formData.mallcoo_shop_id}]"
                  }
                ],
                attrs: { disabled: true, placeholder: "-" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商户名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name",
                      {
                        initialValue: _vm.formData.name,
                        rules: [
                          { required: true, message: "请填写必填项!" }
                        ].concat(_vm.STORE_RULES.NAME)
                      }
                    ],
                    expression:
                      "['name', {\n                    initialValue: formData.name,\n                    rules: [\n                        {required: true, message: '请填写必填项!'},\n                        ...STORE_RULES.NAME\n                    ]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "行业类型" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "biz_type",
                      { initialValue: _vm.formData.biz_type }
                    ],
                    expression:
                      "['biz_type', {initialValue: formData.biz_type}]"
                  }
                ],
                attrs: { disabled: true, placeholder: "-" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "归属商场" } },
            [
              _c("Mall", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["mallcoo", { initialValue: _vm.formData.mallcoo }],
                    expression:
                      "[\n                'mallcoo',\n                {initialValue: formData.mallcoo}\n            ]"
                  }
                ],
                attrs: { showTip: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "归属商圈" } },
            [
              _c("BizDistrict", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "biz_district_id",
                      {
                        initialValue:
                          _vm.formData.biz_district &&
                          _vm.formData.biz_district.id
                      }
                    ],
                    expression:
                      "[\n                'biz_district_id',\n                {initialValue: formData.biz_district && formData.biz_district.id}\n            ]"
                  }
                ],
                attrs: { showTip: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "店铺联系电话" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "phone",
                      {
                        initialValue: _vm.formData.phone,
                        rules: [
                          {
                            message: "必须为数字或-，长度不能超过20",
                            validator: this.validatePhone
                          }
                        ]
                      }
                    ],
                    expression:
                      "[\n                        'phone',\n                        {\n                            initialValue: formData.phone,\n                            rules: [{\n                                message: '必须为数字或-，长度不能超过20',\n                                validator: this.validatePhone\n                            }]\n                        }\n                    ]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "Logo" } },
            [
              _c("ImageCrop", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "logo",
                      {
                        initialValue: _vm.formData.logo
                      }
                    ],
                    expression:
                      "['logo', {\n                    initialValue: formData.logo\n                }]"
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "广告图片" } },
            [
              _c("ImageCrop", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "ad",
                      {
                        initialValue: _vm.formData.ad
                      }
                    ],
                    expression:
                      "['ad', {\n                    initialValue: formData.ad\n                }]"
                  }
                ],
                attrs: { noCrop: true, minWidth: 750 }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "广告链接" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "link_ad",
                      {
                        initialValue: _vm.formData.ad_link,
                        rules: [].concat(_vm.STORE_RULES.AD_LINK)
                      }
                    ],
                    expression:
                      "['link_ad', {\n                    initialValue: formData.ad_link,\n                    rules: [...STORE_RULES.AD_LINK]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        disabled: _vm.auditing,
                        loading: _vm.saving
                      }
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.saving },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("\n                    取消\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }