













































    import {Modal} from 'ant-design-vue';
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {mapState} from 'vuex';
    import moment from 'moment';
    import CreateModal from './CreateModal.vue';
    import ShopUpload from './ShopUpload.vue';
    import * as validators from '../../validators';
    interface EditForm {
        form: any;
    }

    @Component<EditForm>({
        components: {
            CreateModal,
            ShopUpload
        },
        computed: {
            ...mapState({
                formData(state: any) {
                    return state.activityEditPageUnion.data && this.formatFormData(state.activityEditPageUnion.data);
                }
            })
        }
    })
    class EditForm extends Vue {

        public tplClass: any = null;

        public tplClassMaxNumber = 0;

        public form;

        public conflictReplaceList: any;

        public merchantTotal: any;

        public merchantAssignRemoveTotal: any;

        public validators = validators;
        public shopData:any;
        public merRuleId:any;
        public activityId:any;
        public created() {
            console.log('999999');
            console.log(this.$store.state.activityEditPageUnion.data);

        }

        public data(){
            return {
             shopData: [],
            form :this.$form.createForm(this),
             columns: [
            {
                title: '商户号',
                dataIndex: 'merNo',
            },
            {
                title: '终端号',
                dataIndex: 'termNo',
            },
            {
                title: '操作',
                dataIndex: 'index',
                scopedSlots: {
                    customRender: 'action'
                }
            }
          ]
            }
        }
    public changeShopData(value: any){
        if(this.shopData.length === 3000){
            this.$message.error('店铺最多3000家上限');
            return;
        }
        let shopIndex = 1;
        value.forEach((data:any)=> {
            let flag = false;
            for(let shop of this.shopData){
                if(shop.merNo === data[0] && shop.termNo === data[1]){
                    flag = true;
                    break;
                }
            }
            if(this.shopData.length){
                shopIndex = this.shopData[this.shopData.length - 1].index + 1;
            }
            if(!flag){
                const objData = {
                index: shopIndex,
                merNo: data[0],
                termNo: data[1]
            }
            this.shopData.push(objData);
            }
        })
    }


    public deleteShopData(record: any) {
        if(null == this.shopData || this.shopData.length < 2){
            this.$message.error('可用商户、终端号不能为空');
            return
        }
        this.shopData = (this.shopData as object[]).filter((data: any) => data.index !== record.index);
    }

    public handleSubmit(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                if(null == this.shopData || this.shopData.length < 1){
                    this.$message.error('可用商户、终端号不能为空');
                    return
                }
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => this.handleSubmitConfirm(values)
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public handleSubmitConfirm(values: any) {
        const formData = this.formatParams(values);
        console.log(formData)
        this.$store.dispatch('activityEditPageUnion/edit', formData);
        this.$message.success("提交成功");
        const queryString = this.$store.getters[
            'activitiesOpenSearchPage/queryString'
        ]();
        this.$router.push({
            path: '/console/activity/open/search' + queryString
        });
    }

    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters[
                    'activitiesOpenSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/activity/open/search' + queryString
                });
            }
        });
    }

        //



        private formatParams(formData: any) {
            const otherData = {
                merchantList: this.shopData,
                activityId:this.activityId,
                merRuleId:this.merRuleId,
            }
            return otherData;
        }
        private formatFormData(rawData: any) {
            this.shopData= rawData.merchantList.map((item:any, index:number)=> ({
                index,
                ...item,
            }));
            this.activityId = rawData.activityId;
            this.merRuleId = rawData.merRuleId;
            return {
                ...rawData,
                shopData: rawData.merchantList,


            }
        }
    }

    export default EditForm;
