import * as DICTS from '@/common/dicts';
import blankCell from '@/common/blankCell';

const customRender = (v: any) => v || '-';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        fixed: 'left',
        width: 80,
        align: 'center',
    },
    {
        title: 'ID',
        dataIndex: 'applyId',
        fixed: 'left',
        width: 200,
        align: 'center',
        customRender
    },
    {
        title: '商户号',
        dataIndex: 'merchantNo',
        width: 150,
        align: 'center',
        fixed: 'left',
        customRender
    },
    {
        title: '报名时间',
        dataIndex: 'createdTime',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '活动ID',
        dataIndex: 'activityPlanId',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '活动名称',
        dataIndex: 'activityName',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '商户详情',
        dataIndex: 'merDetail',
        align: 'center',
        width: 100,
        scopedSlots: {customRender: 'merDetail'},
    },
    {
        title: '联系人',
        dataIndex: 'contact',
        align: 'center',
        width: 120,
        customRender
    },
    {
        title: '联系电话',
        dataIndex: 'phone',
        align: 'center',
        width: 120,
        customRender
    },
    {
        title: '外呼状态',
        dataIndex: 'callStatusName', // MALLCOO
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '外呼结果',
        dataIndex: 'waihu',
        align: 'center',
        width: 100,
        scopedSlots: {customRender: 'waihu'},
    },
    {
        title: '审核状态',
        dataIndex: 'status', // MALLCOO
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '审核意见',
        dataIndex: 'shenhe',
        align: 'center',
        width: 100,
        scopedSlots: {customRender: 'shenhe'},
    },
   
    {
        title: '审核人',
        dataIndex: 'updateUser',
        align: 'center',
        width: 200,
        customRender
    },
    {
        title: '审核时间',
        dataIndex: 'updateTime',
        align: 'center',
        width: 200,
        customRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        fixed: 'right',
        width: 150,
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
