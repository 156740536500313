



























import { mapState } from 'vuex';
import { Component, Watch, Inject, Vue } from 'vue-property-decorator';
import { Click } from '@/components/Auth';
import UploadDrawer from './UploadDrawer/index.vue';
import PRIVILEGE from '@/common/privileges';
import * as services from './services';

interface Actions {
    selectedRows: any;
    hideMessage: any;
}

@Component({
    components: {
        Click,
        UploadDrawer
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.activityMerSearchPage.loading,
            downloading: (state: any) =>
                state.activityMerSearchPage.downloading,
            selectedRows: (state: any) =>
                state.activityMerSearchPage.selectedRows
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;
    public PRIVILEGE = PRIVILEGE;
    public applyUploadType: String = 'act_mer';
    public data() {
        return {
            hideMessage: null,
            PRIVILEGE
        };
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }

    public handleRefresh() {
        this.$router.push({
            path:
                '/console/activityMer/search' +
                this.$store.getters['activityMerSearchPage/queryString']()
        });
    }

    @Watch('$store.state.activityMerSearchPage.downloading')
    public watchDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }

    @Watch('$store.state.activityMerSearchPage.downloadError')
    public watchDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error('导出失败，请联系技术人员!');
        }
    }

    @Watch('$store.state.activityMerSearchPage.lastDownloadTime')
    public watchLastDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }

    public handleDownload() {
        this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
        this.$store.dispatch('activityMerSearchPage/download');
    }
    public handleAddAdPlan() {
        this.$router.push('/console/activityMer/createActivityMer');
    }

    public async handleRemoveMer() {
        if (!this.selectedRows.length) {
            this.$message.error('请选择要解绑的商户');
            return;
        }

        this.$message.loading('', 0);
        services.removeMer({
            idList: this.selectedRows
        }).then(() => {
            this.$message.destroy();
            this.$message.success('解绑成功!');
            this.dispatch('search');
        })
    }
}

export default Actions;
