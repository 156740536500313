


















import {mapState} from 'vuex';
import { Component, Inject, Watch, Vue } from 'vue-property-decorator';
import DateDrift from 'date-drift';
import moment from 'moment';
import { Click } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

function todayTime() {
    const d = new Date();
    return new Date(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`);
}
interface Actions {
    hideMessage: any;
}

@Component({
    components: {
        Click
    },
    computed: {
        ...mapState({
            downloading() {return this.state().downloading; },
            loading() {return this.state().loading; },
            downloadError() {return this.state().downloadError; },
            lastDownloadTime() {return this.state().lastDownloadTime; },
        })
    }
})
class Actions extends Vue {

    @Inject()
    public state!: any;
    @Inject()
    public dispatch!: any;

    public PRIVILEGE = PRIVILEGE;

    public data() {
        return {
            hideMessage: null
        };
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }

    public handleDownload() {
        this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
        this.dispatch('download');
    }

    public handleRefresh() {
        this.dispatch('search');
    }

    @Watch('downloading')
    public watchDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }

    @Watch('downloadError')
    public watchDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error('导出失败，请联系技术人员!');
        }
    }

    @Watch('lastDownloadTime')
    public watchLastDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }

    public handleDateClick(type: string) {
        let start;
        let end;
        if (type === 'today') {
            start = moment(todayTime());
            end = moment(new DateDrift(todayTime()).driftDays(1).getDate());
        } else if (type === 'yesterday') {
            start = moment(new DateDrift(todayTime()).driftDays(-1).getDate());
            end = moment(todayTime());
        } else if (type === 'last7') {
            start = moment(new DateDrift(todayTime()).driftDays(-6).getDate());
            end = moment(new DateDrift(todayTime()).driftDays(1).getDate());
        } else if (type === 'last30') {
            start = moment(new DateDrift(todayTime()).driftDays(-29).getDate());
            end = moment(new DateDrift(todayTime()).driftDays(1).getDate());
        }
        this.$emit('dateChange', {start, end});
        this.dispatch('queryChange', {
            start_time: start && start.format('YYYY-MM-DD HH:mm:ss'),
            end_time: end && end.format('YYYY-MM-DD HH:mm:ss')
        });
    }
}
export default Actions;
