var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "image-crop" }, [
    _c(
      "div",
      [
        _c(
          "a-upload",
          {
            ref: "image",
            attrs: {
              disabled: _vm.disabled,
              name: "file",
              fileList: _vm.cropping
                ? [
                    {
                      uid: "-1",
                      name: "原始图像",
                      status: "done",
                      url: _vm.oriImageUrl
                    }
                  ]
                : _vm.value,
              action:
                _vm.config.XY_BASE_URL +
                "/console/image/upload?imageMaxSize=" +
                _vm.imageMaxSize,
              headers: {
                Authorization: _vm.token
              },
              showUploadList: {
                showRemoveIcon: !!(
                  (_vm.value &&
                    _vm.value.length > 0 &&
                    _vm.value[0].status !== "uploading") ||
                  _vm.noCrop
                ),
                showPreviewIcon: !_vm.noPreview
              },
              accept: ".jpg,.gif,.jpeg,.png",
              remove: _vm.handleRemoveClick,
              listType: "picture-card",
              beforeUpload: _vm.beforeUpload
            },
            on: { change: _vm.handleImageChange }
          },
          [!_vm.hasImage ? _c("div", [_vm._v("点击上传")]) : _vm._e()]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }