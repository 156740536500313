var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.SHTMANAGE.UPDATE_STATUS] },
        on: { pass: _vm.handleOffClick },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRows.length === 0 || _vm.loading
                    }
                  },
                  [_vm._v("解绑\n                ")]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }