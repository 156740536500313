











import {mapState} from 'vuex';
import {Component, Inject, Vue} from 'vue-property-decorator';
import PRIVILEGE from '@/common/privileges';
import {Click} from '@/components/Auth';
import { Modal } from 'ant-design-vue';
interface Actions {
    hideMessage: any;
    selectedRows: any;
}
@Component({
    components: {
        Click,
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.actManageSearchPage.loading,
            selectedRows: (state: any) =>state.actManageSearchPage.selectedRowKeys
        })
    }
})
class Actions extends Vue {

    @Inject()
    public dispatch!: any;
    public PRIVILEGE = PRIVILEGE;
    @Inject()
    public state!: any;
    public data() {
        return {
            hideMessage: null,
            PRIVILEGE
        };
    }
    public updateStatus() {
        console.log(this.selectedRows)
        Modal.confirm({
            title: '提示',
            content: '该活动中可能存在有效规则，确定将活动置为失效吗？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRows.join(','),
                    status: '1'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public handleCreateSuccess() {
        this.dispatch('search');
    }
    public mounted() {
        console.log(this.$store)
    }
    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }
    public handleRefresh() {
        this.dispatch('search');
    }
    
   
    public handleAddAdPlan() {
        this.$router.push('/console/actManage/createActivity');
    }
}

export default Actions;
