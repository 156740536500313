var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-transfer", {
        staticClass: "tree-transfer",
        attrs: {
          "data-source": _vm.dataSource,
          "target-keys": _vm.targetKeys,
          disabled: _vm.disabled && _vm.dataSource,
          render: function(item) {
            return item.title
          },
          "show-select-all": false
        },
        on: { change: _vm.onChange },
        scopedSlots: _vm._u([
          {
            key: "children",
            fn: function(ref) {
              var ref_props = ref.props
              var direction = ref_props.direction
              var selectedKeys = ref_props.selectedKeys
              var itemSelect = ref.on.itemSelect
              return [
                direction === "left"
                  ? _c("a-tree", {
                      staticClass: "resetATree",
                      attrs: {
                        height: "200",
                        blockNode: "",
                        checkable: "",
                        checkStrictly: "",
                        checkedKeys: selectedKeys.concat(_vm.targetKeys),
                        treeData: _vm.treeDataInfo
                      },
                      on: {
                        check: function(_, props) {
                          _vm.onChecked(
                            _,
                            props,
                            selectedKeys.concat(_vm.targetKeys),
                            itemSelect
                          )
                        },
                        select: function(_, props) {
                          _vm.onChecked(
                            _,
                            props,
                            selectedKeys.concat(_vm.targetKeys),
                            itemSelect
                          )
                        }
                      }
                    })
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }