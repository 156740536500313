



































import {Component, Vue} from 'vue-property-decorator';
import {Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import {deleteEmpty} from '@/utils';
import * as services from './services';

interface BindModal {
    form: any;
    loading: boolean;
    visible: boolean;
}

@Component({
    components: {
        Click
    }
})
class BindModal extends Vue {
    public visible = false;
    public PRIVILEGE = PRIVILEGE;

    public data() {
        return {
            loading: false,
            form: this.$form.createForm(this)
        };
    }

    public handleClose() {
        if (this.loading) {
            return;
        }
        this.visible = false;
    }

    public onClick() {
        this.visible = true;
    }

    public handleSubmit(e: any) {
        e.preventDefault();
        this.form.validateFields(async (error: any, values: any) => {

            if (!error) {
                this.loading = true;
                // tslint:disable-next-line:variable-name
                const store_id = this.$store.state.shopSearchPage.selectedRows;
                let offline_terminal_id;
                let merchant_no;
                this.$store.state.shopSearchPage.list.forEach(function (item:any) {
                    if(store_id == item.id){
                        offline_terminal_id= item.offline_terminal_id,
                        merchant_no = item.merchant_no
                    }
                })
                 if(!offline_terminal_id || !merchant_no ){
                     this.$message.error('商户号或线下终端号为空情况下不能绑定SN');
                     this.loading = false;
                     return;
                 }
                 if(18 != values.sn.length && 19 != values.sn.length ){
                     this.$message.error('SN有误，请输入正确SN');
                     this.loading = false;
                     return;
                     
                 }
                let name = values.name;
                if(19 === values.sn.length){
                    name = values.name || 'Q码收款码';
                }else{
                    name = values.name || '拉卡拉收款码';
                }
                const ret = await services.bind(deleteEmpty({
                    ...values,
                    name,
                    offline_terminal_id,
                    merchant_no
                }));
                this.loading = false;
                if (ret) {
                    this.visible = false;
                    this.$store.dispatch('shopSearchPage/search');
                }
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
}

export default BindModal;
