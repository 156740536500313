







import {Component, Prop, Vue} from 'vue-property-decorator';
import {request_yx} from '@/utils';
import { Modal } from 'ant-design-vue';

@Component({
    components: {}
})
class Role extends Vue {

    @Prop()
    public value!: string;

    public items = [];
    public flag = false;
    public loading = false;
    public tmpValue!: string;
    public policyData = [];

    public async created() {
        this.fetch();
    }

    public async fetch() {
        try {
            this.items = [];
            this.loading = true;
            
            const ret =  await request_yx({
                api: '/console/activity/policy/queryList',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const list = ret;
            this.items = list.map((i: any) => ({
                templetId: i.templetId,
                templetText:i.templetId+'_'+i.name
            }));
            this.policyData = list
        } catch (e) {
                this.$message.error('获取列表失败, 请联系技术人员!');
        } finally {
            this.loading = false;
        }
    }

    public dropdownVisibleChange(open: number) {
        console.log('on dropdownVisibleChange:', open);
        if (open) {
            this.fetch();
        }
    }

    public data() {
        return {
            dataSource: []
        };
    }


    public onInputKeyDown(value:String){
        console.log(this.flag)
        if(this.flag){
            Modal.confirm({
                title: '提示',
                content: '更新政策后页面将重新赋值',
                onOk: () => {
                   
                },
                onCancel() {
                    // do nothing
                }
            });
        }
    }

    public handleChange(value: string) {
        const that = this
        if (that.flag) {
            Modal.confirm({
                title: '提示',
                content: '政策ID改变会初始化页面参数',
                onOk: () => {
                    that.changeVal(value, that);
                },
                onCancel() {
                    that.value = that.tmpValue
                }
            });
        } else {
            that.changeVal(value, that);
        }
    }

    public async changeVal(value: string, that: any) {
        let formData = {}
        if (value == undefined) {
            formData = {}
        } else {
            const ret = await request_yx({
                api: '/console/activity/policy/getActivityInfo',
                method: 'POST',
                data: {templetId:value},
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
            });
            formData = ret;
            that.flag = true;
        }
        that.tmpValue = value
        that.$emit('initFormData', formData);
        that.$emit('change', value);
    }

    public filterOption(input: string, option: any) {
        return (
            option.componentOptions.children[0].text
                .toUpperCase()
                .indexOf(input.toUpperCase()) >= 0
        );
    }
}

export default Role;
