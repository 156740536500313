


















import DetailForm from './RefuseDetailForm.vue';
 import {Component,Prop, Vue,Provide, Watch} from 'vue-property-decorator';
 import {Show} from '@/components/Auth';
 import PRIVILEGE from '@/common/privileges';
 import {Click} from '@/components/Auth';
 interface index {
     visible: boolean;
 }
 @Component({
     components: {
         Show,
         DetailForm,
         Click,
     }
 })
 export default class index extends Vue {
     public PRIVILEGE = PRIVILEGE;
     @Prop()
     public title!: string | number;
     @Prop()
     public callQuestions!: any;
     public visible = false;
     public created() {
        

     }

     @Watch('visible')
     public initQueryData(error: any) {
         console.log('dfd'+this.callQuestions);
       
     }
     public handleClose() {
         this.visible = false;
     }

     public handleOK() {
         this.visible = false;
     }

     public onClick() {
         this.visible = true;
     }
 }
