















































import {Modal} from 'ant-design-vue';
import {Component, Inject, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import columns from './columns';
import {normColumns, calcTableScrollX, getTop} from '@/utils';
import {check, Show, Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import AdAssignDetailModal from '@/components/AdAssignDetail/index.vue';
@Component({
    components: {
        Click,
        Show,
        AdAssignDetailModal
    },
    computed: {
        ...mapState({
            selectedRowKeys: (state: any) => state.advertisementSearchPage.selectedRowKeys,
            list: (state: any) =>
                state.advertisementSearchPage.list.map((item: any, idx: any) => ({
                    idx: (state.advertisementSearchPage.page - 1)
                        * state.advertisementSearchPage.pageSize + idx + 1,
                    ...item
                })),
            total: (state: any) => state.advertisementSearchPage.total,
            pageSize: (state: any) => state.advertisementSearchPage.pageSize,
            page: (state: any) => state.advertisementSearchPage.page,
            loading: (state: any) => state.advertisementSearchPage.loading,
            fields: (state: any) => normColumns(
                columns, state.advertisementSearchPage.fields, state.advertisementSearchPage.sortFields),
            updatingStatus: (state: any) => state.advertisementSearchPage.updatingStatus

        })
    }
})
class SearchTable extends Vue {

    @Inject()
    public dispatch!: any;
    public fixed = 0;
    public PRIVILEGE = PRIVILEGE;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + (-top) + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public handleSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('advertisementSearchPage/setSelectedRowKeys', [...selectedRowKeys]);
    }

    public handleSwitchClick(record: any) {
        if (!record.status) {
            Modal.confirm({
                title: '请确认',
                content: '是否已完成公众号配置?',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.dispatch('updateStatus', {
                        id: record.id,
                        status: true
                    });
                }
            });
        } else {
            this.dispatch('updateStatus', {
                id: record.id,
                status: false
            });
        }
    }

    public handleTableChange(pagination: any, filters: any, sorter: any) {
        const {
            field,
            order
        } = sorter;
        this.$store.commit('advertisementSearchPage/setQuery', {
            sort_name: sorter.field && sorter.field.split('.')[sorter.field.split('.').length - 1],
            sequence: sorter.order
        });
        this.$router.push({
            path: `/console/advertisement/search` + this.$store.getters['advertisementSearchPage/queryString']()
        });

    }

    public data() {
        return {
            columns,
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public handlePageChange(page: number) {
        this.$store.commit('advertisementSearchPage/setPagination', {page});
        this.$router.push({
            path: '/console/advertisement/search' + this.$store.getters['advertisementSearchPage/queryString']()
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('advertisementSearchPage/setPagination', {pageSize, page: 1});
        this.$router.push({
            path: '/console/advertisement/search' + this.$store.getters['advertisementSearchPage/queryString']()
        });
    }
}

export default SearchTable;
