










import { mapState } from 'vuex';
import { Component, Inject, Vue } from 'vue-property-decorator';
import CreateModal from './CreateModal.vue';

@Component({
    components: {
        CreateModal
    },
    computed: {
        ...mapState({
            loading() {
                return this.state().loading;
            }
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;

    public handleRefresh() {
        this.dispatch('search');
    }

}
export default Actions;
