var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.formData
    ? _c(
        "div",
        { attrs: { id: "mall-edit-form-wrapper" } },
        [
          _c(
            "a-form",
            {
              staticClass: "mall-edit-form",
              attrs: { form: _vm.form },
              on: { submit: _vm.handleSubmit }
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "商场ID" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "mall_id",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" }
                            ],
                            initialValue: _vm.formData.id
                          }
                        ],
                        expression:
                          "['mall_id', {rules: [{required: true, message: '请填写必填项!'}], initialValue: formData.id}]"
                      }
                    ],
                    attrs: { disabled: true }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "商场名称" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "mall_name",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" }
                            ].concat(_vm.MALL_RULES.NAME),
                            initialValue: _vm.formData.mall_name
                          }
                        ],
                        expression:
                          "['mall_name', {\n                    rules: [\n                        {required: true, message: '请填写必填项!'},\n                        ...MALL_RULES.NAME\n                    ],\n                    initialValue: formData.mall_name}\n                ]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "appID" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "app_id",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" }
                            ].concat(_vm.MALL_RULES.APPID),
                            initialValue: _vm.formData.app_id
                          }
                        ],
                        expression:
                          "['app_id',{\n                    rules: [\n                        {required: true,message: '请填写必填项!'},\n                        ...MALL_RULES.APPID\n                    ],\n                    initialValue: formData.app_id}\n                ]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "PublicKEY" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "public_key",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" }
                            ].concat(_vm.MALL_RULES.PUBLIC_KEY),
                            initialValue: _vm.formData.public_key
                          }
                        ],
                        expression:
                          "['public_key', {\n                    rules: [\n                    { required: true, message: '请填写必填项!' },\n                    ...MALL_RULES.PUBLIC_KEY\n                    ], initialValue: formData.public_key}]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "PrivateKEY" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "private_key",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" }
                            ].concat(_vm.MALL_RULES.PRIVATE_KEY),
                            initialValue: _vm.formData.private_key
                          }
                        ],
                        expression:
                          "['private_key', {\n                    rules: [\n                    { required: true, message: '请填写必填项!'},\n                    ...MALL_RULES.PRIVATE_KEY\n                    ], initialValue: formData.private_key}]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "商场归属" } },
                [
                  _c("ServicePlatform", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "mall_belong",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" }
                            ],
                            initialValue: _vm.formData.mall_belong
                          }
                        ],
                        expression:
                          "['mall_belong', {rules: [{ required: true, message: '请填写必填项!' }], initialValue: formData.mall_belong}]"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "商场地域" } },
                [
                  _c("Region", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "region",
                          { initialValue: _vm.formData.region }
                        ],
                        expression:
                          "['region', {initialValue: formData.region}]"
                      }
                    ],
                    attrs: { province: true, city: true, district: true }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "商户地址" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "address",
                          {
                            rules: [].concat(_vm.MALL_RULES.ADDRESS),
                            initialValue: _vm.formData.address
                          }
                        ],
                        expression:
                          "['address', {\n                    rules: [...MALL_RULES.ADDRESS],\n                    initialValue: formData.address}]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "协议标题" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "agreement_title",
                          {
                            rules: [].concat(_vm.MALL_RULES.AGREEMENT_TITLE),
                            initialValue: _vm.formData.agreement_title
                          }
                        ],
                        expression:
                          "['agreement_title', {\n                    rules: [...MALL_RULES.AGREEMENT_TITLE],\n                    initialValue: formData.agreement_title\n                }]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "协议URL" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "agreement_url",
                          {
                            rules: [].concat(_vm.MALL_RULES.AGREEMENT_URL),
                            initialValue: _vm.formData.agreement_url
                          }
                        ],
                        expression:
                          "['agreement_url', {\n                    rules: [...MALL_RULES.AGREEMENT_URL],\n                    initialValue: formData.agreement_url\n                }]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "商场Logo" } },
                [
                  _c("ImageCrop", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "logo",
                          {
                            initialValue: _vm.formData.logo,
                            rules: [
                              { required: true, message: "请填写必填项!" }
                            ]
                          }
                        ],
                        expression:
                          "['logo', {initialValue: formData.logo, rules: [{required: true, message: '请填写必填项!'}]}]"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { style: { textAlign: "left" }, attrs: { span: 24 } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            disabled: _vm.auditing,
                            loading: _vm.saving
                          }
                        },
                        [_vm._v("\n                    提交\n                ")]
                      ),
                      _c(
                        "a-button",
                        {
                          style: { marginLeft: "8px" },
                          attrs: { disabled: _vm.saving },
                          on: { click: _vm.handleCancel }
                        },
                        [_vm._v("\n                    取消\n                ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }