var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-button", { on: { click: _vm.handleAddAdPlan } }, [
        _vm._v("新增活动")
      ]),
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.ACTIVITIES_CITIC.STOP] },
        on: { pass: _vm.stopActivities },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      disabled: _vm.selectedRows.length === 0 || _vm.loading
                    }
                  },
                  [_vm._v("终止活动")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "a-button",
        { attrs: { disabled: _vm.loading }, on: { click: _vm.handleRefresh } },
        [_vm._v("刷新")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }