



















import RuleForm from './RuleForm.vue';
import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import {deleteEmpty} from '@/utils';
@Component({
    components: {
        Show,
        RuleForm
    }
})
export default class CreatePage extends Vue {
    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public title!: string | number;
    @Prop()
    public activityInfo !: any;
    public activityId:any;
    @Prop()
    public paytype !: string;
    public created() {
    }

    @Watch('activityInfo')
    public initQueryData(error: any) {
        if(!this.activityInfo){
            return; 
        }
        this.activityId = this.activityInfo.activityId;
        sessionStorage.setItem('temp_subActId', this.activityId);
        const query ={
            activityId:this.activityId,
            page: 1,
            r: Math.random()
        };
        this.$store.dispatch('actShopLimitSearchPage/initQuery', query);
    }

    

    @Watch('$store.state.actManageSearchPage.error') 
    public errorCreateListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    public onClose() {
        this.$emit('close');
        sessionStorage.removeItem('temp_subActId');
    }
}
