





import { mapState } from 'vuex';
import { Component, Watch, Inject, Vue } from 'vue-property-decorator';
import PRIVILEGE from '@/common/privileges';
import { Modal } from 'ant-design-vue';

interface Actions {
    selectedRows: any;
    hideMessage: any;
}

@Component({
    components: {},
    computed: {
        ...mapState({
            loading: (state: any) => state.activityMerBatchSearchPage.loading,
            downloading: (state: any) =>
                state.activityMerBatchSearchPage.downloading,
            selectedRows: (state: any) =>
                state.activityMerBatchSearchPage.selectedRows
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;
    public PRIVILEGE = PRIVILEGE;
    public data() {
        return {
            hideMessage: null,
            PRIVILEGE
        };
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }

    public handleRefresh() {
        this.$router.push({
            path:
                '/console/activityMerBatch/search' +
                this.$store.getters['activityMerBatchSearchPage/queryString']()
        });
    }

    @Watch('$store.state.activityMerBatchSearchPage.downloading')
    public watchDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }

    @Watch('$store.state.activityMerBatchSearchPage.downloadError')
    public watchDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error(error.message);
        }
    }

    @Watch('$store.state.activityMerBatchSearchPage.lastDownloadTime')
    public watchLastDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }
}

export default Actions;
