






































































import { Modal } from 'ant-design-vue';
import { mapState } from 'vuex';
import {
    Component,
    Prop,
    Watch,
    Provide,
    Vue,
    Inject
} from 'vue-property-decorator';
import columns from './columns';
import columnsUnion from './columnsUnion';
import * as services from './services';
import { check } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import { normColumns, calcTableScrollX, getTop } from '@/utils';
import ClearText from '@/components/ClearText.vue';
import DetailDrawer from '../../Draft/DetailPage/index.vue';
import DetailDrawerUnion from '../../Draft/DetailPageUnion/index.vue';
import EditActDrawer from '../../Draft/EditActPage/index.vue';
import EditActDrawerUnion from '../../Draft/EditActPageUnion/index.vue';
import * as Constant from '@/common/constant';
@Component({
    components: {
        ClearText,
        DetailDrawer,
        DetailDrawerUnion,
        EditActDrawer,
        EditActDrawerUnion
    },
    computed: {
        ...mapState({
            lists: (state: any) =>
                state.activitiesStopSearchPage.list.map(
                    (order: any, idx: any) => ({
                        idx:
                            (state.activitiesStopSearchPage.page - 1) * 10 +
                            idx +
                            1,
                        ...order
                    })
                ),
            total: (state: any) => state.activitiesStopSearchPage.total,
            pageSize: (state: any) => state.activitiesStopSearchPage.pageSize,
            page: (state: any) => state.activitiesStopSearchPage.page,
            loading: (state: any) => state.activitiesStopSearchPage.loading,
            fields: (state: any) =>
                normColumns(
                    state.activityManage.payType == Constant.WX_TYPE
                        ? columns
                        : columnsUnion,
                    state.activitiesStopSearchPage.fields,
                    state.activitiesStopSearchPage.sortFields
                ),
            selectedRowKeys: (state: any) =>
                state.activitiesStopSearchPage.selectedRows
            // rowSelection(state: any) {
            //     const self: any = this;
            //     const selectedRowKeys = state.activitiesStopSearchPage.selectedRows;
            //     return {
            //         selectedRowKeys,
            //         onChange: self.onSelectChange,
            //         hideDefaultSelections: true
            //     };
            // }
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public privileges!: any;

    @Inject()
    public commit!: any;

    @Inject()
    public routerPush!: any;

    public currentColumns = columnsUnion;
    @Prop()
    public paytype!: String;
    public currentPaytype!: String;

    public fixed = 0;
    public style = '';
    public editAct = null;
    public editActUnion = null;
    public detail = null;
    public detailUnion = null;
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            columns,
            calcTableScrollX,
            loadingIcon: (
                <span>
                    <a-icon type="loading" /> 加载中
                </span>
            )
        };
    }

    public onSelectChange(selectedRowKeys: any[], selectedRows: any[]) {
        this.$store.commit(
            'activitiesStopSearchPage/setSelectedRows',
            selectedRowKeys
        );
    }
    public handleSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('activitiesStopSearchPage/setSelectedRows', [
            ...selectedRowKeys
        ]);
    }

    public handlePageChange(page: number) {
        this.commit('setPagination', { page });
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.commit('setPagination', { pageSize, page: 1 });
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }
    public handleDrawerOpenClick(type: 'detail' | 'editAct', id: any) {
        this[type] = id;
    }

    public handleDrawerClose(type: 'detail' | 'editAct') {
        this[type] = null;
    }
    public created() {
        
        this.currentPaytype = this.$store.state.activityManage.payType
        console.info("currentPaytype",this.currentPaytype)
        
    }

    @Watch('$store.state.activityManage.payType') 
    public payTypeChange(newVal: String, oldVal: String) {
        
        this.currentPaytype = this.$store.state.activityManage.payType
    }
    
}

export default SearchTable;
