var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "operate-log-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.lists,
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 62),
            y: _vm.fixed || undefined
          },
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: function(total) {
              return "总计 " + total + " 条记录"
            },
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange
          }
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "argument",
            fn: function(text) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(text) +
                            "\n                "
                        )
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            JSON.stringify(text).substring(0, 22) + "..."
                          ) +
                          "\n            "
                      )
                    ],
                    2
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }