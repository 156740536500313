













import {Component, Vue} from 'vue-property-decorator';
import * as services from './services';
import {Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component<PrivilegeAssignModal>({
    components: {
        Click
    },
    computed: {}
})
class PrivilegeAssignModal extends Vue {

    public visible = false;
    public name = '';
    public loading = false;
    public PRIVILEGE = PRIVILEGE;

    public handleClose() {
        if (this.loading) {
            return;
        }
        this.name = '';
        this.visible = false;
    }

    public onClick() {
        this.visible = true;
    }

    public async handleOk() {
        if (this.name.replace(/\s/g, '').length === 0) {
            return this.$message.warning('请输入有效的角色名');
        }
        try {
            this.loading = true;
            await services.createRole({
                name: this.name
            });
            this.name = '';
            this.visible = false;
            this.$emit('success');
        } catch (e) {
            this.$message.error(e.message);
        } finally {
            this.loading = false;
        }
    }
}

export default PrivilegeAssignModal;
