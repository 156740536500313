var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        destroyOnClose: "",
        title: _vm.title,
        visible: !!_vm.detail,
        width: 800,
        placement: "right",
        closable: ""
      },
      on: { close: _vm.onClose }
    },
    [
      _c(
        "div",
        [
          [
            _c(
              "div",
              { staticClass: "ad_detail" },
              [
                _c("DetailForm", {
                  attrs: {
                    expenseAccount: _vm.expenseAccount,
                    name: _vm.name,
                    ownerId: _vm.ownerId,
                    description: _vm.description
                  }
                })
              ],
              1
            )
          ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }