










































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import moment from 'moment';
import Mall from '@/components/custom-form-components/Mall.vue';
import BizDistrict from '@/components/custom-form-components/BizDistrict.vue';

import Region from '@/components/custom-form-components/Region.vue';

import ServicePlatform from '@/components/custom-form-components/ServicePlatform';
// import BizType from '@/components/custom-form-components/BizType.vue';
import NewBizType from '@/components/custom-form-components/NewBizType.vue';
import StoreStatus from '@/components/custom-form-components/StoreStatus';
import qs from 'querystringify';
import {deleteEmpty} from '@/utils';
import {DatePicker,ShopMessageStatus} from '@/components/custom-form-components';
import formRules from '@/common/formRules';


interface SearchForm {
    form: any;
}

interface QueryParams {
    store_name: string;
    merchant_no: string;
    sn: string;
    online_terminal_id: string;
    offline_terminal_id: string;
    mobile: string;
    store_type: string;
    mall_name: number;
    mallcoo_shop_id: string;
    address: string;
    // floor_door_no: string;
    // status: string;
    province: string;
    city: string;
    district: string;
    mcc_min_name: string;
    mcc_middle_name: string;
    mcc_max_name: string;
    biz_district: string;
    refund_open: string;
    start_time: string;
    end_time: string;
    sms_switch:string;
    daily_limit:string;
}

@Component({
    components: {
        Region,
        BizDistrict,
        Mall,
        ServicePlatform,
        // BizType,
        NewBizType,
        StoreStatus,
        DatePicker,
        ShopMessageStatus
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.shopSearchPage.query);
            },
            loading: (state: any) => state.shopSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    public expand: boolean = false;
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this),
            formRules
        };
    }

    public toggle() {
        this.expand = !this.expand;
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/shop/search?${qs.stringify(query)}`
            });
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any): QueryParams {
        return {
            store_name: values.store_name,
            merchant_no: values.merchant_no,
            sn: values.sn,
            online_terminal_id: values.online_terminal_id,
            offline_terminal_id: values.offline_terminal_id,
            mobile: values.mobile,
            store_type: values.store_type,
            mall_name: values.mall_name,
            mallcoo_shop_id: values.mallcoo_shop_id,
            address: values.address,
            province: values.region && values.region[0],
            city: values.region && values.region[1],
            district: values.region && values.region[2],
            mcc_max_name: values.biz_type && values.biz_type[0],
            mcc_middle_name: values.biz_type && values.biz_type[1],
            mcc_min_name: values.biz_type && values.biz_type[2],
            biz_district: values.biz_district,
            refund_open: values.refund_open,
            daily_limit: values.daily_limit,
            sms_switch: values.sms_switch,
            start_time:
                values.range_create_time &&
                values.range_create_time[0] &&
                values.range_create_time[0].format('YYYY-MM-DD HH:mm:ss'),
            end_time:
                values.range_create_time &&
                values.range_create_time[1] &&
                values.range_create_time[1].format('YYYY-MM-DD HH:mm:ss'),
        };
    }

    private formatFormData(query: any) {
        return {
            ...query,
            biz_type: [query.mcc_max_name, query.mcc_middle_name, query.mcc_min_name],
            mall_name: query.mall_name,
            daily_limit: query.daily_limit,
            sms_switch: query.sms_switch,
            biz_district: query.biz_district,
            region: [query.province, query.city, query.district].map((r: any) => r || undefined),
            range_create_time: [
                query.start_time && moment(query.start_time),
                query.end_time && moment(query.end_time)
            ]
        };
    }
}

export default SearchForm;
