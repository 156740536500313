

























import {mapState} from 'vuex';
import {Component, Watch, Vue} from 'vue-property-decorator';
import {Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import SumModal from './SumModal.vue';

interface Actions {
    hideMessage: any;
}

@Component({
    components: {Click, SumModal},
    computed: {
        ...mapState({
            loading: (state: any) => state.orderSearchPage.loading,
            downloading: (state: any) => state.orderSearchPage.downloading,
            orderStatus: (state: any) => state.orderSearchPage.query.status,
        })
    }
})
class Actions extends Vue {
    public data() {
        return {
            hideMessage: null,
            PRIVILEGE
        };
    }

    public handleRefresh() {
        this.$router.push({
            path:
                '/console/order/search' +
                this.$store.getters['orderSearchPage/queryString']()
        });
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }

    @Watch('$store.state.orderSearchPage.downloading')
    public watchDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }

    @Watch('$store.state.orderSearchPage.downloadError')
    public watchDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error('导出失败，请联系技术人员!');
        }
    }

    @Watch('$store.state.orderSearchPage.lastDownloadTime')
    public watchLastDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }

    public handleDownload() {
        this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
        this.$store.dispatch('orderSearchPage/download');
    }
}

export default Actions;
