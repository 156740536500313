

























import { Component, Prop, Vue, Inject, Provide } from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import SearchTable from './SearchTable.vue';
import SearchForm from './SearchForm.vue';
import store from './store';
import { getValueByPath } from '@/utils';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Drawer,
        SearchTable,
        SearchForm
    }
})
class Config extends Vue {
    @Prop()
    public id!: string;

    @Prop()
    public orgId!: string;

    @Prop()
    public title!: string | number;

    @Inject()
    public privileges!: any;

    public PRIVILEGE = PRIVILEGE;

    @Provide()
    public state() {
        return getValueByPath(this.$store.state, [
            'companyQueryConfSearchPage',
            this.id + this.orgId + '@config'
        ]);
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(
            'companyQueryConfSearchPage/' + this.id + this.orgId + '@config/' + action,
            payload
        );
    }

    @Provide()
    public commit(action: string, payload?: any) {
        this.$store.commit(
            'companyQueryConfSearchPage/' + this.id + this.orgId + '@config/' + action,
            payload
        );
    }

    public created() {
        if (!this.state()) {
            this.$store.registerModule(
                ['companyQueryConfSearchPage', this.id + this.orgId + '@config'],
                store()
            );
            this.$watch(function() {
                return (
                    this.$store.state.companyQueryConfSearchPage[
                        this.id + this.orgId + '@config'
                    ] &&
                    this.$store.state.companyQueryConfSearchPage[this.id + this.orgId + '@config']
                        .error
                );
            }, this.errorListener);
        }
    }

    public errorListener(error: any) {
        if (error && !error.silent) {
            this.$message.error(error.message);
        }
    }

    public handleDrawerOpen() {
        this.dispatch('resetQuery', { id: this.id });
    }
}

export default Config;
