

























import { Component, Prop, Vue, Inject, Provide } from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import SearchTable from './SearchTable.vue';
import SearchForm from './SearchForm.vue';
import store from './store';
import { getValueByPath } from '@/utils';

@Component({
    components: {
        Drawer,
        SearchTable,
        SearchForm
    }
})
class Config extends Vue {
    @Prop()
    public id!: string;

    @Inject()
    public privileges!: any;

    @Provide()
    public state() {
        return getValueByPath(this.$store.state, [
            'sharedQuotaConfSearchPage',
            this.id + '@config'
        ]);
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(
            'sharedQuotaConfSearchPage/' + this.id + '@config/' + action,
            payload
        );
    }

    @Provide()
    public commit(action: string, payload?: any) {
        this.$store.commit(
            'sharedQuotaConfSearchPage/' + this.id + '@config/' + action,
            payload
        );
    }

    public created() {
        if (!this.state()) {
            this.$store.registerModule(
                ['sharedQuotaConfSearchPage', this.id + '@config'],
                store()
            );
            this.$watch(function() {
                return (
                    this.$store.state.sharedQuotaConfSearchPage[
                        this.id + '@config'
                    ] &&
                    this.$store.state.sharedQuotaConfSearchPage[
                        this.id + '@config'
                    ].error
                );
            }, this.errorListener);
        }
    }

    public errorListener(error: any) {
        if (error && !error.silent) {
            this.$message.error(error.message);
        }
    }

    public handleDrawerOpen() {
        this.dispatch('resetQuery', { merGroupId: this.id });
    }
}

export default Config;
