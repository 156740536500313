










import {Component, Prop, Inject, Vue} from 'vue-property-decorator';
import {request, request_yx} from '@/utils';
import {Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Click
    }
})
class ClearText extends Vue {
    @Prop()
    public host?: string;

    @Prop()
    public action!: any;
    @Prop()
    public params!: any;
    @Prop()
    public text!: any;
    @Prop()
    public getClearText!: (result: any) => string;
    @Prop({
        default() {
            return [PRIVILEGE.USER_MOBILE];
        },
        type: Array
    })
    public requires!: string[];

    @Inject()
    public privileges!: any;

    public PRIVILEGE = PRIVILEGE;

    public clearText = this.text || '';

    public loading = false;

    public async handleClick() {

        if (this.clearText !== this.text) {
            this.clearText = this.text;
            return;
        }

        try {
            this.loading = true;
            let ret;
            console.log('this.host', this.host)
            if(this.host === 'request_yx') {
                ret = await request_yx({
                    api: this.action,
                    data: this.params
                });
            } else {
                ret = await request({
                    api: this.action,
                    data: this.params
                });
            }
            
            this.clearText = this.getClearText(ret);
        } catch (e) {
            if (!e.is403) {
                this.$message.error('获取明文失败, 请联系技术人员!');
            }
        } finally {
            this.loading = false;
        }
    }
}

export default ClearText;
