var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        destroyOnClose: "",
        title: _vm.title,
        visible: !!_vm.detail,
        width: 800,
        placement: "right",
        closable: ""
      },
      on: { close: _vm.onClose }
    },
    [
      _c("Show", {
        attrs: { requires: [_vm.PRIVILEGE.SUBJECT.UPDATE] },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "ad_detail" },
                  [
                    _c("EditActForm", {
                      attrs: {
                        activityId: _vm.activityId,
                        expenseAccount: _vm.expenseAccount,
                        name: _vm.name,
                        ownerId: _vm.ownerId,
                        description: _vm.description
                      },
                      on: { onCancel: _vm.onCancel, onClose: _vm.onClose }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }