

























































import {Modal} from 'ant-design-vue';
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import BulkSelector from '@/components/custom-form-components/BulkSelector/index.vue';
import {request, deleteEmpty} from '@/utils';
import ServicePlatform from '@/components/custom-form-components/ServicePlatform';
import * as DICTS from '@/common/dicts';
@Component<MallPanel>({
    components: {
        BulkSelector,
        ServicePlatform
    }
})
class MallPanel extends Vue {

    @Prop()
    public adPlanId!: any;

    @Prop()
    public value!: any;

    public columns = [{
        title: '商场名称',
        dataIndex: 'mall_name'
    }, {
        title: '商场归属',
        dataIndex: 'mall_belong',
        customRender: (belong: string) => {
            return DICTS.SERVICE_PLATFORM[belong] || '-';
        }
    }];
    public loading = false;

    public query: any = {};

    public form: any;

    public handleSelectChange(value: any) {
        this.value = value;
    }

    public created() {
        this.form = this.$form.createForm(this);
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }

            this.query = deleteEmpty({
                ...values
            });
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }
}

export default MallPanel;
