









import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import {request} from '@/utils';

@Component<AdStatusCount>({ })
class AdStatusCount extends Vue {

    public notStarted: any = 0;
    public actived: any = 0;
    public done: any = 0;
    public loading = false;

    public async created() {
        this.fetch();
    }

    public async fetch() {
        this.loading = true;
        try {
            const data = await request({
                api: '/console/ad/plan/count_status'
            });

            this.notStarted = data.not_started;
            this.actived = data.actived;
            this.done = data.done;
        } catch (e) {
            this.$message.error(e.message);
        } finally {
            this.loading = false;
        }
    }

}
export default AdStatusCount;
