









































import {Row, Col, Divider} from 'ant-design-vue';
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    components: {
        [Row.name]: Row,
        [Col.name]: Col,
        [Divider.name]: Divider,
    },
})
export default class SearchCommonLayout extends Vue {
    @Prop()
    public humanReadableQuery!: any
}
