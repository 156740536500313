























































import {Component, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import DistrictBelong from '@/components/custom-form-components/DistrictBelong';
import DatePicker from '@/components/custom-form-components/DatePicker.vue';
import {deleteEmpty} from '@/utils';
import qs from 'querystringify';
import moment from 'moment';
import formRules from '@/common/formRules';

interface SearchForm {
    form: any;
}

export interface Query {
    administrativeDivisionCode: string;
    mapDistrictName: string;
    
}

export interface FormData {
    administrativeDivisionCode: string;
    mapDistrictName: string;
}

@Component({
    components: {
        DistrictBelong,
        DatePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.districtCodeSearchPage.query);
            },
            loading: (state: any) => state.districtCodeSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this),
            formRules
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/districtCode/search?${qs.stringify(query)}`
            });

        });
    }

    public handleReset() {
        // this.form.resetFields();
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: FormData): Query {
        return {
            administrativeDivisionCode: values.administrativeDivisionCode,
            mapDistrictName: values.mapDistrictName
        };
    }

    private formatFormData(query: Query): FormData {
        return {
            administrativeDivisionCode: query.administrativeDivisionCode,
            mapDistrictName: query.mapDistrictName
        };
    }
}

export default SearchForm;
