


































































import { mapState } from 'vuex';
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { Click } from '@/components/Auth';
import VueUeditorWrap from 'vue-ueditor-wrap';
import config from '@/config';
import { request } from '@/utils';
@Component<Editor>({
    components: {
        VueUeditorWrap,
        Click
    },
    computed: {
        editorProps() {
            return {
                key: 'key_' + Editor.inc++,
                config: {
                    serverUrl: config.BASE_URL + '/console/ad/ueditor',
                    autoHeightEnabled: false,
                    initialFrameHeight:
                        this.bodyClientHeight -
                        this.toolBarHeight -
                        this.bottomHeight -
                        5,
                    initialFrameWidth: '100%',
                    UEDITOR_HOME_URL: process.env.BASE_URL + 'UEditor/',
                    imagePopup: false,
                    toolbars: [
                        [
                            'source',
                            '|',
                            'undo',
                            'redo',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            'fontborder',
                            'strikethrough',
                            'superscript',
                            'subscript',
                            'removeformat',
                            'formatmatch',
                            'autotypeset',
                            'blockquote',
                            'pasteplain',
                            '|',
                            'forecolor',
                            'backcolor',
                            'insertorderedlist',
                            'insertunorderedlist',
                            'selectall',
                            '|',
                            'rowspacingtop',
                            'rowspacingbottom',
                            'lineheight',
                            '|',
                            'paragraph',
                            'fontfamily',
                            'fontsize',
                            '|',
                            'indent',
                            '|',
                            'justifyleft',
                            'justifycenter',
                            'justifyright',
                            'justifyjustify',
                            '|',
                            'touppercase',
                            'tolowercase',
                            '|',
                            'link',
                            'unlink',
                            '|',
                            'imagenone',
                            'imageleft',
                            'imageright',
                            'imagecenter',
                            '|',
                            'simpleupload',
                            'emotion',
                            'insertvideo',
                            'map',
                            'pagebreak',
                            '|',
                            'horizontal',
                            'spechars',
                            '|',
                            'inserttable',
                            'deletetable',
                            'insertparagraphbeforetable',
                            'insertrow',
                            'deleterow',
                            'insertcol',
                            'deletecol',
                            'mergecells',
                            'mergeright',
                            'mergedown',
                            'splittocells',
                            'splittorows',
                            'splittocols',
                            '|',
                            'print',
                            'searchreplace'
                        ]
                    ]
                }
            };
        }
    }
})
class Editor extends Vue {
    @Prop()
    public initUrl!: any;

    @Prop()
    public privileges!: string[];

    public static inc = 0;

    public bottomHeight = 0;

    public toolBarHeight = 0;

    public bodyClientHeight = document.body.clientHeight;

    public content = '';

    public url = '';

    public visible = false;

    public initing = false;

    public generating = false;

    public contentCache = '';

    public initUrlCache = '';

    public handleCancel() {
        this.initUrlCache = this.initUrl;
        this.visible = false;
    }

    public handleTriggerClick() {
        this.visible = true;
        if (this.initUrlCache !== this.initUrl) {
            this.initContent();
        }
    }

    public handleEditorReady() {
        this.bottomHeight =
            document.getElementsByClassName(
                'edui-editor-bottomContainer edui-default'
            )[0].clientHeight + 50;
        this.toolBarHeight = document.getElementsByClassName(
            'edui-editor-toolbarboxouter edui-default'
        )[0].clientHeight;
    }

    public resizeHandler() {
        this.bodyClientHeight = document.body.clientHeight;
    }

    public copyHandler() {
        const url: any = (this.$refs.url as any).$el;
        url.select();
        window.document.execCommand('Copy');
        this.$message.success('已复制');
    }

    public async initContent() {
        this.initing = true;

        try {
            if (!this.initUrl) {
                return;
            }

            const content = await request({
                method: 'GET',
                api: '/console/ad/landing_page/content',
                data: {
                    url: this.initUrl
                }
            });
            this.contentCache = this.content = content;
            if (content) {
                this.url = this.initUrl;
            } else {
                this.url = '';
            }
        } catch (e) {
            const error: any = new Error('提交失败, 请联系技术人员!');
            error.silent = e.is403;
            if (!error.silent) {
                this.$message.error(error.message);
            }
        } finally {
            this.initing = false;
        }
    }

    public created() {
        window.addEventListener('resize', this.resizeHandler, false);
    }

    public destoryed() {
        window.removeEventListener('resize', this.resizeHandler);
    }

    public async clickHandler() {
        if (this.contentCache === this.content) {
            return;
        }
        try {
            this.url = '';
            this.generating = true;
            const url = await request({
                method: 'POST',
                api: '/console/ad/landing_page/create',
                data: {
                    content: this.content
                }
            });
            this.url = url;
            this.contentCache = this.content;
        } catch (e) {
            const error: any = new Error('提交失败, 请联系技术人员!');
            error.silent = e.is403;
            if (!error.silent) {
                this.$message.error(error.message);
            }
        } finally {
            this.generating = false;
        }
    }
}

export default Editor;
