





































import {Modal,Button} from 'ant-design-vue';
import {Component, Vue, Watch, Inject} from 'vue-property-decorator';
import {Click} from '@/components/Auth';
import {mapState} from 'vuex';
import columns from './columns';
import PRIVILEGE from '@/common/privileges';
interface SearchTable {
    visible: boolean;
}
@Component({
    components: {
        Click
    },
    computed: {
        ...mapState({
            list() {
                return this.state().list;
            },
            
            loading() {
                return this.state().loading;
            },
            unbinding() {
                return this.state().unbindSnIng;
            },
            lastUnbindTime() {
                return this.state().lastUnbindTime;
            }
        })
    }
})
class SearchTable extends Vue {

    @Inject()
    public state!: any;
    @Inject()
    public dispatch!: any;

    public PRIVILEGE = PRIVILEGE;

    public data() {
        return {
            columns,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>,
            visible: false
        };
    }

    @Watch('lastUnbindTime')
    public watchUnbindSuccessed(lastUnbindTime: any) {
        console.log(lastUnbindTime,4444)
        this.$message.success('解绑成功');
        this.$store.dispatch('shopSearchPage/search');
    }

    @Watch('error')
    public watchUnbindError(error: any) {
        this.$message.error(error);
    }

    public handleUnbindClick(record: any) {
        if (record.status !== 1) {
            return false;
        }
        Modal.confirm({
            title: '提示',
            content: '是否确认解绑?',
            onOk: () => {
                this.dispatch('unbind', {
                        sn: record.qrCode
                 });
            },
            onCancel() {
                // do nothing
            }
        });
    }

    public handleOk(e:any) {
    console.log(e);
    // this.visible = false;
   }

    public handlePageChange(page: number) {
        this.dispatch('pageChange', page);
    }

    public handlePageSizeChange(current: number, size: number) {
        this.dispatch('pageSizeChange', size);
    }
}

export default SearchTable;
