















import {AutoComplete} from 'ant-design-vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {request} from '@/utils';

interface Mall {
    options: any[];
    loading: boolean;
}

@Component({
    components: {
        [AutoComplete.name]: AutoComplete
    }
})
class Mall extends Vue {
    @Prop()
    public value!: string;

    @Prop({default: false})
    public showTip!: boolean;

    public async created() {
        this.loading = true;
        try {
            const ret = await request({
                api: '/console/mall/simple_list',
                data: {page: 1, page_size: 1000}
            });

            const list = ret.list;

            this.options = list.map((data: any) => {
                return {
                    value: data.id,
                    label: data.name
                };
            });
        } catch (e) {
            // do nothing
        } finally {
            this.loading = false;
        }
    }

    public data() {
        return {
            options: [],
            loading: false
        };
    }

    public handleChange(value: string) {
        this.$emit('change', value);
    }
}

export default Mall;
