var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.ACTIVITY_INSPECTIOON.LIST] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [_vm._t("trigger")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            title: "审核",
            visible: _vm.visible,
            width: 900
          },
          on: { ok: _vm.handleOK, cancel: _vm.handleClose }
        },
        [
          [
            _c(
              "div",
              { staticClass: "custom-form-item-wrapper" },
              [
                _c(
                  "a-form",
                  { staticClass: "ad-create-form", attrs: { form: _vm.form } },
                  [
                    _c(
                      "a-form-item",
                      { attrs: { label: "意见类型" } },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["type", { initialValue: "1" }],
                                expression: "['type', {initialValue: '1'}]"
                              }
                            ],
                            on: { change: _vm.typeChange }
                          },
                          [
                            _c("a-select-option", { attrs: { value: "1" } }, [
                              _vm._v("名额已满")
                            ]),
                            _c("a-select-option", { attrs: { value: "2" } }, [
                              _vm._v("不符合活动方要求")
                            ]),
                            _c("a-select-option", { attrs: { value: "3" } }, [
                              _vm._v("终端不符合要求")
                            ]),
                            _c("a-select-option", { attrs: { value: "4" } }, [
                              _vm._v("活动已结束")
                            ]),
                            _c("a-select-option", { attrs: { value: "5" } }, [
                              _vm._v("其他")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { attrs: { label: "审核意见" } },
                      [
                        _c("a-textarea", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "auditOpinion",
                                { initialValue: _vm.auditOpinion },
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "请填写必填项!"
                                    },
                                    {
                                      pattern: /^.{0,50}$/,
                                      message: "限长度限制200字符"
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                                    'auditOpinion',{initialValue: auditOpinion},\n                                    {rules: [{required: true, message: '请填写必填项!'},{pattern: /^.{0,50}$/, message: '限长度限制200字符'}]}\n                                ]"
                            }
                          ],
                          attrs: {
                            placeholder: "长度限制200字符",
                            autosize: { minRows: 6, maxRows: 6 }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }