var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Show", {
    attrs: { requires: [_vm.PRIVILEGE.MALL.UPDATE], message: "无权限编辑" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function(slotProps) {
          return [_c("Index")]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }