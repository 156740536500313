var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Show", {
    attrs: { requires: [_vm.PRIVILEGE.UNION_ACTIVITIES_DRAFT.CREATE] },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("div", { staticClass: "ad_create" }, [_c("CreateForm")], 1)
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }