

















































import { Modal } from 'ant-design-vue';
import { mapState } from 'vuex';
import * as services from './services';

import { check } from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import { normColumns, calcTableScrollX, getTop } from '@/utils';
import EditDrawer from '../../account/editPage/index.vue';
import { request_yx } from '@/utils';
import columns from './columns';
import {
    Component,
    Prop,
    Watch,
    Provide,
    Vue,
    Inject
} from 'vue-property-decorator';
import * as Constant from '@/common/constant';

interface SearchTable {}

@Component({
    components: {
        EditDrawer
    },
    computed: {
        ...mapState({
            lists: (state: any) =>
                state.accountActivitySearchPage.list.map(
                    (order: any, idx: any) => ({
                        idx:
                            (state.accountActivitySearchPage.page - 1) * 10 +
                            idx +
                            1,
                        ...order
                    })
                ),
            total: (state: any) => state.accountActivitySearchPage.total,
            pageSize: (state: any) => state.accountActivitySearchPage.pageSize,
            page: (state: any) => state.accountActivitySearchPage.page,
            loading: (state: any) => state.accountActivitySearchPage.loading,
            selectedRowKeys: (state: any) =>
                state.accountActivitySearchPage.selectedRowKeys,
            fields: (state: any) =>
                normColumns( columns,
                    state.accountActivitySearchPage.fields,
                    state.accountActivitySearchPage.sortFields
                )
        })
    }
})
class SearchTable extends Vue {
    @Inject()
    public state!: any;
    @Inject()
    public dispatch!: any;
    @Inject()
    public commit!: any;
    @Inject()
    public getters!: any;
    @Inject()
    public routerPush!: any;
    @Inject('privileges')
    public ownPrivileges!: any;

    public currentColumns = columns;
    @Prop()
    public paytype!: String;
    public fixed = 0;
    public style = '';
    public detail = null;
    public detail = null;
    public editAct = null;
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public data() {
        return {
            columns,
            calcTableScrollX,
            loadingIcon: (
                <span>
                    <a-icon type="loading" /> 加载中
                </span>
            )
        };
    }

    public created() {
    }

    public handleSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('accountActivitySearchPage/setSelectedRows', [
            ...selectedRowKeys
        ]);
    }

    public handlePageChange(page: number) {
        this.commit('setPagination', { page });
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.commit('setPagination', { pageSize, page: 1 });
        this.routerPush();
        this.dispatch('initQuery', this.$route.query);
    }

    public handleAssignSuccess(type: 'detail' | 'editAct') {
        this[type] = null;
        this.dispatch('search');
    }
    public handleDrawerOpenClick(type: 'detail' | 'editAct', id: any) {
        this[type] = id;
    }

    public handleDrawerClose(type: 'detail' | 'editAct') {
        this[type] = null;
    }
}

export default SearchTable;
