















































import {Modal} from 'ant-design-vue';
import {Component, Inject, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import columns from './columns';
import {normColumns, calcTableScrollX, getTop} from '@/utils';
import {check, Show, Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import AdAssignDetailModal from '@/components/AdAssignDetail/index.vue';
const sortFieldMap: any = {
    'price': 'price',
    'created_time':'created_time'
};
@Component({
    components: {
        Click,
        Show,
        AdAssignDetailModal
    },
    computed: {
        ...mapState({
            selectedRowKeys: (state: any) => state.shortMessageSearchPage.selectedRowKeys,
            list: (state: any) =>
                state.shortMessageSearchPage.list.map((item: any, idx: any) => ({
                    idx: (state.shortMessageSearchPage.page - 1)
                        * state.shortMessageSearchPage.pageSize + idx + 1,
                    ...item
                })),
            total: (state: any) => state.shortMessageSearchPage.total,
            pageSize: (state: any) => state.shortMessageSearchPage.pageSize,
            page: (state: any) => state.shortMessageSearchPage.page,
            loading: (state: any) => state.shortMessageSearchPage.loading,
            updatingStatus: (state: any) => state.shortMessageSearchPage.updatingStatus,
            fields: (state: any) => {
                const {
                    sort_name,
                    sequence
                } = state.shortMessageSearchPage.query;
                columns.forEach((col: any) => {
                    if (sortFieldMap[col.dataIndex] === sort_name) {
                        col.sortOrder = String(sequence) === 'True' ? 'ascend' : 'descend';
                    } else {
                        col.sortOrder = false;
                    }
                });
                return normColumns(columns, state.shortMessageSearchPage.fields, state.shortMessageSearchPage.sortFields);
            },
        })
    }
})
class SearchTable extends Vue {

    @Inject()
    public dispatch!: any;
    public fixed = 0;
    public PRIVILEGE = PRIVILEGE;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + (-top) + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }

    public handleSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('shortMessageSearchPage/setSelectedRowKeys', [...selectedRowKeys]);
    }

    
    public data() {
        return {
            columns,
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public handlePageChange(page: number) {
        this.$store.commit('shortMessageSearchPage/setPagination', {page});
        this.$router.push({
            path: '/console/short-message/search' + this.$store.getters['shortMessageSearchPage/queryString']()
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('shortMessageSearchPage/setPagination', {pageSize, page: 1});
        this.$router.push({
            path: '/console/short-message/search' + this.$store.getters['shortMessageSearchPage/queryString']()
        });
    }
    public handleTableChange(pagination: any, filters: any, sorter: any) {
        const {
            sort_name,
            sequence
        } = this.$store.state.shortMessageSearchPage.query;
        const {
            field,
            order
        } = sorter;

        function isFieldEqual() {
            if ((field === undefined || field === 'idx') && sort_name === undefined) {
                return true;
            }
            if (sortFieldMap[field] === sort_name) {
                return true;
            }
            return false;
        }

        function isOrderEqual() {
            if ((order === undefined || field === 'idx') && sequence === undefined) {
                return true;
            }
            if (
                (order === 'descend' && sequence === 'false')
                || (order === 'ascend' && sequence === 'true')
            ) {
                return true;
            }
            return false;
        }

        if (isOrderEqual() && isFieldEqual()) {
            return;
        }

        const newQuery: any = {};

        if (field === undefined || order === undefined) {
            newQuery.sort_name = undefined;
            newQuery.sequence = undefined;
        } else {
            newQuery.sort_name = sortFieldMap[field];
            newQuery.sequence = order === 'descend' ? 'False' : 'True';
        }

        this.$store.commit('shortMessageSearchPage/setQuery', newQuery);
        this.$store.commit('shortMessageSearchPage/setPagination', {page: 1});
        this.$router.push({
            path: `/console/short-message/search` + this.$store.getters['shortMessageSearchPage/queryString']()
        });
    }
}

export default SearchTable;
