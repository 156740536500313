










































import {Modal} from 'ant-design-vue';
import {Component, Inject, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import * as DICTS from '@/common/dicts';
import columns from './columns';
import {normColumns, calcTableScrollX, getTop} from '@/utils';
import {check, Show, Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';
import * as services from '../services';
interface SearchTable {
    selectedRowKeys: any;
}

@Component({
    components: {
        Click,
        Show
    },
    computed: {
        
        ...mapState({
            list: (state: any) =>
                state.StatementOperatePage.list.map((item: any, idx: any) => ({
                    idx: (state.StatementOperatePage.page - 1) * state.StatementOperatePage.pageSize + idx + 1,
                    ...item
                })),
            total: (state: any) => state.StatementOperatePage.total,
            pageSize: (state: any) => state.StatementOperatePage.pageSize,
            page: (state: any) => state.StatementOperatePage.page,
            loading: (state: any) => state.StatementOperatePage.loading,
            fields: (state: any) => normColumns(
                columns, state.StatementOperatePage.fields, state.StatementOperatePage.sortFields),
            updatingStatus: (state: any) => state.StatementOperatePage.updatingStatus,
            rowSelection(state: any) {
                const self: any = this;
                const selectedRowKeys = state.StatementOperatePage.selectedRows;
                return {
                    selectedRowKeys,
                    onChange: self.onSelectChange,
                    hideDefaultSelections: true
                };
            },
        })
    }
})
class SearchTable extends Vue {

    @Inject()
    public dispatch!: any;
    public fixed = 0;
    public PRIVILEGE = PRIVILEGE;
    @Inject()
    public privileges!: any;
    public style = '';
    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + (-top) + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }
    
    public data() {
        return {
            DICTS,
            columns,
            selectedRowKeys: [],
            calcTableScrollX,
            loadingIcon: <span><a-icon type='loading'/> 加载中</span>
        };
    }

    public onSelectChange(selectedRowKeys: any[]) {
        this.$store.commit('StatementOperatePage/setSelectedRows', selectedRowKeys);
    }

    public handlePageChange(page: number) {
        this.$store.commit('StatementOperatePage/setPagination', {page});
        this.$router.push({
            path: '/console/gray/search' + this.$store.getters['StatementOperatePage/queryString']()
        });
    }

    public handlePageSizeChange(current: number, pageSize: number) {
        this.$store.commit('StatementOperatePage/setPagination', {pageSize, page: 1});
        this.$router.push({
            path: '/console/gray/search' + this.$store.getters['StatementOperatePage/queryString']()
        });
    }
    public async handleConfirm(date: any,type: any) {
        const list = check([PRIVILEGE.STATEMENT.CREATE], this.privileges);
        if (list.length > 0) {
            return this.$message.error(
                '需要权限: [' + list.map((l: any) => l.text).join(', ') + ']'
            );
        } else {
            let typeCode = 1
            if('微信' === type){
                typeCode = 2
            }
            Modal.confirm({
                title: '确认重新发起对账?',
                onOk: async () => {
                    try {
                        await services.recon({
                            date: date,
                            type:typeCode
                        });
                        this.dispatch('search');
                    } catch (e) {
                        this.$message.error(e.message);
                    }
                }
            });
        }
    }
}

export default SearchTable;
