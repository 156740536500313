







































import {Modal} from 'ant-design-vue';
import {mapState} from 'vuex';
import {Component, Inject, Vue} from 'vue-property-decorator';
import CreateModal from './CreateModal.vue';
import {Click} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

interface Actions {
    selectedRows: any;
}

@Component({
    components: {
        CreateModal,
        Click
    },
    computed: {
        ...mapState({
            loading() {
                return this.state().loading;
            },
            selectedRows: (state: any) => state.whitelistSearchPage.selectedRows
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;

    public data() {
        return {
            PRIVILEGE
        };
    }

    public handleRefresh() {
        this.dispatch('search');
    }

    public openDebugOpen() {
        Modal.confirm({
            title: '提示',
            content: '确认开启调试?',
            onOk: () => {
                this.$store.dispatch('whitelistSearchPage/openDebug', true);
            },
            onCancel() {
                // do nothing
            }
        });
    }

    public openDebugClose() {
        Modal.confirm({
            title: '提示',
            content: '确认关闭调试?',
            onOk: () => {
                this.$store.dispatch('whitelistSearchPage/openDebug', false);
            },
            onCancel() {
                // do nothing
            }
        });
    }

    public deleteCustomer() {
        Modal.confirm({
            title: '提示',
            content: '该会员授权及信息将被删除！',
            onOk: () => {
                this.$store.dispatch('whitelistSearchPage/deleteCustomer');
            },
            onCancel() {
                // do nothing
            }
        });
    }

}

export default Actions;
