var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.needList.length === 0
    ? _c("span", [_vm._t("content")], 2)
    : _c("span", [
        _c("div", [_vm._v("缺少权限:")]),
        _c(
          "ul",
          _vm._l(_vm.needList, function(need) {
            return _c("li", { key: need.name }, [
              _vm._v("\n            " + _vm._s(need.text) + "\n        ")
            ])
          }),
          0
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }