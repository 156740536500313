var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-tabs",
        {
          attrs: { activeKey: _vm.activeTab, type: "card" },
          on: { change: _vm.tabChange }
        },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "基础规则" } },
            [
              _vm.formData
                ? _c(
                    "a-form",
                    {
                      staticClass: "ad-create-form",
                      attrs: { form: _vm.baseForm }
                    },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "活动名称" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "activityName",
                                  { initialValue: _vm.formData.activityName }
                                ],
                                expression:
                                  "['activityName', {initialValue: formData.activityName}]"
                              }
                            ],
                            attrs: { disabled: true, placeholder: "-" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "主题活动ID" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "gwActivityId",
                                  {
                                    initialValue: _vm.formData.gwActivityId
                                  }
                                ],
                                expression:
                                  "[\n                            'gwActivityId',\n                            {\n                                initialValue: formData.gwActivityId\n                            }\n                        ]"
                              }
                            ],
                            attrs: {
                              type: "number",
                              maxLength: "32",
                              placeholder: "-",
                              disabled: true
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "机构号" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "orgId",
                                  {
                                    initialValue: _vm.formData.orgId
                                  }
                                ],
                                expression:
                                  "[\n                            'orgId',\n                            {\n                               initialValue: formData.orgId\n                            }\n                        ]"
                              }
                            ],
                            attrs: {
                              type: "number",
                              maxLength: "32",
                              placeholder: "-",
                              disabled: true
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "批次号" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "platActivity",
                                  { initialValue: _vm.formData.platActivity }
                                ],
                                expression:
                                  "[\n                            'platActivity',\n                            {initialValue: formData.platActivity}\n                        ]"
                              }
                            ],
                            attrs: { disabled: true, placeholder: "-" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "优惠方式" } },
                        [
                          _c(
                            "a-radio",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["discountWay", { initialValue: 1 }],
                                  expression:
                                    "['discountWay',{initialValue: 1}]"
                                }
                              ],
                              attrs: { checked: true }
                            },
                            [
                              _vm._v(
                                "\n                        定额立减\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "减价金额" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "discountAmount",
                                  {
                                    initialValue:
                                      _vm.formData.discountAmount / 100
                                  }
                                ],
                                expression:
                                  "[\n                            'discountAmount',\n                            {\n                               initialValue: formData.discountAmount/100\n                            }\n                        ]"
                              }
                            ],
                            staticStyle: { width: "95%" },
                            attrs: { disabled: true, placeholder: "-" }
                          }),
                          _vm._v("  元\n                ")
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "使用门槛" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "payLine",
                                  {
                                    initialValue: _vm.formData.payLine / 100
                                  }
                                ],
                                expression:
                                  "[\n                            'payLine',\n                            {\n                               initialValue: formData.payLine/100\n                            }\n                        ]"
                              }
                            ],
                            staticStyle: { width: "68%" },
                            attrs: { disabled: true, placeholder: "-" }
                          }),
                          _vm._v("  元及以上订单金额可用\n                ")
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "可用时间" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "startTime",
                                  {
                                    initialValue:
                                      _vm.formData.startTime +
                                      "—" +
                                      _vm.formData.endTime
                                  }
                                ],
                                expression:
                                  "[\n                            'startTime',\n                            {\n                               initialValue: formData.startTime +'—'+formData.endTime\n                            }\n                        ]"
                              }
                            ],
                            attrs: { disabled: true, placeholder: "-" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "timeRule",
                                    {
                                      initialValue:
                                        _vm.formData.availableTime.type
                                    }
                                  ],
                                  expression:
                                    "['timeRule', {initialValue: formData.availableTime.type}]"
                                }
                              ],
                              attrs: { disabled: "" }
                            },
                            [
                              _c(
                                "a-radio",
                                {
                                  style: _vm.radioStyle,
                                  attrs: { value: "0" }
                                },
                                [_vm._v("有效期内，任意时段可用")]
                              ),
                              _c(
                                "a-radio",
                                {
                                  style: _vm.radioStyle,
                                  attrs: { value: "1" }
                                },
                                [_vm._v("有效期内，规则日期可用")]
                              ),
                              _c(
                                "a-radio",
                                {
                                  style: _vm.radioStyle,
                                  attrs: { value: "2" }
                                },
                                [_vm._v("有效期内，不规则日期可用")]
                              )
                            ],
                            1
                          ),
                          _vm.formData.availableTime.type === "1"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "a-checkbox-group",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "timeRule",
                                            {
                                              initialValue:
                                                _vm.formData.weekDays
                                            }
                                          ],
                                          expression:
                                            "['timeRule', {initialValue: formData.weekDays}]"
                                        }
                                      ],
                                      staticStyle: {
                                        "margin-left": "125px",
                                        width: "80%"
                                      },
                                      attrs: { disabled: "" }
                                    },
                                    [
                                      _c(
                                        "a-row",
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "1" } },
                                                [_vm._v("周一")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "2" } },
                                                [_vm._v("周二")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "3" } },
                                                [_vm._v("周三")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "4" } },
                                                [_vm._v("周四")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "5" } },
                                                [_vm._v("周五")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-row",
                                        {
                                          staticStyle: { "margin-top": "6px" }
                                        },
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "6" } },
                                                [_vm._v("周六")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "a-checkbox",
                                                { attrs: { value: "7" } },
                                                [_vm._v("周日")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.formData.availableTime.type === "2"
                            ? _c(
                                "div",
                                { staticStyle: { "margin-left": "125px" } },
                                [
                                  _vm._v("\n                       日期区间："),
                                  _vm._l(
                                    _vm.formData.availableTime.datePeriods,
                                    function(item, index) {
                                      return _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          _vm._s(item) +
                                            "\n                        "
                                        )
                                      ])
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.formData.availableTime.type != "0"
                            ? _c(
                                "div",
                                { staticStyle: { "margin-left": "125px" } },
                                [
                                  _vm._v("时间区间："),
                                  _vm._l(
                                    _vm.formData.availableTime.timePeriods,
                                    function(item, index) {
                                      return _c("span", [
                                        _c("br"),
                                        _vm._v(
                                          _vm._s(item) +
                                            "\n                         "
                                        )
                                      ])
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "订单优惠标记" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "goodsTag",
                                  { initialValue: _vm.formData.goodsTag }
                                ],
                                expression:
                                  "[\n                            'goodsTag',\n                            {initialValue: formData.goodsTag}\n                        ]"
                              }
                            ],
                            attrs: { disabled: true, placeholder: "-" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "出资方" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "sponsorName",
                                  { initialValue: _vm.formData.sponsorName }
                                ],
                                expression:
                                  "[\n                            'sponsorName',\n                            {initialValue: formData.sponsorName}\n                        ]"
                              }
                            ],
                            attrs: { placeholder: "-" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "预警阈值" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "warnRatio",
                                  { initialValue: _vm.formData.warnRatio }
                                ],
                                expression:
                                  "[\n                            'warnRatio',\n                            {initialValue: formData.warnRatio\n                            }\n                        ]"
                              }
                            ],
                            staticStyle: { width: "95%" },
                            attrs: { placeholder: "-" }
                          }),
                          _vm._v("  %\n                ")
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "短信推送号码" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "warnMobile",
                                  {
                                    initialValue: _vm.formData.warnMobile
                                  }
                                ],
                                expression:
                                  "[\n                            'warnMobile',\n                            {\n                               initialValue: formData.warnMobile\n                            }\n                        ]"
                              }
                            ],
                            attrs: { placeholder: "-" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "其他规则" } },
            [
              _vm.formData
                ? _c(
                    "a-form",
                    {
                      staticClass: "ad-create-form",
                      attrs: { form: _vm.baseForm },
                      on: { submit: _vm.handleCreate }
                    },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "参与次数限制" } },
                        [
                          _vm._v(
                            "\n                    活动期间每个用户可参与\n                    "
                          ),
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "timesLimit",
                                  {
                                    initialValue:
                                      _vm.formData.userLimit.countLimit
                                  }
                                ],
                                expression:
                                  "[\n                            'timesLimit',\n                            {initialValue: formData.userLimit.countLimit}\n                        ]"
                              }
                            ],
                            staticStyle: { width: "30%" },
                            attrs: { placeholder: "-", disabled: true }
                          }),
                          _vm._v(
                            "\n                    次活动\n                "
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "discountRule",
                                    { initialValue: _vm.discountRuleCheck }
                                  ],
                                  expression:
                                    "['discountRule', {initialValue: discountRuleCheck}]"
                                }
                              ],
                              attrs: { disabled: "" }
                            },
                            [
                              _c(
                                "a-radio",
                                {
                                  style: _vm.radioStyle,
                                  attrs: { value: "1" }
                                },
                                [_vm._v("不限制用户每天优惠次数")]
                              ),
                              _c(
                                "a-radio",
                                {
                                  style: _vm.radioStyle,
                                  attrs: { value: "2" }
                                },
                                [
                                  _vm._v(
                                    "\n                            每天每个用户最多可获得\n                            "
                                  ),
                                  _vm.discountRuleCheck === "1"
                                    ? _c("a-input", {
                                        staticStyle: { width: "100px" }
                                      })
                                    : _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "dailyCountLimit",
                                              {
                                                initialValue:
                                                  _vm.formData.userLimit
                                                    .dailyCountLimit
                                              }
                                            ],
                                            expression:
                                              "['dailyCountLimit',{initialValue: formData.userLimit.dailyCountLimit}]"
                                          }
                                        ],
                                        staticStyle: { width: "100px" },
                                        attrs: { disabled: true }
                                      }),
                                  _vm._v(
                                    "\n                            次优惠\n                        "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "用户类型" } },
                        [
                          _c(
                            "a-checkbox",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "allUser",
                                    {
                                      valuePropName: "checked",
                                      initialValue: _vm.formData.allUser
                                    }
                                  ],
                                  expression:
                                    "[\n                            'allUser',\n                             {\n                                valuePropName: 'checked',\n                                initialValue: formData.allUser,\n                             }\n                        ]"
                                }
                              ],
                              attrs: { disabled: "" }
                            },
                            [
                              _vm._v(
                                "\n                        所有人\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("a-form-item", { attrs: { label: "活动店铺" } }),
                      _vm.shopData.length > 0
                        ? _c(
                            "a-form-item",
                            { attrs: { label: "" } },
                            [
                              _c("a-table", {
                                attrs: {
                                  columns: _vm.columns,
                                  dataSource: _vm.shopData,
                                  size: "small"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-item",
                        { attrs: { label: "活动预算" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "budgetAmount",
                                  {
                                    initialValue:
                                      _vm.formData.budgetAmount / 100
                                  }
                                ],
                                expression:
                                  "[\n                            'budgetAmount',\n                            {initialValue: formData.budgetAmount/100}\n                        ]"
                              }
                            ],
                            staticStyle: { width: "95%" },
                            attrs: { disabled: true, placeholder: "请输入" }
                          }),
                          _vm._v("  元\n                ")
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "budgetLimit",
                                    { initialValue: _vm.formData.budgetLimit }
                                  ],
                                  expression:
                                    "['budgetLimit', {initialValue: formData.budgetLimit}]"
                                }
                              ],
                              staticStyle: {
                                "margin-left": "120px",
                                width: "40%"
                              },
                              attrs: { disabled: true }
                            },
                            [
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v("设置当天预算消耗上限")
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v("不设置当天预算消耗上限")
                              ])
                            ],
                            1
                          ),
                          _vm.budgetLimitCheck === "1"
                            ? _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "dailyAmountLimit",
                                      {
                                        initialValue:
                                          _vm.formData.dailyAmountLimit / 100
                                      }
                                    ],
                                    expression:
                                      "['dailyAmountLimit',{initialValue: formData.dailyAmountLimit/100}]"
                                  }
                                ],
                                staticStyle: {
                                  "margin-left": "20px",
                                  width: "32.5%"
                                },
                                attrs: { disabled: true, placeholder: "请输入" }
                              })
                            : _vm._e(),
                          _vm.budgetLimitCheck === "1"
                            ? _c("span", [_vm._v(" 元")])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "付款方式" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "payway",
                                    { initialValue: _vm.formData.paywayCheck }
                                  ],
                                  expression:
                                    "['payway', {initialValue: formData.paywayCheck}]"
                                }
                              ],
                              attrs: { disabled: true }
                            },
                            [
                              _c("a-radio", { attrs: { value: "0" } }, [
                                _vm._v("不限定付款方式")
                              ]),
                              _c("a-radio", { attrs: { value: "1" } }, [
                                _vm._v("指定付款方式")
                              ])
                            ],
                            1
                          ),
                          _c("br"),
                          _vm.paywayCheck === "1"
                            ? _c("span", [_vm._v("指定银行卡")])
                            : _vm._e(),
                          _vm.paywayCheck === "1"
                            ? _c(
                                "a-form-item",
                                { attrs: { label: "银行名称" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "bankName",
                                          {
                                            initialValue: _vm.formData.bankName
                                          }
                                        ],
                                        expression:
                                          "['bankName',{initialValue: formData.bankName}]"
                                      }
                                    ],
                                    attrs: { placeholder: "请输入" }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.paywayCheck === "1"
                            ? _c(
                                "a-form-item",
                                { attrs: { label: "卡类型" } },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: [
                                            "cardType",
                                            {
                                              initialValue:
                                                _vm.formData.cardType
                                            }
                                          ],
                                          expression:
                                            "['cardType',{initialValue: formData.cardType}]"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "a-select-option",
                                        { attrs: { value: "-" } },
                                        [_vm._v("请选择")]
                                      ),
                                      _c(
                                        "a-select-option",
                                        { attrs: { value: "DEBIT" } },
                                        [_vm._v("储蓄卡")]
                                      ),
                                      _c(
                                        "a-select-option",
                                        { attrs: { value: "CREDIT" } },
                                        [_vm._v("信用卡")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.paywayCheck === "1"
                            ? _c(
                                "a-form-item",
                                { attrs: { label: "卡bin" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "cardBin",
                                          { initialValue: _vm.formData.cardBin }
                                        ],
                                        expression:
                                          "['cardBin',{initialValue: formData.cardBin}]"
                                      }
                                    ],
                                    attrs: { placeholder: "请输入" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "prompt",
                                  { initialValue: _vm.formData.prompt }
                                ],
                                expression:
                                  "[\n                            'prompt',\n                            {initialValue: formData.prompt}\n                        ]"
                              }
                            ],
                            staticStyle: { width: "95%" },
                            attrs: {
                              disabled: true,
                              placeholder: "-",
                              autosize: { minRows: 6, maxRows: 6 }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }