var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "role-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.roles,
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 0),
            y: _vm.fixed || undefined
          },
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: function(total) {
              return "总计 " + total + " 条记录"
            },
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }