




































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
interface DatePicker {
  endOpen: any;
  endValue: any;
  startValue: any;
}

@Component<DatePicker>({
    components: {

    },
    computed: {
      startValue() {
        return this.value && this.value[0];
      },
      endValue() {
        return this.value && this.value[1];
      }
    }
})
class DatePicker extends Vue {

    @Prop()
    public value!: any;

    @Prop()
    public disabled!: any;

    public data() {
        return {
          endOpen: false,
          defaultStartTime: moment('00:00:00', 'HH:mm:ss'),
          defaultEndTime: moment('23:59:59', 'HH:mm:ss'),
        };
    }

    public handleChange(value: any) {
      this.$emit('change', value);
    }

    public start(val: any) {
        this.$emit('change', [val, this.endValue]);
    }

    public end(val: any) {
        this.$emit('change', [this.startValue, val]);
    }

    public disabledStartDate(startValue: any) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.format('YYYYMMDD') > endValue.format('YYYYMMDD');
    }

    public disabledEndDate(endValue: any) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return endValue.format('YYYYMMDD') < startValue.format('YYYYMMDD');
    }

    public handleStartOpenChange(open: any) {
      if (!open) {
        this.endOpen = true;
      }
    }

    public handleEndOpenChange(open: any) {
      this.endOpen = open;
    }
}
export default DatePicker;
