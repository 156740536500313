








































































































































import {Component, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import DatePicker from '@/components/custom-form-components/DatePicker.vue';
import {deleteEmpty} from '@/utils';
import qs from 'querystringify';
import moment from 'moment';
import formRules from '@/common/formRules';

interface SearchForm {
    form: any;
}

export interface Query {
    activityId: string;
    startTime: string;
    endTime: string;
    termNo:string;
    mercNo:string;
    settleStatus:string;
    accMode:string;
    logNo:string;
    srefNo:string;
    ruleId:string;
}

export interface FormData {
    activityId: string;
    created_time: any[];
    mercNo:string;
    termNo:string;
    settleStatus:string;
    accMode:string;
    logNo:string;
    srefNo:string;
    ruleId:string;
    tradeType: string;
}

@Component({
    components: {
        DatePicker,
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.subsidyDetailSearchPage.query);
            },
            loading: (state: any) => state.subsidyDetailSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this),
            formRules
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            if(values.termNo && !values.mercNo){
                return this.$message.error('终端号必须同商户号绑定查询');
            }
            // let startTime = new Date(values.created_time[0]);
            // let endTIme = new Date(values.created_time[1]);
            if(!values.created_time || !values.created_time[0] || !values.created_time[1]){
                this.$message.error('交易时间区间必填');
                return;
            }
            // if((endTIme - startTime) / (1000 * 60 * 60 * 24)>30){
            //     this.$message.error('订单创建时间区间不能超过30天');
            //     return;
            // }   
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/subsidyDetail/search?${qs.stringify(query)}`
            });

        });
    }

    public handleReset() {
        // this.form.resetFields();
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: FormData): Query {
        return {
            activityId: values.activityId?values.activityId.trim():'',
            termNo:values.termNo,
            mercNo:values.mercNo,
            accMode:values.accMode,
            logNo:values.logNo,
            srefNo:values.srefNo,
            ruleId:values.ruleId,
            settleStatus:values.settleStatus,
            tradeType: values.tradeType,
            startTime: values.created_time &&
                values.created_time[0] &&
                values.created_time[0].format('YYYY-MM-DD HH:mm:ss'),
            endTime: values.created_time &&
                values.created_time[1] &&
                values.created_time[1].format('YYYY-MM-DD HH:mm:ss')
        };
    }

    private formatFormData(query: Query): FormData {
        const d = new Date();
        let startTime = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`+' 00:00:00';
        query = {
            ...query ,
            startTime:startTime,
            endTime:d.toString(),
        }
        return {
            termNo:query.termNo,
            mercNo:query.mercNo,
            accMode:query.accMode,
            logNo:query.logNo,
            srefNo:query.srefNo,
            ruleId:query.ruleId,
            activityId: query.activityId,
            settleStatus:query.settleStatus,
            tradeType: query.tradeType,
            created_time: [
                query.startTime && moment(query.startTime),
                query.endTime && moment(query.endTime)
            ]
        };
    }
}

export default SearchForm;
