























import { Component, Vue } from 'vue-property-decorator';

import PRIVILEGE from '@/common/privileges';
import { Show } from '@/components/Auth';

import SearchPage from './SearchPage/index.vue';
import SearchUnionPage from './SearchUnionPage/index.vue';

@Component({
    components: {
        SearchPage,
        SearchUnionPage,
        Show
    },
    computed: {}
})
class Index extends Vue {
    public activeKey = 'wechat';

    public PRIVILEGE = PRIVILEGE;

    public created() {
        console.info("hexiao index")
        if (this.$route.path === '/console/hexiao/searchPage') {
            this.activeKey = 'wechat';
        } else if (this.$route.path === '/console/hexiao/searchUnionPage') {
            this.activeKey = 'union';
        }
    }

    public handleChange(key: string) {
        this.activeKey = key;

        if (key === 'wechat') {
            this.$router.push({
                path: '/console/hexiao/searchPage'
            });
        } else if (key === 'union') {
            this.$router.push({
                path: '/console/hexiao/searchUnionPage'
            });
        }
    }
}

export default Index;
