



































































































































































































































































































































































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {
    DisableDatePicker,
    ScheduleTimePicker,
    IrregularScheduleTimePicker
} from '@/components/custom-form-components';
import CreateModal from './CreateModal.vue';
import ShopUpload from './ShopUpload.vue';
import * as validators from '../../validators';

interface CreateForm {
    baseForm: any;
    otherForm: any;
}

@Component<CreateForm>({
    components: {
        DisableDatePicker,
        CreateModal,
        ShopUpload,
        ScheduleTimePicker,
        IrregularScheduleTimePicker
    },
    computed: {
        ...mapState({})
    }
})
class CreateForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;

    public baseForm;

    public otherForm;

    public baseData: any;
    public activeTab: any;
    public isShowAllDiscount: boolean = false;
    public timeRuleData: any;
    public timeRulevisible: boolean = false;
    public noTimeRuleData: any;
    public noTimeRulevisible: boolean = false;
    public shopData: any;
    public bankData: any;
    public activitySponsors: any;
    public budgetLimitCheck: any;
    public paywayCheck: any;
    public discountRuleCheck: any;

    public isDefault: boolean = false;

    public conflictReplaceList: any;

    public merchantTotal = 0;

    public validators = validators;
    public timeArrayTemp = [];
    public dateArrayTemp: any = [];
    public created() {}

    public data() {
        return {
            baseData: {},
            baseForm: this.$form.createForm(this),
            otherForm: this.$form.createForm(this),
            activeTab: '1',
            isShowAllDiscount: false,
            timeRuleData: {},
            timeRulevisible: false,
            noTimeRuleData: {},
            noTimeRulevisible: false,
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
                marginLeft: '120px'
            },
            budgetLimit: '2',
            budgetInput: false,
            discountRuleCheck: '1',
            budgetLimitCheck: '2',
            paywayCheck: '0',
            shopData: [],
            bankData: [],
            activitySponsors: [],
            timeArrayTemp: [],
            ateArrayTemp: [],
            columns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo'
                },
                {
                    title: '终端号',
                    dataIndex: 'termNo'
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ]
        };
    }

    public discountRuleChange(e: any) {
        this.discountRuleCheck = e.target.value;
    }

    public timeRuleChange(e: any) {
        if (e.target.value == '1') {
            //.timeRuleData = {};
            this.timeRulevisible = true;
        } else if (e.target.value == '2') {
            //.noTimeRuleData = {};
            this.noTimeRulevisible = true;
        }
    }

    public initTimeRuleData(data: any) {
        this.timeRuleData = data;
    }

    public showAllDiscount(data: any) {
        console.info('showAllDiscount', data);
        if ('1' == data.target.value) {
            this.isShowAllDiscount = false;
        } else {
            this.isShowAllDiscount = true;
        }
    }

    public handleTimeRuleHide() {
        console.log(this.timeRuleData.days);
        if (!this.timeRuleData.days || this.timeRuleData.days.length === 0) {
            this.$message.error('星期不能为空');
            return;
        }
        if (
            !this.timeRuleData.timerange ||
            this.timeRuleData.timerange.length === 0 ||
            this.timeRuleData.timerange.length % 2 !== 0
        ) {
            this.$message.error('时间区间不能为空');
            return;
        }
        for (var rule of this.timeRuleData.timerange) {
            if (rule === null) {
                this.$message.error('时间区间不能为空');
                return;
            }
        }
        this.timeArrayTemp = [];
        for (var i = 1; i <= this.timeRuleData.timerange.length / 2; i++) {
            const startTime = this.timeRuleData.timerange[(i - 1) * 2].format(
                'HH:mm'
            );
            const endTime = this.timeRuleData.timerange[i * 2 - 1].format(
                'HH:mm'
            );
            // @ts-ignore
            this.timeArrayTemp.push(startTime + '-' + endTime);
        }
        this.timeRulevisible = false;
    }

    public handleTimeRuleHideCancel() {
        this.timeRulevisible = false;
    }

    public initNoTimeRuleData(data: any) {
        this.noTimeRuleData = data;
    }

    public handleNoTimeRuleHide() {
        if (
            !this.noTimeRuleData.daterange ||
            this.noTimeRuleData.daterange.length === 0
        ) {
            this.$message.error('日期区间不能为空');
            return;
        }
        for (var drule of this.noTimeRuleData.daterange) {
            if (drule === null) {
                this.$message.error('日期区间不能为空');
                return;
            }
        }
        if (
            !this.noTimeRuleData.timerange ||
            this.noTimeRuleData.timerange.length === 0 ||
            this.noTimeRuleData.timerange.length % 2 !== 0
        ) {
            this.$message.error('时间区间不能为空');
            return;
        }
        for (var rule of this.noTimeRuleData.timerange) {
            if (rule === null) {
                this.$message.error('时间区间不能为空');
                return;
            }
        }
        this.timeArrayTemp = [];
        this.dateArrayTemp = [];
        for (var i = 1; i <= this.noTimeRuleData.timerange.length / 2; i++) {
            const startTime = this.noTimeRuleData.timerange[(i - 1) * 2].format(
                'HH:mm'
            );
            const endTime = this.noTimeRuleData.timerange[i * 2 - 1].format(
                'HH:mm'
            );
            // @ts-ignore
            this.timeArrayTemp.push(startTime + '-' + endTime);
        }

        this.noTimeRuleData.daterange.forEach((date: any) => {
            if (date) {
                const startDate = date[0].format('YYYYMMDD');
                const endDate = date[1].format('YYYYMMDD');
                this.dateArrayTemp.push(startDate + '-' + endDate);
            }
        });
        this.noTimeRulevisible = false;
    }
    public handleNoTimeRuleHideCancel() {
        this.noTimeRulevisible = false;
    }
    public tabChange(key: any) {
        if (key === '2') {
            this.baseForm.validateFields((error: any, values: any) => {
                if (!error) {
                    const reduceAmount = parseFloat(values.reduceAmount);
                    const useSill = parseFloat(values.useSill);

                    if (+reduceAmount > +useSill) {
                        this.$message.error('减价金额必须小于使用门槛金额');
                        return;
                    }
                    if (values.warnRatio > 100) {
                        this.$message.error('预警阈值不能大于100%');
                        return;
                    }
                    if (values.timeRule == '1') {
                        if (
                            this.timeRuleData.days == undefined ||
                            this.timeRuleData.days.length === 0
                        ) {
                            this.$message.error('规则日期天数不能为空错误');
                            return;
                        }
                        if (
                            this.timeRuleData.timerange == undefined ||
                            this.timeRuleData.timerange.length % 2 !== 0
                        ) {
                            this.$message.error('规则日期时间范围错误');
                            return;
                        }
                        for (var rule of this.timeRuleData.timerange) {
                            if (rule == null) {
                                this.$message.error('规则日期时间不能为空');
                                return;
                            }
                        }
                    } else if (values.timeRule == '2') {
                    }
                    this.formatBaseForm(values);
                    this.activeTab = '2';
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        } else {
            console.info('activeTab', key);
            this.activeTab = key;
        }
    }

    public budgetLimitChange(value: any, options: any) {
        this.budgetLimitCheck = value;
        if (value === '2') {
            this.otherForm.setFieldsValue({ budgetLimitInput: '' });
        }
    }

    public paywayChange(e: any) {
        if (e.target.value == '1') {
            this.paywayCheck = '1';
        } else {
            this.paywayCheck = '0';
            this.otherForm.setFieldsValue({ bankName: '' });
            this.otherForm.setFieldsValue({ cardType: '' });
            this.otherForm.setFieldsValue({ cardBin: '' });
        }
    }

    public handleNext(e: any) {
        e.preventDefault();
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                // if (this.isShowAllDiscount) {
                //     const discountRatio = parseFloat(values.discountRatio);
                //     const orderRequire = parseFloat(values.orderRequire);
                //     const limitSingleDiscount = parseFloat(
                //         values.limitSingleDiscount
                //     );

                //     if (discountRatio > 0.99) {
                //         this.$message.error('折扣比例必须不大于0.99');
                //         return;
                //     }
                //     if (orderRequire <= 1) {
                //         this.$message.error('订单需求必须大于1');
                //         return;
                //     }
                //     if (limitSingleDiscount > 5000) {
                //         this.$message.error('单笔优惠上限必须不大于5000');
                //         return;
                //     }
                // }

                const reduceAmount = parseFloat(values.reduceAmount);
                const useSill = parseFloat(values.useSill);

                if (+reduceAmount > +useSill) {
                    this.$message.error('减价金额必须小于使用门槛金额');
                    return;
                }
                if (values.warnRatio > 100) {
                    this.$message.error('预警阈值不能大于100%');
                    return;
                }
                if (values.timeRule == '1') {
                    if (
                        this.timeRuleData.days == undefined ||
                        this.timeRuleData.days.length === 0
                    ) {
                        this.$message.error('规则日期天数不能为空错误');
                        return;
                    }
                    if (
                        this.timeRuleData.timerange == undefined ||
                        this.timeRuleData.timerange.length % 2 !== 0
                    ) {
                        this.$message.error('规则日期时间范围错误');
                        return;
                    }
                    for (var rule of this.timeRuleData.timerange) {
                        if (rule == null) {
                            this.$message.error('规则日期时间不能为空');
                            return;
                        }
                    }
                } else if (values.timeRule == '2') {
                }
                this.formatBaseForm(values);
                this.activeTab = '2';
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public changeShopData(value: any) {
        if (this.shopData.length === 3000) {
            this.$message.error('店铺最多3000家上限');
            return;
        }
        value.forEach((data: any) => {
            let flag = false;
            for (let shop of this.shopData) {
                if (shop.merNo === data[0] && shop.termNo === data[1]) {
                    flag = true;
                    break;
                }
            }
            if (!flag) {
                const objData = {
                    index: this.shopData.length + 1,
                    merNo: data[0],
                    termNo: data[1]
                };
                this.shopData.push(objData);
            }
        });
    }

    public deleteShopData(record: any) {
        this.shopData = (this.shopData as object[]).filter(
            (data: any) => data.index !== record.index
        );
    }

    public handleCreate(e: any) {
        e.preventDefault();
        this.otherForm.validateFields((error: any, values: any) => {
            if (!error) {
                console.log('budgetAmount', values.budget);
                console.log('dailyAmountLimit', values.budgetLimitInput);
                console.log(
                    null != values.budgetLimitInput &&
                        '' != values.budgetLimitInput
                );
                if (
                    null != values.budgetLimitInput &&
                    '' != values.budgetLimitInput &&
                    parseFloat(values.budgetLimitInput) >
                        parseFloat(values.budget)
                ) {
                    this.$message.error('当天消耗金额上限必须小于活动预算金额');
                    return;
                }
                if (null == this.shopData || this.shopData.length < 1) {
                    this.$message.error('请添加活动商户');
                    return;
                }
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => this.handleSubmitConfirm(values)
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public handleSubmitConfirm(values: any) {
        let baseData = this.baseData;
        console.log(this.baseData);
        if (null == baseData) {
            baseData = this.formatBaseForm(values);
        }
        const otherData = this.formatOtherForm(values);
        const formData = { ...baseData, ...otherData };
        console.log('formData', formData);
        this.$store.dispatch('activityCreatePage/create', formData);
        
    }
    @Watch('$store.state.activityCreatePage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
           this.$message.success('提交成功');
            const queryString = this.$store.getters[
                'activitiesDraftSearchPage/queryString'
            ]();
            this.$router.push({
                path: '/console/activity/draft/search' + queryString
            });
        }
    }
    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters[
                    'activitiesDraftSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/activity/draft/search' + queryString
                });
            }
        });
    }

    public handleBack() {
        this.activeTab = '1';
    }

    @Watch('$store.state.adCreatePage.id')
    public watchCreateSuccess(id: string) {}

    private formatBaseForm(formData: any) {
        let timeArray = [];
        let dateArray: any = [];
        if (formData.timeRule == '1') {
            for (var i = 1; i <= this.timeRuleData.timerange.length / 2; i++) {
                const startTime = this.timeRuleData.timerange[
                    (i - 1) * 2
                ].format('HH:mm');
                const endTime = this.timeRuleData.timerange[i * 2 - 1].format(
                    'HH:mm'
                );
                timeArray.push(startTime + '-' + endTime);
            }
        } else if (formData.timeRule == '2') {
            console.log(this.noTimeRuleData.daterange);
            for (
                var i = 1;
                i <= this.noTimeRuleData.timerange.length / 2;
                i++
            ) {
                const startTime = this.noTimeRuleData.timerange[
                    (i - 1) * 2
                ].format('HH:mm');
                const endTime = this.noTimeRuleData.timerange[i * 2 - 1].format(
                    'HH:mm'
                );
                timeArray.push(startTime + '-' + endTime);
            }

            this.noTimeRuleData.daterange.forEach((date: any) => {
                if (date) {
                    const startDate = date[0].format('YYYYMMDD');
                    const endDate = date[1].format('YYYYMMDD');
                    dateArray.push(startDate + '-' + endDate);
                }
            });
        }

        const sponsor = {
            sponsorName: formData.capital,
            warnRatio: formData.warnRatio,
            warnMobile: formData.warnMobile
        };
        this.activitySponsors.push(sponsor);
        this.baseData = {
            activityName: formData.name,
            gwActivityId: formData.gwActivityId,
            orgId: 'lakala',
            activityType: formData.discountWay + '',
            platActivity: formData.batchNo,
            goodsTag: formData.discountFlag,
            

            // discountRatio: (formData.discountRatio * 1).toFixed(0),
            // orderRequire: (formData.orderRequire * 100).toFixed(0),
            // limitSingleDiscount: (formData.limitSingleDiscount * 100).toFixed(
            //     0
            // ),
            discountAmount: (formData.reduceAmount * 100).toFixed(0),

            payLine: (formData.useSill * 100).toFixed(0),
            startTime: formData.range_valiable_time[0].format(
                'YYYY-MM-DD HH:mm:ss'
            ),
            endTime: formData.range_valiable_time[1].format(
                'YYYY-MM-DD HH:mm:ss'
            ),
            availableTime: {
                type: formData.timeRule,
                weekDays: this.timeRuleData.days,
                datePeriods: dateArray,
                timePeriods: timeArray
            },
            activitySponsors: this.activitySponsors
        };
    }

    private formatOtherForm(formData: any) {
        let dailyAmountLimit = '';
        if (
            null != formData.budgetLimitInput &&
            '' != formData.budgetLimitInput
        ) {
            dailyAmountLimit = (formData.budgetLimitInput * 100).toFixed(0);
        }
        const objData = {
            bankName: formData.bankName,
            cardType: formData.cardType,
            cardBin: formData.cardBin
        };
        this.bankData.push(objData);
        const otherData = {
            budgetAmount: (formData.budget * 100).toFixed(0),
            allUser: formData.allUser,
            dailyAmountLimit: dailyAmountLimit,
            userLimit: {
                countLimit: formData.timesLimit,
                dailyCountLimit: formData.discountRuleInput
            },
            merchantList: this.shopData,
            payType: formData.payway,
            bankCardList: this.bankData,
            prompt: formData.remark
        };
        return otherData;
    }
}

export default CreateForm;
