var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-checkbox-group",
    {
      key: _vm.id,
      staticStyle: { width: "100%" },
      attrs: { value: _vm.value },
      on: { change: _vm.changeHandler }
    },
    [
      _c(
        "a-checkbox",
        {
          attrs: {
            disabled:
              _vm.disabled ||
              _vm.allowContainers.indexOf("MINI_APP") < 0 ||
              _vm.selectedByOthers.indexOf("MINI_APP") >= 0,
            value: "MINI_APP"
          }
        },
        [_vm._v("惠码小程序")]
      ),
      _c(
        "a-checkbox",
        {
          attrs: {
            disabled:
              _vm.disabled ||
              _vm.allowContainers.indexOf("H5") < 0 ||
              _vm.selectedByOthers.indexOf("H5") >= 0,
            value: "H5"
          }
        },
        [_vm._v("惠码H5")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }