




































































































































import {Modal} from 'ant-design-vue';
import {Component, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import config from '@/config';
import moment from 'moment';
import {DatePicker} from '@/components/custom-form-components';
import * as services from './services';

interface EditActForm {
    form: any;
    otherForm:any
}

@Component({
        components: {
            DatePicker
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.activityMerSearchPage.loading,
                formData(state: any) {
                    return state.activityMerSearchPage.data && this.formatFormData(state.activityMerSearchPage.data);
                },
                
            }),
            
        }
    })
    class createForm extends Vue {
        public districtCodeList=[];
        public awardRuleData= [];
        public activityId:any ;
        public ruleId:any;
        public oldStartTime='';
        form: any;
        public data() {
            return {
                config,
                form: this.$form.createForm(this)
            };
        }
        public created() {
            
        }

        public handleChangeTimeRange(value: any) {
            const [startTime, endTime] = value;
            Modal.confirm({
                title: '修改活动商户有效期',
                content: `请确认是否修改活动商户有效期为 ${startTime.format('YYYY-MM-DD HH:mm:ss')} ~ ${endTime.format('YYYY-MM-DD HH:mm:ss')}`,
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    services.updateShopLimitTimeRange({
                        id: this.formData.id,
                        startTime: startTime.format('YYYYMMDDHHmmss'),
                        endTime: endTime.format('YYYYMMDDHHmmss')
                    }).then((res: any) => {
                        if(res ===  null){
                            this.$message.success("修改活动商户有效期成功");
                            this.$emit('onCancel');
                        }
                    });
                },
            });
        }
        
        public handleCreate(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (!error) {
                    // if(values.ruleId){
                        // if(!values.ruleTime || !values.ruleTime[0]|| !values.ruleTime[1]){
                        //     this.$message.error('请选择规则时间！');
                        //     return false;
                        // }
                        if(values.ruleTime && values.ruleTime[0]){
                            const newStartTime= values.ruleTime[0].format('YYYYMMDDHHmmss');
                            console.log(this.oldStartTime);
                            console.log(newStartTime)
                            if(newStartTime != this.oldStartTime){
                                this.$message.error('规则起始时间不能修改');
                                return false;   
                            }
                        }
                    // }
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'activityMerSearchPage/editAct',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
        @Watch('$store.state.activityMerSearchPage.updatedSuccess')
        public updatedSuccess(newVal: boolean, oldVal: boolean) {
            if (newVal) {
                this.$message.success('提交成功');
                this.$emit('onCancel');
            }
        }
        public async handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                    this.$emit('onCancel');
                },
                onCancel() {
                    // do nothing
                }
            });
        }
       
        /**
         * 将表单数据格式化为请求参数
         */
        private formatParams(formData: any) {
            
            return {
                ...formData,
                operateType: '2',
                activityId:this.activityId,
                ruleId:this.ruleId,
                startTime: 
                    formData.ruleTime &&
                    formData.ruleTime[0] &&
                    formData.ruleTime[0].format('YYYYMMDDHHmmss'),
                endTime:
                    formData.ruleTime &&
                    formData.ruleTime[1] &&
                    formData.ruleTime[1].format('YYYYMMDDHHmmss'),
            };
        }

        
        private formatFormData(rawData: any) {
            this.activityId = rawData.activityId;
            this.ruleId = rawData.ruleId;
            this.oldStartTime = rawData.startTime && moment(rawData.startTime).format('YYYYMMDDHHmmss');
            return {
                ...rawData,
                ruleTime: [
                    rawData.startTime && moment(rawData.startTime),
                    rawData.endTime && moment(rawData.endTime)
                ]
            }
        }
    }

    export default createForm;
