var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("a-table", {
        ref: "table",
        staticClass: "biz-district-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.bizDistricts,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
          },
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 62),
            y: _vm.fixed || undefined
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: function(total) {
              return "总计 " + total + " 条记录"
            },
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function(text, record) {
              return _c(
                "span",
                { staticClass: "ops" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            path: "/console/bizDistrict/" + record.id
                          })
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "StoreDrawer",
                    {
                      attrs: {
                        district_id: record.id,
                        title: "商户列表(" + record.name + ")"
                      }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            href: "javascript:;"
                          },
                          slot: "trigger"
                        },
                        [_vm._v("入驻列表")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "MemberDrawer",
                    {
                      key: record.id,
                      attrs: {
                        id: record.id,
                        title: "会员列表(" + record.name + ")"
                      }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            href: "javascript:;"
                          },
                          slot: "trigger"
                        },
                        [_vm._v("会员")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }