












import SearchCommonLayout from '@/components/SearchCommonLayout.vue';
import SearchForm from './SearchForm.vue';
import SearchTable from './SearchTable.vue';

import { Component, Prop, Watch, Provide, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { queryToHumanReadable } from '@/utils';
import PRIVILEGE from '@/common/privileges';

const humanReadableQuerySchema = {};

@Component({
    components: {
        SearchForm,
        SearchTable,
        SearchCommonLayout
    },
    computed: {
        ...mapState({
            
        })
    }
})
export default class WhitelistPage extends Vue {
    @Prop()
    public active!: boolean;

    public storeName = 'StatementInfoPage';
    
    @Provide()
    public state() {
        return this.$store.state[this.storeName];
    }

    @Provide()
    public getters(name: string) {
        return this.$store.getters[this.storeName + '/' + name];
    }

    @Provide()
    public commit(mutation: string, payload?: any) {
        this.$store.commit(this.storeName + '/' + mutation, payload);
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(this.storeName + '/' + action, payload);
    }

    @Provide()
    public routerPush() {
        this.$router.push({
            path: '/console/statement/info' + this.getters('queryString')()
        });
    }

    public created() {}

}
