var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("ChangeStatusModal", {
        attrs: { detail: _vm.changeStatus, title: "状态变更" },
        on: {
          close: function($event) {
            return _vm.handleDrawerClose("changeStatus")
          }
        }
      }),
      _c("a-table", {
        ref: "table",
        staticClass: "gray-list-search-table",
        attrs: {
          size: "middle",
          rowKey: "openId",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.list,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
          },
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 0),
            y: _vm.fixed || undefined
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: function(total) {
              return "总计 " + total + " 条记录"
            },
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function(value, record) {
              return [
                _c(
                  "span",
                  { style: { paddingLeft: "5px" } },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { size: "small", href: "javascript:;" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.handleDrawerOpenClick(
                              "changeStatus",
                              record
                            )
                          }
                        }
                      },
                      [_vm._v("状态变更")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }