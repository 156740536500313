







import Vue from 'vue';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {LocaleProvider} from 'ant-design-vue';

export default Vue.extend({
    data() {
        return {
            locale: zhCN
        };
    },
    components: {
        [LocaleProvider.name]: LocaleProvider
    },

    created() {
        if (!this.$store.state.login.session.token) {
            const session = window.localStorage.getItem('session');
            if (session) {
                let url = this.$route.fullPath;
                if (/\login/.test(url)) {
                    url = '/console/mall/search';
                }
                this.$store.commit('login/autoLogin', {session: JSON.parse(session), from: url});
                // this.$store.commit('login/loginSuccessed', JSON.parse(session));
            } else {
                this.$router.replace({
                    path: `/login`
                });
            }
        }
    },

    watch: {

        '$store.state.login.session'(session) {
            if (session && session.token) {
                this.$router.replace({
                    path: this.$store.state.login.redirect || `/console/welcome`
                });
            } else {
                this.$router.replace({
                    path: `/login`
                });
            }
        }
    }
});
