














































import {AutoComplete, Row, Col} from 'ant-design-vue';
import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
import {request} from '@/utils';

interface NewBizType {
    divisionsSource: any[];
    groupsSource: any[];
    divisions: string[];
    groups: string[];
    sections: string[];
}

@Component({
    components: {
        [AutoComplete.name]: AutoComplete,
        [Row.name]: Row,
        [Col.name]: Col
    },
    computed: {
        span() {
            const {division, group, section} = this as any;
            return 24 / [division, group, section].filter(Boolean).length;
        },
    }
})
class NewBizType extends Vue {
    @Prop()
    public value!: any;

    @Prop()
    public division!: boolean;

    @Prop()
    public group!: boolean;

    @Prop()
    public section!: boolean;

    @Prop()
    public disableAutoComplete!: boolean;

    @Watch('value')
    public async watchValueChange(value: any) {
        const self: any = this;
        if (value && value[0]) {
            const divisionItem = self.divisionsSource.find(
                (r: any) => r.business_type_name === value[0]
            );
            self.groupsSource = await this.getBizType({id: divisionItem.id});
            self.groups = self.groupsSource.map((i: any) => i.business_type_name);
        }

        if (value && value[1]) {
            const groupItem = self.groupsSource.find(
                (r: any) => r.business_type_name === value[1]
            );
            self.sections = (await this.getBizType({id: groupItem.id})).map((i: any) => i.business_type_name);
        }
    }

    public data() {
        return {
            divisionsSource: [],
            groupsSource: [],
            divisions: [],
            groups: [],
            sections: [],
        };
    }

    public handleProvinceChange(e: any) {
        this.$emit('change', [
            e,
            // this.getValue('group'),
            // this.getValue('section')
        ]);
    }

    public handleCityChange(e: any) {
        this.$emit('change', [
            this.getValue('division'),
            e,
            // this.getValue('section')
        ]);
    }

    public handleDistrictChange(e: any) {
        this.$emit('change', [
            this.getValue('division'),
            this.getValue('group'),
            e
        ]);
    }

    public filterOption(input: any, option: any) {
        return (
            option.componentOptions.children[0].text
                .toUpperCase()
                .indexOf(input.toUpperCase()) >= 0
        );
    }

    public async created() {
        try {
            this.divisionsSource =  await this.getBizType();
            this.divisions = this.divisionsSource.map((i: any) => i.business_type_name);
        } catch (e) {
            if (!e.is403) {
                this.$message.error('获取行业列表失败, 请联系技术人员!');
            }
        }
    }

    public async getBizType(params?: any) {
        try {
            const res =  (await request({
                api: '/console/client/biz/type',
                data: params
            }));
            return res;
        } catch (e) {
            if (!e.is403) {
                this.$message.error('获取行业列表失败, 请联系技术人员!');
            }
        }
    }

    private getValue(key: string) {
        switch (key) {
            case 'division':
                return this.value && this.value[0];
            case 'group':
                return this.value && this.value[1];
            case 'section':
                return this.value && this.value[2];
        }
    }
}

export default NewBizType;
