














































import {Component, Prop, Vue, Watch, Inject, Provide} from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import SearchTable from './SearchTable.vue';
import SearchForm from './SearchForm.vue';
import store from './store';
import {getValueByPath} from '@/utils';
import {Show, check} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Drawer,
        SearchTable,
        SearchForm,
        Show
    }
})
class Member extends Vue {
    @Prop()
    // tslint:disable-next-line:variable-name
    public store_id!: string;

    @Prop()
    public title!: string | number;

    @Inject()
    public privileges!: any;

    public cacheStoreId!: any;
    public PRIVILEGE = PRIVILEGE;

    @Provide()
    public state() {
        return getValueByPath(this.$store.state, [
            'shopSearchPage',
            this.cacheStoreId + '@member'
        ]);
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(
            'shopSearchPage/' + this.cacheStoreId + '@member/' + action,
            payload
        );
    }

    @Provide()
    public commit(action: string, payload?: any) {
        this.$store.commit(
            'shopSearchPage/' + this.cacheStoreId + '@member/' + action,
            payload
        );
    }

    public created() {
        // if (!this.state()) {
        //     this.$store.registerModule(
        //         ['shopSearchPage', this.store_id + '@member'],
        //         store()
        //     );
        //     this.commit('setQuery', {store_id: this.store_id});
        //     this.$watch(function() {
        //         return this
        //             .$store.state.shopSearchPage[this.store_id + '@member'].error;
        //     }, this.errorListener);

        // }
    }

    public errorListener(error: any) {
        if (error) {
            this.$message.error(error.message);
        }
    }

    @Watch('store_id')
    public handleDrawerOpen(storeId: any) {

        if (check([this.PRIVILEGE.STORE.MEMBER], this.privileges).length > 0) {
            return;
        }

        if (!storeId) {
            return;
        }

        this.cacheStoreId = storeId;
        if (!this.state()) {
            this.$store.registerModule(
                ['shopSearchPage', this.store_id + '@member'],
                store()
            );
            this.commit('setQuery', {store_id: this.store_id});
            this.$watch(function() {
                return this.$store.state.shopSearchPage[this.store_id + '@member']
                    && this.$store.state.shopSearchPage[this.store_id + '@member'].error;
            }, this.errorListener);

        }
        this.dispatch('resetQuery', {store_id: this.store_id});
    }

    public onClose() {
        this.$emit('close');
    }
}

export default Member;
