var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        destroyOnClose: "",
        title: _vm.title,
        visible: !!_vm.activityId,
        width: 800,
        placement: "right",
        closable: ""
      },
      on: { close: _vm.onClose }
    },
    [
      _c(
        "div",
        [[_c("div", { staticClass: "ad_detail" }, [_c("DetailForm")], 1)]],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }