

















































































import {Modal} from 'ant-design-vue';
import {Component,Inject, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import config from '@/config';
import {DisableDatePicker} from '@/components/custom-form-components';
import BankMerActivityInfo from '@/components/custom-form-components/BankMerActivityInfo.vue';
import BankRuleInfo from '@/components/custom-form-components/BankRuleInfo.vue';
interface EditActForm {
    form: any;
}

@Component({
        components: {
            DisableDatePicker,
            BankMerActivityInfo,
            BankRuleInfo
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.activityMerSearchPage.loading,
                
            }),
            
            
        }
    })
    class createForm extends Vue {
        @Inject()
        public dispatch!: any;
        form: any;
        public ruleList!:any;
        public data() {
            return {
                config,
                ruleList:{},
                form: this.$form.createForm(this),
            };
        }
        public created() {
            
        }
       
        public handleCreate(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (!error) {
                    //规则ID录入了则必须填写时间
                    if(values.ruleId){
                        if(!values.ruleTime || !values.ruleTime[0]|| !values.ruleTime[1]){
                            this.$message.error(
                                '请选择规则时间！'
                            );
                            return false;
                        }
                    }
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'activityMerSearchPage/create',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
        @Watch('$store.state.activityMerSearchPage.updatedSuccess')
        public updatedSuccess(newVal: boolean, oldVal: boolean) {
            if (newVal) {
                console.log(123);
                this.$message.success('提交成功');
                const queryString = this.$store.getters[
                    'activityMerSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/activityMer/search' + queryString,
                });
                this.dispatch('search');
            }
        }
        public async handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                     const queryString = this.$store.getters[
                        'activityMerSearchPage/queryString'
                    ]();
                    this.$router.push({
                        path: '/console/activityMer/search' + queryString,
                    });
                },
                onCancel() {
                    // do nothing
                }
            });
        }
        
        /**
         * 将表单数据格式化为请求参数
         */
        private formatParams(formData: any) {
            return {
                ...formData,
                operateType: '1',
                startTime: 
                    formData.ruleTime &&
                    formData.ruleTime[0] &&
                    formData.ruleTime[0].format('YYYYMMDDHHmmss'),
                endTime:
                    formData.ruleTime &&
                    formData.ruleTime[1] &&
                    formData.ruleTime[1].format('YYYYMMDDHHmmss'),
            };
        }
        private initFormData(data:any){
            console.log(data)
            this.form.setFieldsValue({ruleId:'',ruleName:''});
            if(data){
                this.ruleList = data;
            }
        }
    }

    export default createForm;
