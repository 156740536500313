var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("Click", {
        attrs: { requires: [_vm.PRIVILEGE.USER.CREATE] },
        on: { pass: _vm.onClick },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [_vm._t("trigger")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            visible: _vm.visible,
            confirmLoading: _vm.loading,
            form: _vm.form
          },
          on: { ok: _vm.handleCreate, cancel: _vm.handleClose }
        },
        [
          _c(
            "a-form",
            {
              staticClass: "account-create-form",
              attrs: { form: _vm.form },
              on: { submit: _vm.handleCreate }
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "手机号" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "mobile",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                              {
                                pattern: /^\d{11}$/,
                                message: "请输入正确手机号"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                        'mobile',\n                        {rules: [{required: true, message: '请填写必填项!'}, {pattern: /^\\d{11}$/,  message: '请输入正确手机号'}]}\n                    ]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "姓名" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "first_name",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                              {
                                pattern: /^[·A-Za-z\u4e00-\u9fa5]{0,30}$/,
                                message: "姓名为汉字或字母, 长度不能超过30"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                        'first_name',\n                        {rules: [{required: true, message: '请填写必填项!'}, {pattern: /^[·A-Za-z\\u4e00-\\u9fa5]{0,30}$/,  message: '姓名为汉字或字母, 长度不能超过30'}]}\n                    ]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "密码" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "password",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                              {
                                pattern: /^[_0-9a-zA-Z]{6,20}$/,
                                message: "密码为数字、字母或下划线, 6到20位"
                              }
                            ]
                          }
                        ],
                        expression:
                          "['password',{rules: [{required: true, message: '请填写必填项!'}, {pattern: /^[_0-9a-zA-Z]{6,20}$/,  message: '密码为数字、字母或下划线, 6到20位'}]}]"
                      }
                    ],
                    attrs: { type: "password", placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "确认密码" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "password_confirm",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" },
                              {
                                pattern: /^[_0-9a-zA-Z]{6,20}$/,
                                message: "密码为数字、字母或下划线, 6到20位"
                              }
                            ]
                          }
                        ],
                        expression:
                          "['password_confirm', {rules: [{ required: true, message: '请填写必填项!' }, {pattern: /^[_0-9a-zA-Z]{6,20}$/,  message: '密码为数字、字母或下划线, 6到20位'}]}]"
                      }
                    ],
                    attrs: { type: "password", placeholder: "请输入" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "角色" } },
                [
                  _c("Role", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "group_id",
                          {
                            rules: [
                              { required: true, message: "请填写必填项!" }
                            ]
                          }
                        ],
                        expression:
                          "['group_id', {rules: [{ required: true, message: '请填写必填项!' }]}]"
                      }
                    ]
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }