









import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import {request} from '@/utils';

@Component<SmStatusCount>({ })
class SmStatusCount extends Vue {

    
    public on: any = 0;
    public off: any = 0;
    public WAITE:any = 0;
    public loading = false;

    public async created() {
        this.fetch();
    }

    public async fetch() {
        this.loading = true;
        try {
            const data = await request({
                api: '/console/sms/package/countstatus'
            });

            this.on = data.ON;
            this.off = data.OFF;
            this.WAITE = data.WAITE;
        } catch (e) {
            this.$message.error(e.message);
        } finally {
            this.loading = false;
        }
    }

}
export default SmStatusCount;
