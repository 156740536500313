

































import {Component, Prop, Vue, Inject, Provide} from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import SearchTable from './SearchTable.vue';
import SearchForm from './SearchForm.vue';
import store from './store';
import {getValueByPath} from '@/utils';
import {Show, check} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Drawer,
        SearchTable,
        SearchForm,
        Show
    }
})
class Detail extends Vue {
    @Prop()
    // tslint:disable-next-line:variable-name
    public user_id!: string;

    @Prop()
    public title!: string | number;

    @Inject()
    public privileges!: any;

    public PRIVILEGE = PRIVILEGE;

    @Provide()
    public state() {
        return getValueByPath(this.$store.state, [
            'customerSearchPage',
            this.user_id + '@store'
        ]);
    }

    @Provide()
    public dispatch(action: string, payload?: any) {
        this.$store.dispatch(
            'customerSearchPage/' + this.user_id + '@store/' + action,
            payload
        );
    }

    @Provide()
    public commit(action: string, payload?: any) {
        this.$store.commit(
            'customerSearchPage/' + this.user_id + '@store/' + action,
            payload
        );
    }

    public created() {
        if (!this.state()) {
            this.$store.registerModule(
                ['customerSearchPage', this.user_id + '@store'],
                store()
            );
            this.commit('setQuery', {user_id: this.user_id});
            this.$watch(function() {
                return this.$store.state.customerSearchPage[this.user_id + '@store']
                    && this.$store.state.customerSearchPage[this.user_id + '@store'].error;
            }, this.errorListener);

        }
    }

    public errorListener(error: any) {
        if (error) {
            this.$message.error(error.message);
        }
    }

    public handleDrawerOpen() {
        if (check([this.PRIVILEGE.CUSTOMER.DETAIL], this.privileges).length === 0) {
            this.dispatch('resetQuery', {user_id: this.user_id});
        }
    }
}

export default Detail;
