

























































































































import { mapState } from 'vuex';
import { Modal } from 'ant-design-vue';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { deleteEmpty, request } from '@/utils';
import BulkSelector from '@/components/custom-form-components/BulkSelector/index.vue';
@Component({
    components: {
        BulkSelector
    },
    computed: {}
})
class WhitelistModal extends Vue {
    public form: any = null;

    public selectedRowKeys = [];

    public visible = false;

    public addVisible = false;

    public query = {};

    public addMerchantNo = '';

    public addOfflineTerminalId = '';

    public adding = false;

    public removing = false;

    public created() {
        this.form = this.$form.createForm(this);
    }

    public handleTriggerClick() {
        this.visible = true;
    }

    public async handleAddOk() {
        if (!this.addMerchantNo) {
            return this.$message.error('请输入正确的商户号');
        }
        this.adding = true;
        try {
            await request({
                method: 'POST',
                api: '/console/ad/ad_block/create',
                data: {
                    merchant_no: this.addMerchantNo,
                    offline_terminal_id: this.addOfflineTerminalId
                }
            });
            this.addVisible = false;
            this.$message.success('添加成功');
            (this.$refs.table as BulkSelector).search();
        } catch (e) {
            if (!e.is403) {
                this.$message.error(e.message);
            }
        } finally {
            this.adding = false;
        }
    }

    public handleRemove() {
        Modal.confirm({
            title: '移除',
            content: '确定移除出白名单列表吗?',
            onOk: async () => {
                this.removing = true;
                try {
                    await request({
                        method: 'POST',
                        api: '/console/ad/ad_block/remove',
                        data: {
                            ids: this.selectedRowKeys
                        }
                    });
                    this.$message.success('移除成功');
                    this.selectedRowKeys = [];
                    (this.$refs.table as BulkSelector).search(1);
                } catch (e) {
                    if (!e.is403) {
                        this.$message.error(e.message);
                    }
                } finally {
                    this.removing = false;
                }
            }
        });
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }

            this.query = deleteEmpty({
                ...values
            });
        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach(key => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }
}

export default WhitelistModal;
