







































































































import {Modal} from 'ant-design-vue';
import {Component, Vue} from 'vue-property-decorator';
import {CreateParams} from './services';
import {mapState} from 'vuex';
import {MALL as MALL_RULES} from '@/common/formItemRules';
import ServicePlatform from '@/components/custom-form-components/ServicePlatform';
import Region from '@/components/custom-form-components/Region.vue';
import ImageCrop from '@/components/custom-form-components/ImageCrop/index.vue';
import config from '@/config';

interface SearchForm {
    form: any;
    hasLogo: boolean;
}

@Component({
    components: {
        ServicePlatform,
        Region,
        ImageCrop
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.mallCreatePage.loading
        })
    }
})
class SearchForm extends Vue {
    public data() {
        return {
            MALL_RULES,
            config,
            form: this.$form.createForm(this),
            hasLogo: false
        };
    }

    public handleCreate(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => {
                        this.$store.dispatch(
                            'mallCreatePage/create',
                            this.formatParams(values)
                        );
                    },
                    onCancel() {
                        // do nothing
                    }
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters['mallSearchPage/queryString']();
                this.$router.push({
                    path: '/console/mall/search' + queryString
                });
            },
            onCancel() {
                // do nothing
            }
        });
    }

    /**
     * 将表单数据格式化为请求参数
     */
    private formatParams(formData: any) {
        const {
            // 排除region字段
            region,
            ...rest
        } = formData;

        const params: CreateParams = {
            ...rest
        };

        params.logo = formData.logo && formData.logo[0] && formData.logo[0].url;
        params.province = formData.region && formData.region[0];
        params.city = formData.region && formData.region[1];
        params.district = formData.region && formData.region[2];
        return params;
    }
}

export default SearchForm;
