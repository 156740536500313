var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SearchTable", {
    attrs: {
      columns: _vm.columns,
      dataSource: _vm.list,
      total: _vm.total,
      page: _vm.page,
      pageSize: _vm.pageSize,
      loading: _vm.loading,
      rowKey: "id",
      scroll: { x: 1000 }
    },
    on: {
      pageChange: _vm.handlePageChange,
      pageSizeChange: _vm.handlePageSizeChange
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }