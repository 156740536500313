var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-tabs",
    {
      staticStyle: { overflow: "visible" },
      attrs: { activeKey: _vm.activeKey, animated: false },
      on: { change: _vm.handleChange }
    },
    [
      _c(
        "a-tab-pane",
        {
          key: "newbie",
          staticStyle: { padding: "20px" },
          attrs: { tab: "进店红包" }
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.MARKETINGDATA.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c("newbieSearchPage", {
                      attrs: { active: _vm.activeKey === "newbie" }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "lucky_packet",
          staticStyle: { padding: "20px" },
          attrs: { tab: "拼手气红包" }
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.MARKETINGDATA.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c("LuckyPacketSearchPage", {
                      attrs: { active: _vm.activeKey === "lucky_packet" }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "orientation",
          staticStyle: { padding: "20px" },
          attrs: { tab: "定向券" }
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.MARKETINGDATA.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c("OrientationSearchPage", {
                      attrs: { active: _vm.activeKey === "orientation" }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "mall",
          staticStyle: { padding: "20px" },
          attrs: { tab: "商场券" }
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.MARKETINGDATA.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c("MallSearchPage", {
                      attrs: { active: _vm.activeKey === "mall" }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-tab-pane",
        {
          key: "other",
          staticStyle: { padding: "20px" },
          attrs: { tab: "商圈券" }
        },
        [
          _c("Show", {
            attrs: { requires: [_vm.PRIVILEGE.MARKETINGDATA.TAB] },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c("OtherSearchPage", {
                      attrs: { active: _vm.activeKey === "other" }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }