var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-form-item-wrapper" },
    [
      _c(
        "a-form",
        { staticClass: "ad-create-form", attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "商户名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merchantName",
                      {
                        initialValue: _vm.formData.merchantName
                      }
                    ],
                    expression:
                      "[\n                    'merchantName',\n                    {\n                        initialValue: formData.merchantName\n                    }\n                ]"
                  }
                ],
                attrs: { readonly: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商户号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merchantNo",
                      {
                        initialValue: _vm.formData.merchantNo
                      }
                    ],
                    expression:
                      "[\n                    'merchantNo',\n                    {\n                        initialValue: formData.merchantNo\n                    }\n                ]"
                  }
                ],
                attrs: { readonly: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "门店地址" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merchantAddr",
                      {
                        initialValue: _vm.formData.merchantAddr
                      }
                    ],
                    expression:
                      "[\n                    'merchantAddr',\n                    {\n                        initialValue: formData.merchantAddr\n                    }\n                ]"
                  }
                ],
                attrs: { readonly: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商户类别" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merchantMcc",
                      {
                        initialValue: _vm.formData.merchantMcc
                      }
                    ],
                    expression:
                      "[\n                    'merchantMcc',\n                    {\n                        initialValue: formData.merchantMcc\n                    }\n                ]"
                  }
                ],
                attrs: { readonly: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "手机号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "phone",
                      {
                        initialValue: _vm.formData.phone
                      }
                    ],
                    expression:
                      "[\n                    'phone',\n                    {\n                        initialValue: formData.phone\n                    }\n                ]"
                  }
                ],
                attrs: { readonly: true }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }