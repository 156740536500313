


































































import { Modal } from 'ant-design-vue';
import { Component,Inject, Vue,Provide, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import CreateModal from './shopLimitModal.vue';
import ShopUpload from './ShopUpload.vue';
import { calcTableScrollX, getTop } from '@/utils';
interface CreateForm {
    otherForm: any;
}

@Component<CreateForm>({
    components: {
        CreateModal,
        ShopUpload,
    },
    computed: {
        ...mapState({
            lists: (state: any) =>
                state.actShopLimitSearchPage.list.map(
                    (order: any, idx: any) => ({
                        idx:
                            (state.actShopLimitSearchPage.page - 1) * 10 +
                            idx +
                            1,
                        ...order
                    })
                ),
            total: (state: any) => state.actShopLimitSearchPage.total,
            pageSize: (state: any) => state.actShopLimitSearchPage.pageSize,
            page: (state: any) => state.actShopLimitSearchPage.page,
            loading: (state: any) => state.actShopLimitSearchPage.loading,
        }),
        
    }
})
class CreateForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    public fixed = 0;
    public otherForm;
    @Prop()
    public activityId:any ;
    public baseData: any;
    public activeTab: any;
    public shopData: any;
    public tmpShopData:any;
    public style = '';
    public created() {};
    @Inject()
    public commit!: any;
    @Inject()
    public routerPush!: any;
    public data() {
        return {
            baseData: {},
            otherForm: this.$form.createForm(this),
            calcTableScrollX,
            shopData: [],
            tmpShopData: [],
            columns: [
                {
                    title: '商户号',
                    dataIndex: 'merchantNo',
                    align: 'center',
                    width: 200

                },
                {
                    title: '终端号',
                    dataIndex: 'termNo',
                    align: 'center',
                    width: 100
                },
                {
                    title: '限额',
                    dataIndex: 'limit',
                    align: 'center',
                    width: 150  
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ],
        };
    }

    public mounted() {
        this.fixed = window.document.body.clientHeight - 102;
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('scroll', this.handleWindowScroll);
    }
    public handleWindowScroll() {
        const top = getTop(this.$refs.wrapper) - window.scrollY;
        if (top < 0) {
            this.style = 'transform:translateY(' + -top + 'px)';
        } else {
            this.style = '';
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    public handleWindowResize() {
        this.fixed = window.document.body.clientHeight - 102;
    }
   
    public deleteShopData(record: any) {
        Modal.confirm({
            title: '提示',
            content: '确认删除该商户?',
            onOk: () => {
                const data = {
                    ...record,
                    activityId:this.activityId
                }
                this.$store.dispatch(
                    'actShopLimitSearchPage/deleteShopLimit',
                    data
                );
                this.$message.success('提交成功');
                //刷新表格------------
                setTimeout(this.search,300);
            },
            onCancel() {
                // do nothing
            }
        });
    }

    
    private resetQuery(){
        Object.keys(this.otherForm.getFieldsValue()).forEach((key) => {
            this.otherForm.setFieldsValue({
                [key]: undefined
            });
        });
    }
    public search() {
        const merchantNo = this.otherForm.getFieldValue('merchantNo')
        const termNo = this.otherForm.getFieldValue('termNo')
        const query ={
            activityId:this.activityId,
            termNo:termNo,
            merchantNo:merchantNo,
            page: 1,
            r: Math.random()
        };
        this.$store.dispatch('actShopLimitSearchPage/initQuery', query);
       
    }
    public handlePageChange(page: number) {
         this.$store.dispatch('actShopLimitSearchPage/pageChange', page);
    }
}

export default CreateForm;
