














import DetailForm from './DetailForm.vue';
 import {Component,Prop, Vue,Provide, Watch} from 'vue-property-decorator';
 import {Show} from '@/components/Auth';
 import PRIVILEGE from '@/common/privileges';
 import {Click} from '@/components/Auth';
 interface index {
     visible: boolean;
 }
 @Component({
     components: {
         Show,
         DetailForm,
         Click,
     }
 })
 export default class index extends Vue {
     public PRIVILEGE = PRIVILEGE;
     @Prop()
     public title!: string | number;
     @Prop()
     public merDetail !: any;
     public visible = false;
     public created() {
        

     }

     @Watch('visible')
     public initQueryData(error: any) {
         console.log('dfd'+this.merDetail);
         sessionStorage.removeItem('mer_merchantName');
         sessionStorage.removeItem('mer_merchantMcc');
         sessionStorage.removeItem('mer_merchantAddr');
         sessionStorage.removeItem('mer_phone');
         sessionStorage.removeItem('mer_merchantNo');
         if(this.merDetail == null){
             return; 
         }
         sessionStorage.setItem('mer_merchantName', this.merDetail.merchantName);
         sessionStorage.setItem('mer_merchantMcc', this.merDetail.merchantMcc);
         sessionStorage.setItem('mer_merchantAddr', this.merDetail.merchantAddr);
         sessionStorage.setItem('mer_phone', this.merDetail.merchantPhone);
         sessionStorage.setItem('mer_merchantNo', this.merDetail.merchantNo);
     }
     public handleClose() {
         this.visible = false;
     }

     public handleOK() {
         
         this.visible = false;

     }

     public onClick() {
         this.visible = true;
     }
 }
