




























































import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
interface DetailForm {
  form: any;
  formData: any;
}
@Component<DetailForm>({
    components: {
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return ( this.formatFormData());
            },
        }),
    },
})

class DetailForm extends Vue {
    @Prop()
    public value!: any;
    public form;
   
   public data() {
      return {
          form: this.$form.createForm(this),
      };
  }
  private formatFormData() {
        return {
            merchantName: sessionStorage.getItem('mer_merchantName'),
            merchantMcc:sessionStorage.getItem('mer_merchantMcc'),
            merchantAddr:sessionStorage.getItem('mer_merchantAddr'),
            phone:sessionStorage.getItem('mer_phone'),
            merchantNo:sessionStorage.getItem('mer_merchantNo')
        };
    }
    
}
export default DetailForm;
