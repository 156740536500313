























































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import PhoneCode from '@/components/custom-form-components/PhoneCode.vue';
import {mapState} from 'vuex';
import ResetPasswordModal from './ResetPasswordModal.vue';

interface Login {
    form: any;
}

@Component<Login>({
    components: {
        PhoneCode,
        ResetPasswordModal
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.login.ing
        })
    }
})
class Login extends Vue {
    public mobile = '';

    public data() {
        return {
            form: this.$form.createForm(this)
        };
    }

    @Watch('$store.state.login.error')
    public showError(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    public handleSendCode() {
        const mobile = this.form.getFieldValue('mobile');
        this.mobile = mobile + '_' + Date.now();
    }

    public handleSubmit(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                this.$store.dispatch('login/login', values);
            }
        });
    }
}

export default Login;
