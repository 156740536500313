






























































import {Component, Vue} from 'vue-property-decorator';
import * as services from './services';
import PhoneCode from '@/components/custom-form-components/PhoneCode.vue';

interface ResetPasswordModal {
    form: any;
}

@Component({
    components: {
        PhoneCode
    }
})
class ResetPasswordModal extends Vue {

    public visible = false;

    public loading = false;

    public mobile = '';

    public data() {
        return {
            form: this.$form.createForm(this)
        };
    }

    public handleClose() {
        if (this.loading) {
            return;
        }
        this.visible = false;
    }

    public onClick() {
        this.visible = true;
    }

    public handleSendCode() {
        const mobile = this.form.getFieldValue('mobile');
        this.mobile = mobile + '_' + Date.now();
    }

    public handleOk(e: any) {
        e.preventDefault();
        this.form.validateFields(async (error: any, values: any) => {
            if (!error) {

                if (values.password !== values.password_confirm) {
                    return this.$message.error('密码与确认密码不同，请重新填写');
                }

                try {
                    this.loading = true;
                    await services.resetPassword(values);
                    this.visible = false;
                    // this.$emit('success');
                } catch (e) {
                    if (e.message === 'Network Error') {
                        return this.$message.error('网络错误');
                    } else {
                        this.$message.error(e.message);
                    }
                } finally {
                    this.loading = false;
                }
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
}

export default ResetPasswordModal;
