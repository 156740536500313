var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("span", { on: { click: _vm.onClick } }, [_vm._t("trigger")], 2),
      _c(
        "a-drawer",
        {
          attrs: {
            destroyOnClose: "",
            title: _vm.title,
            visible: _vm.visible,
            width: _vm.width,
            placement: "right",
            closable: ""
          },
          on: { close: _vm.onClose }
        },
        [_vm._t("content")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }