


























































































































































































































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {
    DisableDatePicker,
    ScheduleTimePicker,
    IrregularScheduleTimePicker
} from '@/components/custom-form-components';
import CreateModal from './CreateModal.vue';
import ShopUpload from './ShopUpload.vue';
import moment from 'moment';
import * as validators from '../../validators';

interface EditActForm {
    baseForm: any;
    merchantForm: any;
}

@Component<EditActForm>({
    components: {
        DisableDatePicker,
        CreateModal,
        ShopUpload,
        ScheduleTimePicker,
        IrregularScheduleTimePicker
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return (
                    state.unionCouponSearchPage.data &&
                    this.formatFormData(state.unionCouponSearchPage.data)
                );
            },
            spinning(state) {
                return(
                    state.unionCouponSearchPage.loading
                )
            }
        })
    }
})
class EditActForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    public validators = validators;
    //页面tab
    public baseForm;
    public merchantForm;
    public activeTab: any;
    public onlyMer?: boolean;
    public baseData: any;
    public shopData: any;
    public activitySponsors: any;
    public promoType: any;

    public data() {
        return {
            activeTab: '1',
            baseData: {},
            //第一页数据
            baseForm: this.$form.createForm(this),
            activitySponsors: [],
            //第二页数据
            // discountForm: this.$form.createForm(this),
            promoType: '2',

            //第三页数据
            merchantForm: this.$form.createForm(this),
            shopData: [],
            hasTermNocolumns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo'
                },
                {
                    title: '终端号',
                    dataIndex: 'termNo'
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ],
            noTermNocolumns: [
                {
                    title: '商户号',
                    dataIndex: 'merNo'
                },
                {
                    title: '操作',
                    dataIndex: 'index',
                    scopedSlots: {
                        customRender: 'action'
                    }
                }
            ]
        };
    }

    public tabChange(key: any) {
        if (key === '2') {
            this.handleNextAction();
        } else {
            console.info('activeTab', key);
            this.activeTab = '1';
        }
    }

    public handleNext(e: any) {
        console.log('eeee',e)
        e.preventDefault();
        this.handleNextAction();
    }

    public handleNext2(e: any) {
        e.preventDefault();
        this.handleNextAction2();
    }

    public handleNextAction() {
        this.baseForm.validateFields((error: any, values: any) => {
            if (!error) {
                this.formatBaseForm(values, '1');
                this.activeTab = '2';
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
 
    public handleNextAction2() {
        this.merchantForm.validateFields((error: any, values: any) => {
            if (!error) {
                console.info('merchantForm start');
                this.formatBaseForm(values, '2');
                if (null == this.shopData || this.shopData.length < 1) {
                    this.$message.error('请添加活动商户');
                    return;
                }
                console.info('handleSubmitConfirm start');
                this.handleSubmitConfirm(values);
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }

    public changeDiscountType(e: any) {
        this.promoType = e.target.value;
    }

    public changeShopData(value: any) {
        if (this.shopData.length === 3000) {
            this.$message.error('店铺最多3000家上限');
            return;
        }
        value.forEach((data: any) => {
            if (this.onlyMer) {
                let flag = false;
                for (let shop of this.shopData) {
                    if (shop.merNo === data) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    const objData = {
                        index: this.shopData.length + 1,
                        merNo: data
                    };
                    this.shopData.push(objData);
                }
            } else {
                let flag = false;
                for (let shop of this.shopData) {
                    if (shop.merNo === data[0] && shop.termNo === data[1]) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    const objData = {
                        index: this.shopData.length + 1,
                        merNo: data[0],
                        termNo: data[1]
                    };
                    this.shopData.push(objData);
                }
            }
        });
    }

    public deleteShopData(record: any) {
        this.shopData = (this.shopData as object[]).filter(
            (data: any) => data.index !== record.index
        );
    }

    public handleSubmitConfirm(values: any) {
        console.log('baseData', this.baseData)
        console.log('values', values)
        
        Modal.confirm({
            title: '提示',
            content: '是否确认提交?',
            onOk: () => {
                const editActParams = {
                    ...this.baseData,
                    ...{promotionId: this.$store.state.unionCouponSearchPage.data.promotionId}
                }
                console.log('editActParams', editActParams);
                this.$store.dispatch(
                    'unionCouponSearchPage/editAct', 
                    editActParams
                );
            }
        });
    }
  
    @Watch('$store.state.unionCouponSearchPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$message.success("提交成功");
            this.$emit('onCancel');
        }
    }


    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                 this.$emit('onCancel');
            }
        });
       
    }

    private formatBaseForm(formData: any, activeTab: any) {
        if (activeTab == '1') {
            this.baseData = {
                name: formData.name,
                status: formData.status
            };
        } else if (activeTab == '2') {
            this.baseData.merchantList = this.shopData;
            this.baseData.promotionId = this.$store.state.unionCouponSearchPage.data.promotionId;
            this.baseData.listType = formData.listType;
            this.baseData.merLimit = formData.listType === 'merId' ? '1' : '2'; // 商户/商终限制标识：0-不限制(暂时不用);1-商户列表;2-商终列表
            this.baseData.groupId = this.$store.state.unionCouponSearchPage.data.groupId;
        }
    }
    private formatFormData(rawData: any) {
        if(rawData.listType === 'merId') {
            this.onlyMer = true
        } else {
            this.onlyMer = false
        }
        this.promoType = rawData.promoType;
        this.shopData = rawData.merchantList;
        this.shopData = rawData.merchantList.map(
            (item: any, index: number) => ({
                index,
                ...item
            })
        );
        return {
            ...rawData,
            activityTime: [
                rawData.startTime && moment(rawData.startTime),
                rawData.endTime && moment(rawData.endTime)
            ],
            
        };
    }
    private chooseMerchantType(e:any) {
        console.log(e.target.value)
        this.shopData = []
        if(e.target.value === 'merId') {
            this.onlyMer = true
        } else {
            this.onlyMer = false
        }
    }
}

export default EditActForm;
