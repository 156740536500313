var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mall-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate }
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: "商场名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "mall_name",
                      {
                        rules: [
                          { required: true, message: "请填写必填项!" }
                        ].concat(_vm.MALL_RULES.NAME)
                      }
                    ],
                    expression:
                      "[\n                    'mall_name',\n                    {rules: [{required: true, message: '请填写必填项!'}, ...MALL_RULES.NAME]}\n                ]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "appID" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "app_id",
                      {
                        rules: [
                          { required: true, message: "请填写必填项!" }
                        ].concat(_vm.MALL_RULES.APPID)
                      }
                    ],
                    expression:
                      "['app_id',{rules: [{required: true, message: '请填写必填项!'}, ...MALL_RULES.APPID]}]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "PublicKEY" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "public_key",
                      {
                        rules: [
                          { required: true, message: "请填写必填项!" }
                        ].concat(_vm.MALL_RULES.PUBLIC_KEY)
                      }
                    ],
                    expression:
                      "['public_key', {rules: [{ required: true, message: '请填写必填项!' }, ...MALL_RULES.PUBLIC_KEY]}]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "PrivateKEY" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "private_key",
                      {
                        rules: [
                          { required: true, message: "请填写必填项!" }
                        ].concat(_vm.MALL_RULES.PRIVATE_KEY)
                      }
                    ],
                    expression:
                      "['private_key', {rules: [{ required: true, message: '请填写必填项!' }, ...MALL_RULES.PRIVATE_KEY]}\n                ]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商场归属" } },
            [
              _c("ServicePlatform", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "mall_belong",
                      { rules: [{ required: true, message: "请填写必填项!" }] }
                    ],
                    expression:
                      "['mall_belong', {rules: [{ required: true, message: '请填写必填项!' }]}]"
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商场地域" } },
            [
              _c("Region", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["region"],
                    expression: "['region']"
                  }
                ],
                attrs: { province: true, city: true, district: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商户地址" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "address",
                      {
                        rules: [].concat(_vm.MALL_RULES.ADDRESS)
                      }
                    ],
                    expression:
                      "['address', {\n                    rules: [...MALL_RULES.ADDRESS]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "协议标题" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "agreement_title",
                      {
                        rules: [].concat(_vm.MALL_RULES.AGREEMENT_TITLE)
                      }
                    ],
                    expression:
                      "['agreement_title', {\n                    rules: [...MALL_RULES.AGREEMENT_TITLE]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "协议URL" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "agreement_url",
                      {
                        rules: [].concat(_vm.MALL_RULES.AGREEMENT_URL)
                      }
                    ],
                    expression:
                      "['agreement_url', {\n                    rules: [...MALL_RULES.AGREEMENT_URL]\n                }]"
                  }
                ],
                attrs: { placeholder: "请输入" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "商场Logo" } },
            [
              _c("ImageCrop", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "logo",
                      { rules: [{ required: true, message: "请填写必填项!" }] }
                    ],
                    expression:
                      "['logo', {rules: [{required: true, message: '请填写必填项!'}]}]"
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { style: { textAlign: "left" }, attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        loading: _vm.loading
                      }
                    },
                    [_vm._v("\n                    提交\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v("\n                    取消\n                ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }