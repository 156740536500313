






import {mapState} from 'vuex';
import {Component, Inject, Vue} from 'vue-property-decorator';
import CreateModal from './CreateModal.vue';
import ShopSmsCount from '@/components/ShopSmsCount.vue';
@Component<Actions>({
    components: {
        ShopSmsCount
    },
    computed: {
        ...mapState({
            loading() {
                return this.state().loading;
            }
        })
    }
})
class Actions extends Vue {

    @Inject()
    public dispatch!: any;

    @Inject()
    public state!: any;

    public handleCreateSuccess() {
        this.dispatch('search');
    }

    public handleRefresh() {
        this.dispatch('search');
    }
}

export default Actions;
