var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.collapsed,
          expression: "!collapsed"
        }
      ],
      staticClass: "ant-advanced-search-form",
      attrs: { form: _vm.form },
      on: { submit: _vm.handleSearch }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { attrs: { span: "6" } },
            [
              _c(
                "a-form-item",
                { attrs: { label: "商户名称" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "store_name",
                          { initialValue: _vm.formData.store_name }
                        ],
                        expression:
                          "[\n                      'store_name',\n                      {initialValue: formData.store_name}\n                    ]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: "6" } },
            [
              _c(
                "a-form-item",
                { attrs: { label: "LKL商户号" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "merchant_no",
                          {
                            initialValue: _vm.formData.merchant_no,
                            rules: [
                              {
                                pattern: /^[A-Za-z\d]{15}$/,
                                message: "请输入15位商户号"
                              }
                            ]
                          }
                        ],
                        expression:
                          "['merchant_no', {\n                        initialValue: formData.merchant_no,\n                        rules: [{pattern: /^[A-Za-z\\d]{15}$/, message: '请输入15位商户号'}]\n                    }]"
                      }
                    ],
                    attrs: { placeholder: "请输入", maxLength: 15 }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: "6" } },
            [
              _c(
                "a-form-item",
                { attrs: { label: "线下终端号" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "offline_terminal_id",
                          {
                            initialValue: _vm.formData.offline_terminal_id,
                            rules: [
                              {
                                pattern: /^[A-Za-z\d]{8}$/,
                                message: "请输入8位终端号"
                              }
                            ]
                          }
                        ],
                        expression:
                          "['offline_terminal_id', {\n                        initialValue: formData.offline_terminal_id,\n                        rules: [{pattern: /^[A-Za-z\\d]{8}$/, message: '请输入8位终端号'}]\n                    }]"
                      }
                    ],
                    attrs: { placeholder: "请输入", maxLength: 8 }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: "6" } },
            [
              _c(
                "a-form-item",
                { attrs: { label: "惠码SN号" } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "sn",
                          {
                            initialValue: _vm.formData.sn,
                            rules: [{ pattern: /^\d+$/, message: "必须为数字" }]
                          }
                        ],
                        expression:
                          "['sn', {\n                            initialValue: formData.sn,\n                            rules: [{pattern: /^\\d+$/, message: '必须为数字'}]\n                        }\n                    ]"
                      }
                    ],
                    attrs: { placeholder: "请输入" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { staticStyle: { "text-align": "left" }, attrs: { span: "12" } },
            [
              _c(
                "a-form-item",
                { attrs: { label: "创建时间" } },
                [
                  _c("DatePicker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "range_create_time",
                          {
                            initialValue: _vm.formData.range_create_time,
                            rules: [
                              { required: true, message: "请选择订单创建时间!" }
                            ]
                          }
                        ],
                        expression:
                          "['range_create_time', {initialValue: formData.range_create_time,rules: [{required: true, message: '请选择订单创建时间!'}]}]"
                      }
                    ]
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: "6" } },
            [
              _c(
                "a-form-item",
                { attrs: { label: "交易方式" } },
                [
                  _c("payType", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "pay_type",
                          { initialValue: _vm.formData.pay_type }
                        ],
                        expression:
                          "['pay_type', {initialValue: formData.pay_type}]"
                      }
                    ]
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: "6" } },
            [
              _c(
                "a-form-item",
                { attrs: { label: "交易状态" } },
                [
                  _c("OrderStatus", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "status",
                          { initialValue: _vm.formData.status }
                        ],
                        expression:
                          "['status', {initialValue: formData.status}]"
                      }
                    ]
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { style: { display: _vm.expand ? "block" : "none" } },
        [
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "归属商场" } },
                    [
                      _c("Mall", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "mall_name",
                              { initialValue: _vm.formData.mall_name }
                            ],
                            expression:
                              "['mall_name', {initialValue: formData.mall_name}]"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "商场券号" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "mallcoo_code",
                              {
                                initialValue: _vm.formData.mallcoo_code,
                                rules: [
                                  { pattern: /^\d+$/, message: "必须为数字" }
                                ]
                              }
                            ],
                            expression:
                              "['mallcoo_code', {\n                            initialValue: formData.mallcoo_code,\n                            rules: [{pattern: /^\\d+$/, message: '必须为数字'}]\n                        }]"
                          }
                        ],
                        attrs: { placeholder: "请输入" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "商圈券号" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "hami_coupon_no",
                              {
                                initialValue: _vm.formData.hami_coupon_no,
                                rules: [
                                  { pattern: /^\d+$/, message: "必须为数字" }
                                ]
                              }
                            ],
                            expression:
                              "['hami_coupon_no', {\n                            initialValue: formData.hami_coupon_no,\n                            rules: [{pattern: /^\\d+$/, message: '必须为数字'}]\n                        }]"
                          }
                        ],
                        attrs: { placeholder: "请输入" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "归属商圈" } },
                    [
                      _c("BizDistrict", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "district_name",
                              { initialValue: _vm.formData.district_name }
                            ],
                            expression:
                              "['district_name', {initialValue: formData.district_name}]"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "手机号" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "mobile",
                              {
                                initialValue: _vm.formData.mobile,
                                rules: [
                                  { pattern: /^\d+$/, message: "必须为数字" }
                                ]
                              }
                            ],
                            expression:
                              "['mobile', {\n                            initialValue: formData.mobile,\n                            rules: [{pattern: /^\\d+$/, message: '必须为数字'}]\n                        }]"
                          }
                        ],
                        attrs: { placeholder: "请输入" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "订单金额(元)" } },
                    [
                      _c("PriceRange", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "total_amount",
                              { initialValue: _vm.formData.total_amount }
                            ],
                            expression:
                              "['total_amount', {initialValue: formData.total_amount}]"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "实收金额(元)" } },
                    [
                      _c("PriceRange", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "pay_amount",
                              { initialValue: _vm.formData.pay_amount }
                            ],
                            expression:
                              "['pay_amount', {initialValue: formData.pay_amount}]"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "折扣金额(元)" } },
                    [
                      _c("PriceRange", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "discount_amount",
                              { initialValue: _vm.formData.discount_amount }
                            ],
                            expression:
                              "['discount_amount', {initialValue: formData.discount_amount}]"
                          }
                        ],
                        attrs: { max: 0 }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                {
                  staticStyle: { "text-align": "left" },
                  attrs: { span: "12" }
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "交易时间" } },
                    [
                      _c("DatePicker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "range_trade_time",
                              { initialValue: _vm.formData.range_trade_time }
                            ],
                            expression:
                              "['range_trade_time', {initialValue: formData.range_trade_time}]"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                {
                  staticStyle: { "text-align": "left" },
                  attrs: { span: "12" }
                },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "退款时间" } },
                    [
                      _c("DatePicker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "range_refund_time",
                              { initialValue: _vm.formData.range_refund_time }
                            ],
                            expression:
                              "['range_refund_time', {initialValue: formData.range_refund_time}]"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "商圈折扣金额" } },
                    [
                      _c("PriceRange", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "hami_discount",
                              { initialValue: _vm.formData.hami_discount }
                            ],
                            expression:
                              "['hami_discount', {initialValue: formData.hami_discount}]"
                          }
                        ],
                        attrs: { max: 0 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "tradeNo" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "trade_no",
                              { initialValue: _vm.formData.trade_no }
                            ],
                            expression:
                              "[\n                        'trade_no',\n                        {initialValue: formData.trade_no}\n                        ]"
                          }
                        ],
                        attrs: { placeholder: "请输入" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "交易类型" } },
                    [
                      _c("PayChannel", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "pay_channel",
                              { initialValue: _vm.formData.pay_channel }
                            ],
                            expression:
                              "['pay_channel', {initialValue: formData.pay_channel}]"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "会员名" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "nick_name",
                              { initialValue: _vm.formData.nick_name }
                            ],
                            expression:
                              "['nick_name', {initialValue: formData.nick_name}]"
                          }
                        ],
                        attrs: { placeholder: "请输入" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "会员卡号" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "user_name",
                              { initialValue: _vm.formData.user_name }
                            ],
                            expression:
                              "['user_name', {initialValue: formData.user_name}]"
                          }
                        ],
                        attrs: { placeholder: "请输入" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "机构名称" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "comp_org_name",
                              { initialValue: _vm.formData.comp_org_name }
                            ],
                            expression:
                              "[\n                        'comp_org_name',\n                        {initialValue: formData.comp_org_name}\n                        ]"
                          }
                        ],
                        attrs: { placeholder: "请输入" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: "6" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "order_ID" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "order_no",
                              { initialValue: _vm.formData.order_no }
                            ],
                            expression:
                              "[\n                        'order_no',\n                        {initialValue: formData.order_no}\n                        ]"
                          }
                        ],
                        attrs: { placeholder: "请输入" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { style: { textAlign: "right" }, attrs: { span: "24" } },
            [
              _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleReset }
                    },
                    [_vm._v("\n                    重置\n                ")]
                  ),
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        disabled: _vm.loading
                      }
                    },
                    [_vm._v("\n                    查询\n                ")]
                  ),
                  _c(
                    "a",
                    { staticClass: "expand", on: { click: _vm.toggle } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.expand ? "收起" : "展开") +
                          " "
                      ),
                      _c("a-icon", {
                        attrs: { type: _vm.expand ? "up" : "down" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }